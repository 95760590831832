import styled from "styled-components"

const SummaryContainerStyled = styled.section`
  padding: 1em;

  & p.title-summary {
    color: ${({ theme }) => theme.palette.grey60};
    font-size: 1.125rem;
    text-align: center;
    margin: 0.5rem 0 1rem 0;
  }
`
export default SummaryContainerStyled
