import { Fragment, FunctionComponent, useEffect, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import { useParams } from "react-router-dom"
import UseGetUserById from "hooks/UseGetUserById"
import {
  detailUserScreenStyle
} from "overrides/theme/entel/base/pages/UsersScreens/styles/DetailUserScreen.style"

// icons
import user_icon_profile from "assets/icons/entel/user_icon_alt.svg"

const DetailUserScreen: FunctionComponent<any> = () => {
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const { userId }: any = useParams()
  const { loading, data } = UseGetUserById(userId)

  useEffect(() => {
    displayHeaderRouteName.dispatch(`Perfil de '${userId}'`)
    //eslint-disable-next-line
  }, [userId])

  const getSources = () => {
    const sources = (data?.groups || []).filter((group: any) => group?.type === "Source" || group?.type === "source")
    if (sources.some((src: any) => src.id === "ALLSOURCES")) return "Todos los sources"
    return sources.map(({id}: any) => `${id} `)
  }

  return (
    <Fragment>
      {loading && <Loading defaultOpened={loading} />}
      <div className="detail_user_screen__wapper_container">
        <div
          className={
            Boolean(data?.deleted)
              ? "detail_user_screen__container detail_user_screen__border_red"
              : "detail_user_screen__container"
          }
        >
          <div className="detail_user_screen__body">
            <img src={user_icon_profile} alt="X" className="detail_user_screen__profile_icon" />
            <div className="detail_user_screen__info">
              <h4 className="dus__info_name">{data?.name}</h4>
              <p className="dus__info_id">{data?.id}</p>
              <p className="dus__info_label">Rol</p>
              <p className="dus__info_role">{data?.role}</p>
            </div>
            <div className="detail_user_screen__cont-more-info">
              <p className="dus__info_label">Source(s)</p>
              <p className="dus__info_id">{getSources()}</p>
              <p className="dus__info_label">Correo</p>
              <p className="dus__info_id">{data?.email}</p>
            </div>
            <div className="detail_user_screen__enabled_info">
              <p className={Boolean(data?.deleted) ? "dus__info_disabled_label" : "dus__info_enabled_label"}>
                Usuario {Boolean(data?.deleted) ? "Deshabilitado" : "Habilitado"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{detailUserScreenStyle}</style>
    </Fragment>
  )
}

export default DetailUserScreen
