import { useContext, useState } from "react"
import { AuthContext } from "context/context/AuthContext"
import { manifestSearch } from "services/ListenerServices"
import Logger from "classes/Logger"
import useCancelToken from "hooks/UseCancelToken"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { IRangeExtend } from "interfaces/IContentCelendar"
import { formatDate } from "helpers/FormatDateHelper"

const UseManifest = () => {

  const [list, setList] = useState([])
  const [initial, setInitial] = useState(true)
  const { newCancelToken } = useCancelToken()
  const [loading, setLoading] = useState(false)
  const { errorHander } = useContext(GlobalContext)

  const {
    state: { user },
  } = useContext(AuthContext)

  const searchManifest = async (rangeDate: IRangeExtend) => {
    try {
      setInitial(false)
      setLoading(true)

      let body: any = {
        from: rangeDate ? `${rangeDate.startDate && formatDate(rangeDate.startDate, "yyyy-MM-dd")}` : null,
        to: rangeDate ? `${rangeDate.endDate && formatDate(rangeDate.endDate, "yyyy-MM-dd")}T23:59:59` : null,
        sources: user?.currentSources && user?.currentSources?.length > 1  ? [user?.role] : [user?.currentSources[0]]
      }

      const {
        data: { code, message },
      } = await manifestSearch(body, newCancelToken())

      if (code === TransactionCodeEnum.ok) {
        setList(message)
        setLoading(false)
      }else{
        Logger.error(code)
        errorHander?.dispatch({ hasError: true, code: code })
        setLoading(false)
      }
    } catch (error: any) {
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
      setLoading(false)
      setInitial(false)
    }
  }

  return {
    list,
    initial,
    loading,
    searchManifest,
  }
}

export default UseManifest
