export const buttonOptionCalendarStyle = `
  .button_option_calendar__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    gap: 0.5rem;
    color: #ffffff;
    background: #3E405B;
    border-radius: 12px;
    transition: all 500ms ease;
    padding: 2px 20px;
  }
  .button_option_calendar__active {
    background: #002eff;
  }
  .button_option_calendar__container:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  .button_option_calendar__container .button_option_calendar__icon {
    font-size: 1rem;
  }
`

export const contentCalendarStyle = `
  .content_calendar__container {
    padding: 1.5rem 1rem 0.5rem 1rem;
    margin: 1rem;
    background: #ffffff;
    border-radius: 8px;  
    font-family: Poppins;
  }
  .content_calendar__container .content_calendar__content_option_calendar {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
  .content_calendar__container .content_calendar__content_calendar_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content_calendar__content_calendar_body > button {
    margin-bottom: 1rem;
    width: 100%;
  }
  .content_calendar__content_calendar_submit_button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    border-radius: 8px;
    border: 0;
    height: 44px;
    transition: all 500ms ease-out;
    color: white;
    padding: 0.5rem 1rem;
    position: relative;
    font-size: 1rem;
    font-weight: 700;
    user-select: none;
    background: #002eff;
    border: 1px solid #002eff;
    color: #ffffff;
  }
  .content_calendar__content_calendar_submit_button > svg {
    font-size: 2rem;
  }
  .content_calendar__content_calendar_submit_button:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
`