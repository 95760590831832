import { MouseEvent, useRef, useState, Fragment } from "react"
import Modal from "components/Commons/Modal/Modal"
import ContentCalendar from "overrides/theme/shiperto/base/components/Core/Order/FilterDate/ContentCalendar/ContentCalendar"
import { orderFilterStyle } from "overrides/theme/shiperto/base/components/Core/Order/FilterDate/OrderFilterDate.style"
import { IconCalendar, IconArrowDownMain, IconClose } from "theme/common/Icon.styled"
import { IOrderFilterDate, IRangeExtend } from "interfaces/IContentCelendar"
import { formatDate } from "helpers/FormatDateHelper"

const OrderFilterDate = ({ onSelectedRangeDate }: IOrderFilterDate) => {
  const formatString = "yyyy-MM-dd"

  const [rangeSelected, setRangeSelected] = useState<IRangeExtend>()

  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  //events
  const handleClickCalendar = () => refModal.current?.open()

  const handleChangeRangeDate = (range: IRangeExtend) => {
    setRangeSelected(range)
    onSelectedRangeDate(range)
  }

  const handleClickClearFilter = (e: MouseEvent<SVGAElement>) => {
    e.stopPropagation()
    setRangeSelected(undefined)
    onSelectedRangeDate(undefined)
  }

  return (
    <Fragment>
      <div className="order_filter_date__container">
        <div className="order_filter_date__content_date" onClick={handleClickCalendar}>
          <IconCalendar className="order_filter_date__calendar" />
          <div className="order_filter_date__info_date">
            {rangeSelected ? (
              <>
                <span>
                  {`${
                    rangeSelected.startDate && formatDate(rangeSelected.startDate, formatString)
                  } / ${rangeSelected.endDate && formatDate(rangeSelected.endDate, formatString)}`}
                </span>
                <IconClose onClick={handleClickClearFilter} />
              </>
            ) : (
              <>
                <span>Seleccione...</span>
                <IconArrowDownMain />
              </>
            )}
          </div>
        </div>
      </div>
      <style jsx>{orderFilterStyle}</style>
      <Modal ref={refModal}>
        <ContentCalendar
          closeModal={refModal.current?.close}
          onChangeRangeDate={handleChangeRangeDate}
          initRange={rangeSelected}
        />
      </Modal>
    </Fragment>
  )
}

export default OrderFilterDate
