import axiosClient from "config/axiosClient"
import { IResponseService } from "interfaces/IResponseService"
import { IOrder } from "interfaces/IOrder"
import configEnv from "config/configEnv"
import { AxiosResponse, CancelToken } from "axios"

const clientAxios = axiosClient("OOM")
const clientAxiosOITM = axiosClient("OITM")

export const getOrderById = async (
  orderId: string,
  cancelToken: CancelToken,
  status: boolean = false,
): Promise<AxiosResponse<IResponseService<IOrder>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/${orderId}?status=${status}`

  const response = await client.get<IResponseService<IOrder>>(url, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const updateOrderById = async (
  orderId: string | undefined,
  body: object,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/${orderId}`

  const response = await client.post<IResponseService<string>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const createOrderService = async (
  channel: string | undefined,
  body: object,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}?channel=${channel}`

  const response = await client.put<IResponseService<any>>(url, [body], {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const massiveLoadOrders = async (
  body: any,
  params: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/massive_load`

  const response = await client.post<IResponseService<string>>(url, body, {
    headers: { "Content-Type": "multipart/form-data" },
    params,
    cancelToken,
  })

  return response
}

export const getTicketOrderServiceProd = async (
  orderId: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<any>> => {
  const client = clientAxiosOITM.getClient()

  const url = `${configEnv.urlTicket}/${orderId}`

  const response = await client.get<any>(url, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}
