import { ComponentsType } from "types/CommonsTypes"
import { lazy } from "react"

const ManualTransition = lazy(() => import("components/Core/ProductTransition/ManualTransition"))
const TagConsolidation = lazy(() => import("webapp-clients/PRVL/Components/TagConsolidation"))
const TagCustomOrder = lazy(() => import("webapp-clients/PRVL/Components/TagCustomOrder"))
const CustomFilters = lazy(() => import("webapp-clients/PRVL/Components/CustomFilters"))
const TicketOrder = lazy(() => import("webapp-clients/PRVL/Components/TicketOrder"))

export const privilege: ComponentsType = {
  ProductTransition: ManualTransition,
  TagConsolidation,
  TagCustomOrder,
  CustomFilters,
  TicketOrder
}
