import AppRouter from "routes/AppRouter"
import AuthProvider from "context/providers/AuthProvider"
import GlobalStyled from "theme/base/GlobalStyled"
import { ThemeProvider } from "styled-components"
import { theme } from "theme/ThemeUI"
import { Auth0Provider } from "@auth0/auth0-react"

const AppContainer = () => {
  return (
    <Auth0Provider
      clientId="fvegw4mxZuaXbcJEHWrO8Q5GwHJHl6bx"
      domain="dev-anongdl0uc06cwqh.us.auth0.com"
      authorizationParams={{
        redirect_uri: `${window.location.origin}/callback`,
      }}
    >
      <AuthProvider>
        <ThemeProvider theme={theme.default}>
          <GlobalStyled />
          <AppRouter />
        </ThemeProvider>
      </AuthProvider>
    </Auth0Provider>
  )
}

export default AppContainer
