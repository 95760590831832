import { useContext } from "react"
import { ROUTE_ORDER } from "helpers/constHelper"
import { AuthContext } from "context/context/AuthContext"
import { sideBarData } from "theme/assets/Data/SideBarData"
import { summaryOptions } from "theme/assets/Data/SummaryOptionsData"
import { IMenu } from "interfaces/IMenu"
import { useTranslation } from "react-i18next"
import { keyBy } from "helpers/UtilsHelper"
import useEnv from "hooks/core/useEnv"

/*

Hook to handle the Menu of the User (List of states available)


*/

const UseGetMenuUser = () => {
  const { t } = useTranslation()

  let {
    state: { optionsMenuAccess },
  } = useContext(AuthContext)

  const { sg } = useEnv()

  /**
   * the getMenuSideBar return Array with the information of siderBarData
   *
   * @returns {siderBarData[]}
   */
  const getMenuSideBar = () => {
    const indexMenuOrders = sideBarData.findIndex(({ path }) => path.includes(ROUTE_ORDER))

    if (indexMenuOrders !== -1) {
      sideBarData[indexMenuOrders].subNav = getMenuSummaryOrder("")
    }

    return sideBarData
  }

  /*

  Return Array with the information required to display the List of States 

  @param {String} status - Order/SG status of OMNIX 
  @return {<IMenu>[]} Array with the required information


  */
  const getMenuSummaryOrder = (shippingTypeOption: string = ""): Array<IMenu> => {
    const keyBySummaryOptions = keyBy(summaryOptions, "status")

    if (shippingTypeOption !== "") {
      optionsMenuAccess = optionsMenuAccess.filter((_option: any) =>
        sg.get_sg_shippingtype_status_list(shippingTypeOption).includes(_option.statusOrder),
      )
    }

    return optionsMenuAccess?.map((option) => {
      const summaryOption = keyBySummaryOptions[option.statusOrder]
      return {
        ...summaryOption,
        title: t(`${summaryOption?.status}.title`),
        subTitle: t(`${summaryOption?.status}.subTitle`),
        path: summaryOption?.path || "",
        optionCount: option.optionCount,
      }
    })
  }

  return {
    getMenuSideBar,
    getMenuSummaryOrder,
  }
}

export default UseGetMenuUser
