import { Suspense } from "react"
import useMulticlient from "hooks/core/useMulticlient"

const Multiclient = ({ path_override, has_children, children, ...props }: any) => {
  let Component = useMulticlient().get_component(path_override)

  return <Suspense fallback={null}>{has_children ? <Component {...props}>{children}</Component> : <Component {...props} />}</Suspense>
}

export default Multiclient
