export const tagPrintBodyStyle = `
  .pt__print-tag__container {
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .pt__flex_column {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
  }
  .pt__flex_row {
    display: flex;
    flex-direction: row;
  }
  .pt__title-text {
    font-size: 16px;
    font-weight: 500;
  }
  .pt__normal-text {
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
  }
  .pt__small-text {
    display: inline-block;
    font-size: 8px;
    font-weight: 500;
  }
  .pt__visibility_hidden {
    visibility: hidden;
  }
  .pt__print-tag__qrcode {
    width: 500px;
    margin-top: 50px;
  }
`
export const rightSideStyle = `
  .pt__print-tag__right {
    width: 260px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .pt__right_body1 {
    width: inherit;
    height: 32px;
    border: 1px solid #000;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
  }
  .pt__right_body1 .pt__right_body1_box1, .pt__right_body1 .pt__right_body1_box2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 8px 0;
    text-align: left;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .pt__right_body1 .pt__right_body1_box1 {
    width: 60%;
    border-right: 1px solid #000;
  }
  .pt__right_body1 .pt__right_body1_box2 {
    width: 40%;
  }
  .pt__right_body2 {
    width: inherit;
    height: 320px;
    border: 1px solid #000;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .pt__right_body2_box1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .pt__right_body2_box1_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .pt__right_body2_box1_header .pt__small-text:last-child {
    padding-top: 4px;
  }
  .pt__right_body2_box1_header_title_cont {
    padding: 0 8px 5px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    border-bottom-right-radius: 5px;
    margin-right: 10px;
  }
  .pt__right_body2_box1_body {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .pt__right_body2_box1_body .pt__right_body2_box1_body_child {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
  }
  .pt__right_body2_box1_body .pt__right_body2_box1_body_child:first-child {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .pt__right_body2_box1_body .pt__right_body2_box1_body_child:last-child {
    border-bottom: 1px solid #000;
  }
  .pt__right_body2_box1_container_check {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-left: 8px;
    cursor: pointer;
    font-size: 8px;
  }
  .pt__right_body2_box1_container_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .pt__right_body2_box1_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 15px;
    border: 1px solid #000;
  }
  .pt__right_body2_box1_container_check_double {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .pt__right_body2_box1_container_check_double .pt__right_body2_box1_container_check:first-child {
    padding-left: 8px;
  }
  .pt__right_body2_box2 {
    width: 100%;
    height: 90px;
    padding: 0 8px;
    border-bottom: 1px solid #000;
  }
  .pt__right_body2_box3 {
    position: relative;
    width: 100%;
    height: 120px;
    padding: 0 8px
  }
  .pt__right_body2_box3_name_cont {
    position: absolute;
    bottom: 0;
    width: 95%;
    text-align: center;
  }
`

export const leftSideStyle = `
  .pt__flex_column {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
  }
  .pt__print-tag__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .pt__tag__left-first {
    margin-bottom: 16px;
  }
  .pt__tag__left-first .pt__logo {
    width: 140px;
  }
  .pt__tag__left-first .pt__info-shiperto {
    gap: 0;
  }
  .pt__tag__left-first span {
    font-size: 8px;
    font-weight: 500;
    padding-left: 10px;
  }
  .pt__tag__left-last {
    width: 170px;
    height: 320px;
    padding: 8px;
    border: 1px solid #000;
    border-radius: 5px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .pt__number-guide {
    font-size: 14px;
    font-weight: 600;
  }
  .pt__title-text {
    font-size: 16px;
    font-weight: 500;
  }
  .pt__normal-text {
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    line-height: 0px;
  }
`

export const centerSideStyle = `
  .pt__print-tag__center {
    width: 570px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: 10px;
  }
  .pt__center_body1 {
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .pt__print-tag__center_body1_os {
    text-align: left;
    width: 100px;
  }
  .pt__center_body2 {
    width: inherit;
    height: 320px;
    display: flex;
    flex-direction: column;
  }
  .pt__body2_box {
    width: inherit;
    height: 106.6px;
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
  }
  .pt__body2_box:first-child {
    border-top-left-radius: 5px;
    border-bottom: none;
  }
  .pt__body2_box:last-child {
    border-bottom-left-radius: 5px;
    border-top: none;
  }
  .pt__body2_box1_header {
    padding: 8px 0 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .pt__body2_box1_header_cont_zipcode {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -12px;
  }
  .pt__body2_box1_header_cont_zipcode .pt__code {
    width: 100px;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom-left-radius: 5px;
    margin-left: 10px;
    padding-left: 8px;
  }
  .pt__body2_box1_body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 6px 8px 10px;
  }
  .pt__body2_box_align {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .pt__body2_box1_body .pt__body2_box_align .pt__flex_column {
    width: 33%;
    height: 100%;
    text-align: left;
  }
  .pt__body2_box1_body .pt__body2_box_align .pt__flex_column:nth-child(2) {
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8px;
  }
  .pt__body2_box1_body .pt__flex_column:nth-child(2) {
    height: 100%;
    width: 50%;
  }
  .pt__other_info_cont {
    width: 220px;
    height: 100%;
  }
  .pt__zone-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 50px;
  }
  .pt__last_center_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .pt__last_center_div_column {
    height: 100%;
    text-align: left;
  }
  .pt__last_center_div_column:nth-child(1) {
    width: 38%;
  }
  .pt__last_center_div_column:nth-child(2) {
    width: 30%;
  }
  .pt__last_center_div_column:nth-child(3) {
    width: 36%;
  }
  .pt__last_column1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .pt__last_column1 div {
    width: 50%;
    height: 50%;
    padding: 0 8px;
  }
  .pt__last_column1 div:nth-child(1) {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }
  .pt__last_column1 div:nth-child(2) {
    border-bottom: 1px solid #000;
  }
  .pt__last_column1 div:nth-child(3) {
    border-right: 1px solid #000;
  }
  .pt__last_column2 {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    text-align: center;
  }
  .pt__last_column3 {
    display: flex;
    flex-direction: column;
  }
  .pt__last_column3 div {
    width: 100%;
    height: 50%;
    padding: 0 8px;
  }
  .pt__last_column3 div:nth-child(1) {
    border-bottom: 1px solid #000;
  }
`