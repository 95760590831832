import styled from "styled-components"

export const ContainerFiles = styled.section`
  background: ${({ theme }) => theme.palette.grey5};
  border-radius: 0 0 10px 10px;
  width: 100%;
  transition: all 0.3s ease-in;
  
  & .filepond--label-action {
    color: ${({ theme }) => theme.palette.grey90};
    font-weight: bold;
  }
  
  & .filepond--drop-label {
    background: ${({ theme }) => theme.palette.grey5};
    cursor: pointer;
  }

  

  & .filepond--file-action-button {
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.palette.primary};
    }
  }

  & .filepond--file {
    color: ${({ theme }) => theme.palette.grey90};
    background: ${({ theme }) => theme.palette.grey5};
  }
  
  & .filepond--image-preview {
    background: ${({ theme }) => theme.palette.grey5};
  }

  & .filepond--image-preview-overlay-idle {
    color: transparent;
  }

  & .filepond--credits {
    display: none;
  }
`
