import styled from "styled-components"
import { CardItemStyled } from "theme/common/CardItem.styled"
import { boxShadow, displays, textuppercase } from "theme/base/Variables"
import { respondTo } from "theme/base/Mixins"
import { animateBubble } from "theme/base/Animations"

const CardOptionStyled = styled(CardItemStyled)`
  align-items: center;
  justify-content: unset;
  gap: 10px;
  background: white;
  box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.12);
  padding-left: 0.5rem;
  position: relative;
  height: 90px;
  transition: box-shadow 0.3s;

  &.topseller {
    background-color: #E92070;

    & .section-count {
      & .icon-option, & .option-count {
        color: white;
      }
    }

    & .section-title {
      & .option-name, & .icon-arrow {
        color: white;
      }
    }

  }

  &:hover {
    ${boxShadow.shadow04}
  }

  & .section-count {
    ${displays.flexColumn};

    ${respondTo.sm`
      flex-direction: row;
      gap: 0.7rem;
    `}

    & .icon-option {
      color: ${({ theme }) => theme.palette.grey50};
      font-size: 2rem;
    }
    & .option-count {
      color: ${({ theme }) => theme.palette.primary};
      font-size: 1.8rem;
      font-weight: 600;
    }
  }

  & .section-title {
    ${displays.flexBase};
    flex-grow: 1;

    & .option-name {
      ${textuppercase}
      color: ${({ theme }) => theme.palette.grey90};
      font-weight: 600;
      text-align: center;
      flex-grow: 1;
    }

    & .icon-arrow {
      color: ${({ theme }) => theme.palette.grey50};
      font-size: 2rem;
      min-width: 30px;
    }
  }

  & > mark {
    position: absolute;
    right: -5px;
    top: -15px;
    font-size: 1rem;
    line-height: 30px;
    border-radius: 30px;
    width: 32px;
    height: 32px;
    color: white;
    background: rgba(255, 0, 0, 0.7);
    text-align: center;
    min-width: 2em;
    font-weight: bold;
    animation: ${animateBubble} 1.5s;
    animation-iteration-count: 3;
    transform-origin: top center;
  }
`

export default CardOptionStyled
