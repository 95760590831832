import {Fragment} from 'react'
import {centerSideStyle} from "overrides/theme/shiperto/base/components/OrderDetail/TagPrint/styles/TagPrintBody.style"

const CenterSide = ({data}: any) => {
  const { sender, addressee, salesChannelId } = data;

  const subString = (text:string, minLength:number = 45) => {
    return text?.length < minLength ? text : `${text?.substring(0, minLength)}...`
  }

  return (
    <Fragment>
      <div className="pt__print-tag__center">
        <div className="pt__center_body1">
          <div className="pt__print-tag__center_body1_os">
            <span className="pt__title-text">{salesChannelId}</span>
          </div>
        </div>
        <div className="pt__center_body2">
          <div className="pt__body2_box">
            <div className="pt__body2_box1_header">
              <span className="pt__title-text">REMITENTE</span>
              <div className="pt__body2_box1_header_cont_zipcode">
                <p className="pt__normal-text">CODIGO POSTAL</p>
                <div className="pt__code">
                  <p className="pt__normal-text">{sender?.zipcode}</p>
                </div>
              </div>
            </div>
            <div className="pt__body2_box1_body">
              <div className="pt__body2_box_align">
                <div className="pt__flex_column">
                  <span className="pt__normal-text">NOMBRE/RAZON SOCIAL</span>
                  <span className="pt__normal-text">CIUDAD</span>
                  <span className="pt__normal-text">DIRECCION</span>
                  <span className="pt__normal-text">DEPARTAMENTO</span>
                  <span className="pt__normal-text">PAIS</span>
                </div>
                <div className="pt__flex_column">
                  <span className="pt__normal-text">{subString(sender?.name)}</span>
                  <span className="pt__normal-text">{subString(sender?.city)}</span>
                  <span className="pt__normal-text">{subString(sender?.address)}</span>
                  <span className="pt__normal-text">{subString(sender?.department)}</span>
                  <span className="pt__normal-text">{subString(sender?.country)}</span>
                </div>
                <div className="pt__flex_row pt__other_info_cont">
                  <div className="pt__flex_column">
                    <span className="pt__normal-text">NIT</span>
                    <span className="pt__normal-text">TEL</span>
                    <span className="pt__normal-text">REF</span>
                  </div>
                  <div className="pt__flex_column">
                    <span className="pt__normal-text">{sender?.nit}</span>
                    <span className="pt__normal-text">{sender?.phone}</span>
                    <span className="pt__normal-text"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt__body2_box">
            <div className="pt__body2_box1_header">
              <span className="pt__title-text">DESTINATARIO</span>
              <div className="pt__body2_box1_header_cont_zipcode">
                <p className="pt__normal-text">CODIGO POSTAL</p>
                <div className="pt__code">
                  <p className="pt__normal-text">{addressee.zipcode}</p>
                </div>
              </div>
            </div>
            <div className="pt__body2_box1_body">
              <div className="pt__body2_box_align">
                <div className="pt__flex_column">
                  <span className="pt__normal-text">NOMBRE/RAZON SOCIAL</span>
                  <span className="pt__normal-text">CIUDAD</span>
                  <span className="pt__normal-text">DIRECCION</span>
                  <span className="pt__normal-text">DEPARTAMENTO</span>
                  <span className="pt__normal-text">PAIS</span>
                </div>
                <div className="pt__flex_column">
                  <span className="pt__normal-text">{subString(addressee?.name)}</span>
                  <span className="pt__normal-text">{subString(addressee?.city)}</span>
                  <span className="pt__normal-text">{subString(addressee?.address)}</span>
                  <span className="pt__normal-text">{subString(addressee?.department)}</span>
                  <span className="pt__normal-text">{subString(addressee?.country)}</span>
                </div>
                <div className="pt__flex_row pt__other_info_cont">
                  <div className="pt__flex_column">
                    <span className="pt__normal-text">TEL</span>
                    <span className="pt__normal-text">CANTIDAD</span>
                  </div>
                  <div className="pt__flex_column">
                    <span className="pt__normal-text">{addressee?.phone}</span>
                    <span className="pt__normal-text">_</span>
                  </div>
                </div>
              </div>
              <div className="pt__zone-container">
                <span className="pt__normal-text">ZONA</span>
              </div>
            </div>
          </div>
          <div className="pt__body2_box">
            <div className="pt__last_center_div">
              <div className="pt__last_center_div_column pt__last_column1">
                <div>
                  <span className="pt__small-text">Valor</span>
                </div>
                <div>
                  <span className="pt__small-text">V Declarado</span>
                </div>
                <div>
                  <span className="pt__small-text">Peso (gr)</span>
                </div>
                <div>
                  <span className="pt__small-text">P Volumetrico (Kg)</span>
                </div>
              </div>
              <div className="pt__last_center_div_column pt__last_column2">
                <span className="pt__small-text">Observaciones</span>
              </div>
              <div className="pt__last_center_div_column pt__last_column3">
                <div>
                  <span className="pt__small-text">Fecha de entrega - Hora</span>
                </div>
                <div>
                  <span className="pt__small-text">NOMBRE COMPLETO DEL DISTRIBUIDOR</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{centerSideStyle}</style>
    </Fragment>
  )
}

export default CenterSide