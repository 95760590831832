import { Fragment, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import TagPrintBody from 'overrides/theme/entel/base/components/OrderDetail/TagPrint/TagPrintBody/TagPrintBody'
import {tagPrintContentStyle} from "overrides/theme/entel/base/components/OrderDetail/TagPrint/styles/TagPrintContent.style"

const pageStyle ="@page { size: letter landscape; }";

const TagPrintButton = ({shippingGroup, order} : any) => {
  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
    documentTitle: `tag_${shippingGroup?.id}_${order?.id}_${Date.now()}`
  });

  const data = {
    numberGuide: "",
    salesChannelId: order?.salesChannelId || "",
    creationDate: order?.creationDate || "",
    sender: {
      name: shippingGroup?.source?.name || "",
      country: order?.shippingAddress?.country || "",
      city: order?.shippingAddress?.city || "",
      department: "" || "",
      address: order?.shippingAddress?.address || "",
      phone: order?.shippingAddress?.contact?.phone || "",
      zipcode: "",
    },
    addressee: {
      name: order?.shippingAddress?.contact?.name || "",
      country: order?.shippingAddress?.country || "",
      city: order?.shippingAddress?.city || "",
      department: "" || "",
      address: order?.shippingAddress?.address || "",
      phone: order?.shippingAddress?.contact?.phone || "",
      zipcode: "",
    }
  }

  return (
    <Fragment>
      <div style={{ display: "none" }}>
        <TagPrintBody
          ref={componentRef}
          data={data}
          qrContent={shippingGroup?.id}
        />
      </div>
      <div className="pt__app-container">
        <button
          className="pt__button_print_tag"
          onClick={handlePrint}>Imprimir
        </button>
      </div>
      <style jsx>{tagPrintContentStyle}</style>
    </Fragment>
  );
}

export default TagPrintButton;