import { useContext, useEffect, useState } from "react"
import Logger from "classes/Logger"
import { request_post } from "services/OSRM"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"

const UseListSourcesOSRM = (loadSources: boolean, MAX_RECORDS_PER_PAGE: number = 10) => {
  const [data, set_data] = useState<any>([])
  const [pages, set_pages] = useState(0)
  const [page, set_page] = useState(0)
  const [loading, set_loading] = useState(true)
  const { errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()

  useEffect(() => {
    if (loadSources) {
      const getListSources = async () => {
        try {
          set_loading(true)
          let bodyReq = {
            size: MAX_RECORDS_PER_PAGE,
            from: page * MAX_RECORDS_PER_PAGE
          }
          const {
            data: { message: { pages, records } },
          } = await request_post("search/sources", bodyReq, newCancelToken())
          set_data(records)
          set_pages(pages)
          set_loading(false)
        } catch (error: any) {
          set_loading(false)
          Logger.error(error)
          if (isCancel(error)) return
    
          errorHander?.dispatch({
            hasError: true,
            code: error.response?.status || TransactionCodeEnum.server,
          })
        }
      }
      getListSources()
    }
    // eslint-disable-next-line
  }, [page])

  const handleClickPage = (pageSeleted: number) => set_page(pageSeleted)

  return {
    data,
    pages,
    page,
    loading,
    handleClickPage,
  }
}

export default UseListSourcesOSRM