import { useEffect, useReducer } from "react"
import { AuthReducerInit } from "context/inits/AuthReducerInit"
import { AuthReducer } from "context/reducers/AuthReducer"
import { AuthContext } from "context/context/AuthContext"
import { IContext } from "interfaces/IContext"
import { WEB_APP_USER } from "helpers/constHelper"
import { useTranslation } from "react-i18next"
import Logger from "classes/Logger"

/**
 * Constant AuthProvider return an Element and save user information to local storage
 * @param props IContext.
 * @return {Element} AuthContext.Provider
 */
const AuthProvider = (props: IContext) => {
  const [state, dispatch] = useReducer(AuthReducer, AuthReducerInit())
  const { i18n } = useTranslation()

  useEffect(() => {
    if (!state.user) {
      localStorage.removeItem(WEB_APP_USER)
      return
    }

    localStorage.setItem(WEB_APP_USER, JSON.stringify(state))

    if (i18n.language !== state.user.mainGroup.id) {
      i18n.changeLanguage(state.user.mainGroup.id, (err) => {
        if (err) return Logger.error("something went wrong loading", err)
      })
    }
  }, [state, i18n])

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
