import styled, { css } from "styled-components"
import { displays } from "theme/base/Variables"

const SortOrderStyled = styled.section<{ disabled?: boolean }>`
  ${displays.flexBase};
  align-items: flex-start;
  gap: 0.5rem;
  user-select: none;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
  color: ${({ theme }) => theme.palette.grey60};
  transition-property: opacity, pointer-events;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}

  & > svg {
    font-size: 2rem;
  }
`

export default SortOrderStyled
