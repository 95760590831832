import { useContext, lazy } from "react"
import { AuthContext } from "context/context/AuthContext"
import { Env, MultiClient } from "classes"
import * as themes from "overrides/layout"

const useMulticlient = () => {
  const auth: any = useContext(AuthContext)
  const [client, env] = MultiClient.get_user_env(auth.user?.mainGroup.id, Env.clients, Env.env, Env.defaults)

  const get_layout_path = (path: any, path_list: any) => {
    let path_overrided = path
    //eslint-disable-next-line
    const theme: any = themes

    for (const path_current of path_list) {
      const replaced = path_current.replaceAll("/", "?.")
      //eslint-disable-next-line
      const path_current_active = eval(`theme?.${replaced}`)

      if (!!path_current_active) {
        if (path_current_active.includes(path)) {
          path_overrided = `overrides/theme/${path_current}/${path}`
          console.log("%cuseMulticlient.js line:22 e", "color: #007acc;", path_overrided)
          break
        }
      }
    }

    return import(`../../${path_overrided}`)
  }

  const get_component: any = (path: string) => {
    const path_list = [`${client}/${env}`, `${client}/base`, `omnix/${env}`, `omnix/base`]

    return lazy(() => get_layout_path(path, path_list))
  }

  return { get_component }
}

export default useMulticlient
