import { useEffect, useState } from "react"
import RolePermission from "overrides/theme/entel/base/pages/UsersScreens/RolePermissionList.json"
import LabelSwitch from "overrides/theme/entel/base/components/Commons/LabelSwitch/LabelSwitch"

const event_change_permission_state = (e: any, set_user_permission_list: any, system_permission_list: any) => {
  let value = JSON.parse(e.target.value)
  const mode = !e.target.checked ? "del" : "add"
  value.checked = !value.checked

  if (mode === "add") {
    if (!!value.parent) {
      set_user_permission_list((current: any) => [...current, value])
    } else {
      let values = system_permission_list.filter(
        (permission: any) => permission.parent === value.id || permission.id === value.id,
      )

      set_user_permission_list((current: any) => {
        let o: any = [...current, ...values]
        let y: any = []

        o.forEach((element: any) => {
          const found = y.find((yy: any) => yy.id === element.id && yy.parent === element.parent)
          if (!found) {
            y.push(element)
          }
        })

        return y
      })
    }
  }

  if (mode === "del") {
    if (!!value.parent) {
      set_user_permission_list((current: any) =>
        current.filter((permission: any) => value.id !== permission.id || value.parent !== permission.parent),
      )
    } else {
      set_user_permission_list((current: any) =>
        current.filter((permission: any) => permission.parent !== value.id && value.id !== permission.id),
      )
    }
  }
}

const Permission = (props: any) => {
  const { id, level, parent, checked, value, system_permission_list, set_user_permission_list } = props
  const props_to_permissions = { id, level, parent, checked, value }

  const template = (
    <div
      className={`new_user_screen__content-checked new_user_screen__content-checked-permission new_user_screen__content-checked-${level}`}
    >
      <LabelSwitch
        id={`perm--${JSON.stringify(props_to_permissions)}`}
        label={id}
        value={JSON.stringify(props_to_permissions)}
        onChange={(e: any) => event_change_permission_state(e, set_user_permission_list, system_permission_list)}
        checked={checked}
      />
    </div>
  )

  return template
}

const permission_is_checked = (x: any, user_permission_list: any) => {
  const found = user_permission_list.find((permission: any) => permission.id === x.id && permission.parent === x.parent)
  return !!found
}

const Permissions = (props: any) => {
  let template: any = []
  const { system_permission_list, set_user_permission_list, user_permission_list } = props

  for (const x of system_permission_list) {
    template.push(
      <Permission
        key={`${x.parent ?? ""}-${x.id}`}
        {...x}
        set_user_permission_list={set_user_permission_list}
        system_permission_list={system_permission_list}
        checked={permission_is_checked(x, user_permission_list)}
      />,
    )
  }

  return template
}

const format_system_permission_list = (list: any, user?: any) => {
  const o = []
  const user_permission_state = !!user ? true : false

  for (const x in list) {
    o.push({ id: x, level: 1, parent: null, checked: user_permission_state, value: x })

    if (Array.isArray(list[x])) {
      for (const xx of list[x]) {
        o.push({ id: xx, level: 2, parent: x, checked: user_permission_state, value: x })
      }
    }
  }

  return o
}

const event_change_permission_state_all = (
  e: any,
  system_permission_list: any,
  set_user_permission_list: any,
  set_user_permission_list_all: any,
) => {
  const v = e.target.checked
  set_user_permission_list_all(v)

  if (v) {
    set_user_permission_list(system_permission_list)
  } else {
    set_user_permission_list([])
  }
}

const PermissionList = (props: any) => {
  const {
    userData,
    user_permission_list,
    set_user_permission_list,
    user_permission_list_all,
    set_user_permission_list_all,
  } = props
  const [system_permission_list, set_system_permission_list] = useState<any>(null)
  const { list }: any = RolePermission

  useEffect(() => {
    if (!!userData) {
      set_user_permission_list(format_system_permission_list(userData?.rolePermissions ?? {}, true))
    }
    //eslint-disable-next-line
  }, [userData])

  useEffect(() => {
    set_system_permission_list(format_system_permission_list(list))
    //eslint-disable-next-line
  }, [user_permission_list])

  const template = (
    <div className="new_user_screen__container_permissions">
      <h4 className="new_user_screen__title_section">Permisos</h4>

      
        <div className="new_user_screen__content-checked">
          <div className="new_user_screen__content-checked new_user_screen__content-checked-title">
            <p style={{marginTop: "1rem"}}></p>
            <LabelSwitch
              id="perm--all-permissions"
              value="all-permissions"
              label="Asignar todos los permisos"
              onChange={(e: any) =>
                event_change_permission_state_all(
                  e,
                  system_permission_list,
                  set_user_permission_list,
                  set_user_permission_list_all,
                )
              }
            />
          </div>
          {system_permission_list && !user_permission_list_all && (
            <div className="new_user_screen__container_list_permissions">
              <Permissions
                system_permission_list={system_permission_list}
                set_user_permission_list={set_user_permission_list}
                user_permission_list={user_permission_list}
              />
            </div>
          )}
        </div>
      
    </div>
  )

  return template
}

export default PermissionList
