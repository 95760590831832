export const detailUserScreenStyle = `
  .detail_user_screen__border_red {
    border: solid 1px #ff1900;
  }
  .detail_user_screen__wapper_container {
    width: 100%;
    display: grid;
    place-items: center;
  }
  .detail_user_screen__container {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 1rem;
  }
  .detail_user_screen__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
  }
  .detail_user_screen__body .detail_user_screen__profile_icon {
    width: 6rem;
  }
  .detail_user_screen__info {
    padding: 1em 0;
    gap: 1.5em;
    text-align: center;
    font-family: "Poppins";
    font-size: 1rem;
  }
  .detail_user_screen__info .dus__info_name {
    color: #23203f;
    font-weight: 500;
    font-size: 1.2rem;
  }
  .detail_user_screen__info .dus__info_id,
  .detail_user_screen__info .dus__info_label {
    color: #716f87;
  }
  .detail_user_screen__info .dus__info_label {
    padding: 0.8em 0;
    font-family: "Montserrat";
    font-size: 0.8em;
  }
  .detail_user_screen__info .dus__info_role {
    font-weight: 600;
    color: #002eff;
    padding-bottom: 1em;
  }
  .detail_user_screen__cont-more-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-top: 0.5px solid #e0e0e0;
    border-bottom: 0.5px solid #e0e0e0;
    padding: 1em;
  }
  .detail_user_screen__cont-more-info .dus__info_id,
  .detail_user_screen__cont-more-info .dus__info_label {
    color: #716f87;
  }
  .detail_user_screen__cont-more-info .dus__info_label {
    padding: 0.8em 0;
    font-family: "Montserrat";
    font-size: 0.8em;
  }
  .detail_user_screen__enabled_info {
    display: grid;
    place-items: center;
    padding-top: 1em;
    font-family: "Poppins";
    font-weight: 600;
  }
  .detail_user_screen__enabled_info .dus__info_enabled_label {
    color: #45dd42;
  }
  .detail_user_screen__enabled_info .dus__info_disabled_label {
    color: #ff1900;
  }
  @media screen and (min-width: 1280px) {
    .detail_user_screen__container {
      width: 80%;
      margin-top: 3rem;
    }
    .detail_user_screen__body .detail_user_screen__profile_icon {
      margin-top: -5rem;
      width: 6rem;
    }
  }
`