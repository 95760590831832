import { FunctionComponent, useRef, Fragment, useEffect, useState, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import IPage from "interfaces/IPage"
import { ISearchResults } from "interfaces/ISearchResults"
import { RouteComponentProps, useHistory, useParams } from "react-router-dom"
import { useGetSgsByStatus } from "overrides/theme/shiperto/base/hooks"
import ErrorHandled from "overrides/theme/shiperto/base/components/Commons/Error/ErrorHandler"
import Paginate from "overrides/theme/shiperto/base/components/Commons/Pagination/Paginate"
import SearchForm from "overrides/theme/shiperto/base/components/SearchForm/SearchForm"
import OrderList from "overrides/theme/shiperto/base/components/Core/Order/OrderList"
import {searchResultsScreenStyle} from "overrides/theme/shiperto/base/pages/SearchResults/SearchResultsScreen.style"
import Loading from "components/Commons/Loading/Loading"
import { useEnv } from "hooks"

const SearchResultsScreen: FunctionComponent<IPage & RouteComponentProps<ISearchResults>> = () => {
  const { query, optionFilter }: any = useParams()
  const { permissions } = useEnv()
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const history = useHistory()
  const [data, set_data] = useState(null)
  const [pages, set_pages] = useState(0)
  const [page, set_page] = useState(0)
  const [loading, set_loading] = useState(false)
  const refElemOrders = useRef<HTMLDivElement>(null)
  const { sg_group_by, get_sg_list_grouped_by_date } = useGetSgsByStatus()

  useEffect(() => {
    displayHeaderRouteName.dispatch(`Busqueda por: ${query}`)
    //eslint-disable-next-line
  }, [query])

  useEffect(() => {
    const post_request = async () => {
      set_loading(true)
      const res: any = await get_sg_list_grouped_by_date(page)
      const rest_data: any = sg_group_by(res.data.message.records)
      set_pages(res.data.message.pages)
      set_data(rest_data)
      set_loading(false)
    }

    post_request()
    //eslint-disable-next-line
  }, [query, optionFilter, page])

  const handleClickPage = (pageSeleted: number) => set_page(pageSeleted)

  const handleClickSearch = (e: any) => {
    if (!!e.query) history.push(`/search/${e.optionFilter}/${e.query}`)
  }

  return (
    <Fragment>
      <div className="search-results-screen__container">
      {permissions.get_permission("global", "block-search-results") && <SearchForm
          search={query}
          optionFilter={optionFilter}
          onClickSearch={handleClickSearch}
          isLoading={loading}
          backButton={true}
        />}
        {loading ? (
          <Loading defaultOpened={loading} />
        ) : (
          <>
            {Object.keys(data || {}).length ? (
              <div>
                <div className="search-results__order-list-container">
                  <OrderList ordersGroup={data} currentDisplayList="grid" showStatus />
                </div>
                {pages > 1 && (
                  <div className="order-screen__paginate-container">
                    <Paginate
                      pageCount={pages}
                      onChangePage={handleClickPage}
                      elementFocus={refElemOrders}
                      pageInit={page}
                    />
                  </div>
                )}
              </div>
            ) : (
              <ErrorHandled orders={data} message="Sin resultados para esta busqueda" />
            )}
          </>
        )}
      </div>
      <style jsx>{searchResultsScreenStyle}</style>
    </Fragment>
  )
}

export default SearchResultsScreen
