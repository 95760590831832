import styled, { css } from "styled-components"
import { displays } from "theme/base/Variables"

const baseStyledSign = css`
  ${displays.flexBase}
  background-color: ${({ theme }) => theme.palette.grey85};
  padding-left: 0.5rem;
  & span {
    font-size: 1rem;
    margin-left: 1rem;
    text-transform: uppercase;
  }
`

const SideBarStyled = styled.nav<{ sidebar: boolean }>`
  background: ${({ theme }) => theme.palette.grey75};
  color: white;
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  transform: ${({ sidebar }) => (sidebar ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  font-size: 1rem;

  & .sidebar-wraper {
    width: 100%;

    & .header-sidebar {
      ${baseStyledSign}
      height: 80px;
      font-size: 1.5rem;
      font-weight: 600;
    }

    & .option-side-bar {
      margin: 20px;
    }

    hr {
      border: 1px solid #979797;
      margin-top: 2.5rem;
    }

    & .content-sign-out {
      ${baseStyledSign}
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 46px;
      & svg {
        font-size: 2rem;
      }
    }
  }
`
export default SideBarStyled
