export const OrderDetailModalActionStyle = `
  .order_detail_modal_action__root {
    display: grid;
    place-items: center;
  }
  .order_detail_modal_action__main_cont {
    width: 95%;
    background: white;
    border: 1px solid #EE7628;
    border-radius: 0.5em;
    padding: 1.5em;
    display: grid;
    place-items: center;
    gap: 1em;
  }
  .order_detail_modal_action__cont_title {
    text-align: center;
    gap: 0.5em;
  }
  .order_detail_modal_action__cont_title .order_detail_modal_action__cont_title_main {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.2rem;
  }
  .order_detail_modal_action__cont_title .order_detail_modal_action__cont_title_main span {
    color: #DA5600;
  }
  .order_detail_modal_action__cont_title .order_detail_modal_action__cont_title_descr {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1rem;
    color: var(--color-main-gray);
  }
  .order_detail_modal_action__cont_body {
    display: flex;
    width: 100%;
  }
  .order_detail_modal_action__cont_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  .order_detail_modal_action__btn_no {
    background: #E5E5E5;
    color: var(--color-main-gray);
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
  }
  .order_detail_modal_action__btn_yes {
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background: linear-gradient(171.14deg, #EEAB28 3.14%, #EE7628 20.68%, #DA5600 103.69%);
    box-shadow: 0px 4px 8px rgba(181, 185, 255, 0.4);
    border-radius: 2rem;
    padding: 0.5rem 1.4rem;
    margin-left: 0.5rem;
  }
  @media screen and (min-width: 768px) {
    .order_detail_modal_action__main_cont {
      min-width: 600px;
    }
  }
`

export const FormRescheduleActionStyle = `
  .form_reschedule_action__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .form_reschedule_action__main .form_reschedule_action__input_form {
    width: 100%;
    height: 3rem;
    background-color: #FAE5B0;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 1rem;
    border-radius: 1.5rem;
    padding: 0 2em;
  }
  .form_reschedule_action__main textarea.form_reschedule_action__input_form {
    padding: 1em 2em;
    height: 5rem;
    resize: none;
  }
  .form_reschedule_action__main .form_reschedule_action__input_form:disabled {
    background-color: #D6C496;
    cursor: not-allowed;
  }
  .order_detail_modal_action__cont_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  .order_detail_modal_action__btn_no {
    background: #E5E5E5;
    color: var(--color-main-gray);
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
  }
  .order_detail_modal_action__btn_yes {
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background: linear-gradient(171.14deg, #EEAB28 3.14%, #EE7628 20.68%, #DA5600 103.69%);
    box-shadow: 0px 4px 8px rgba(181, 185, 255, 0.4);
    border-radius: 2rem;
    padding: 0.5rem 1.4rem;
    margin-left: 0.5rem;
  }
`