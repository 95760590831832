import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  OrderDetailModalActionStyle,
  FormCancelActionStyle,
} from "overrides/theme/entel/base/pages/DetailOrderScreens/styles/OrderDetailModalAction.style"
import { useForm } from "react-hook-form"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { useParams } from "react-router-dom"
import get_cancel_reasons from "overrides/theme/entel/base/helpers/cancel_reasons"

const FormSelectAction = ({ set_transport_reference }: any) => {
  const { t } = useTranslation()
  const { register, watch } = useForm<any>()
  const transport_reference = watch("transportReference")

  useEffect(() => {
    transport_reference && set_transport_reference(transport_reference)
    // eslint-disable-next-line
  }, [transport_reference])

  return (
    <>
      <form className="form_cancel_action__main">
        <input
          {...register("transportReference")}
          type="text"
          className="form_cancel_action__input_form"
          placeholder={t("Courier...")}
        />
      </form>
      <style jsx>{FormCancelActionStyle}</style>
    </>
  )
}

const FormCancelAction = ({ close, action, confirmAction, cancel_reasons, set_reason_cancel }: any) => {
  const { t } = useTranslation()
  const { register, watch } = useForm<any>()
  const cancel_reason = watch("reason")

  useEffect(() => {
    cancel_reason && set_reason_cancel(cancel_reason)
    // eslint-disable-next-line
  }, [cancel_reason])

  const is_there_cancel_options = Boolean(cancel_reasons?.length)

  return (
    <>
      <form className="form_cancel_action__main">
        {is_there_cancel_options ? (
          <select {...register("reason")} className="form_cancel_action__input_form form_cancel_action__select_form">
            <option value="" selected disabled>
              Seleccione motivo de cancelación...
            </option>
            {cancel_reasons.map((reason: any, i: number) => (
              <option key={i} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </select>
        ) : null}
        <div className="order_detail_modal_action__cont_buttons">
          <button type="button" className="order_detail_modal_action__btn_no" onClick={close}>
            No
          </button>
          <button
            className="order_detail_modal_action__btn_yes"
            type="button"
            onClick={() => {
              confirmAction()
              close()
            }}
          >
            {`Si, ${t(`${action.action}`)} Órden`}
          </button>
        </div>
      </form>
      <style jsx>{FormCancelActionStyle}</style>
    </>
  )
}

const OrderDetailModalAction = ({ action, confirmAction, close, set_reason_cancel, set_transport_reference }: any) => {
  const { t } = useTranslation()
  const isCancel = action.action === "cancel"
  const isAssigned = action.action === "select"
  const statusses: any = translatesObj
  const { type, shippingtype }: any = useParams()
  const cancel_reasons = get_cancel_reasons(type, shippingtype)

  return (
    <>
      <div className="order_detail_modal_action__root">
        <div className="order_detail_modal_action__main_cont">
          <div className="order_detail_modal_action__cont_title">
            <p className="order_detail_modal_action__cont_title_main">
              ¿Desea aplicar <span>"{t(statusses[action.action])}"</span> a esta órden?
            </p>
            <p className="order_detail_modal_action__cont_title_descr">Esta acción NO se puede deshacer</p>
          </div>
          <div className="order_detail_modal_action__cont_body">
            {isCancel && (
              <FormCancelAction
                action={action}
                close={close}
                confirmAction={confirmAction}
                cancel_reasons={cancel_reasons}
                set_reason_cancel={set_reason_cancel}
              />
            )}
            {isAssigned && (
              <FormSelectAction
                action={action}
                close={close}
                confirmAction={confirmAction}
                set_transport_reference={set_transport_reference}
              />
            )}
          </div>
          {!isCancel && (
            <div className="order_detail_modal_action__cont_buttons">
              <button className="order_detail_modal_action__btn_no" onClick={close}>
                No
              </button>
              <button
                className="order_detail_modal_action__btn_yes"
                onClick={() => {
                  confirmAction()
                  close()
                }}
              >
                {`Si, aplicar ${t(statusses[action.action])}`}
              </button>
            </div>
          )}
        </div>
      </div>
      <style jsx>{OrderDetailModalActionStyle}</style>
    </>
  )
}

export default OrderDetailModalAction
