import { useContext, useEffect, useState } from "react"
import Logger from "classes/Logger"
import { listUsersService } from "services/OSM"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"
import { useParams, useHistory } from "react-router-dom"

const MAX_RECORDS_PER_PAGE = 10

const UseListUsers = ({groups = [], showDeleted = false}: any) => {
  const { query }: any = useParams()
  const [data, set_data] = useState(null)
  const [pages, set_pages] = useState(0)
  const [page, set_page] = useState(0)
  const [loading, set_loading] = useState(true)
  const { errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()
  const history = useHistory()

  useEffect(() => {
    const getListUsers = async () => {
      try {
        set_loading(true)
        let bodyReq = {
          maxRecords: MAX_RECORDS_PER_PAGE,
          skipRecords: page * MAX_RECORDS_PER_PAGE,
          groups,
          showDeleted
        }
        if (query) {
          bodyReq = Object.assign({query}, bodyReq)
        }
        const {
          data: { message: { maxPages, records } },
        } = await listUsersService(bodyReq, newCancelToken())
        set_data(records)
        set_pages(maxPages)
        set_loading(false)
      } catch (error: any) {
        set_loading(false)
        Logger.error(error)
        if (isCancel(error)) return
  
        errorHander?.dispatch({
          hasError: true,
          code: error.response?.status || TransactionCodeEnum.server,
        })
      }
    }
    getListUsers()
    // eslint-disable-next-line
  }, [page, query])

  const handleClickPage = (pageSeleted: number) => set_page(pageSeleted)

  const handleClickSearch = (e: any) => {
    if (!!e.query) history.push(`/search/users/${e.query}`)
  }

  return {
    data,
    pages,
    page,
    loading,
    query,
    handleClickPage,
    handleClickSearch
  }
}

export default UseListUsers