import { useContext } from "react"
import { BrowserRouter, Switch } from "react-router-dom"
import PrivateRouteComponent from "components/Commons/PrivateRouteComponent"
import PublicRouteComponent from "components/Commons/PublicRouteComponent"
import { AuthContext } from "context/context/AuthContext"
import DashboarContainer from "containers/DashBoard/DashboardContainer"
import LoginScreen from "pages/UserScreens/LoginScreen/LoginScreen"
import LoadScreen from "overrides/theme/entel/base/pages/LoadScreen/LoadScreen"

const AppRouter = () => {
  const {
    state: { user, optionsMenuAccess },
  } = useContext(AuthContext)

  const isLogged = (user && optionsMenuAccess.length > 0) || false

  return (
    <BrowserRouter>
      <Switch>
        <PublicRouteComponent
          exact
          path="/login"
          isAuthenticated={isLogged}
          redirectPath="/dashboard"
          render={(props: any) => <LoginScreen {...props} name="LoginScreen" />}
        />
        <PublicRouteComponent
          exact
          path="/callback"
          isAuthenticated={isLogged}
          redirectPath="/dashboard"
          render={(props: any) => <LoadScreen {...props} name="LoadScreen" />}
        />
        <PrivateRouteComponent
          path="/"
          component={DashboarContainer}
          isAuthenticated={isLogged}
          redirectPath="/login"
        />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
