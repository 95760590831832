import { IProcessItem } from "interfaces/IItemsOrder"
import AlertStyled from "theme/common/Alert.styled"
import SummaryProductCard from "components/OrderDetail/SummaryProducts/SummaryProductCard/SummaryProductCard"
import { GridContainer } from "theme/common/Grid.styled"

const SummaryProductList = ({
  itemsOrder,
  titleMain,
  groupPicking = false,
}: {
  itemsOrder: Array<IProcessItem>
  titleMain?: string
  groupPicking: boolean
}) => {
  const renderGroupPicking = () => {
    const itemsConfirm = itemsOrder.filter(({ isConfirm }) => isConfirm)
    const itemsReject = itemsOrder.filter(({ isConfirm }) => !isConfirm)

    return (
      <>
        <AlertStyled variant="success">Productos Confirmados</AlertStyled>
        <GridContainer className="padding-content" minSize={"350px"}>
          {itemsConfirm.map((item) => (
            <SummaryProductCard key={item.sku} hasIcon={true} itemOrder={item} />
          ))}
        </GridContainer>
        {itemsReject.length > 0 && (
          <>
            <AlertStyled variant="danger">Productos Rechazados</AlertStyled>
            <GridContainer className="padding-content" minSize={"350px"}>
              {itemsReject.map((item) => (
                <SummaryProductCard key={item.sku} hasIcon={true} itemOrder={item} />
              ))}
            </GridContainer>
          </>
        )}
      </>
    )
  }
  return (
    <>
      {groupPicking ? (
        renderGroupPicking()
      ) : (
        <>
          {titleMain && <AlertStyled variant="success">{titleMain}</AlertStyled>}

          <GridContainer className="padding-content" minSize={"350px"}>
            {itemsOrder.map((item) => (
              <SummaryProductCard hasIcon={false} key={item.sku} itemOrder={item} />
            ))}
          </GridContainer>
        </>
      )}
    </>
  )
}

export default SummaryProductList
