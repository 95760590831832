import { useTranslation } from "react-i18next"
import SimplyCarousel from "overrides/theme/shiperto/base/pages/DetailOrderScreens/Carousel"
import Item from "overrides/theme/shiperto/base/pages/DetailOrderScreens/Item"

const OrderDetailProductList = (props: any) => {
  const { t } = useTranslation()
  const { data } = props

  const template = (
    <article className="order__products">
      <h2>{t("Productos")}</h2>
      <section className="order__products__list">
        <SimplyCarousel>
          {data._items.map((item: any, index: any) => (
            <Item key={index} item={item} />
          ))}
        </SimplyCarousel>
      </section>
    </article>
  )

  return template
}

export default OrderDetailProductList
