import { useMemo } from "react"
import { Link } from "react-router-dom"
import { abbreviateNumber } from "helpers/UtilsHelper"

const CardOption = ({ optionCount, title, path, status }: any) => {
  const countAbbreviate = useMemo(() => abbreviateNumber(optionCount || 0), [optionCount])

  return (
    <Link
      className="dashboard__content__summary__item"
      to={{
        pathname: path,
      }}
    >
      <div className="dashboard__content__summary__item__icon">
        <div className="dashboard__content__summary__item__icon_wrapper">
          <img src={require(`assets/icons/shiperto-status/${status.toLowerCase()}-icon.svg`)} alt={status} />
        </div>
      </div>
      <div className="dashboard__content__summary__item__title">
        <div>
          <p>{title}</p>
        </div>
      </div>
      <div className="dashboard__content__summary__item__count">
        <div>
          <p>{countAbbreviate}</p>
        </div>
      </div>
    </Link>
  )
}

export default CardOption
