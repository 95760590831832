import { FilterOptionsCalendarEnum } from "enums/FilterOptionsCalendarEnum"
import { IRangeExtend } from "interfaces/IContentCelendar"
import { addDays } from "date-fns"

export const rangeDefault: IRangeExtend = {
  key: "selection",
  startDate: addDays(new Date(), -7),
  endDate: new Date(),
  activeOptionFilter: FilterOptionsCalendarEnum.lastWeek,
}
