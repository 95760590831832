import LogoClient from "components/Commons/BrandClient/LogoClient"
import { ITagInfoCustomer } from "interfaces/ITagInfoCustomer"
import { dataFields, Fields } from "components/OrderDetail/TagPrint/render"

const TagInfoCustomer = (props: ITagInfoCustomer) => {
  return (
    <div className="info-customer">
      <LogoClient />
      <Fields field={dataFields} data={props} />
    </div>
  )
}

export default TagInfoCustomer
