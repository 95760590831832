import { Fragment, useState } from "react"
import {
  sidebarStyle,
  buttonMenuStyle,
  optionOrderMenuButtonStyle,
} from "overrides/theme/shiperto/base/components/Theme/Layout/Main/styles/Sidebar.style"
import useComponentVisible from "hooks/useComponentVisible"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

// icons
import hamburger_icon from "assets/icons/hamburger.svg"
import close_icon from "assets/icons/close.svg"
import home_icon from "assets/icons/menu-home-icon.svg"
import buy_icon from "assets/icons/menu-buy-icon.svg"
import stats_icon from "assets/icons/menu-stats-icon.svg"
import calendar_icon from "assets/icons/menu-calendar-icon.svg"
import admin_actions_icon from "assets/icons/menu-admin-actions-icon.svg"
import package_icon from "assets/icons/menu/package-icon.svg"
import delivery_icon from "assets/icons/menu/delivery-icon.svg"
import shipped_icon from "assets/icons/menu/shipped-icon.svg"
import pickup_icon from "assets/icons/menu/pickup-icon.svg"
import delivered_icon from "assets/icons/menu/delivered-icon.svg"
import closed_icon from "assets/icons/menu/closed-icon.svg"
import canceled_icon from "assets/icons/menu/canceled-icon.svg"
import { useEnv } from "hooks"

const shipping_type = "sp_hd"

const listOrderMenu = [
  {
    name: "Órdenes por empacar",
    icon: package_icon,
    route: `/order/${shipping_type}/pending_package`,
  },
  {
    name: "Órdenes por despachar",
    icon: delivery_icon,
    route: `/order/${shipping_type}/awaiting_delivery`,
  },
  {
    name: "Órdenes en tránsito",
    icon: shipped_icon,
    route: `/order/${shipping_type}/in_transit`,
  },
  {
    name: "Órdenes por recoger",
    icon: pickup_icon,
    route: `/order/${shipping_type}/ready_for_pickup`,
  },
  {
    name: "Órdenes entregadas",
    icon: delivered_icon,
    route: `/order/${shipping_type}/delivered`,
  },
  {
    name: "Órdenes cerradas",
    icon: closed_icon,
    route: `/order/${shipping_type}/closed`,
  },
  {
    name: "Órdenes canceladas",
    icon: canceled_icon,
    route: `/order/${shipping_type}/canceled`,
  },
]

export const Sidebar = () => {
  const [ordersPopupRef, showPopupOrders, setShowPopupOrders] = useComponentVisible(false)
  const [expandHamburger, setExpandHamburger] = useState(false)
  const { permissions } = useEnv()

  const ButtonMenu = ({ icon, alt, action, route, tip }: any) => (
    <Fragment>
      <ReactTooltip />
      {action ? (
        <div onClick={() => action()} className="sidebar__menu--button-component">
          <img src={icon} alt={alt} data-tip={tip} />
        </div>
      ) : (
        <Link to={route} className="sidebar__menu--button-component">
          <img src={icon} alt={alt} data-tip={tip} />
        </Link>
      )}
      <style jsx>{buttonMenuStyle}</style>
    </Fragment>
  )

  const OptionOrderMenuButton = ({ icon, name, route }: any) => (
    <Fragment>
      <a href={route} className="sidebar__order-menu--button">
        <img src={icon} alt={name} />
        <p>{name}</p>
      </a>
      <style jsx>{optionOrderMenuButtonStyle}</style>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="sidebar__cont-sidebar">
        <div className="sidebar__cont-button--hamburger">
          <div
            className={expandHamburger ? "sidebar__hamburger sidebar__hamburger_close" : "sidebar__hamburger"}
            onClick={() => setExpandHamburger(!expandHamburger)}
          >
            <img src={expandHamburger ? close_icon : hamburger_icon} alt="X" />
          </div>
        </div>
        <div className="sidebar__cont-menu">
          <div
            className={
              !expandHamburger ? "sidebar__cont--menu--buttons" : "sidebar__cont--menu--buttons sidebar__box--show"
            }
          >
            {permissions.get_permission("menu", "item-home") && (
              <ButtonMenu tip="Inicio" icon={home_icon} alt="home_icon" route={"/"} />
            )}
            {permissions.get_permission("menu", "item-orders") && (
              <ButtonMenu
                tip="Órdenes"
                icon={buy_icon}
                alt="buy_icon"
                action={() => setShowPopupOrders(!showPopupOrders)}
              />
            )}
            {permissions.get_permission("menu", "item-reports") && (
              <ButtonMenu tip="Reportes" icon={stats_icon} alt="stats_icon" route={"/charts"} />
            )}
            {permissions.get_permission("menu", "item-users") && (
              <ButtonMenu tip="Perfiles" icon={admin_actions_icon} alt="calendar_icon" route={"/users"} />
            )}
            {permissions.get_permission("menu", "item-order-import") && (
              <ButtonMenu tip="Órdenes Masivas" icon={calendar_icon} alt="admin_actions_icon" route={"/bulk"} />
            )}
          </div>
        </div>
        <div
          ref={ordersPopupRef}
          className={
            showPopupOrders ? "sidebar__container-orders-popup sidebar__box--show" : "sidebar__container-orders-popup"
          }
        >
          {listOrderMenu.map((item, index) => (
            <OptionOrderMenuButton icon={item.icon} name={item.name} route={item.route} key={index} />
          ))}
        </div>
      </div>
      <style jsx>{sidebarStyle}</style>
    </Fragment>
  )
}

export default Sidebar
