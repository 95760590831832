import {Fragment, forwardRef} from 'react'
import LeftSide from "overrides/theme/shiperto/base/components/OrderDetail/TagPrint/TagPrintBody/LeftSide"
import CenterSide from "overrides/theme/shiperto/base/components/OrderDetail/TagPrint/TagPrintBody/CenterSide"
import RightSide from "overrides/theme/shiperto/base/components/OrderDetail/TagPrint/TagPrintBody/RightSide"
import { tagPrintBodyStyle } from "overrides/theme/shiperto/base/components/OrderDetail/TagPrint/styles/TagPrintBody.style"
import Barcode from "components/Commons/BarCode"

const TagPrintBody = forwardRef<any, any>(({data, qrContent}, ref) => {
  return (
    <Fragment>
      <div ref={ref}>
        <div className="pt__print-tag__container">
          <LeftSide data={data} />
          <CenterSide data={data} />
          <RightSide data={data} />
        </div>
        <div className="pt__print-tag__qrcode">
          <Barcode value={qrContent} />
        </div>
      </div>
      <style jsx>{tagPrintBodyStyle}</style>
    </Fragment>
  )
})

export default TagPrintBody