export const cardUserItemStyle = `
  .lus__card_user_item_container {
    width: auto;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 1rem;
    padding: 0 1em 1em;
    box-shadow: 0px 6px 24px rgba(207, 195, 172, 0.2);
    border: 1px solid #DDDDE9;
    transition: all 0.5s ease-in-out;
    padding: 1em;
    gap: 1em;
  }
  .lus__card_user_item_element {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0 0.5em;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .lus__card_user_item_element .lus__cui_element_value {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1rem;
    color: #716F87;
  }
  .lus__card_user_item_element .lus__cui_element_username {
    font-family: 'Poppins';
    color: #23203F;
  }
  .lus__cui_element_actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0 2em;
    padding-right: 1em;
  }
  .lus__cui_element_actions img {
    cursor: pointer;
  }
  .lus__cui_title {
    display: block;
  }
  .lus__cui_btn_enable {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1rem;
    padding: 0.3em 0.5em;
    color: #002eff;
    border: 1px solid #002eff;
    border-radius: 0.8em;
  }
  .lus__cui_btn_enable:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  .lus__cui_btn_border_deleted {
    border: 1px solid #FF0000;
  }
  @media screen and (min-width: 1280px) {
    .lus__card_user_item_container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .lus__cui_title {
      display: none;
    }
  }
`

export const listUsersScreenStyle = `
  .list_users_screen__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .list_users_screen__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em 1em;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 1rem;
    margin-top: 1rem;
  }
  .list_users_screen__content_header {
    padding: 1em;
  }
  .list_users_screen__content_header {
    display: none;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    font-family: 'Montserrat';
    font-size: 1rem;
    color: #716F87;
  }
  .list_users_screen__content_header p:last-child {
    text-align: right;
    padding-right: 1em;
  }
  .list_users_screen__content_body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1em;
  }
  .list_users_screen__container_pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  @media screen and (min-width: 1280px) {
    .list_users_screen__body {
      padding: 1em;
    }
    .list_users_screen__content_header {
      display: grid;
    }
    .list_users_screen__content_body {
      display: flex;
      flex-direction: column;
    }
  }
`