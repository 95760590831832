import { Env } from "classes"

const entel_status = Env.theme.entel.sg.default.status

const summary_status = entel_status.map((status: any) => {
  const action = status.toLowerCase()
  return {
    status: action,
    title: status,
    optionCount: 0,
    path: `/order/hd/${action}`,
  }
})

export default summary_status
