import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { IconCheck, IconCancel } from "theme/common/Icon.styled"
import { IPickupAction } from "interfaces/IShippingGroupAction"

const PickupAction = ({
  disabledPickup = false,
  loadingPickup,
  loadingCancel,
  onClickPickup,
  onClickCancel,
  shouldBeFilePresentOnSubmit,
  disabledButton= false,
}: IPickupAction) => {
  return (
    <ContentDetailOrderActionsStyled>
      {onClickPickup && !shouldBeFilePresentOnSubmit() && (
        <ButtonStyled
          variant="primary"
          loadingButton={loadingPickup}
          disabled={disabledPickup || loadingPickup}
          disabledButton={disabledButton}
          onClick={onClickPickup}
        >
          <IconCheck />
          <span>Confirmar entrega</span>
        </ButtonStyled>
      )}

      {onClickCancel && (
        <ButtonStyled
          variant="secondary"
          loadingButton={loadingCancel}
          disabled={loadingCancel}
          onClick={onClickCancel}
        >
          <IconCancel />
          <span>Cancelar orden</span>
        </ButtonStyled>
      )}
    </ContentDetailOrderActionsStyled>
  )
}

export default PickupAction
