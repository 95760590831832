import styled from "styled-components"

export const ContentStyledManifest = styled.div`
  .box-table {
    margin-bottom: 1.5rem;
    
    & > table {
      width: 100%;
      margin-top: 20px;

      & > thead > tr > th {
        color: #000000;
        padding: 0.3rem;
        background: #E5E7ED;
        text-align: center;
      }

      & > tbody > tr {
        border-bottom: #E5E7ED solid 2px;

        & > td {
          color: #000000;
          padding: 0.5rem;
          text-align: center;
          font-weight: normal;
        }
      }
    }

    .link-download {
      cursor: pointer;
      background: #451287;
      border-radius: 25em;
      color: #fff;
      margin: 0 0.25em;
      padding: 0.25em 1em;
      transition: all 500ms ease-out;
      -webkit-transition: all 500ms ease-out;

      &:hover:not(:focus) {
        background: #8C004C;
      }
    }
  }

  .box-message {
    margin-top: 20px;
  }

  .box-loading {
    margin-top: 20px;
    position: relative;
  }
`