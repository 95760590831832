import { useContext, useState } from "react"
import Logger from "classes/Logger"
import { createUserService, updateUserService, changePasswordUserService, deleteUserService, enableUserService } from "services/OSM"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"
import { useHistory } from "react-router-dom"

const useUpsertUsers = ({ redirectAfter = false, updatePass = false }: any) => {
  const [data_user, set_data_user] = useState(null)
  const [loading_upsert, set_loading] = useState(false)
  const [loading_pass, set_loading_pass] = useState(false)
  const [loading_delete, set_loading_delete] = useState(false)
  const [loading_enable, set_loading_enable] = useState(false)
  const { notification, errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()
  const history = useHistory()

  const redirectAfterAction = (currentUserId: string) => history.push(`/users/${currentUserId}`)

  const createUserAction = async (body: any) => {
    console.log(body)
    try {
      set_loading(true)
      const {
        data: { message },
      } = await createUserService(body, newCancelToken())
      set_data_user(message)
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "se creó correctamente el usuario",
          title: "Usuario Creado",
          type: "success",
        },
      })
      set_loading(false)
      redirectAfter && redirectAfterAction(body?.id)
    } catch (error: any) {
      set_loading(false)
      Logger.error(error)
      if (isCancel(error)) return
      if (error.response?.status === 401) return errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: JSON.stringify(error.response?.data?.message),
          title: "ERROR",
          type: "danger",
        },
      })
    }
  }

  const updateUserAction = async (userId: string, body: any, passwordCred: any = {}) => {
    try {
      set_loading(true)
      const {
        data: { message },
      } = await updateUserService(userId, body, newCancelToken())
      set_data_user(message)
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "se actualizó correctamente el usuario",
          title: "Usuario Actualizado",
          type: "success",
        },
      })
      if (updatePass) {
        const { old_password, password } = passwordCred
        await changePasswordUserAction(old_password, password)
      }
      set_loading(false)
      redirectAfter && redirectAfterAction(userId)
    } catch (error: any) {
      set_loading(false)
      Logger.error(error)
      if (isCancel(error)) return
      if (error.response?.status === 401) return errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: JSON.stringify(error.response?.data?.message),
          title: "ERROR",
          type: "danger",
        },
      })
    }
  }

  const changePasswordUserAction = async (old_password: string, password: string) => {
    try {
      set_loading_pass(true)
      await changePasswordUserService({ old_password, password }, newCancelToken())
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "se actualizó correctamente la contraseña",
          title: "Contraseña Actualizada",
          type: "success",
        },
      })
      set_loading_pass(false)
    } catch (error: any) {
      set_loading_pass(false)
      Logger.error(error)
      if (isCancel(error)) return
      if (error.response?.status === 401) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "La contraseña actual es incorrecta, verifíque e intente de nuevo",
            title: "Contraseña NO Actualizada",
            type: "danger",
          },
        })
      } else {
        errorHander?.dispatch({
          hasError: true,
          code: error.response?.status || TransactionCodeEnum.server,
        })
      }
    }
  }

  const deleteUserAction = async (userId: string) => {
    try {
      set_loading_delete(true)
      await deleteUserService(userId, newCancelToken())
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "se eliminó correctamente el usuario",
          title: "Usuario Eliminado",
          type: "success",
        },
      })
      set_loading_delete(false)
      redirectAfter && redirectAfterAction(userId)
    } catch (error: any) {
      set_loading_delete(false)
      Logger.error(error)
      if (isCancel(error)) return
      if (error.response?.status === 401) return errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: JSON.stringify(error.response?.data?.message),
          title: "ERROR",
          type: "danger",
        },
      })
    }
  }

  const enableUserAction = async (userId: string, body: any = {}) => {
    try {
      set_loading_enable(true)
      await enableUserService(userId, body, newCancelToken())
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "se habilitó correctamente el usuario",
          title: "Usuario Habilitado",
          type: "success",
        },
      })
      set_loading_enable(false)
      history.push(`/users/${userId}`)
    } catch (error: any) {
      set_loading_enable(false)
      Logger.error(error)
      if (isCancel(error)) return
      if (error.response?.status === 401) return errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: JSON.stringify(error.response?.data?.message),
          title: "ERROR",
          type: "danger",
        },
      })
    }
  }

  return {
    data_user,
    loading_upsert,
    loading_pass,
    loading_delete,
    loading_enable,
    createUserAction,
    updateUserAction,
    changePasswordUserAction,
    deleteUserAction,
    enableUserAction
  }
}

export default useUpsertUsers
