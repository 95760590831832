import { lazy } from "react"
import { ComponentsType } from "types/CommonsTypes"

const ScannerlTransition = lazy(() => import("overrides/theme/entel/base/components/Core/ProductTransition/ScannerlTransition"))

const FileUpload = lazy(() => import("components/Commons/FileUpload/FileUpalder"))

const ContentFileMedia = lazy(
  () => import("webapp-clients/Wom/Components/ViewContentMedia/ContentFileMedia"),
)

export const entel: ComponentsType = {
  ContentFileMedia,
  ProductTransition: ScannerlTransition,
  FileUpload
}
