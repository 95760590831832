import { useEffect, useState, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import Logger from "classes/Logger"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import { NOTIFY_RETURNED } from "helpers/constHelper"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"
import { ProcessShippingGroupActionType } from "types/OrderType"
import { ShippingTypeEnum } from "enums/OrderEnum"

const UseDeliveredReturnOrder = ({
  order,
  shippingGroupId,
  setFinishedShippingGroupAction,
}: ProcessShippingGroupActionType ) => {
  const { notification, errorHander } = useContext(GlobalContext)

  const [canIuseDeliveredReturn, setCanIuseDeliveredReturn] = useState(false)

  const [loadingDeliveredReturn, setLoadingDeliveredReturn] = useState(false)

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.deliveredReturnOrderAction] })

  useEffect(() => {
    permission[ResourcePermissionsEnum.deliveredReturnOrderAction] && order?.shippingType === ShippingTypeEnum.HD && setCanIuseDeliveredReturn(true)
    //eslint-disable-next-line
  }, [permission, order])

  const handleClickDeliveredReturnOrder = async (action: EShippinggroupAction) => {
    try {
      setLoadingDeliveredReturn(true)
      const {
        data: { code },
      } = await executeShipingGroupAction({
        shippingGroupId,
        action,
      })

      setLoadingDeliveredReturn(false)

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        setFinishedShippingGroupAction(true)

        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Orden retornada correctamente",
            title: NOTIFY_RETURNED,
            type: "success",
          },
        })
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadingDeliveredReturn(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    handleClickDeliveredReturnOrder,
    loadingDeliveredReturn,
    canIuseDeliveredReturn,
  }
}

export default UseDeliveredReturnOrder
