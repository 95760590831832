export const OrderDetailModalActionStyle = `
  .order_detail_modal_action__root {
    display: grid;
    place-items: center;
  }
  .order_detail_modal_action__main_cont {
    width: 95%;
    background: white;
    border: 1px solid #002eff;
    border-radius: 0.5em;
    padding: 1.5em;
    display: grid;
    place-items: center;
    gap: 1em;
  }
  .order_detail_modal_action__cont_title {
    text-align: center;
    gap: 0.5em;
  }
  .order_detail_modal_action__cont_title .order_detail_modal_action__cont_title_main {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.2rem;
  }
  .order_detail_modal_action__cont_title .order_detail_modal_action__cont_title_main span {
    color: #DA5600;
  }
  .order_detail_modal_action__cont_title .order_detail_modal_action__cont_title_descr {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1rem;
    color: var(--color-main-gray);
  }
  .order_detail_modal_action__cont_body {
    display: flex;
    width: 100%;
  }
  .order_detail_modal_action__cont_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  .order_detail_modal_action__btn_no {
    background: #E5E5E5;
    color: var(--color-main-gray);
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
  }
  .order_detail_modal_action__btn_yes {
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background: #002eff;
    box-shadow: 0px 4px 8px rgba(181, 185, 255, 0.4);
    border-radius: 2rem;
    padding: 0.5rem 1.4rem;
    margin-left: 0.5rem;
  }
  @media screen and (min-width: 768px) {
    .order_detail_modal_action__main_cont {
      min-width: 600px;
    }
  }
`

export const FormCancelActionStyle = `
.form_cancel_action__main .form_cancel_action__input_form {
    width: 100%;
    height: 3rem;
    background-color: #f3f5ff;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 1rem;
    border-radius: 1.5rem;
    padding: 0 2em;
  }
  .form_cancel_action__main .form_cancel_action__input_form:disabled {
    cursor: not-allowed;
  }
  .form_cancel_action__main .form_cancel_action__select_form {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 3rem !important;
    background-color: #f3f5ff;
    cursor: pointer;
  }
  .form_cancel_action__main .form_cancel_action__input_form:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px #f3f5ff inset;
  }
  .form_cancel_action__main .form_cancel_action__input_form::placeholder {
    color: #716F87;
  }
  .form_cancel_action__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  .order_detail_modal_action__cont_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0.5em;
  }
  .order_detail_modal_action__btn_no {
    background: #E5E5E5;
    color: var(--color-main-gray);
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
  }
  .order_detail_modal_action__btn_yes {
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background: #002eff;
    box-shadow: 0px 4px 8px rgba(181, 185, 255, 0.4);
    border-radius: 2rem;
    padding: 0.5rem 1.4rem;
    margin-left: 0.5rem;
  }
`