import { useState } from "react"
import ReactSimplyCarousel from "react-simply-carousel"

const SimplyCarousel = (props: any) => {
  const { children } = props
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  return (
    <ReactSimplyCarousel
      activeSlideIndex={activeSlideIndex}
      onRequestChange={setActiveSlideIndex}
      itemsToShow={3}
      itemsToScroll={1}
      centerMode={true}
      forwardBtnProps={{
        //here you can also pass className, or any other button element attributes
        style: {
          alignSelf: "center",
          background: "#FFFFFF",
          borderRadius: "50%",
          border: "1px solid #EE7628",
          boxShadow: "0px 6px 8px rgba(171, 171, 171, 0.35)",
          color: "#EE7628",
          cursor: "pointer",
          fontSize: "20px",
          height: 30,
          right: "-10px",
          position: "absolute",
          lineHeight: 1,
          textAlign: "center",
          width: 30,
        },
        children: <span>{`>`}</span>,
      }}
      backwardBtnProps={{
        //here you can also pass className, or any other button element attributes
        style: {
          alignSelf: "center",
          background: "#FFFFFF",
          borderRadius: "50%",
          border: "1px solid #EE7628",
          boxShadow: "0px 6px 8px rgba(171, 171, 171, 0.35)",
          color: "#EE7628",
          cursor: "pointer",
          fontSize: "20px",
          height: 30,
          left: "-10px",
          position: "absolute",
          lineHeight: 1,
          textAlign: "center",
          width: 30,
        },
        children: <span>{`<`}</span>,
      }}
      containerProps={{
        style: {
          justifyContent: "space-around",
        }
      }}
      speed={400}
      easing="linear"
      infinite={true}
    >
      {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
      {children}
    </ReactSimplyCarousel>
  )
}

export default SimplyCarousel
