import { useEffect, useState } from "react"
import { IOrder } from "interfaces/IOrder"
import { TypeVariation6 } from "types/CommonsTypes"
import { ShippingTypeEnum } from "enums/OrderEnum"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"

const UseSendCode = ({ order, shippinGroupDetail }: { order: IOrder | TypeVariation6, shippinGroupDetail: any }) => {
  const [canIuseSendDeliveryCode, setCanIuseSendDeliveryCode] = useState(false)
  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.sendDeliveryCode] })

  useEffect(() => {
    permission[ResourcePermissionsEnum.sendDeliveryCode] &&
      order?.shippingType === ShippingTypeEnum.HD &&
      order?.custom?.portabilidad === "SI" &&
      shippinGroupDetail?.custom?.infoEntrega?.codigoEntrega &&
      setCanIuseSendDeliveryCode(true)
  },
  //eslint-disable-next-line
  [permission, order, shippinGroupDetail])

  return {
    canIuseSendDeliveryCode,
  }
}

export default UseSendCode
