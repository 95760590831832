import { FunctionComponent, useRef, useCallback } from "react"
import BackBotton from "components/Commons/BackBotton/BackBotton"
import config from "config/configEnv"
import IPage from "interfaces/IPage"
import { ISearchResults } from "interfaces/ISearchResults"
import { SecondtitleStyled } from "theme/common/Paragraph.styled"
import { RouteComponentProps, useParams } from "react-router-dom"

import OrderContainerStyled from "components/Core/Order/OrderList.styled"
import useGetSgsByStatus from "hooks/useGetSgsByStatus"
// import useGetOrderByStatus from "hooks/useGetOrderByStatus"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"
import SkeletonGrid from "components/Commons/Skeleton/SkeletonGrid"
import OrderList from "components/Core/Order/OrderList"
import ErrorHandled from "components/Commons/Error/ErrorHandler"
import Paginate from "components/Commons/Pagination/Paginate"
import { OptionsFilterEnum } from "enums/OptionsFilterEnum"
import SearchForm from "components/SearchForm/SearchForm"

const SearchResultsScreen: FunctionComponent<IPage & RouteComponentProps<ISearchResults>> = (
  props: RouteComponentProps<ISearchResults> & IPage,
) => {
  const { query, optionFilter }: any = useParams()

  const useGetOrderByStatus = useGetSgsByStatus()

  const refElemOrders = useRef<HTMLDivElement>(null)

  const optionfilter = OptionsFilterEnum[optionFilter as keyof typeof OptionsFilterEnum]
  const filterOrder = optionfilter ? { [optionfilter]: query } : { [OptionsFilterEnum.query]: query }

  const { ordersGroup, loading, maxPage, setPage, page, setSearch } = useGetOrderByStatus(
    EShippinggroupStatus.all.toString(),
    filterOrder,
  )

  const handleClickPage = (pageSeleted: number) => {
    setPage(pageSeleted)
  }

  const handleClickSearch = useCallback(
    ({ query, optionFilter }: ISearchResults) => {
      if (optionFilter) {
        setSearch({
          [optionFilter]: query,
        })
      }
    },
    [setSearch],
  )

  return (
    <>
      <BackBotton>
        <SecondtitleStyled>{`Busqueda por "${query}"`}</SecondtitleStyled>
      </BackBotton>
      <OrderContainerStyled>
        <>
          <SearchForm
            search={query}
            optionFilter={optionFilter}
            onClickSearch={handleClickSearch}
            isLoading={loading}
            backButton={true}
          />

          {loading ? (
            <SkeletonGrid count={config.maxRecords} isGroupOrder={true} />
          ) : (
            <>
              {ordersGroup ? (
                <>
                  <OrderList ordersGroup={ordersGroup} showStatus />
                  {maxPage > 1 && (
                    <Paginate
                      pageCount={maxPage}
                      onChangePage={handleClickPage}
                      elementFocus={refElemOrders}
                      pageInit={page}
                    />
                  )}
                </>
              ) : (
                <ErrorHandled message="Sin resultados para esta busqueda" />
              )}
            </>
          )}
        </>
      </OrderContainerStyled>
    </>
  )
}

export default SearchResultsScreen
