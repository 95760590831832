import { useState, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { attachFileShippingGroup, executeShipingGroupAction } from "services/ShippingGroupService"
import Logger from "classes/Logger"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { NOTIFY_DELIVERED } from "helpers/constHelper"
import { ProcessShippingGroupActionType } from "types/OrderType"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import { FilesFormType } from "types/FilesFormType"
import { updateOrderById } from "services/OrderServices"
import useCancelToken from "hooks/UseCancelToken"
import { deliveryCodeValidate } from "services/ListenerServices"

const useTransitOrder = ({
  shippingGroupId,
  setFinishedShippingGroupAction,
  order,
  refModal,
  inputVerificationCode,
}: ProcessShippingGroupActionType) => {
  const [loadingTransit, setLoadingTransit] = useState(false)
  const [loadingValidateCode, setLoadingValidateCode] = useState(false)
  const [checkValidateCode, setCheckValidateCode] = useState(false)

  const { notification, errorHander } = useContext(GlobalContext)
  const { newCancelToken } = useCancelToken()

  const attachFile = async (filesShippinGroup: FileList): Promise<boolean> => {
    const formAttachFile = new FormData()

    for (let indexFile = 0; indexFile < filesShippinGroup.length; indexFile++) {
      const file = filesShippinGroup[indexFile]
      if (file) formAttachFile.append(`$attach${indexFile}`, file)
    }

    formAttachFile.append("tags", [...filesShippinGroup].map(({ name }) => name).join(","))
    const {
      data: { code },
    } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

    return code === TransactionCodeEnum.ok
  }

  const deliveredOrder = async () => {
    const {
      data: { code },
    } = await executeShipingGroupAction({
      shippingGroupId,
      action: EShippinggroupAction.delivered,
    })

    if (code === TransactionCodeEnum.ok) {
      setFinishedShippingGroupAction(true)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Productos para entregar correctamente",
          title: NOTIFY_DELIVERED,
          type: "success",
        },
      })
    }
  }

  const submitDeliveredOrder = async ({ filesShippinGroup }: FilesFormType) => {
    try {
      setLoadingTransit(true)

      let successAttachFiles: boolean = true

      if (filesShippinGroup) {
        await updateOrderById(
          order?.id,
          {
            custom: {
              contrato_portabilidad: "SI",
            },
          },
          newCancelToken(),
        )
        successAttachFiles = await attachFile(filesShippinGroup)
      }

      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error, al cargar los archivo",
            title: NOTIFY_DELIVERED,
            type: "danger",
          },
        })

        return
      }

      await deliveredOrder()

      setLoadingTransit(false)
    } catch (error: any) {
      setLoadingTransit(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const checkVerificationCode = async () => {
    try {
      setLoadingValidateCode(true)
      
      const body = {
        shippingGroupId: shippingGroupId,
        code: inputVerificationCode,
      }

      const {
        data: { code, message },
      } = await deliveryCodeValidate(body, newCancelToken())

      if (code === TransactionCodeEnum.ok && message) {
        setCheckValidateCode(true)
      }else{
        setLoadingValidateCode(false)
        refModal.current?.open()
      }
    } catch (error: any) {
      setLoadingValidateCode(false)
      refModal.current?.open()
    }
  }

  return {
    loadingTransit,
    loadingValidateCode,
    submitDeliveredOrder,
    checkValidateCode,
    checkVerificationCode,
  }
}

export default useTransitOrder
