export const ChartsScreenStyles = `
  .reports {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 2px 4px rgb(0 0 0 / 10%);
    border-radius: 2rem;
    padding: 1.8rem;
    margin: 0 0 1.2rem 0;
  }
  .reports h1 {
    color: var(--color-blue-dark);
    font-family: "Poppins", Arial, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 1rem;
  }
  .reports iframe {
    height: 100vh;
    width: 100%;
  }
`