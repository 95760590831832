import { getValueShippinggroupStatusEnum } from "helpers/UtilsHelper"
import { useParams } from "react-router-dom"
import useUserPermissions from "hooks/UseUserPermissions"
import { useContext } from "react"
import { AuthContext } from "context/context/AuthContext"
import { ConfigGroupOrderType } from "types/OrderType"
import { WebAppClientsEnum } from "enums/CommonEnum"
import { typeObjectGeneric } from "types/CommonsTypes"
import { EShippinggroupAction, EShippinggroupStatus } from "enums/shippinggroupEmun"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import { IOrderShippinggroup } from "interfaces/IOrder"
// import { ShippingTypeEnum } from "enums/OrderEnum"
import { useTranslation } from "react-i18next"
import { ShippingTypeEnum } from "enums/OrderEnum"

const useConditionalsGroupOrder = () => {
  const { type } = useParams<{ type: string }>()
  const {
    state: { user },
  } = useContext(AuthContext)

  const { t } = useTranslation()

  const typeStatus = getValueShippinggroupStatusEnum(type)

  const configGroupOrder: typeObjectGeneric<Array<ConfigGroupOrderType>> = {
    [WebAppClientsEnum.wom]: [
      {
        status: EShippinggroupStatus.package,
        action: EShippinggroupAction.packed,
        labelAction: t("LABEL_PACKED_GROUP"),
        permission: ResourcePermissionsEnum.packingGroup,
        predicate: ({ custom }: IOrderShippinggroup) => !custom?.infoElocker?.isElocker,
      },
      {
        status: EShippinggroupStatus.delivery,
        action: EShippinggroupAction.shipped,
        permission: ResourcePermissionsEnum.shippingGroup,
        predicate: ({ custom, shippingType }: IOrderShippinggroup) =>
          !custom?.infoElocker && shippingType === ShippingTypeEnum.HD,
        labelAction: t("LABEL_SHIPPED_GROUP"),
      },
      {
        status: EShippinggroupStatus.in_transit_for_reception,
        action: EShippinggroupAction.delivered,
        permission: ResourcePermissionsEnum.shippingGroup,
        labelAction: t("LABEL_DELIVERED_FROM_IN_TRANSIT_FOR_RECEPTION"),
      },
    ],
    [WebAppClientsEnum.privilege]: [
      {
        status: EShippinggroupStatus.package,
        action: EShippinggroupAction.packed,
        labelAction: t("LABEL_PACKED_GROUP"),
        permission: ResourcePermissionsEnum.packingGroup,
      },
      {
        status: EShippinggroupStatus.delivery,
        action: EShippinggroupAction.shipped,
        labelAction: t("LABEL_SHIPPED_GROUP"),
        permission: ResourcePermissionsEnum.shippingGroup,
      },
    ],
  }

  const config = configGroupOrder[user?.mainGroup.id as keyof typeof configGroupOrder]

  const statusGroup = config?.find(({ status }) => status === typeStatus)

  const { permission } = useUserPermissions({
    resources: statusGroup?.permission ? [statusGroup?.permission] : [],
  })

  return {
    canSelectMultiple: !!statusGroup && permission[statusGroup.permission],
    statusGroup,
    typeStatus: type,
  }
}

export default useConditionalsGroupOrder
