export const loadingFormStyle = `
  .cont-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  .cont-loading--sub__left {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cont-loading--sub__right {
    display: none;
  }
  .sub--left__body {
    width: 90%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 1em;
  }
  .sub--left__body .client-logo {
    width: 10em;
    padding-bottom: 2em;
  }
  .sub--left__body .info-loading {
    padding: 1em 0;
  }
  .info-loading h4 {
    font-family: 'Poppins';
    font-weight: 600;
    color: #23203F;
    padding-bottom: 1em;
    font-size: 1.8rem;
  }
  .info-loading__welcome {
    font-family: 'Montserrat';
    font-weight: 500;
    color: #23203F;
    font-size: 1rem;
  }
  .info-loading__description {
    font-weight: 400;
    color: #716F87;
    font-size: 1rem;
  }
  .sub--left__body .cont-form-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .cont-remember {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3em;
    padding: 0.5em 0.8em;
    font-family: 'Montserrat';
    color: #716F87;
    font-size: 1rem;
  }
  .cont-remember__check-container {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    padding-left: 2em;
  }
  .cont-remember__check-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .cont-remember__check-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.2rem;
    width: 1.2rem;
    background-color: #FFFFFF;
    border: solid 1px #002eff;
    border-radius: 0.2rem;
  }
  .cont-remember__check-container:hover input ~ .cont-remember__check-checkmark {
    background-color: #002eff;
  }
  .cont-remember__check-container input:checked ~ .cont-remember__check-checkmark {
    background-color: #002eff;
  }
  .cont-remember__check-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .cont-remember__check-container input:checked ~ .cont-remember__check-checkmark:after {
    display: block;
  }
  .cont-remember__check-container .cont-remember__check-checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .cont-form-loading .button-form {
    margin: 1rem 0;
    width: 70%;
    height: 3rem;
    border-radius: 1.5rem;
    font-family: "Poppins";
    font-size: 1.2rem;
    color: #FFFFFF;
    background: linear-gradient(90deg, #002eff -2.99%, #002eff 16.38%, #002eff 108.07%);
  }
  .error-message {
    width: 100%;
    background: #D75350;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 500;
    color: #FFFFFF;
    padding: 0.5em;
    border-radius: 0.5rem;
  }
  @media screen and (min-width: 768px) {
    .sub--left__body {
      width: 80%;
      height: 90%;
      justify-content: center;
      padding: 3em;
    }
    .sub--left__body .client-logo {
      width: 12rem;
    }
  }
  @media screen and (min-width: 1280px) {
    .cont-loading {
      flex-direction: row;
    }
    .cont-loading--sub__left {
      width: 60%;
    }
    .cont-loading--sub__right {
      width: 40%;
      height: 100%;
      display: flex;
      background: red;
      position: relative;
      background: linear-gradient(171.14deg, #002eff 3.14%, #002eff 20.68%, #002eff 103.69%);
    }
    .cont-loading--sub__right .shade-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
    .sub--left__body {
      width: 70%;
      height: 90%;
      padding: 2em 5em;
    }
    .sub--right__body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 1;
      padding: 2em 5em;
    }
    .sub--right__body .main-bg-image {
      width: 28em;
      padding-bottom: 3em;
    }
    .sub--right__body .bg-title {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 1.2rem;
      color: #FFFFFF;
      padding-bottom: 1em;
    }
    .sub--right__body .bg-description {
      font-family: 'Montserrat';
      font-size: 1rem;
      color: #FFFFFF;
    }
  }
  @media screen and (min-width: 1820px) {
    .sub--right__body .main-bg-image {
      width: 35em;
    }
  }
`