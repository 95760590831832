import { ContainerWindowModal, ContainerButtons, TitleModal, DescriptionModal } from "./WindowModal.styled"
import { ButtonStyled } from "theme/common/Button.styled"

const WindowModal: React.FC<any> = ({
  title,
  description = '',
  handleConfirm,
  handleCancel,
  showConfirm = true, 
  showCancel = true,
  children
}) => {
  
  return (
    <ContainerWindowModal>
      <TitleModal>{title}</TitleModal>

      {(description.trim() !== "") && (
        <>
          {description && showCancel ? (
            <DescriptionModal>
              {`${description}, ¿Desea continuar?`}
            </DescriptionModal>
          ): (
            <DescriptionModal>
              {`${description}`}
            </DescriptionModal>
          )}
        </>
      )}

      {children}
      
      <ContainerButtons>
        {showConfirm && (
          <ButtonStyled
            variant="primary"
            onClick={handleConfirm}
          >
            Continuar
          </ButtonStyled>
        )}
        {showCancel && (
          <ButtonStyled
            variant="secondary"
            onClick={handleCancel}
          >
            Cancelar
          </ButtonStyled>
        )}
      </ContainerButtons>
    </ContainerWindowModal>
  )
}

export default WindowModal