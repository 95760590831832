import { OptionsSendCodeEnum } from "enums/OptionsSendCodeEnum"
import { ShippingTypeEnum } from "enums/OrderEnum"

export const SendCodeOptions: Array<object> = [
  {
    value: OptionsSendCodeEnum.sms,
    label: "SMS",
    shippingType: ShippingTypeEnum.SP,
    portabilidad: "SI"
  },
  {
    value: OptionsSendCodeEnum.email,
    label: "E-Mail",
    shippingType: ShippingTypeEnum.SP,
  },
  {
    value: OptionsSendCodeEnum.sms,
    label: "SMS",
    shippingType: ShippingTypeEnum.HD,
    portabilidad: "SI"
  },
]