import { useReducer } from "react"
import pickingReducer, { initialStatePicking } from "pages/DetailOrderScreens/Confirm/PickingReducer"
import { ItemsOrderType, ItemOrderType } from "types/OrderType"
import { IItems } from "interfaces/IItemsOrder"
import { Env } from "classes"

interface IUSePickingState {
  itemsOrder: ItemsOrderType
  status: string
  shippingType: string
}

const usePickingItems = ({ itemsOrder, status, shippingType }: IUSePickingState) => {
  const [pickingTransition, dispachPickingTransition] = useReducer(pickingReducer, initialStatePicking)

  const is_picking_state = [
    Env.theme.entel.sg[shippingType.toUpperCase()].status[2],
    Env.theme.entel.sg[shippingType.toUpperCase()].status[3],
  ].includes(status.toUpperCase())

  const show_series_input_if = is_picking_state && itemsOrder?.length !== pickingTransition.processItems.length

  const getCurrentNextItem = () => {
    let currentItem: ItemOrderType = null
    let nextItems: Array<IItems> = []

    if (itemsOrder && pickingTransition.itemIdexActive > -1) {
      currentItem = itemsOrder[pickingTransition.itemIdexActive]

      nextItems = itemsOrder.filter(({ sku }) => !pickingTransition.processItems.some((item) => item.sku === sku))
    }

    return {
      currentItem,
      nextItems,
    }
  }

  const { currentItem, nextItems } = getCurrentNextItem()

  const hasAllItemValidSeries: any = itemsOrder?.every((item: IItems) =>
    item?.series?.every((_it: any) => Boolean(_it?.valid_serie)),
  )

  const hide_options_if_all_series_completed = !Boolean(is_picking_state && hasAllItemValidSeries)

  return {
    currentItem,
    nextItems,
    pickingTransition,
    is_picking_state,
    show_series_input_if,
    hide_options_if_all_series_completed,
    dispachPickingTransition,
  }
}

export default usePickingItems
