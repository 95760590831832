import { IProcessItem } from "interfaces/IItemsOrder"

type State = {
  itemIdexActive: number
  processItems: Array<IProcessItem>
  isEditing: boolean
}

type PickingActionTypes =
  | { type: "ADD_PROCESS_ITEM"; payload: IProcessItem }
  | { type: "EDIT_ITEM_SERIES"; payload: { itemEdit: IProcessItem; nextItemIndex: number } }
  | { type: "CHANGE_ITEM_ACTIVE_FOR_EDIT"; payload: string }
  | { type: "RESET_PICKING" }

export const initialStatePicking: State = {
  itemIdexActive: 0,
  processItems: [],
  isEditing: false,
}
const pickingReducer = (state: State, action: PickingActionTypes): State => {
  switch (action.type) {
    case "ADD_PROCESS_ITEM":
      return {
        ...state,
        itemIdexActive: state.itemIdexActive + 1,
        processItems: [...state.processItems, action.payload],
      }

    case "EDIT_ITEM_SERIES": {
      const newProcessItems = state.processItems.map((item) => {
        if (item.sku === action.payload.itemEdit.sku) {
          return {
            ...action.payload.itemEdit,
          }
        }

        return item
      })
      

      return {
        isEditing: false,
        itemIdexActive: action.payload.nextItemIndex,
        processItems: newProcessItems,
      }
    }
    case "CHANGE_ITEM_ACTIVE_FOR_EDIT":
      const index = state.processItems.findIndex(({ sku }) => sku === action.payload)
      return {
        ...state,
        isEditing: true,
        itemIdexActive: index,
      }

    case "RESET_PICKING": {
      return {
        ...initialStatePicking,
      }
    }
    default:
      return state
  }
}

export default pickingReducer
