import { useContext, useEffect, useState } from "react"
import Logger from "classes/Logger"
import { getOrderById } from "services/OrderServices"
import { getShippinGroupDetail } from "services/ShippingGroupService"
import { OderType } from "types/OrderType"
import { getItemsQuantity } from "services/ItemsService"
import { IShippinGroupDetail } from "interfaces/IOrder"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"
import { keyBy } from "helpers/UtilsHelper"

/*
Hook to handle Order Detail and SG detail requests

@param {String} orderId - ID of a Order
@param {String} shippingGroupId - ID of a SG
@return {Object<OderType>} order Object with the order info
@return {Object<IShippinGroupDetail | null >} shippingGroupDetail Object with the SG info

*/

const useGetOrderDetail = (orderId: string, shippingGroupId: string) => {
  const [order, setOrder] = useState<OderType>(null)
  const [shippingGroupDetail, setShippingGoupDetail] = useState<IShippinGroupDetail | null>(null)

  const { errorHander } = useContext(GlobalContext)

  const { isCancel, newCancelToken } = useCancelToken()

  useEffect(() => {
    getDetailOrder(orderId, shippingGroupId)
    // eslint-disable-next-line
  }, [orderId, shippingGroupId])

  /*
Set the order and  shippingGroupDetail state also search the information of the items included in the SG

@param {String} orderId - ID of a Order
@param {String} shippingGroupId - ID of a SG
@return {void} Nothing


*/
  const getDetailOrder = async (orderId: string, shippingGroupId: string) => {
    try {
      const {
        data: { message: order },
      } = await getOrderById(orderId, newCancelToken())

      setOrder(order)

      const {
        data: { message: shippingGroup },
      } = await getShippinGroupDetail(orderId, shippingGroupId, newCancelToken())

      const itemsShippinGroup = shippingGroup?.source?.items
      const skus = itemsShippinGroup.map(({ sku }) => sku)
      const {
        data: { message: items },
      } = await getItemsQuantity(skus, newCancelToken(), order?.channel)

      const keyByItemDetail = keyBy(items.records, "sku")
      const keyByItemAditionalInfo = keyBy(order.items, "sku")

      shippingGroup.source.items = shippingGroup.source.items.map(({ quantity, sku, custom }) => {
        const itemDetail: any = keyByItemDetail[sku]
        const aditionalInfo: any = keyByItemAditionalInfo[sku]
        return {
          sku,
          quantity,
          orderId,
          custom: {
            ...custom,
            thumbnail: aditionalInfo?.custom?.thumbnail,
          },
          name: itemDetail?.name || "",
          categories: itemDetail?.categories || [],
          color: aditionalInfo?.color,
          season: aditionalInfo?.season,
          size: aditionalInfo?.size,
          brand: aditionalInfo?.brand,
          model: aditionalInfo?.model,
        }
      })

      setShippingGoupDetail(shippingGroup)
    } catch (error: any) {
      Logger.error(error)
      if (isCancel(error)) return

      errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
    }
  }

  return {
    order,
    shippingGroupDetail,
  }
}

export default useGetOrderDetail
