import { useEffect, useState, useContext, useReducer } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { ItemsOrderType, ItemOrderType, ProcessShippingGroupActionType } from "types/OrderType"
import { IItems } from "interfaces/IItemsOrder"
import Logger from "classes/Logger"
import { executeShipingGroupAction, retryGetShippinGroupElocker } from "services/ShippingGroupService"
import { MESSAGE_RETIES_ELOCKER, NOTIFY_PICKING } from "helpers/constHelper"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import useCancelToken from "hooks/UseCancelToken"
import { DeliveryTypeEnum, ShippingTypeEnum } from "enums/OrderEnum"
import pickingReducer, { initialStatePicking } from "pages/DetailOrderScreens/Confirm/PickingReducer"
import { OrderShippingType } from "types/OrderType"
import useGetSourceInfo from "hooks/useGetSourceInfo"
import { updateShippingGroup } from "services/ShippingGroupService"
import { useEnv } from "hooks"

const useConfirmOrder = ({
  order,
  shippinGroupDetail,
  itemsOrder,
  shippingGroupId,
  checkedNoLocker,
  setFinishedShippingGroupAction,
}: { itemsOrder: ItemsOrderType } & { shippinGroupDetail: OrderShippingType } & {
  checkedNoLocker: boolean
} & ProcessShippingGroupActionType) => {
  const { notification, errorHander } = useContext(GlobalContext)

  const [pickingTransition, dispachPickingTransition] = useReducer(pickingReducer, initialStatePicking)
  const [itemsAllProcessed, setItemsAllProcessed] = useState(false)
  const [loadingConfirm, setLoadinfConfirm] = useState(false)
  const [canIuseLocker, setCanIuseLocker] = useState(false)
  const [currentSource, setCurrentSource] = useState<string>("")
  const { sources } = useGetSourceInfo(currentSource)
  const [_client] = useEnv().multiclient.get_user_env()

  const { isCancel, newCancelToken } = useCancelToken()

  const validateStatusLockerWom = () => {
    if (
      _client === "wom" &&
      order?.shippingType === ShippingTypeEnum.SP &&
      order?.deliveryType === DeliveryTypeEnum.ED &&
      shippinGroupDetail?.source
    ) {
      setCurrentSource(shippinGroupDetail?.source?.id)
      if (sources && sources[0]?.custom?.providerLocker !== "" && sources[0]?.custom?.providerLocker !== undefined) {
        setCanIuseLocker(true)
      }
    }
  }

  useEffect(() => {
    shippinGroupDetail && validateStatusLockerWom()
    // eslint-disable-next-line
  }, [shippinGroupDetail, sources])

  useEffect(() => {
    if (pickingTransition.isEditing) {
      setItemsAllProcessed(false)
      return
    }
    if (itemsOrder?.length === pickingTransition.processItems.length) {
      setItemsAllProcessed(true)
    }
  }, [pickingTransition.processItems, pickingTransition.isEditing, itemsOrder])

  const getCurrentNextItem = () => {
    let currentItem: ItemOrderType = null
    let nextItems: Array<IItems> = []

    if (itemsOrder && pickingTransition.itemIdexActive > -1) {
      currentItem = itemsOrder[pickingTransition.itemIdexActive]

      nextItems = itemsOrder.filter(({ sku }) => !pickingTransition.processItems.some((item) => item.sku === sku))
    }

    return {
      currentItem,
      nextItems,
    }
  }

  const handleClickConfirmOrder = async () => {
    try {
      const confirmItems = pickingTransition.processItems.filter(({ isConfirm }) => isConfirm)

      if (confirmItems.length === 0) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "No hay ningún producto para confirmar",
            title: NOTIFY_PICKING,
            type: "info",
          },
        })
        return
      }
      setLoadinfConfirm(true)

      if (checkedNoLocker) {
        const dataSG = await updateShippingGroup(
          {
            shippingGroupId,
            custom: {
              noLocker: true,
            },
          },
          newCancelToken(),
        )

        console.log(dataSG)
      }

      const {
        data: { code },
      } = await executeShipingGroupAction({
        action: EShippinggroupAction.accept,
        shippingGroupId,
        requestBody: {
          seller: {
            id: "1",
          },
          items: confirmItems.map(({ sku, quantity, custom }) => ({
            sku,
            quantity,
            custom,
          })),
        },
      })

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        // verify await get info elocker

        if (order && order.shippingType === ShippingTypeEnum.SP && order.deliveryType === DeliveryTypeEnum.ED) {
          Logger.info(`Elocker retries (ORDEN_ID ${order.id}) START ${new Date().toLocaleTimeString()}`)

          const successGetElocker = await retryGetShippinGroupElocker({
            orderId: order.id,
            shippingGroupId,
            cancelToken: newCancelToken(),
          })

          Logger.info(`Elocker retries (ORDEN_ID ${order.id}) END ${new Date().toLocaleTimeString()}`)
          if (!successGetElocker) {
            notification?.dispatch({
              type: "ADD_NOTIFICATION",
              payload: {
                message: MESSAGE_RETIES_ELOCKER,
                title: NOTIFY_PICKING,
                type: "info",
              },
            })
          }
        }

        setFinishedShippingGroupAction(true)
        setLoadinfConfirm(false)

        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Orden confirmada correctamente",
            title: NOTIFY_PICKING,
            type: "success",
          },
        })
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadinfConfirm(false)

      if (isCancel(error)) return
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const backToScan = () => {
    setItemsAllProcessed(false)
    dispachPickingTransition({ type: "RESET_PICKING" })
  }

  const { currentItem, nextItems } = getCurrentNextItem()

  return {
    currentItem,
    nextItems,
    itemsAllProcessed,
    handleClickConfirmOrder,
    loadingConfirm,
    backToScan,
    dispachPickingTransition,
    pickingTransition,
    canIuseLocker,
  }
}

export default useConfirmOrder
