import { Fragment, memo, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { ISearch, ISearchForm } from "interfaces/ISearchForm"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaSearchForm } from "config/ShemaForms"
import {
  inputFormStyle,
  searchFormStyle,
} from "overrides/theme/shiperto/base/components/SearchForm/styles/SearchFormUsers.style"
import loupe_icon from "assets/icons/loupe-icon.svg"
import arrow_left_icon from "assets/icons/arrow-left-icon.svg"
import plus_icon from "assets/icons/plus-icon.svg"
import { useEnv } from "hooks"

const SearchFormUsers = memo(({ onClickSearch, search, isLoading, backButton }: ISearch) => {
  const { register, handleSubmit, setValue } = useForm<ISearchForm>({
    resolver: yupResolver(shemaSearchForm),
  })
  const history = useHistory()
  const { permissions } = useEnv()
  const formRef: any = useRef(null)

  useEffect(() => {
    if (search) setValue("search", search)
  }, [search, setValue])

  const handleClickSubmit = ({ search }: ISearchForm) => {
    onClickSearch({ query: search })
  }

  const InputForm = () => (
    <Fragment>
      <div className="searchform__input--form">
        <input {...register("search")} type="text" placeholder="Buscar usuario" disabled={isLoading} />
        <img
          src={loupe_icon}
          alt="X"
          className="searchform__arrow-left_lupe_icon"
          onClick={() => formRef?.current?.requestSubmit()}
        />
      </div>
      <style jsx>{inputFormStyle}</style>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="searchform__container">
        <form className="searchform__input-form-container" onSubmit={handleSubmit(handleClickSubmit)} ref={formRef}>
          {backButton && (
            <div className="searchform__arrow-left--container" onClick={() => history.goBack()}>
              <img src={arrow_left_icon} alt="<-" />
            </div>
          )}
          <InputForm />
        </form>
        {permissions.get_permission("users", "action-add") && (
          <Link to={"/users/new"} className="searchform__button_create">
            <span>Crear Usuario</span>
            <img src={plus_icon} alt="X" />
          </Link>
        )}
      </div>
      <style jsx>{searchFormStyle}</style>
    </Fragment>
  )
})

export default SearchFormUsers
