import { useContext } from "react"
import { useParams } from "react-router-dom"
import { AuthContext, GlobalContext } from "context/context"
import { useCancelToken, useEnv } from "hooks"
import configEnv from "config/configEnv"
import { TODAY, YERTERDAY } from "helpers/constHelper"
import { formatDate } from "helpers/FormatDateHelper"
import { groupBy } from "helpers/UtilsHelper"
import { request_post } from "services/OSRM"

const formatDateOrders = (orders: any) => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)

  const todayFormat = formatDate(today)
  const yesterDayFormat = formatDate(yesterday)

  orders.forEach((order: any) => {
    let orderDate = formatDate(order.orderCreationDate)
    order.orderCreationDateFormat =
      orderDate === todayFormat ? TODAY : orderDate === yesterDayFormat ? YERTERDAY : orderDate
  })

  return orders
}

const useGetSgsByStatus = () => {
  const context_auth: any = useContext(AuthContext)
  const { query, optionFilter, shippingtype, type }: any = useParams()
  const { isCancel, newCancelToken } = useCancelToken()
  const { sg } = useEnv()
  const { errorHander } = useContext(GlobalContext)

  const get_user_channels = () => {
    let channels = context_auth.state.user.groups
    channels = channels.filter((channel: any) => channel.type === "Channel").map((channel: any) => channel.id)
    return channels
  }

  const get_user_sources = () => {
    let res = context_auth.state.user.groups
    res = res.filter((item: any) => item.type === "Source").map((item: any) => item.id)
    res = res.includes("ALLSOURCES") ? [] : res
    return res
  }

  const get_sg_list_grouped_by_date = async (page: any, sort: any = "desc") => {
    const request_skip_records = page * configEnv.maxRecords
    const request_max_records = configEnv.maxRecords
    const shipping_type: any = shippingtype?.toUpperCase()

    const request_fields: any = [
      "id",
      "orderId",
      "orderCreationDate",
      "channel",
      "shippingType",
      "salesChannelId",
      "currentStatus",
      "status",
      "source",
      "target",
      "custom",
    ]

    const request_sort = { orderCreationDate: sort }

    const request: any = {}
    request.filters = {}
    request.from = request_skip_records
    request.size = request_max_records
    request.fields = request_fields
    request.sortBy = request_sort
    request.filters.channel = get_user_channels()
    request.filters.shippingType = shipping_type ? shipping_type?.split("_") : sg.get_shippingtype_list()
    request.filters.shippingType = request.filters.shippingType.map((ship_t: any) => ship_t === "HDR" ? "HD" : ship_t)
    if (!!type) request.filters["currentStatus.tag"] = type.toUpperCase()
    if (!!query && optionFilter === "query") request.queryString = query
    if (!!query && optionFilter !== "query") request.filters[`${optionFilter}`] = query

    if (!!shipping_type) {
      request.filters["source.id"] = !["RT", "HDR"].includes(shipping_type) ? get_user_sources() : []
      request.filters["target.source.id"] = ["RT", "HDR"].includes(shipping_type) ? get_user_sources() : []
    } else {
      // TODO: double requets to get both list: source && target.source.id .. dev below
    }

    try {
      return await request_post(`search/shipping_groups`, request, newCancelToken())
    } catch (e: any) {
      if (isCancel(e)) return
      errorHander?.dispatch({ hasError: true, code: e.response?.status })
    }

  }

  const sg_group_by = (data: any) => groupBy("orderCreationDateFormat", formatDateOrders(data))

  return {
    sg_group_by,
    get_sg_list_grouped_by_date,
  }
}

export default useGetSgsByStatus
