import { useReducer } from "react"
import { OrderContext } from "context/context"
import { OrderReducer } from "context/reducers"
import { IContext } from "interfaces/IContext"
import { OrderReducerInit } from "context/inits"

/**
 * Order provider
 *
 * TODO: * Make IOrder Interface *
 *
 * @version 1.0.1
 * @author Naldo Duran<naldorck@gmail.com>
 *
 * @returns {OrderProvider}
 */
const OrderProvider = (props: IContext) => {
  const [state, dispatch] = useReducer(OrderReducer, OrderReducerInit())

  return <OrderContext.Provider value={{ state, dispatch }}>{props.children}</OrderContext.Provider>
}

export default OrderProvider
