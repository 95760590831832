import { useState, useRef, useEffect } from "react"
import SideBarStyled from "./SideBar.styled"
import { LinkIconStyled } from "theme/common/Link.styled"
import HeaderDefault from "components/Theme/Header/Header"
import HeaderWom from "overrides/theme/wom/base/components/Theme/Header/Header"
import { IconArrowLeft, IconClose } from "theme/common/Icon.styled"
import NavigationMenu from "components/Theme/Sidebar/Navigation/NavigationMenu"
import SupportSection from "components/Theme/Sidebar/Support/SupportSection"
import useOnClickOutside from "hooks/useOnClickOutside"
import useLogin from "hooks/UseLogin"
import UseGetMenuUser from "hooks/UseGetMenuUser"
import Env from "classes/Env"
import { useEnv } from "hooks"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"
import { IMenu } from "interfaces/IMenu"

const SideBar = () => {
  const [sidebar, setSidebar] = useState(false)
  const refElement = useRef<HTMLDivElement>(null)

  const [client] = useEnv().multiclient.get_user_env()

  const { getMenuSideBar } = UseGetMenuUser()

  const _menu = getMenuSideBar()
  const [menu, setMenu] = useState(_menu);

  useOnClickOutside({ refElement, handleAction: () => setSidebar(false) })

  const { logout } = useLogin()

  const showSidebar = () => setSidebar(!sidebar)

  const signOut = () => logout()

  const Header = client !== Env.theme.wom.id ? HeaderDefault : HeaderWom

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.createTROrder, ResourcePermissionsEnum.shippingGroup] });

  useEffect( () => {
    const addMenu:any = []
    addMenu.push(...menu)
    
    if(permission[ResourcePermissionsEnum.createTROrder]) {
      const transfer:IMenu = {
        title: "Transferencia",
        path: "/page/transfer",
      }
      addMenu.push(transfer)
    }

    if(permission[ResourcePermissionsEnum.shippingGroup]) {
      const manifest:IMenu = {
        title: "Consultar Manifiestos",
        path: "/page/manifest",
      }
      addMenu.push(manifest)
    }

    setMenu(addMenu)
    // eslint-disable-next-line
  }, [permission])

  return (
    <>
      <Header onShowSidebar={showSidebar} />

      <SideBarStyled sidebar={sidebar} ref={refElement}>
        <div className="sidebar-wraper">
          <div className="header-sidebar">
            <LinkIconStyled to="#" onClick={showSidebar}>
              <IconArrowLeft />
              <span>Cerrar</span>
            </LinkIconStyled>
          </div>
          <section className="option-side-bar">
            {menu?.map((item) => (
              <NavigationMenu menu={item} toggleMenu={showSidebar} key={item.title} />
            ))}
          </section>
          <hr />
          <SupportSection />
          <div className="content-sign-out">
            <LinkIconStyled to="#" onClick={signOut}>
              <IconClose />
              <span>SALIR DE OMNIX</span>
            </LinkIconStyled>
          </div>
        </div>
      </SideBarStyled>
    </>
  )
}

export default SideBar
