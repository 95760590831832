import BackBottonStyled from "components/Commons/BackBotton/BackBotton.styled"
import { useHistory } from "react-router-dom"
import { IconArrowLeft } from "theme/common/Icon.styled"

const BackBotton = ({ children }: any) => {
  const history = useHistory()

  const handleClicGoBack = () => history.goBack()

  return (
    <BackBottonStyled>
      <div className="icon-go-to-back" onClick={handleClicGoBack}>
        <IconArrowLeft />
      </div>
      {children}
    </BackBottonStyled>
  )
}

export default BackBotton
