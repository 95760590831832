import styled from "styled-components"
import { displays } from "theme/base/Variables"

const OrderContainerStyled = styled.section<{ hasCustomFilters?: boolean }>`
  padding: 0 1rem;

  & .section-filters {
    ${displays.flexWrap};
    justify-content: ${({ hasCustomFilters }) => (hasCustomFilters ? "space-between" : "flex-end")};
    gap: 1rem 0;
  }

  & .content-dispatch-action {
    ${displays.flexBase};
    gap: 15px;
    margin: 1rem 0;
    /* check label */
    & > label {
      width: 100%;
    }

    & > button {
      width: 100%;
    }

    &.courier-action {
      justify-content: space-between;
      
      > button {
        width: inherit;
      }
    }

    .attachFilesCourierList {
      .filepond--root {
        height: initial !important;
        contain: initial;
        position: initial;
        margin-bottom: initial;

        .filepond--drop-label {
          height: initial;
          position: initial;
          min-height: initial;
          opacity: initial !important;
          visibility: initial !important;
          transform: initial !important;
          padding: 0.5rem 1rem;

          label {
            padding: 0;
          }
        }

        .filepond--list-scroller,
        .filepond--assistant,
        .filepond--drip,
        .filepond--data,
        .filepond--panel {
          display: none !important;
        }
      }
    }
  }

  & .content-group {
    & .group-item {
      ${displays.flexCenter};
    }
  }
`
export default OrderContainerStyled
