// TODO: Refactor
import useCore from "./clients/useCore"
import usePrivilege from "./clients/usePrivilege"
import useWom from "./clients/useWOM"
import useEnv from "hooks/core/useEnv"

const useGetSgsByStatus = () => {
  let client = useCore
  const [_client] = useEnv().multiclient.get_user_env()
  
  if(_client === "wom") {
    client = useWom
  } else if (_client === "privilege") {
    client = usePrivilege
  } else {
    client = useCore
  }
  return client
}

export default useGetSgsByStatus
