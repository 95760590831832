import { Fragment, useEffect, useImperativeHandle, useState, useCallback, forwardRef } from "react"
import { createPortal } from "react-dom"

const loadingElement = document.getElementById("loading")

const Loading = forwardRef(({defaultOpened = false }: any, ref) => {
  const [isOpen, setIsOpen] = useState(defaultOpened)

  const close = useCallback(() => setIsOpen(false), [])

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close],
  )

  const handleEscape = useCallback(
    (event: any) => {
      if (event.keyCode === 27) close()
    },
    [close],
  )

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false)
    return () => {
      document.removeEventListener("keydown", handleEscape, false)
    }
  }, [handleEscape, isOpen])

  if (!loadingElement) return null
  return createPortal(
    isOpen ? (
      <Fragment>
        <div className="cont">
          <svg>
            <circle cx="50" cy="50" r="40" stroke="#DA5600" strokeDasharray="78.5 235.5" strokeWidth="3" fill="none" />
            <circle cx="50" cy="50" r="30" stroke="#EEAB28" strokeDasharray="62.8 188.8" strokeWidth="3" fill="none" />
            <circle cx="50" cy="50" r="20" stroke="#FAE5B0" strokeDasharray="47.1 141.3" strokeWidth="3" fill="none" />
          </svg>
        </div>
        <style jsx>{`
          .cont {
            position: fixed;
            overflow: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.65);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999999;
            box-sizing: border-box;
          }
          .cont circle {
            transform-origin: center;
            transform-box: fill-box;
            transform-origin: center;
            animation: rotate linear infinite;
          }
          .cont circle:nth-child(1) {
            animation-duration: 1.6s;
          }
          .cont circle:nth-child(2) {
            animation-duration: 1.2s;
          }
          .cont circle:nth-child(3) {
            animation-duration: 0.8s;
          }
          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
          .cont svg {
            width: 100px;
            height: 100px;
          }
        `}</style>
      </Fragment>
    ) : null,
    loadingElement,
  )
})

export default Loading
