import styled from "styled-components"

export const ContentSelect = styled.div`
  position: relative;

  & label {
    color: ${({ theme }) => theme.palette.grey20};
    background: ${({ theme }) => theme.palette.white};
    font-size: 1rem;
    font-weight: 700;
    position: absolute;
    left: 15px;
    top: 11px;
    transition: 0.2s ease all;
    padding: 0 5px;
  }

  & svg.icon-arrow {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 1.5rem;
  }
`

export const SelectStyled = styled.select.attrs({
  required: true,
})`
  height: 44px;
  background: white;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.quaternary || theme.palette.grey20};
  border-radius: 8px;
  padding: 0 1rem;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary};
  appearance: none;


  &::-ms-expand {
    display: none;
  }

  &:disabled {
    background-color: #ECEDF1;
    color: #000;
    opacity: 1;
    border: #ECEDF1 solid 1px;
  }

    &:valid ~ label,
    &:focus ~ label {
      top: -8px;
      font-size: 0.75rem;
    }

  }

  & > option {
    color: ${({ theme }) => theme.palette.primary};
    padding: 15px 5px;

    &:checked {
      background: ${({ theme }) => theme.palette.grey5};
    }

    &:hover {
      background: ${({ theme }) => theme.palette.grey5};
    }
  }
`
export const ContentSelectFilter = styled.div`
  padding: 0 1rem;
`