import { memo, useContext } from "react"
import { AuthContext } from "context/context/AuthContext"
import { pathLogClient } from "helpers/websiteHelper"

const LogoClient = memo(({ render }: { render?: (logo: JSX.Element) => JSX.Element }) => {
  const authState = useContext(AuthContext)
  const user = authState?.state.user

  //get url logo by client pathLogClient config
  const hasLogoClient = user && pathLogClient.includes(user.mainGroup.id)

  if (!hasLogoClient) return null

  return (
    <>
      {render ? (
        render(
          <img
            src={require(`theme/assets/img/LogosClients/${user?.mainGroup.id}.svg`)}
            alt="logo"
          />,
        )
      ) : (
        <img
          src={require(`theme/assets/img/LogosClients/${user?.mainGroup.id}.svg`)}
          alt="logo"
        />
      )}
    </>
  )
})

export default LogoClient
