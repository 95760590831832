import styled from "styled-components"

const SupportWraper = styled.article`
  margin: 15px 20px;
  padding: 0.5rem;

  & .support-title {
    font-weight: 600;
  }

  & p {
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
`

export default SupportWraper
