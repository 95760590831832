import styled, { css } from "styled-components"
import { displays } from "theme/base/Variables"

const active = css`
  ${displays.flexCenter}
  height: 28px;
  width: 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.secondary};
  color: ${({ theme }) => theme.palette.white};
`
const PaginateStyled = styled.div`
  margin: 1rem 0;

  & ul {
    ${displays.flexCenter}
    gap:0.5rem;

    & .active {
      ${active}
    }

    & .prev-next {
      ${displays.flexCenter}
      font-size: 2rem;
      height: 32px;
      width: 48px;
      border-radius: 16px;
      background: ${({ theme }) => theme.palette.grey70};
      font-size: 1rem;
      color: ${({ theme }) => theme.palette.white};

      & > svg {
        font-size: 1.2rem;
      }
    }
  }
`

export default PaginateStyled
