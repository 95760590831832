/**
 * Initial values from order reducer
 *
 * @version 1.0.1
 * @author Naldo Duran<naldorck@gmail.com>
 *
 * @returns {typeof IOrderType} * TODO Interface *
 */

const OrderReducerInit = () => {
  return {
    action: null,
    next_page: null
  }
}

export default OrderReducerInit