import { ISelectBoxProps } from "interfaces/ISelectBox"
import { IconArrowDownMain } from "theme/common/Icon.styled"
import { ContentSelect, SelectStyled } from "./SelectBox.styled"

const SelectBox: React.FC<ISelectBoxProps> = ({ options, onchange, defaultValue }) => {
  return (
    <ContentSelect>
      <SelectStyled
        onChange={(event) => {
          onchange(event.currentTarget.value)
        }}
        defaultValue={defaultValue || ""}
        placeholder="Select"
      >
        <option disabled hidden value="">Seleccione...</option>
        {options.map(({ id, value }) => (
          <option key={id} value={id}>
            {value}
          </option>
        ))}
      </SelectStyled>
      <IconArrowDownMain className="icon-arrow" />
      {/* <label>Label</label> */}
    </ContentSelect>
  )
}

export default SelectBox
