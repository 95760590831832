import { useContext } from "react"
import { AuthContext } from "context/context/AuthContext"
import { Field } from "components/OrderDetail/TagPrint/render"

const Fields = (props: any): any => {
  const authState = useContext(AuthContext)
  const website: any = authState?.state.user?.mainGroup.id

  let { field, data } = props
  let values: object
  let template: any

  values = "core" in field ? (values = { ...field["core"] }) : {}
  values = website in field ? { ...values, ...field[website] } : values

  values = Object.entries(values)
    .filter((item: any) => item[1] !== null)
    .map((item: any) => {
      const _item = item[1]
      item[1] = { ...item[1], value: data[_item["input"]] }

      if ("children" in _item) {
        Object.entries(item[1]["children"]).forEach((childrenItem: any) => {
          const childrenInput: any = childrenItem[1]["input"]
          let childrenElement = item[1]["children"][childrenInput]

          item[1]["children"][childrenInput] = {
            ...childrenElement,
            value: data[childrenElement["input"]],
          }
        })
      }

      return item
    })
    .filter((item: any) => typeof item[1]["value"] !== "undefined" || "forceShow" in item[1])
    .sort(([, a], [, b]): any => a["order"] - b["order"])
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

  template = Object.entries(values).map(([key, value]: any) => <Field key={key} {...value} />)

  return template
}

export default Fields
