import styled from "styled-components"

const HomeButtonStyled = styled.section`
  padding: 1rem;

  & > button {
    width: 100%;
  }
`

export default HomeButtonStyled
