import { IOptionSelect } from "interfaces/ISelectBox"

export const ShippingTypeOptions: Array<IOptionSelect> = [
  {
    value: "Ver todos",
    id: "",
  },
  {
    value: "HD",
    id: "HD",
  },
  {
    value: "SP",
    id: "SP",
  },
  {
    value: "TR",
    id: "TR",
  },
  {
    value: "RT",
    id: "RT",
  },
]