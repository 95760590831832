import { useContext, useEffect, useState } from "react"
import Logger from "classes/Logger"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"
import { fetchGetTicket } from "mocks/get_ticket"
import { AuthContext } from "context/context"
import { WebAppClientsEnum } from "enums/CommonEnum"
import { getTicketOrderServiceProd } from "services/OrderServices"
import useEnv from "./core/useEnv"

interface IGetTicketOrder {
  orderId: string
}

const UseGetTicketOrder = ({ orderId }: IGetTicketOrder) => {
  const [ticket, set_ticket] = useState<any>()
  const [loading_ticket, set_loading_ticket] = useState<boolean>(true)
  const { errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()
  const {
    state: { user },
  } = useContext(AuthContext)
  const client = user?.mainGroup.id
  const [, _env] = useEnv().multiclient.get_user_env()

  useEffect(() => {
    if (client === WebAppClientsEnum.privilege) {
      _env === "prod" ? getTicketActionFromService() : getTicketAction()
    }
    // eslint-disable-next-line
  }, [])

  const getTicketAction = async () => {
    try {
      const data: any = await fetchGetTicket(orderId)
      set_ticket(data?.base64)
      set_loading_ticket(false)
    } catch (error: any) {
      Logger.error(error)
      if (isCancel(error)) return

      errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
      set_loading_ticket(false)
    }
  }

  const getTicketActionFromService = async () => {
    try {
      const { data: _data }: any = await getTicketOrderServiceProd(orderId, newCancelToken())
      set_ticket(_data?.base64)
      set_loading_ticket(false)
    } catch (error: any) {
      Logger.error(error)
      if (isCancel(error)) return

      if (error.response?.status === 401) {
        errorHander?.dispatch({
          hasError: true,
          code: error.response?.status || TransactionCodeEnum.server,
        })
      }
      set_loading_ticket(false)
    }
  }

  return { ticket, loading_ticket }
}

export default UseGetTicketOrder
