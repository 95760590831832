import { IconArrowDown, IconArrowUp } from "theme/common/Icon.styled"

import { IMenu } from "interfaces/IMenu"

export const sideBarData: IMenu[] = [
  {
    title: "Inicio",
    path: "/dashboard",
    iconClosed: <IconArrowDown />,
    iconOpened: <IconArrowUp />,
  },
  {
    title: "Pedidos",
    iconClosed: <IconArrowDown />,
    iconOpened: <IconArrowUp />,
    path: "/order",

    subNav: [],
  },
  {
    title: "Reportes",
    path: "/report",
  },
]
