import { createContext } from "react"
import { OrderReducerInit } from "context/inits"

/**
 * Order Context
 *
 * TODO: * Make IOrderType Interface *
 * 
 * @version 1.0.1
 * @author Naldo Duran<naldorck@gmail.com>
 *
 * @returns {typeof OrderContext} 
 */

const OrderContext = createContext<any>({
  state: OrderReducerInit(),
  dispatch: () => null
})

export default OrderContext