import { useState, useEffect } from "react"
import { IOptionSelect } from "interfaces/ISelectBox"
import { useEnv } from "hooks"
import { ShippingTypeOptions } from "theme/assets/Data/ShippingTypeOptionsData"

const UseFilterShippingType = ({
    shippingTypeOption,
}: { shippingTypeOption:string } ) => {
    const [client] = useEnv().multiclient.get_user_env()

    const [optionsComboBox, setOptionsComboBox] = useState<Array<IOptionSelect>>([])

    const [canIuseFilterShippingType, setCanIuseFilterShippingType] = useState(false)
    
    useEffect( () => {
        let values: IOptionSelect[] = []
        ShippingTypeOptions.map(item => {
            values.push(item)
            return false
        })
        setOptionsComboBox(values);
        
        (client === "sandbox" || client === "entel") && setCanIuseFilterShippingType(true)
        //eslint-disable-next-line
    }, [])
  
    return {
        optionsComboBox,
        canIuseFilterShippingType,
    }
}
  
export default UseFilterShippingType