import { useState } from "react"
import { SortOrderEnum } from "enums/OrderEnum"
import { STORAGE_SORT_ORDER } from "helpers/constHelper"
import { ISortOrders } from "interfaces/ISortOrders"
import { IconSortAsc, IconSortDesc } from "theme/common/Icon.styled"
import SortOrderStyled from "./SortOrder.styled"

const SortOrder = ({ handleChangeSort, disabled }: ISortOrders) => {
  const storageSortOrder = localStorage.getItem(STORAGE_SORT_ORDER)
  const [isAscendig, setIsAscendig] = useState(
    !storageSortOrder ? true : JSON.parse(storageSortOrder) === SortOrderEnum.asc,
  )

  const handleClickSort = () => {
    const sortAscendig = !isAscendig
    setIsAscendig(sortAscendig)
    handleChangeSort(sortAscendig ? SortOrderEnum.asc : SortOrderEnum.desc)
  }

  return (
    <SortOrderStyled onClick={handleClickSort} disabled={disabled}>
      {isAscendig ? (
        <>
          <p> Ver las más recientes </p>
          <IconSortAsc />
        </>
      ) : (
        <>
          <p> Ver las más antiguas</p>
          <IconSortDesc />
        </>
      )}
    </SortOrderStyled>
  )
}
export default SortOrder
