import styled, { css } from "styled-components"
import { displays } from "theme/base/Variables"
import { respondTo } from "theme/base/Mixins"

const HeaderStyle = styled.header<{ showProfileUser: boolean }>`
  ${displays.flexColumn};
  align-items: initial;
  padding: 1rem;
  font-size: 1.5rem;

  & .options-header {
    ${displays.flexBetween};
    gap: 0.5rem;

    & .section-icon-menu {
      ${displays.flexBase};
      gap: 0.5rem;
    }

    & svg {
      font-size: 2rem;
    }

    .path-current {
      color: ${({ theme }) => theme.palette.white};
      font-size: 1.5rem;
      ${displays.flexBase};
      flex-grow: 2;
      gap: 0.5rem;
      background: ${({ theme }) => theme.palette.primary};
      border-radius: 7px;
      justify-content: center;
      padding: 0.5rem;
      & > span {
        font-size: 1rem;
        font-weight: bold;
        word-wrap: break-word;
        max-width: 200px;
      }

      ${respondTo.sm`
        & > span {
            font-size: 1.125rem;
            word-wrap: unset;
            max-width: unset;
     
        }
      `}
    }
    & .icon-user {
      ${displays.flexBase}
      gap: .5rem;

      & p {
        font-size: 1.125rem;
      }
    }
  }

  ${({ showProfileUser, theme }) =>
    showProfileUser &&
    css`
      background: ${theme.palette.primary};
      color: ${theme.palette.white};
      transition: all 0.3s ease-in;
      & img,
      svg {
        filter: brightness(0) invert(1);
      }
    `}
`

export default HeaderStyle
