import { useState } from "react"

const useLocalStorage = <T>(key: string, initialState: T) => {
  const [storedValue, setStoreValue] = useState<T>(() => {
    try {
      const value = localStorage.getItem(key)
      return value ? JSON.parse(value) : initialState
    } catch (error) {
      return initialState
    }
  })

  const setValue = (value: T | ((value: T) => T)) => {
    const valeStorage = value instanceof Function ? value(storedValue) : value
    setStoreValue(value)

    localStorage.setItem(key, JSON.stringify(valeStorage))
  }

  return [storedValue, setValue] as const
}

export default useLocalStorage
