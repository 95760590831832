import { AuthContext } from "context/context/AuthContext"
import { WebAppClientsEnum } from "enums/CommonEnum"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import { getFormatedSeriesProduct } from "helpers/UtilsHelper"
import { IProcessItem } from "interfaces/IItemsOrder"
import { Tuple, TypeVariation4, TypeVariation6 } from "types/CommonsTypes"
import { ActionCancelOrderType, ProductInfoByClientType } from "types/MultiClientType"
import { useContext } from "react"

export const useGetProductInfoByClient = (
  itemOrder: IProcessItem | TypeVariation6,
): Array<Tuple<TypeVariation4 | null | number>> => {
  const {
    state: { user },
  } = useContext(AuthContext)

  let response: Array<Tuple<TypeVariation4 | null | number>>

  const defaulInfo: Array<Tuple<TypeVariation4 | null | number>> = [
    ["SKU", itemOrder?.sku],
    ["Categoria", itemOrder?.categories?.map((category) => category).join("-")],
    ["Cantidad", itemOrder?.quantity],
  ]

  const client: ProductInfoByClientType = {
    [WebAppClientsEnum.wom]: itemOrder?.custom?.series
      ? [...defaulInfo, ["Series", getFormatedSeriesProduct(itemOrder.custom.series)]]
      : defaulInfo,
    [WebAppClientsEnum.privilege]: [
      ["SKU", itemOrder?.sku],
      ["Color", itemOrder?.color?.code],
      ["Talla", itemOrder?.size?.code],
      ["Temporada", itemOrder?.season],
      ["Marca", itemOrder?.brand],
      ["Modelo", itemOrder?.model?.name],
      ["Cantidad", itemOrder?.quantity],
    ],
    [WebAppClientsEnum.entel]: [
      ["Nombre", itemOrder?.name],
      ["SKU", itemOrder?.sku],
      ["Cantidad", itemOrder?.quantity],
    ],
  }

  response = client[user?.mainGroup.id as keyof typeof client] || defaulInfo
  response = response.filter((item: any) => typeof item[1] !== "undefined" && item[1] !== null)
  return response
}

export const useGetCancelActionByClient = (): Tuple<EShippinggroupAction> => {
  const {
    state: { user },
  } = useContext(AuthContext)

  const defaultAction: Tuple<EShippinggroupAction> = [
    ResourcePermissionsEnum.rejectOrder,
    EShippinggroupAction.reject,
  ]

  const client: ActionCancelOrderType = {
    [WebAppClientsEnum.wom]: [
      ResourcePermissionsEnum.cancelLogistic,
      EShippinggroupAction.cancelLogistic,
    ],
    [WebAppClientsEnum.sandbox]: [
      ResourcePermissionsEnum.rejectOrder,
      EShippinggroupAction.cancel,
    ],
    [WebAppClientsEnum.privilege]: defaultAction,
    [WebAppClientsEnum.entel]: [
      ResourcePermissionsEnum.rejectOrder,
      EShippinggroupAction.cancel,
    ],
  }

  return client[user?.mainGroup.id as keyof typeof client] || defaultAction
}
