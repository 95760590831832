import { useContext, useState } from "react"
import Logger from "classes/Logger"
import { massiveLoadOrders } from "services/OrderServices"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"
import { useHistory } from "react-router-dom"

const useMassiveLoadOrders = ({ params = {}, redirectTo = false }: any) => {
  const [loading, set_loading] = useState(false)
  const { notification, errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()
  const history = useHistory()

  const loadOrdersAction = async (body: any) => {
    try {
      set_loading(true)
      await massiveLoadOrders(body, params, newCancelToken())
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "se cargaron correctamente las órdenes",
          title: "Ordenes Cargadas",
          type: "success",
        },
      })
      set_loading(false)
      redirectTo && history.push(redirectTo)
    } catch (error: any) {
      set_loading(false)
      Logger.error(error)
      if (isCancel(error)) return
      if (error.response?.status === 500) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "algo salió mal al cargar las órdenes",
            title: "ERROR",
            type: "danger",
          },
        })
      } else {
        errorHander?.dispatch({
          hasError: true,
          code: error.response?.status || TransactionCodeEnum.server,
        })
      }
    }
  }

  return {
    loading,
    loadOrdersAction,
  }
}

export default useMassiveLoadOrders
