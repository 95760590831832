/**
 * Order Action list
 *
 * @version 1.0.1
 * @author Naldo Duran<naldorck@gmail.com>
 *
 * @returns {IOrderType} * TODO Interface *
 */
const OrderActionsType = {
  set_action: "[order] set action",
  set_next_page: "[order] set next page",
}

export default OrderActionsType
