import { Fragment, useState } from "react"
import { SortOrderEnum } from "enums/OrderEnum"
import { STORAGE_SORT_ORDER } from "helpers/constHelper"
import { ISortOrders } from "interfaces/ISortOrders"
import { IconSortAsc, IconSortDesc } from "theme/common/Icon.styled"
import {sortOrderStyled} from "overrides/theme/shiperto/base/components/Core/Order/Sort/SortOrder.styled"

const SortOrder = ({ handleChangeSort, disabled }: ISortOrders) => {
  const storageSortOrder = localStorage.getItem(STORAGE_SORT_ORDER)
  const [isAscendig, setIsAscendig] = useState(
    !storageSortOrder ? true : JSON.parse(storageSortOrder) === SortOrderEnum.asc,
  )

  const handleClickSort = () => {
    const sortAscendig = !isAscendig
    setIsAscendig(sortAscendig)
    handleChangeSort(sortAscendig ? SortOrderEnum.asc : SortOrderEnum.desc)
  }

  return (
    <Fragment>
      <div onClick={handleClickSort} className="sort-order__container">
        {isAscendig ? (
          <>
            <p> Ver las más recientes </p>
            <IconSortAsc />
          </>
        ) : (
          <>
            <p> Ver las más antiguas</p>
            <IconSortDesc />
          </>
        )}
      </div>
      <style jsx>{sortOrderStyled}</style>
    </Fragment>
  )
}
export default SortOrder
