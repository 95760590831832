import { FunctionComponent, useEffect, Fragment, useContext, Suspense } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { shemaBulkFilesUpladerForm } from "config/ShemaForms"
import Logger from "classes/Logger"
import IPage from "interfaces/IPage"
import useFormConfig from "hooks/Media/useFormConfig"
import folder_upload_icon from "assets/icons/folder_upload_icon.svg"
import { bulkLoadScreenStyle } from "overrides/theme/shiperto/base/pages/BulkLoadScreen/BulkLoadScreen.style"
import useMassiveLoadOrders from "hooks/useMassiveLoadOrders"
import Loading from "components/Commons/Loading/Loading"

const BulkLoadScreen: FunctionComponent<IPage> = (props: any) => {
  const { name } = props
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const { errors, FileUpload, handleSubmit, register, setValue } = useFormConfig({
    customSchema: shemaBulkFilesUpladerForm,
  })
  const { loading, loadOrdersAction } = useMassiveLoadOrders({
    params: { channel: "shiperto", autoConfirm: true },
    redirectTo: "/"
  })

  useEffect(() => {
    displayHeaderRouteName.dispatch("Creación de Órdenes Masivas")
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  const onSubmit = (data: any) => {
    const { filesBulkOrders } = data
    const formData = new FormData()
    formData.append("file", filesBulkOrders[0])
    loadOrdersAction(formData)
  }

  return (
    <Fragment>
      {loading && <Loading defaultOpened={loading} />}
      <div className="bulk_load_screen_main_container">
        <div className="bulk_load_screen_header">
          <h4>Importar Órdenes</h4>
        </div>
        <div className="bulk_load_screen_body">
          <form className="bulk_load_screen_body_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="bulk_load_screen_body_form_group">
              <label>Tipo de carga</label>
              <select disabled>
                <option disabled value="">
                  Tipo
                </option>
                <option {...register("type")} selected value="orders">
                  Órdenes
                </option>
              </select>
            </div>
            <img src={folder_upload_icon} alt="X" className="bulk_load_screen_body_form_icon_load" />
            <div className="bulk_load_screen_uploader">
              {FileUpload && (
                <Suspense fallback={null}>
                  <section className="padding-content ">
                    <FileUpload name="filesBulkOrders" register={register} setValue={setValue} />
                  </section>
                </Suspense>
              )}

              {errors?.filesBulkOrders && (
                <p className="bulk_load_screen_body_form_error_message">{errors?.filesBulkOrders.message}</p>
              )}
            </div>
            <button type="submit" className="button-form">
              Cargar Archivo
            </button>
            <div className="bulk_load_screen_body_form_disclaimer_cont">
              <p>**Consideraciones: Formato CSV, Peso max. 3MB, Max. 50 registros.</p>
            </div>
          </form>
          <div className="bulk_load_screen_body_cont_download_template">
            <a
              className="bulk_load_screen_body_button_download_template"
              href="/shiperto_bulk_order_template.csv"
              target="_blank"
            >Descargar plantilla</a>
          </div>
        </div>
      </div>
      <style jsx>{bulkLoadScreenStyle}</style>
    </Fragment>
  )
}

export default BulkLoadScreen
