import styled from "styled-components"

export const ContainerWindowModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: auto;
  max-height: auto;
  background: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.primary};
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem;
  text-align: center;
`
export const TitleModal = styled.h4`
  padding: 1rem 0;
`

export const DescriptionModal = styled.p`
  padding: 2rem 0;
  font-weight: none;
  color: ${({ theme }) => theme.palette.black};
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: auto;
  & button + button {
    margin-left: 10px;
  }
`