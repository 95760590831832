export const OrderDetailSeriesListStyle = `
  .detail_serie__main {
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: 1px solid red;
    padding: 1rem 1.5rem;
    background: white;
    border: 1px solid #DDDDE9;
    border-radius: 1rem;
  }
  .detail_serie__main .detail_serie__main__line {
    width: 95%;
    border-bottom: 1px solid #DDDDE9;  
  }
  .detail_serie__main p, .detail_serie__info_cont p {
    white-space: no-wrap;
  }
  .detail_series__cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 1rem;
  }
  .detail_serie__info_cont {
    display: flex;
    flex-direction: column;
  }
  .detail_serie__main h4, .detail_serie__info_cont p span {
    font-weight: 600;
  }
  @media screen and (min-width: 768px) {
    
  }
`
