import ProcessProductCard from "components/Transfer/ProcessProductCard/ProcessProductCard"
import { IProcessProductsList } from "interfaces/IProcessProductsList"
import { GridContainer } from "theme/common/Grid.styled"

const ProcessProductsList = ({ processItems = [], onClickDeleteItem }: IProcessProductsList) => {
  return (
    <GridContainer className="padding-content" minSize={"320px"}>
      {processItems.map(({ sku, isConfirm, name, custom, quantity }) => (
        <ProcessProductCard
          key={sku}
          isConfirm={isConfirm}
          name={name}
          sku={sku}
          custom={custom}
          quantity={quantity}
          onClickDeleteItem={onClickDeleteItem}
        />
      ))}
    </GridContainer>
  )
}

export default ProcessProductsList
