import { forwardRef } from "react"
import InputContainerStyled from "theme/common/Input.styled"
import { IPropsInput } from "interfaces/IPropsInput"

const Input = forwardRef<null, IPropsInput>(
  ({ type, placeHolder, label, name, className, autocompleteValue, value, onChange, disabled = false }, ref) => {
    return (
      <InputContainerStyled className={className}>
        {label && <label htmlFor={name}>{label}</label>}
        <input
          type={type}
          placeholder={placeHolder}
          id={name}
          name={name}
          autoComplete={autocompleteValue}
          ref={ref}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </InputContainerStyled>
    )
  },
)

export default Input
