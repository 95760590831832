import { IRulesJsonEngine } from "interfaces/IRulesJsonEngine"
import { IUserGroups } from "interfaces/IUser"
import { Engine } from "json-rules-engine"

class JsonEngine extends Engine {
  private constructor({ rules }: IRulesJsonEngine) {
    super(rules)
  }

  private static instance: JsonEngine | null

  public static getInstance({ rules }: IRulesJsonEngine): JsonEngine {
    if (!JsonEngine.instance) {
      JsonEngine.instance = new JsonEngine({ rules })
      this.configurateUserPermissionRules()
    }

    return JsonEngine.instance
  }

  private static configurateUserPermissionRules() {
    this.instance?.addOperator("empty", (factValue: Array<string>) => {
      return !factValue || (factValue && factValue.length === 0)
    })
    this.instance?.addOperator("notExistsOrWithValue", (factValue: boolean, jsonValue) => {
      return !factValue || factValue.toString() === jsonValue
    })
    this.instance?.addOperator(
      "inGroups",
      (factValue: Array<string>, userGroups: Array<IUserGroups>) => {
        return (
          factValue &&
          userGroups &&
          userGroups
            .map((group) => {
              return group.id
            })
            .indexOf(factValue[0]) >= 0
        )
      },
    )
    this.instance?.addOperator(
      "groupContains",
      (userGroups: Array<IUserGroups>, groupToSearchStr: any) => {
        return (
          groupToSearchStr &&
          userGroups &&
          userGroups
            .map((group) => {
              return group.id
            })
            .indexOf(groupToSearchStr) >= 0
        )
      },
    )
  }

  public static clear() {
    this.instance = null
  }
}

export default JsonEngine
