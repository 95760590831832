export const searchResultsScreenStyle = `
  .search-results-screen__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .search-results__order-list-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    padding: 1em;
    margin-top: 1em;
    border-radius: 1rem;
  }
  .order-screen__paginate-container {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`