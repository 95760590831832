import {
  IconBoxUnpacking,
  IconBoxArchiveRegister,
} from "theme/common/Icon.styled"

export const pageOptions = [
  {
    title: "Transferencia",
    icon: <IconBoxUnpacking />,
    path: "/page/transfer",
  },
  {
    title: "Consultar Manifiestos",
    icon: <IconBoxArchiveRegister />,
    path: "/page/manifest",
  },
]