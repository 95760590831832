import { IProcessItem } from "interfaces/IItemsOrder"
import ProcessProductCardStyled from "components/OrderDetail/ProcessProducts/ProcessProductCard/ProcessProductCard.styled"
import { IconCheck, IconReject } from "theme/common/Icon.styled"
import { LinkBase } from "theme/common/Link.styled"
import { getFormatedSeriesProduct } from "helpers/UtilsHelper"

const ProcessProductCard = ({
  name,
  sku,
  quantity,
  custom,
  isConfirm,
  onClickEditItem,
}: IProcessItem) => {
  const handleClickEdit = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (onClickEditItem) onClickEditItem(sku)
  }

  return (
    <ProcessProductCardStyled isConfirm={isConfirm}>
      <div className="section-info">
        <p className="relevant-title">{name}</p>
        <p>
          <span>SKU:</span> <span className="relevant-title">{sku}</span>
        </p>

        {quantity && (
          <p>
            <span>Cantidad:</span> <span className="relevant-title">{quantity}</span>
          </p>
        )}
        {custom?.series && (
          <p>
            <span>Seriales:</span>
            <span className="relevant-title">{getFormatedSeriesProduct(custom.series)}</span>
          </p>
        )}
      </div>
      <div className="section-info">
        {isConfirm ? <IconCheck /> : <IconReject />}
        <LinkBase onClick={handleClickEdit}>EDITAR</LinkBase>
      </div>
    </ProcessProductCardStyled>
  )
}

export default ProcessProductCard
