import { FunctionComponent, useCallback, useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import Logger from "classes/Logger"
import SummaryOptions from "components/Summary/SummaryOptions"
import IPage from "interfaces/IPage"
import UseGetMenuUser from "overrides/theme/wom/base/hooks/UseGetMenuUser"
import UseGroupedOrderByCount from "overrides/theme/wom/base/hooks/UseGroupedOrderByCount"
import { ISearchResults } from "interfaces/ISearchResults"
import { AuthContext } from "context/context/AuthContext"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"
import SelectBox from "components/Commons/SelectBox/SelectBox"
import UseFilterShippingType from "hooks/UseFilterShippingType"
import { ContentSelectFilter } from "components/Commons/SelectBox/SelectBox.styled"
import Multiclient from "components/Core/Multiclient"

const DashBordScreen: FunctionComponent<IPage> = (props: any) => {

  const {
    state: { user },
  } = useContext(AuthContext)

  const monitorUser = () => {
    return user?.role === ResourceRoleEnum["webapp-monitor-source"]
  }

  const { name } = props
  const history = useHistory()

  const [shippingTypeOption, setShippingTypeOption] = useState("")

  const handleChangeShippingTypeOptions = (value: string) => setShippingTypeOption(value)

  const {
    optionsComboBox,
    canIuseFilterShippingType
  } = UseFilterShippingType({
    shippingTypeOption
  })

  const [reload, setReload] = useState(false)

  const [initState, setInitState] = useState(true)

  const { getMenuSummaryOrder } = UseGetMenuUser()

  const { groupedOrder } = UseGroupedOrderByCount(shippingTypeOption)

  const summaryMenuOrder = getMenuSummaryOrder(shippingTypeOption)

  useEffect(() => {
    const st = setTimeout(() => {
      if(monitorUser()) {
        groupedOrder()
        setReload(!reload)
      }
    }, 1000 * 60)

    return () => clearTimeout(st)
    //eslint-disable-next-line
  }, [reload])

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  const handleClickSearch = useCallback(
    ({ query, optionFilter }: ISearchResults) => {
      if (query) history.push(`/search/${query}/${optionFilter}`)
    },
    [history],
  )

  useEffect( () => {
    if(canIuseFilterShippingType && initState) setInitState(false);

    if(!initState) groupedOrder()
    //eslint-disable-next-line
  }, [canIuseFilterShippingType, shippingTypeOption])

  return (
    <>
      {!monitorUser() && (
        <Multiclient
          path_override="overrides/theme/wom/base/components/SearchForm/SearchForm"
          onClickSearch={handleClickSearch}
        />
      )}
      {canIuseFilterShippingType && (
        <ContentSelectFilter>
            <SelectBox
              options={optionsComboBox}
              onchange={handleChangeShippingTypeOptions}
              defaultValue={""}
            />
        </ContentSelectFilter>
      )}
      {summaryMenuOrder && <SummaryOptions summaryOptions={summaryMenuOrder} />}
    </>
  )
}

export default DashBordScreen
