import { useTranslation } from "react-i18next"
import SimplyCarousel from "overrides/theme/entel/base/pages/DetailOrderScreens/Carousel"
import { OrderDetailSeriesListStyle } from "overrides/theme/entel/base/pages/DetailOrderScreens/styles/OrderDetailSeriesList.style"

const DetailSerie = ({ item }: any) => {
  return (
    <div className="detail_serie__main">
      <h4>SKU: {item?.sku}</h4>
      <div className="detail_serie__main__line"></div>
      <div className="detail_series__cont">
        {item?.series.map((serie: any, i: number) => (
          <div className="detail_serie__info_cont">
            <p>
              <span>Serie {i + 1}:</span> {serie?.serie}
            </p>
            <p><span>Estado:</span> {serie?.state}</p>
            <p><span>Nota:</span> {serie?.notes}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

const OrderDetailSeriesList = (props: any) => {
  const { t } = useTranslation()
  const { data } = props
  const series = data?._sg?.logisticPlan?.items?.map((item: any) => {
    return { sku: item?.sku, series: item?.series || [] }
  })
  const isThereSeries = series?.some((item: any) => Boolean(item?.series?.length)) || null

  const template = (
    <article className="order__products">
      <h2>{t("Series")}</h2>
      <section className="order__products__list">
        <SimplyCarousel>
          {series?.map((item: any, index: any) => (
            <DetailSerie key={index} item={item} />
          ))}
        </SimplyCarousel>
      </section>
      <style jsx>{OrderDetailSeriesListStyle}</style>
    </article>
  )

  return isThereSeries && template
}

export default OrderDetailSeriesList
