import { lazy } from "react"
import { ComponentsType } from "types/CommonsTypes"

const ManualTransition = lazy(() => import("components/Core/ProductTransition/ManualTransition"))

const TagCustomOrder = lazy(() => import("webapp-clients/Sbx/components/TagCustomOrder"))

const FileUpload = lazy(() => import("components/Commons/FileUpload/FileUpalder"))

const ContentFileMedia = lazy(
  () => import("webapp-clients/Wom/Components/ViewContentMedia/ContentFileMedia"),
)

export const sandbox: ComponentsType = {
  ContentFileMedia,
  ProductTransition: ManualTransition,
  FileUpload,
  TagCustomOrder
}
