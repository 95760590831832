import { passwordStrength } from 'check-password-strength'

const passwordStrengthValidation = (text: string): [number, string] => {
  if (!text) return [0, ""]
  const value = passwordStrength(text).id
  switch (value) {
    case 0:
    case 1:
      return [1, "Débil"]
    case 2:
      return [2, "Normal"]
    case 3:
      return [3, "Fuerte"]
    default:
      return [0, ""]
  }
}

/* eslint-disable */
export const password_generator = (len: number = 10) => {
  let string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
  let numeric = '0123456789';
  let punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
  let password = "";
  let character = "";
  while( password.length<len ) {
      let entity1 = Math.ceil(string.length * Math.random()*Math.random());
      let entity2 = Math.ceil(numeric.length * Math.random()*Math.random());
      let entity3 = Math.ceil(punctuation.length * Math.random()*Math.random());
      let hold = string.charAt( entity1 );
      hold = (password.length%2==0)?(hold.toUpperCase()):(hold);
      character += hold;
      character += numeric.charAt( entity2 );
      character += punctuation.charAt( entity3 );
      password = character;
  }
  password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
  return password.substr(0,len);
}
/* eslint-enable */

export default passwordStrengthValidation