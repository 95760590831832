export enum ShippingTypeEnum {
  SP = "SP",
  HD = "HD",
  SD = "SD",
  ED = "ED",
}

export enum DeliveryTypeEnum {
  ED = "ED",
  SD = "SD",
}

export enum SortOrderEnum {
  asc = "asc",
  desc = "desc",
}

export enum ChannelEnum {
  ecommerce = "ecommerce",
  televentaswm = "televentaswm",
  televentas = "TELEVENTAS",
  crm = "crm"
}
