import { INotification } from "interfaces/INotification"
import { NotificationListStyled } from "theme/common/Notification.styled"
import Multiclient from "components/Core/Multiclient"

const NotificationList = ({ notifications }: { notifications: Array<INotification> }) => {
  return (
    <NotificationListStyled>
      {notifications.map(({ id, title, type, message, delay }) => (
        <Multiclient
          path_override="components/Commons/Notifications/Notification"
          key={id}
          id={id}
          message={message}
          title={title}
          type={type}
          delay={delay}
        />
      ))}
    </NotificationListStyled>
  )
}

export default NotificationList
