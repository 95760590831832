import { IProductDetail } from "interfaces/IProductDetail"
import Skeleton from "react-loading-skeleton"

const ProductDetail = ({ fields, hasPlaceHolder = true }: IProductDetail) => {
  return (
    <>
      {fields.map((field) => {
        const [label, value] = field
        return (
          <p key={label}>
            {value ? (
              <>
                <span className="relevant-title"> {label}: </span>
                {value}
              </>
            ) : (
              <>{hasPlaceHolder ? <Skeleton width={150} /> : null}</>
            )}
          </p>
        )
      })}
    </>
  )
}

export default ProductDetail
