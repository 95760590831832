import { useEffect, useState } from "react"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"

const UseViewPickupCode = () => {

	const [canIuseViewPickupCode, setcanIuseViewPickupCode] = useState(false)
	const [canIuseViewPickupCodePorta, setCanIuseShowPickupCode] = useState(false)
	const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.viewPickupCode, ResourcePermissionsEnum.viewPickupCodePorta] });

	useEffect( () => {
		permission[ResourcePermissionsEnum.viewPickupCode] && setcanIuseViewPickupCode(true)
		permission[ResourcePermissionsEnum.viewPickupCodePorta] && setCanIuseShowPickupCode(true)
	}, [permission])

  return {
    canIuseViewPickupCode,
		canIuseViewPickupCodePorta,
  }
}

export default UseViewPickupCode
