import { useEffect, useContext } from "react"
import ErrorHandledStyled from "components/Commons/Error/ErrorHandler.styled"
import logo from "theme/assets/img/omnix-logo.svg"
import HomeBotton from "components/Commons/HomeBotton/HomeBotton"
import { IErrorHandler } from "interfaces/IErrorHandler"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { ERROR_GENERIC, Error_GNERAL } from "helpers/constHelper"
import useLogin from "hooks/UseLogin"
import { GlobalContext } from "context/context/GlobalContext"

const ErrorHandler = ({
  code = TransactionCodeEnum.notFound,
  message = ERROR_GENERIC,
}: IErrorHandler) => {
  const { errorHander } = useContext(GlobalContext)
  const { logout } = useLogin()

  let messageResult = code !== TransactionCodeEnum.notFound ? Error_GNERAL : message
  useEffect(() => {
    if (code === TransactionCodeEnum.unauthorized) logout()
  }, [code, logout, errorHander])

  return (
    <ErrorHandledStyled>
      <div className="content-error">
        <div className="content-error-bg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <img alt="logo" src={logo} loading="lazy" />
        <h1>oops!</h1>
        <p className="error-title">
          <strong>{code}</strong> : {messageResult}
        </p>
        <div className="content-botton-back">
          <HomeBotton onClickBackBotton={() => errorHander?.dispatch({ hasError: false })} />
        </div>
      </div>
    </ErrorHandledStyled>
  )
}

export default ErrorHandler
