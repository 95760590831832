import { useEffect, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { getGroupedOrder } from "services/ShippingGroupService"
import { AuthContext } from "context/context/AuthContext"
import Logger from "classes/Logger"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { IRequestGroupedOrder } from "interfaces/IOrder"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"
import useEnv from "hooks/core/useEnv"
/*
  Hook to handle the main menu counter for the SG states
*/
const UseGroupedOrderByCount = (shippingTypeOption: string = "") => {
  const {
    state: { user, optionsMenuAccess },
    dispatch,
  } = useContext(AuthContext)

  const { sg } = useEnv()

  const { errorHander } = useContext(GlobalContext)

  /*
   * Make a request to backEnd to get the quantity of the SGS in the sources available for the current user
   * after obtain the information dispatch a action to save the information in the context
   * @return {void} Nothing
   */

  const groupedOrder = async () => {
    if (!user?.currentSources) {
      Logger.error("No found source")
      return
    }
    try {
      let courier = user.groups.filter((_group: any) => _group.type === "Courier").map(({ id }) => id)

      let requestGroupedOrder: IRequestGroupedOrder = {
        shippingTypes: shippingTypeOption !== "" ? [shippingTypeOption] : sg.get_shippingtype_list(),
        sources: user.currentSources,
        status: !courier.length ? sg.get_sg_status_list() : [EShippinggroupStatus.transit, EShippinggroupStatus.pickup, EShippinggroupStatus.closed],
        couriers: courier.length > 0 ? courier : undefined
      }

      if (
        optionsMenuAccess.some(
          ({ statusOrder }) => statusOrder === EShippinggroupStatus.transit_source,
        ) || sg.get_shippingtype_list().includes("RT")
      ) {
        requestGroupedOrder.targetSources = user.currentSources
      }

      const {
        data: { message: groupedOrders },
      } = await getGroupedOrder(requestGroupedOrder)

      dispatch({ type: "[auth] Update Menu user", payload: groupedOrders })
      console.log("UseGroupedOrderByCount")
    } catch (error: any) {
      Logger.error(error)
      if (error.response?.status === TransactionCodeEnum.notFound) {
        dispatch({ type: "[auth] Update Menu user", payload: [] })
        return
      }
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  useEffect(() => {
    groupedOrder()
    //eslint-disable-next-line
  }, [user?.currentSources])

  return {
    groupedOrder
  }
}

export default UseGroupedOrderByCount
