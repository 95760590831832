import { RouteComponentProps } from "react-router-dom"
import { IOptionsAccess } from "interfaces/IUser"
import { getValueShippinggroupStatusEnum } from "./UtilsHelper"

/**
 * This function validates the accesses that the user has to access certain routes, and returns no true or false whether or not he has access to this route.
 * @param param0 {String}. It receives a match which are the props of the String type component.
 * @param optionsMenuAcces {Array} receives an array of access options.
 * @returns {Boolean}.
 */

export const validateAccessRouteOrder = (
  { match }: RouteComponentProps<{ type: string }>,
  optionsMenuAcces?: Array<IOptionsAccess>,
): boolean => {
  const status = getValueShippinggroupStatusEnum(match.params.type)

  if (!status) return false

  // TODO: these paths don't exist in optionsMenuAcces which blocks access to the page they're forced to display with this, this needs refactoring.
  const customRoutes = [
    "pickup_in_my_source",
    "transit_to_source"
  ]

  return optionsMenuAcces
    ? optionsMenuAcces.some(({ statusOrder }) => {
      if (customRoutes.includes(match?.params?.type)) return true
      return statusOrder === status
    })
    : false
}
