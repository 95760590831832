import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { AuthContext } from "context/context/AuthContext"
import { GlobalContext } from "context/context/GlobalContext"
import { useCancelToken } from "hooks"
import { get_osrm_order_group } from "services/ShippingGroupService"
import { Env } from "classes"

const useOrderGrouped = () => {
  const { t } = useTranslation()
  const shiperto_shipping_types = Env.theme.shiperto.sg.shipping_types
  const context_auth: any = useContext(AuthContext)
  const { errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()

  const get_user_channels = () => {
    let channels = context_auth.state.user.groups
    channels = channels.filter((channel: any) => channel.type === "Channel").map((channel: any) => channel.id)
    return channels
  }

  const get_user_sources = () => {
    let res = context_auth.state.user.groups
    res = res.filter((item: any) => item.type === "Source").map((item: any) => item.id)
    res = res.includes("ALLSOURCES") ? [] : res
    return res
  }

  const get_order_grouped = async (shipping_type_list: any, status_list: any, type_source: string) => {
    try {
      const request: any = {
        filters: {
          channel: get_user_channels(),
          [type_source]: get_user_sources(),
          shippingType: shipping_type_list.map((ship_t: any) =>
            ship_t === shiperto_shipping_types[3] ? shiperto_shipping_types[0] : ship_t,
          ),
          "currentStatus.tag": status_list,
        },
        groupBy: {
          "currentStatus.tag": { size: 20 },
        },
      }

      const response = await get_osrm_order_group(request, newCancelToken)
      return response
    } catch (e: any) {
      if (e.response?.status !== 404) errorHander?.dispatch({ hasError: true, code: e.response?.status })
      if (isCancel(e)) return
    }
  }

  const order_list = (response_status_list: any, status_list: any, shipping_type_list: any) => {
    if (!response_status_list) return
    const list = status_list.map((status: any) => {
      const output = {
        optionCount: 0,
        path: `/order/${shipping_type_list.join("_").toLowerCase()}/${status.toLowerCase()}`,
        status: status,
        subTitle: t(`${status}.subTitle`),
        title: t(`${status}.title`),
      }

      for (let response_status of response_status_list) {
        if (!!response_status[status]) {
          output.optionCount = response_status[status]
        }
      }

      return output
    })

    return list
  }

  const order_group_formatted = async (
    shipping_type_list: any,
    status_list: any,
    type_source: string = "source.id",
  ) => {
    const response = await get_order_grouped(shipping_type_list, status_list, type_source)
    return order_list(response?.data?.message?.["currentStatus.tag"], status_list, shipping_type_list)
  }

  return {
    order_group_formatted,
  }
}

export default useOrderGrouped
