import { createContext } from "react"
import { ContextPropType } from "types/ContextPropType"
import { AuthActionTypes } from "context/actionsTypes/AuthTypes"
import { IAuth } from "interfaces/IUser"
import { AuthReducerInit } from "context/inits/AuthReducerInit"

// this is for the context is for the user login
export const AuthContext = createContext<ContextPropType<IAuth, AuthActionTypes>>({
  state: AuthReducerInit(),
  dispatch: () => null,
})
