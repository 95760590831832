import { Env } from "classes"

const get_cancel_reasons = (current_status: string, shippingtype: string) => {
  const statusses = Env.theme.entel.sg[shippingtype.toUpperCase()].status

  const cancel_reasons: any = {
    [statusses[2]]: [{ label: "Series no disponibles", value: "SERIES_NOT_AVAILABLE" }],
    [statusses[4]]: [{ label: "Error en picking", value: "PICKING_ERROR" }],
    [statusses[8]]: [{ label: "Orden incompleta", value: "INCOMPLETE_ORDER" }],
    [statusses[9]]: [
      { label: "Máximo intentos de entrega", value: "MAX_DELIVERY_ATTEMPT" },
      { label: "No pago de envío", value: "NOT_DELIVERY_PAYMENT" },
    ],
    default: [],
  }
  return cancel_reasons[current_status.toUpperCase()] || cancel_reasons.default
}

export default get_cancel_reasons
