import { Suspense, useContext } from "react"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import { OrderDetailType } from "types/OrderType"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { AuthContext } from "context/context/AuthContext"
import { clients } from "webapp-clients/Clients"
import { IconCancel, InboxArchiveFill } from "theme/common/Icon.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import UseCancelOrder from "hooks/UseCancelOrder"
import { useGetCancelActionByClient } from "hooks/UseGetMultiClient"
import UseDeliveredReturnOrder from "hooks/UseDeliveredReturnOrder"

const InReturnScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  finishedShippingGroupAction,
  order,
  setFinishedShippingGroupAction,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker

  const {
    handleClickDeliveredReturnOrder,
    loadingDeliveredReturn,
    canIuseDeliveredReturn,
  } = UseDeliveredReturnOrder({
    order,
    shippingGroupId,
    setFinishedShippingGroupAction
  })

  const handleClickDeliveredReturn = () => {
    handleClickDeliveredReturnOrder(EShippinggroupAction.delivered_return)
  }

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const {
    handleClickCancelOrder,
    loadingCancel,
    canIuseCancel
  } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })

  return (
    <>
      {itemsOrder ? (
        <>
          {!finishedShippingGroupAction && canIuseCancel && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}
          
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos en retorno" : undefined}
          />

          {!finishedShippingGroupAction && canIuseDeliveredReturn && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="primary"
                onClick={handleClickDeliveredReturn}
                loadingButton={loadingDeliveredReturn}
                disabled={loadingDeliveredReturn}
              >
                <InboxArchiveFill />
                <span>Iniciar retorno</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(InReturnScreen)
