import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import useTransitOrder from "pages/DetailOrderScreens/Transit/UseTransitOrder"
import TransitAction from "overrides/theme/wom/base/components/OrderDetail/TransitProductOrder/TransitAction"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { Suspense, useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "context/context/AuthContext"
import { clients } from "webapp-clients/Clients"
import AlertStyled from "theme/common/Alert.styled"
import { IconAttachment } from "theme/common/Icon.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import useHideActionButtons from "hooks/UseHideActionButtons"
import { IconCancel } from "theme/common/Icon.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import useCancelDecreaseOrder from "hooks/UseCancelDecreaseOrder"
import UseDeliveredRejectOrder from "hooks/UseDeliveredRejectOrder"
import { WebAppClientsEnum } from "enums/CommonEnum"
import UseCancelOrder from "hooks/UseCancelOrder"
import { useGetCancelActionByClient } from "hooks/UseGetMultiClient"
import Input from "components/Commons/Input"
import WindowModal from "components/Commons/WindowModal/WindowModal"


// TODO: multiclient
import { useForm } from "react-hook-form"
import { useEnv } from "hooks"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"
import { ShippingTypeEnum } from "enums/OrderEnum"
import UseSendCode from "hooks/UseSendCode"
import SendCode from "components/OrderDetail/SendCode/SendCode"
import Modal from "components/Commons/Modal/Modal"
import { ContentSendCode } from "components/OrderDetail/SendCode/SendCode.styled"
import { shemaFileUpladerFormFull } from "config/ShemaForms"
import { FilesFormType } from "types/FilesFormType"
import FileUpload from "components/Commons/FileUpload/FileUpalder"
import { yupResolver } from "@hookform/resolvers/yup"

const TransitScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { hideTransitActions, updateVisibilityButtonActions } = useHideActionButtons()
  const [_client] = useEnv().multiclient.get_user_env()

  const [canIuseCourierNoExistTransit, setCanIuseCourierNoExistTransit] = useState(true)
  const [rolSellerAndSupCourier, setRolSellerAndSupCourier] = useState(false)

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id,
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[_client as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const NoExistTransit = client["NoExistTransit" as keyof typeof client]
  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(shemaFileUpladerFormFull([])) : undefined,
  })

  const filesShippinGroup = watch("filesShippinGroup")
  const [disabledButton, setDisabledButton] = useState(true)

  const [inputVerificationCode, setInputVerificationCode] = useState('');

  const handleVerificationCode = (value: string) => {
    setInputVerificationCode(value);
  }

  const { submitDeliveredOrder, loadingTransit, loadingValidateCode, checkValidateCode, checkVerificationCode } = useTransitOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
    order,
    refModal,
    inputVerificationCode,
  })

  const { canIuseSendDeliveryCode } = UseSendCode({
    order,
    shippinGroupDetail,
  })
  
  useEffect(() => {
    if (canIuseSendDeliveryCode) {
      filesShippinGroup?.length && canIuseSendDeliveryCode && checkValidateCode
        ? setDisabledButton(false)
        : setDisabledButton(true)
    } else {
      filesShippinGroup?.length ? setDisabledButton(false) : setDisabledButton(true)
    }
  }, [filesShippinGroup, canIuseSendDeliveryCode, checkValidateCode])

  const { handleClickCancelDecreaseOrder, loadingCancelDecrase, canIuseCancelDecraese } =
    useCancelDecreaseOrder({
      order,
      shippingGroupId,
    })

  const { handleClickDeliveredRejectOrder, loadingDeliveredReject, canIuseDeliveredReject } =
    UseDeliveredRejectOrder({
      order,
      shippingGroupId,
    })

  const handleClickCancelDecrease = () => {
    handleClickCancelDecreaseOrder(EShippinggroupAction.cancel_decrease)
  }

  const handleClickDeliveredReject = () => {
    handleClickDeliveredRejectOrder(EShippinggroupAction.delivered_reject)
  }

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const { handleClickCancelOrder, loadingCancel, canIuseCancel } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })
  
  const womRoleCourier = () => {
    if ((user?.role === ResourceRoleEnum["webapp-seller-courier"] || user?.role === ResourceRoleEnum["webapp-sup-courier"]) && (order?.shippingType !== ShippingTypeEnum.HD))
      return false
    else
      return true
  }

  const callbackProcessCode = (state: any) => {
    console.log(state)
  }

  useEffect(() => {
    const courierNoExistTransi: boolean = womRoleCourier()
    setCanIuseCourierNoExistTransit(courierNoExistTransi)
    setRolSellerAndSupCourier(user?.role === ResourceRoleEnum["webapp-seller-courier"] || user?.role === ResourceRoleEnum["webapp-sup-courier"])
    // eslint-disable-next-line
  }, [user, order])

  return (
    <>
      {NoExistTransit && canIuseCourierNoExistTransit && (
        <Suspense fallback={null}>
          <NoExistTransit
            shippingGroupId={shippingGroupId}
            courierDeliveryInfo={shippinGroupDetail?.custom?.courierDeliveryInfo}
            shippinGroupDetail={shippinGroupDetail}
          />
        </Suspense>
      )}
      {itemsOrder ? (
        <>
          {canIuseCancel && _client === WebAppClientsEnum.sandbox && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}

          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos en tránsito" : undefined}
          />
          
          {canIuseSendDeliveryCode && !checkValidateCode && (
            <>
              <AlertStyled variant="info">
                <p>Código de verificación</p>
              </AlertStyled>
              <ContentSendCode className="code-check">
                <div className="box-form">
                  <div className="box-form-fields">
                    <Input
                      type="text"
                      name="verificationCode"
                      placeHolder="Código de verificación"
                      onChange={(e) => handleVerificationCode(e.target.value)}
                      disabled={loadingValidateCode}
                    />
                  </div>
                  <div className="box-form-action">
                    <ButtonStyled
                      variant="primary"
                      loadingButton={loadingValidateCode}
                      disabled={loadingValidateCode}
                      type="button"
                      onClick={checkVerificationCode}
                    >
                      <span>Validar</span>
                    </ButtonStyled>
                  </div>
                </div>
              <Modal ref={refModal}>
                <WindowModal
                  title="Código de verificación"
                  description="El código de verificación no coincide con el enviado al cliente."
                  handleConfirm={() => {
                    refModal.current?.close()
                  }}
                  showCancel={false}
                />
              </Modal>
            </ContentSendCode>
            </>
          )}
          
          {canIuseSendDeliveryCode && !checkValidateCode && (
            <SendCode
              orderId={order?.id}
              customer={order?.customer}
              shippingGroupId={shippingGroupId}
              onProcessCode={callbackProcessCode}
              shippingType={order?.shippingType}
              portabilidad={order?.custom?.portabilidad}
            />
          )}

          {!finishedShippingGroupAction && (
            <>
              <form onSubmit={handleSubmit(submitDeliveredOrder)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section>
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} label="Archivos adjuntos" />
                    </section>
                  </Suspense>
                )}

                {disabledButton && (
                  <AlertStyled variant="danger" className="transparent">
                    <p>Para continuar, necesita adjuntar al menos un archivo</p>
                  </AlertStyled>
                )}

                {errors.filesShippinGroup && (
                  <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>
                )}

                {canIuseCancelDecraese && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickCancelDecrease}
                      loadingButton={loadingCancelDecrase}
                      disabled={loadingCancelDecrase}
                    >
                      <IconCancel />
                      <span>Cancelar Orden sin Modificar Inventario</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {canIuseDeliveredReject && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickDeliveredReject}
                      loadingButton={loadingDeliveredReject}
                      disabled={loadingDeliveredReject}
                    >
                      <IconCancel />
                      <span>Cancelar orden por siniestro</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {!hideTransitActions && <TransitAction loadingTransit={loadingTransit} disabledButton={disabledButton} />}
              </form>
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
              shippinGroupDetail={shippinGroupDetail}
            />
          )}
          {!rolSellerAndSupCourier && FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(TransitScreen)
