import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import useTransitOrder from "pages/DetailOrderScreens/Transit/UseTransitOrder"
import TransitAction from "components/OrderDetail/TransitProductOrder/TransitAction"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { Suspense, useContext, useEffect } from "react"
import { AuthContext } from "context/context/AuthContext"
import { clients } from "webapp-clients/Clients"
import useHideActionButtons from "hooks/UseHideActionButtons"
import { IconCancel, IconCheck } from "theme/common/Icon.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import useCancelDecreaseOrder from "hooks/UseCancelDecreaseOrder"
import UseUpdateCourierDelivery from "hooks/UseUpdateCourierDelivery"
import UseDeliveredRejectOrder from "hooks/UseDeliveredRejectOrder"
import { WebAppClientsEnum } from "enums/CommonEnum"
import UseCancelOrder from "hooks/UseCancelOrder"
import { useGetCancelActionByClient } from "hooks/UseGetMultiClient"

// Cloned from TransitScreen
// TODO: multiclient
import useFormConfig from "hooks/Media/useFormConfig"
import useFormConfigSandbox from "overrides/theme/sandbox/base/Hooks/Media/useFormConfig"
import { useEnv } from "hooks"

const OrderDetailScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { hideTransitActions, updateVisibilityButtonActions } = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id,
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const [_client] = useEnv().multiclient.get_user_env()

  // TODO: multiclient
  // Refactor this, ovverride component needed
  const useFormConfigWrapper = (user?.mainGroup.id === "wom") ? useFormConfigSandbox : useFormConfig
  const { handleSubmit } = useFormConfigWrapper({})

  const { submitDeliveredOrder, loadingTransit } = useTransitOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
    order,
  })

  const { handleClickCancelDecreaseOrder, loadingCancelDecrase, canIuseCancelDecraese } =
    useCancelDecreaseOrder({
      order,
      shippingGroupId,
    })

  const { handleClickDeliveredRejectOrder, loadingDeliveredReject, canIuseDeliveredReject } =
    UseDeliveredRejectOrder({
      order,
      shippingGroupId,
    })

  const { handleClickUpdateCourierDelivery, loadingUpdateCourierDelivery, canIuseUpdateCourierDelivery } =
    UseUpdateCourierDelivery({
      shippingGroupId,
    })

  const handleClickCancelDecrease = () => {
    handleClickCancelDecreaseOrder(EShippinggroupAction.cancel_decrease)
  }

  const handleClickDeliveredReject = () => {
    handleClickDeliveredRejectOrder(EShippinggroupAction.delivered_reject)
  }

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const { handleClickCancelOrder, loadingCancel, canIuseCancel } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })

  return (
    <>
      
      {itemsOrder ? (
        <>
          {canIuseCancel && _client === WebAppClientsEnum.sandbox && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}

          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos en tránsito" : undefined}
          />
          {!finishedShippingGroupAction && (
            <>
              <form onSubmit={handleSubmit(submitDeliveredOrder)}>               

                {canIuseCancelDecraese && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickCancelDecrease}
                      loadingButton={loadingCancelDecrase}
                      disabled={loadingCancelDecrase}
                    >
                      <IconCancel />
                      <span>Cancelar Orden sin Modificar Inventario</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {canIuseDeliveredReject && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickDeliveredReject}
                      loadingButton={loadingDeliveredReject}
                      disabled={loadingDeliveredReject}
                    >
                      <IconCancel />
                      <span>Cancelar orden por siniestro</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {!hideTransitActions && <TransitAction loadingTransit={loadingTransit} />}
              </form>

              {canIuseUpdateCourierDelivery && (
                <ContentDetailOrderActionsStyled>
                  <ButtonStyled
                    variant="primary"
                    onClick={handleClickUpdateCourierDelivery}
                    loadingButton={loadingUpdateCourierDelivery}
                    disabled={loadingUpdateCourierDelivery}
                  >
                    <IconCheck />
                    <span>Confirmar recepción OPL</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              )}
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(OrderDetailScreen)
