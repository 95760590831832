import { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const SkeletonThemeContainer = ({ children }: any) => {
  return (
    <SkeletonTheme baseColor="#E0E0E0" highlightColor="#F4F5F8">
      {children}
    </SkeletonTheme>
  )
}

export default SkeletonThemeContainer
