import Input from "components/Commons/Input"
import RadioButton from "components/Commons/RadioButton"
import { OptionsSendCodeEnum } from "enums/OptionsSendCodeEnum"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { SendCodeOptions } from "theme/assets/Data/SendCodeOptions"
import AlertStyled from "theme/common/Alert.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import { ContentSendCode } from "./SendCode.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import { notificationPickupCode, deliveryCodeGenerate } from "services/ListenerServices"
import Logger from "classes/Logger"
import useCancelToken from "hooks/UseCancelToken"
import WindowModal from "components/Commons/WindowModal/WindowModal"
import Modal from "components/Commons/Modal/Modal"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { ISendCode } from "interfaces/ISendCode"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"
import { ShippingTypeEnum } from "enums/OrderEnum"

const SendCode = ({ orderId, customer, shippingGroupId, onProcessCode, shippingType, portabilidad }: ISendCode) => {
  const [loading, setLoading] = useState(false)
  const [currentOption, setcurrentOption] = useState(OptionsSendCodeEnum.sms.toString())
  const [canEditPickupCode, setcanEditPickupCode] = useState(false)

  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const { isCancel, newCancelToken } = useCancelToken()

  const preLoadedInfo = {
    phone:
      shippingType === ShippingTypeEnum.HD && (customer?.custom?.numeroAportar || customer?.custom?.numeroaportar || customer?.custom?.numeroAPortar)
        ? customer?.custom?.numeroAportar || customer?.custom?.numeroaportar || customer?.custom?.numeroAPortar
        : (shippingType === ShippingTypeEnum.SP && portabilidad === "SI" && (customer?.custom?.numeroAportar || customer?.custom?.numeroaportar || customer?.custom?.numeroAPortar) ? customer?.custom?.numeroAportar || customer?.custom?.numeroaportar  || customer?.custom?.numeroAPortar
          : (shippingType === ShippingTypeEnum.SP && portabilidad === "SI" && (!customer?.custom?.numeroAportar && !customer?.custom?.numeroaportar && !customer?.custom?.numeroAPortar)
          ? "" : customer?.phone || "")),
    email: customer?.email ? customer?.email : "",
  }

  const [modal, setModal] = useState({
    title: "",
    description: "",
  })

  const {
    register,
    trigger,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: preLoadedInfo,
  })

  const handleChangeOptionCode = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setcurrentOption(ev.target.value)
    reset(preLoadedInfo)
  }

  const submitSendCode = async () => {
    try {
      let isValid: boolean = false
      let receiver: any
      setModal((modal) => ({ ...modal, title: "", description: "" }))

      if (currentOption === OptionsSendCodeEnum.sms) {
        isValid = await trigger("phone")
        receiver = getValues("phone")
      }
      if (currentOption === OptionsSendCodeEnum.email) {
        isValid = await trigger("email")
        receiver = getValues("email")
      }

      if (isValid) {
        setLoading(true)

        const body = {
          orderId: orderId,
          shippingGroupId: shippingGroupId,
          type: currentOption,
          receiver: receiver,
        }

        const {
          data: { code, message },
        } = await notificationPickupCode(body, newCancelToken())

        if (code === TransactionCodeEnum.ok) {
          setLoading(false)

          setModal((modal) => ({
            ...modal,
            title: "Código de Retiro",
            description: "El código de retiro se envió correctamente",
          }))

          onProcessCode({ code: message?.codigoRetiro })

          refModal.current?.open()
        } else {
          setLoading(false)

          setModal((modal) => ({
            ...modal,
            title: "Código de Retiro",
            description: "Ha ocurrido un error inesperado al enviar el código. Intente nuevamente",
          }))

          refModal.current?.open()
        }
      }
    } catch (error: any) {
      setLoading(false)

      Logger.error(error)
      if (isCancel(error)) return

      setModal((modal) => ({
        ...modal,
        title: `Error: ${error.response?.status || TransactionCodeEnum.server}`,
        description: "Ha ocurrido un error inesperado al enviar el código. Intente nuevamente",
      }))

      refModal.current?.open()
    }
  }

  const submitDeliveryCode = async () => {
    try {
      setModal((modal) => ({ ...modal, title: "", description: "" }))
      setLoading(true)

      const body = {
        shippingGroupId: shippingGroupId,
      }

      const {
        data: { code },
      } = await deliveryCodeGenerate(body, newCancelToken())

      if (code === TransactionCodeEnum.ok) {
        setLoading(false)

        setModal((modal) => ({
          ...modal,
          title: "Código de Entrega",
          description: "El código de entrega se envió correctamente",
        }))

        refModal.current?.open()
      } else {
        setLoading(false)

        setModal((modal) => ({
          ...modal,
          title: "Código de Retiro",
          description: "Ha ocurrido un error inesperado al enviar el código. Intente nuevamente",
        }))

        refModal.current?.open()
      }
    } catch (error: any) {
      setLoading(false)

      Logger.error(error)
      if (isCancel(error)) return

      setModal((modal) => ({
        ...modal,
        title: `Error: ${error.response?.status || TransactionCodeEnum.server}`,
        description: "Ha ocurrido un error inesperado al enviar el código. Intente nuevamente",
      }))

      refModal.current?.open()
    }
  }

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.editDataPickupCode] })

  const options = portabilidad === "SI"
    ? SendCodeOptions.filter((item: any) => item?.shippingType === shippingType && item.portabilidad === "SI")
    : SendCodeOptions.filter((item: any) => item?.shippingType === shippingType)

  useEffect(() => {
    permission[ResourcePermissionsEnum.editDataPickupCode] && setcanEditPickupCode(true)
  }, [permission])

  return (
    <>
      <Modal ref={refModal}>
        <WindowModal
          title={modal.title}
          description={modal.description}
          handleConfirm={() => {
            refModal.current?.close()
          }}
          showCancel={false}
        />
      </Modal>

      <ContentSendCode>
        <AlertStyled variant="info">
          <p>Enviar Código de {shippingType === ShippingTypeEnum.HD ? 'Entrega' : 'Retiro'}</p>
        </AlertStyled>
        <form>
          {errors.phone && (
            <div className="box-error">
              <ErrorFieldForm>{errors.phone.message}</ErrorFieldForm>
            </div>
          )}
          {errors.email && (
            <div className="box-error">
              <ErrorFieldForm>{errors.email.message}</ErrorFieldForm>
            </div>
          )}

          <div className="box-form">
            <div className="box-form-options">
              {options.map(({ value, label }: any) => (
                <RadioButton
                  key={value}
                  name="optionCode"
                  value={value}
                  checked={value === currentOption}
                  onChange={handleChangeOptionCode}
                >
                  {label}
                </RadioButton>
              ))}
            </div>
            <div className="box-form-fields">
              {currentOption === OptionsSendCodeEnum.sms ? (
                <Input
                  {...register("phone", {
                    required: "Debe de ingresar el celular",
                    pattern: {
                      value: /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/,
                      message: "Debe de ingresar un Celular válido",
                    },
                  })}
                  type="text"
                  placeHolder="Celular"
                  className="field-input"
                  disabled={shippingType === ShippingTypeEnum.SP && portabilidad === "SI" ? true : (canEditPickupCode && shippingType !== ShippingTypeEnum.HD ? false : true)}
                />
              ) : (
                <Input
                  {...register("email", {
                    required: "Debe de ingresar el E-Mail",
                    pattern: {
                      //eslint-disable-next-line
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Debe de ingresar un E-Mail válido",
                    },
                  })}
                  type="text"
                  placeHolder="E-Mail"
                  className="field-input"
                  disabled={canEditPickupCode ? false : true}
                />
              )}
            </div>
            <div className="box-form-action">
              {shippingType === ShippingTypeEnum.HD ? (
                <ButtonStyled
                  variant="primary"
                  loadingButton={loading}
                  disabled={loading}
                  type="button"
                  onClick={submitDeliveryCode}
                >
                  <span>Enviar</span>
                </ButtonStyled>
              ) : (
                <ButtonStyled
                  variant="primary"
                  loadingButton={loading}
                  disabled={loading}
                  type="button"
                  onClick={submitSendCode}
                >
                  <span>Enviar</span>
                </ButtonStyled>
              )}
            </div>
          </div>
        </form>
      </ContentSendCode>
    </>
  )
}

export default SendCode
