import { useContext } from "react"
import { AuthContext } from "context/context/AuthContext"
import MultiClient from "classes/MultiClient"
import { Env } from "classes"

/**
 * Multicliente logic. Get configuration and resources from environment
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 *
 */
const useEnv = () => {
  const auth: any = useContext(AuthContext)
  const [client, env] = MultiClient.get_user_env(auth.user?.mainGroup.id, Env.clients, Env.env, Env.defaults)
  //eslint-disable-next-line
  const theme: any = Env.theme
  // Next will be in a forEach, testing a single value...
  //eslint-disable-next-line
  const permission_list = auth.state.user?.rolePermissions
  const str_default = "default"

  const core = {
    get_value_simple: (path: string) => {
      const replaced = path.replaceAll("/", "?.")
      //eslint-disable-next-line
      const value_client = eval(`theme?.${client}?.${replaced}`)
      //eslint-disable-next-line
      const value_core_filter = eval(`theme?.core?.${replaced}`)

      return value_client ?? value_core_filter
    },
    get_value: (path: string) => {
      let default_path: string[] | string = path.split("/")
      default_path[1] = str_default
      default_path = default_path.join("/")
      default_path = default_path.replaceAll("/", "?.")

      const replaced = path.replaceAll("/", "?.")
      //eslint-disable-next-line
      const value_client = eval(`theme?.${client}?.${replaced}`)
      //eslint-disable-next-line
      const value_client_default = eval(`theme?.${client}?.${default_path}`)
      //eslint-disable-next-line
      const value_core_filter = eval(`theme?.core?.${replaced}`)
      //eslint-disable-next-line
      const value_core_filter_default = eval(`theme?.core?.${default_path}`)

      return value_client ?? value_client_default ?? value_core_filter ?? value_core_filter_default
    },
    get_status_list_complex: (list: any, shippingtype_list: any) => {
      let output: any = []

      shippingtype_list.forEach((shipping_type: any) => {
        list[shipping_type]["status"].forEach((status: any) => {
          if (!output.includes(status)) {
            output = [...output, status]
          }
        })
      })

      return output
    },
  }

  const multiclient = {
    get_user_env: () => [client, env],
  }

  const permissions = {
    get_permission: (permission_parent: any, permission_id?: any) => {
      if (!permission_list) {
        return true
      }
      if(!!permission_id) {
        if(!!permission_list.hasOwnProperty(permission_parent)) { 
          return !!permission_list[permission_parent].includes(permission_id)
        }  
      }
      if(!!permission_list.hasOwnProperty(permission_parent)) { 
        return !!permission_list[permission_parent]
      }

      return false
    },
  }

  const sg = {
    get_sg_shippingtype_list_all: () => core.get_value(`sg`),
    get_shippingtype_list: () => core.get_value_simple(`sg/shipping_types`),
    get_sg_status_list: () => core.get_value(`sg/${str_default}/status`),
    get_sg_actions_list: () => core.get_value(`sg/${str_default}/actions`),
    get_sg_status_actions_list: () => core.get_value(`sg/${str_default}/status_actions`),
    get_sg_shippingtype_status_list: (shippingtype: string) => core.get_value(`sg/${shippingtype}/status`),
  }

  return {
    core,
    multiclient,
    sg,
    permissions
  }
}

export default useEnv
