export const paginateStyled = `
  .paginate__container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    background: #FFFFFF;
    padding: 0.5em 1em;
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 1.5rem;
  }
  .paginate__container ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  .paginate__container ul .active {
    background: #002eff;
    color: #FFFFFF;
  }
  .paginate__container ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3em;
    width: 3em;
    border-radius: 1.5em;
    background: #E0E0E0;
    color: #716F87;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.4s;
  }
  .paginate__container ul li a {
    padding: 1em;
  }
  .paginate__container ul li:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  .paginate__container ul .prev-next {
    height: 3em;
    width: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5em;
    background: #FFFFFF;
    color: #002eff;
    border: 1.5px solid #002eff;
  }
`