import styled from "styled-components"

export const ModalMessageContentStyled = styled.div`
  .error-code {
    color: #000000;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .error-title {
    color: #000000;
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 1rem;

    .currren-source {
      font-weight: 700;
    }
  }

  .box-error-table {
    margin-bottom: 1.5rem;
    
    & > table {
      width: 100%;

      & > thead > tr > th {
        width: 50%;
        color: #000000;
        padding: 0.3rem;
        background: #E5E7ED;
      }

      & > tbody > tr > td {
        color: #000000;
        padding: 0.2rem;
        font-weight: normal;
      }
    }
  }
`