import { useContext } from "react"
import { Helmet } from "react-helmet"
import { GlobalContext } from "context/context/GlobalContext"
import { useEnv } from "hooks"
import shiperto_icon from "assets/icons/ship_ico.svg"
import logo from "theme/assets/img/ico_header_logo.svg"

interface helmetInterface {
  currentPath?: string
}

const capitalizeWord = (word: string) => {
  let _cw = word.charAt(0)
  let _remain = word.substring(1)
  return _cw.toUpperCase() + _remain
}

const HelmetComponent = ({ currentPath }: helmetInterface) => {
  const [_client] = useEnv().multiclient.get_user_env()
  const { displayHeaderRouteName } = useContext(GlobalContext)

  const getFavicon = () => {
    switch (_client) {
      case "shiperto":
        return shiperto_icon
      default:
        return logo
    }
  }

  return (
    <Helmet>
      <link rel="icon" href={getFavicon()} />
      <title>
        {capitalizeWord(_client)} | {currentPath || displayHeaderRouteName.state || "Web App Omnix"}
      </title>
    </Helmet>
  )
}

export default HelmetComponent
