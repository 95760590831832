import { useEffect, useRef } from "react"
import axios, { CancelToken, CancelTokenSource } from "axios"

/**
 * Hook useCancelToken 
 * Return Objet with information the function newCancelToken and String isChanel
 * @return {Function} newCancelToken. 
 * @return {String} isChanel. 
 */
const useCancelToken = () => {
  const axiosSource = useRef<CancelTokenSource>()

  /**
   * newCancelToken return String with information Token
   * @returns String
   */
  const newCancelToken = (): CancelToken => {
    axiosSource.current = axios.CancelToken.source()
    return axiosSource.current.token
  }

  useEffect(
    () => () => {
      if (axiosSource.current) axiosSource.current.cancel()
    },
    [],
  )

  return {
    newCancelToken,
    isCancel: axios.isCancel,
  }
}

export default useCancelToken
