import { ComponentsType } from "types/CommonsTypes"
import { lazy } from "react"

const ScannerlTransition = lazy(
  () => import("components/Core/ProductTransition/ScannerlTransition"),
)
const TagCustomOrder = lazy(() => import("webapp-clients/Wom/Components/TagCustomOrder"))
//const FilesList = lazy(() => import("components/Core/DocumentsFiles/FilesList"))
const FilesList = lazy(() => import("overrides/theme/wom/base/components/Core/DocumentsFiles/FilesList"))

const FileUpload = lazy(() => import("components/Commons/FileUpload/FileUpalder"))

const ContentFileMedia = lazy(
  () => import("webapp-clients/Wom/Components/ViewContentMedia/ContentFileMedia"),
)

const NoExistTransit = lazy(() => import("webapp-clients/Wom/Components/NoExistTransit"))

export const wom: ComponentsType = {
  ProductTransition: ScannerlTransition,
  TagCustomOrder,
  FilesList,
  FileUpload,
  ContentFileMedia,
  NoExistTransit,
}
