import React, { Suspense, useContext, useEffect } from "react"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import PackageAction from "components/OrderDetail/PackageProductOrder/PackageAction"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import { OrderDetailType } from "types/OrderType"
import usePackageOrder from "pages/DetailOrderScreens/Package/UsePackageOrder"
import { IconSpinner } from "theme/common/Icon.styled"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { AuthContext } from "context/context/AuthContext"
import { clients } from "webapp-clients/Clients"
import useHideActionButtons from "hooks/UseHideActionButtons"
import { WebAppClientsEnum } from "enums/CommonEnum"
import { IconCancel } from "theme/common/Icon.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import UseCancelOrder from "hooks/UseCancelOrder"
import { useGetCancelActionByClient } from "hooks/UseGetMultiClient"
import { useEnv } from "hooks"

const InfoElocker = React.lazy(() => import("components/OrderDetail/ElockerQR/InfoElocker"))

const PackageScreen = ({
  order,
  shippinGroupDetail,
  shippingGroupId,
  finishedShippingGroupAction,
  setFinishedShippingGroupAction,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const {hidePackageActions, updateVisibilityButtonActions} = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const [_client] = useEnv().multiclient.get_user_env()

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const documents = shippinGroupDetail?.custom?.documents

  const {
    loadingPackage,
    handleClickPackageOrder,
    canViewQrCancel,
    canViewQrEntry,
    canViewQrExit,
    denyPackingAction,
  } = usePackageOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
    isElocker: elockerInfo?.isElocker,
  })

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const {
    handleClickCancelOrder,
    loadingCancel,
    canIuseCancel
  } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })

  return (
    <>
      {itemsOrder ? (
        <>
        
          {canIuseCancel && _client === WebAppClientsEnum.sandbox && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}

          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos Empacados" : undefined}
          />

          {elockerInfo?.isElocker && (
            <Suspense
              fallback={
                <>
                  <IconSpinner variant="big" className="content-center" />
                  <p className="padding-content">Cargando información Elocker</p>
                </>
              }
            >
              <InfoElocker
                codigoCancelacion={canViewQrCancel ? elockerInfo.codigoCancelacion : null}
                codigoEntrega={canViewQrEntry ? elockerInfo.codigoEntrega : null}
                codigoRetiro={canViewQrExit ? elockerInfo.codigoRetiro : null}
                puerta={elockerInfo.puerta}
              />
            </Suspense>
          )}

          {!finishedShippingGroupAction && !denyPackingAction && !hidePackageActions && (
            <PackageAction
              loadingPaking={loadingPackage}
              onClickPackageOrder={handleClickPackageOrder}
            />
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(PackageScreen)
