import { useEffect } from "react"
import { IElementSideBar } from "interfaces/IMenu"

/**
 * Hook useOnClickOutSide
 * is for the menu what you do is listen to the mousedown event to verify it if you click outside the menu and automatically the manu closes
 * @param {refElement} : IElementSideBar. is an element where it is referenced where it is clicked
 * @param {handleAction} : IElementSideBar. it's a callback and what it does is update the status to false to close the menu
 */

const useOnClickOutSide = ({ refElement, handleAction }: IElementSideBar) => {
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      const tagetElement = event.target instanceof Node ? event.target : null
      if (!refElement.current || refElement.current.contains(tagetElement))
        return

      handleAction()
    }

    document.addEventListener("mousedown", handleMouseDown)
    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
    }
  }, [refElement, handleAction])
}

export default useOnClickOutSide
