export const newUserScreenStyle = `
  .new_user_screen__container_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5em 0;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 1rem;
  }
  .new_user_screen__container_header {
    padding: 0.5em 1em;
    border-bottom: 0.5px solid #dddde9;
  }
  .new_user_screen__arrow_left_button {
    width: 3rem;
    height: 3rem;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 3rem;
    cursor: pointer;
  }
  .new_user_screen__arrow_left_button:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  .new_user_screen__title_section, .new_user_screen__title_section_cursive {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1rem;
    color: #23203F;
  }
  .new_user_screen__title_section_cursive {
    font-weight: 500;
    font-style: italic;
  }
  .new_user_screen__container_body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .new_user_screen__container_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
  }
  .new_user_screen__container_group_inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 0 3em;
    gap: 1.5em;
  }
  .new_user_screen__container_input_group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .new_user_screen__container_input_group .new_user_screen__input_form {
    width: 100%;
    height: 3rem;
    background-color: #f3f5ff;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 1rem;
    border-radius: 1.5rem;
    padding: 0 2em;
  }
  .new_user_screen__container_input_group .new_user_screen__input_form:disabled {
    cursor: not-allowed;
  }
  .new_user_screen__container_input_group .new_user_screen__select_form {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 3rem !important;
    background-color: #f3f5ff;
    cursor: pointer;
  }
  .new_user_screen__container_input_group .new_user_screen__input_form:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px #f3f5ff inset;
  }
  .new_user_screen__container_input_group .new_user_screen__input_form::placeholder {
    color: #716F87;
  }
  .new_user_screen__container_generate_pass {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    padding: 1em 0;
  }
  .new_user_screen__button_generate_pass {
    width: 70%;
    padding: 0.6em 2em;
    border: 1px solid #002eff;
    font-family: 'Poppins';
    font-size: 1rem;
    color: #23203F;
    border-radius: 1.5rem;
  }
  .new_user_screen__button_generate_pass:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  .new_user_screen__input_group_generate {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .new_user_screen__input_pass {
    width: 100%;
    height: 3rem;
    border-radius: 1.5rem;
    font-family: "Montserrat";
    font-size: 1rem;
    padding: 0 2em;
    border: 1px solid #807882;
  }
  .border_input_pass_normal {
    border: 1px solid #F58400;
  }
  .border_input_pass_ok {
    border: 1px solid #45DD42;
  }
  .border_input_pass_danger {
    border: 1px solid #FF1900;
  }
  .new_user_screen__container_generate_pass_alert {
    display: flex;
    width: 8rem;
    height: 3rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #FFF;
    padding: 0.5em 1em;
    border-radius: 0.5rem;
    gap: 0.5em;
  }
  .background_alert_normal {
    background: #F58400;
  }
  .background_alert_ok {
    background: #45DD42;
  }
  .background_alert_danger {
    background: #FF1900;
  }
  .new_user_screen__container_permissions {
    width: 100%;
    padding: 1em 0;
  }
  .new_user_screen__container_permissions .new_user_screen__content-checked {
    display: block;
  }
  .new_user_screen__container_list_permissions {
    width: 100%;
    transition: all 0.3s ease-in;
    max-height: 26rem;
    overflow-y: scroll;
    border: 1px solid #716F87;
    padding: 0 0.5em;
    margin-top: 0.5em;
    border-radius: 0.5rem;
  }
  .new_user_screen__container_sources {
    width: 100%;
    padding: 1em 0;
  }
  .new_user_screen__content-checked {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .new_user_screen__content-checked-title {
    margin-bottom: 1rem;
  }
  .new_user_screen__content-checked.new_user_screen__content-checked-2 {
    padding-left: 2rem;
  }
  .new_user_screen__content-checked.new_user_screen__content-checked-permission {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .new_user_screen__content-checked.new_user_screen__content-checked-permission.new_user_screen__content-checked-2 {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
  .new_user_screen__check-container {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 2em;
  }
  .new_user_screen__check-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .new_user_screen__container_list_sources {
    width: 100%;
    transition: all 0.3s ease-in;
    max-height: 20rem;
    overflow-y: scroll;
    border: 1px solid #716F87;
    padding: 0.5em;
    margin-top: 0.5em;
    border-radius: 0.5rem;
  }
  .new_user_screen__container_permissions {
    width: 100%;
    padding: 1em;
  }
  .new_user_screen__container_validation_error_fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
  }
  .new_user_screen__container_error_fields_message {
    width: 100%;
    font-family: 'Poppins';
    font-size: 1rem;
    color: #fff;
    padding: 1em;
    background: red;
    border-radius: 1rem;
  }
  .new_user_screen__container_button_submit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .new_user_screen__button_submit {
    width: 70%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 1rem;
    color: #fff;
    padding: 0 1em;
    gap: 0 1em;
    background: #002eff;
    border-radius: 1.5rem;
  }
  .new_user_screen__button_submit:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  @media screen and (min-width: 768px) {
    .new_user_screen__container_form {
      width: 100%;
    }
    .new_user_screen__container_input_group {
      flex-direction: row;
    }
    .new_user_screen__container_input_group .new_user_screen__input_form {
      width: 50%;
    }
    .new_user_screen__button_generate_pass {
      width: auto;
    }
    .new_user_screen__input_group_generate {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .new_user_screen__input_pass {
      width: 25rem;
    }
    .new_user_screen__container_permissions {
      width: 100%;
    }
    .new_user_screen__container_error_fields_message {
      width: 50%;
    }
    .new_user_screen__container_button_submit {
      justify-content: flex-start;
    }
    .new_user_screen__button_submit {
      width: 15rem;
    }
  }
  @media screen and (min-width: 1280px) {
    .new_user_screen__container_body {
      flex-direction: row;
    }
    .new_user_screen__container_error_fields_message {
      width: 35%;
    }
    .new_user_screen__container_list_permissions {
      max-height: 40rem;
    }
  }
`