export const orderFilterStyle = `
  .order_filter_date__container {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    padding-bottom: 1rem;
  }
  .order_filter_date__container .order_filter_date__content_date {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .order_filter_date__content_date > svg.order_filter_date__calendar {
    color: #002eff;
    font-size: 1.5rem;
  }
  .order_filter_date__content_date .order_filter_date__info_date{
    display: flex;
    align-items: center;
    border: 1px solid #002eff;
    border-radius: 18px;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    min-width: 220px;
    height: 36px;
    position: relative;
  }
  .order_filter_date__info_date svg {
    position: absolute;
    right: 5px;
    font-size: 1.5rem;
    color: #002eff;
    margin-left: 10px;
  }
`