import config from "config/config"

const DEFAULT_NAMESPACE = config.defaults.namespace

export default class Logger {

  static consoleType = (message: any, currentDate: number, type: string, namespace?: string): void => {
    if (typeof message === "string") {
      console.log(`[${currentDate}] [${namespace || DEFAULT_NAMESPACE}] [${type}] ${message}`)
    } else {
      console.log(`[${currentDate}] [${namespace || DEFAULT_NAMESPACE}] [${type}]`, message)
    }
  }

  static info(message: any, namespace?: string): void {
    this.consoleType(message, new Date().getTime(), "INFO", namespace)
  }

  static warn(message: any, namespace?: string): void {
    this.consoleType(message, new Date().getTime(), "WARN", namespace)
  }

  static error(message: any, namespace?: string): void {
    this.consoleType(message, new Date().getTime(), "ERROR", namespace)
  }
}
