import { useContext, useEffect, useState } from "react"
import Logger from "classes/Logger"
import { getRolesService } from "services/OSM"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"

const UseListRoles = (load_roles: boolean = false) => {
  const [data_list_roles, set_data] = useState<any>([])
  const [loading_roles, set_loading] = useState(false)
  const { errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()

  useEffect(() => {
    if (load_roles) {
      const getListRoles = async () => {
        try {
          set_loading(true)
          const {
            data: { message },
          } = await getRolesService(newCancelToken())
          set_data(message)
          set_loading(false)
        } catch (error: any) {
          set_loading(false)
          Logger.error(error)
          if (isCancel(error)) return
    
          errorHander?.dispatch({
            hasError: true,
            code: error.response?.status || TransactionCodeEnum.server,
          })
        }
      }
      getListRoles()
    }
    // eslint-disable-next-line
  }, [load_roles])

  return {
    data_list_roles,
    loading_roles,
  }
}

export default UseListRoles