// import { useMemo } from "react"
import { Link } from "react-router-dom"
// import { abbreviateNumber } from "helpers/UtilsHelper"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"

import logo from "assets/icons/entel-status/awaiting_delivery-icon.svg"

const CardOption = ({ optionCount, title, path, status }: any) => {
  const statusses: any = translatesObj
  // const countAbbreviate = useMemo(() => abbreviateNumber(optionCount || 0), [optionCount])

  return (
    <Link
      className="dashboard__content__summary__item"
      to={{
        pathname: path,
      }}
    >
      <div className="dashboard__content__summary__item__icon">
        <div className="dashboard__content__summary__item__icon_wrapper">
          <img src={logo} alt={status} />
        </div>
      </div>
      <div className="dashboard__content__summary__item__title">
        <div>
          <p>{statusses[title]?.title}</p>
        </div>
      </div>
      <div className="dashboard__content__summary__item__count">
        {/* <div>
          <p>{countAbbreviate}</p>
        </div> */}
      </div>
    </Link>
  )
}

export default CardOption
