import { Fragment, useContext, useEffect, useState } from "react"
import { AuthContext } from "context/context/AuthContext"
import { GlobalContext } from "context/context/GlobalContext"
import { useHistory, useParams } from "react-router-dom"
import UseGetUserById from "hooks/UseGetUserById"
import useUpsertUsers from "hooks/useUpsertUsers"
import UseListRoles from "hooks/UseListRoles"
import UseListSourcesOSRM from "hooks/UseListSourcesOSRM"
import info_icon from "assets/icons/info_icon.svg"
import plus_icon from "assets/icons/plus-icon.svg"
import arrow_left_icon from "assets/icons/arrow-left-icon.svg"
import Loading from "components/Commons/Loading/Loading"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { schemaUserFormValidation } from "config/ShemaForms"
import passwordStrengthValidation, { password_generator } from "helpers/passwordStrengthValidation"
import { newUserScreenStyle } from "overrides/theme/shiperto/base/pages/UsersScreens/styles/NewUserScreen.style"
import PermissionList from "overrides/theme/shiperto/base/pages/UsersScreens/PermissionList"
import LabelSwitch from "overrides/theme/shiperto/base/components/Commons/LabelSwitch/LabelSwitch"

const NewUserScreen = () => {
  const { userId }: any = useParams()
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const history = useHistory()
  const { data: data_sources, loading: loading_sources } = UseListSourcesOSRM(true, 500)
  const { data_list_roles, loading_roles } = UseListRoles(true)
  const { data: userData, loading } = UseGetUserById(userId)
  const [user_permission_list, set_user_permission_list] = useState([])
  const [user_permission_list_all, set_user_permission_list_all] = useState(false)

  const { dispatch, state: { user } } = useContext(AuthContext)

  const [allGroupSources, setAllGroupSources] = useState<any>({
    id: "ALLSOURCES",
    checked: true,
    mainGroup: "logytech",
    type: "Source",
  })
  const [groupSources, setGroupSources] = useState<any>([])
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schemaUserFormValidation(Boolean(userId))),
  })
  const oldPasswordTyped = watch("old_password")
  const passwordTyped = watch("password")
  const [idStrength, valueStrength] = passwordStrengthValidation(passwordTyped)
  const { loading_upsert, loading_pass, createUserAction, updateUserAction } = useUpsertUsers({
    redirectAfter: true,
    updatePass: Boolean(userId && oldPasswordTyped && passwordTyped),
  })

  const format_role_permission_list = (list: any) => {
    if (!list) {
      return {}
    }

    let output: any = {}
    const level_2 = list.filter((item: any) => item.level === 2)

    level_2.forEach((item: any) => {
      output[item.parent] = []
    })
    level_2.forEach((item: any) => {
      output[item.parent] = [...output[item.parent], item.id]
    })

    return output
  }

  useEffect(() => {
    displayHeaderRouteName.dispatch(userId ? "Editar Perfil" : "Crear Perfil")
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!userId && data_sources) {
      setGroupSources(
        data_sources.map((source: any) => ({
          id: source.id,
          checked: false,
          mainGroup: "logytech",
          type: "Source",
        })),
      )
    }
    //eslint-disable-next-line
  }, [data_sources])

  useEffect(() => {
    if (groupSources.some((src: any) => src.checked === false)) {
      setAllGroupSources({...allGroupSources, checked: false})
    } else {
      setAllGroupSources({...allGroupSources, checked: true})
    }
    //eslint-disable-next-line
  }, [groupSources])

  useEffect(() => {
    if (userId && userData && data_list_roles && data_sources) {
      const fillForm = () => {
        reset({
          id: userData.id,
          name: userData.name,
          email: userData.email,
          role: userData.role,
        })

        const hasAllSources = userData?.groups?.some((group: any) => group.id === "ALLSOURCES")
        setAllGroupSources({
          ...allGroupSources,
          checked: Boolean(hasAllSources)
        })
        setGroupSources(
          data_sources.map((source: any) => {
            let src = userData.groups?.some((groupItem: any) => groupItem.id === source.id) || hasAllSources
            return {
              id: source.id,
              checked: Boolean(src),
              mainGroup: "logytech",
              type: "Source",
            }
          }),
        )
      }
      fillForm()
    }
    //eslint-disable-next-line
  }, [userData, data_list_roles, data_sources])

  const groupsToRequest = () => {
    if (allGroupSources.checked) {
      let allS = allGroupSources
      delete allS.checked
      return [allS]
    } else {
      const groupS = groupSources.map((groupItem: any) => {
        let gs = groupItem.checked && groupItem
        delete gs.checked
        return gs
      })
      return groupS.filter(Boolean)
    }
  }

  const handleClickSubmit = (data: any) => {
    const { id, name, email, role, old_password, password } = data
    const groups = [{ type: "Channel", id: "shiperto" }]
    if (userId) {
      const source_list = [...groups, ...groupsToRequest()]

      if (user?.id === userId) {
        const user_data: any = {
          name,
          email,
          role,
          rolePermissions: format_role_permission_list(user_permission_list),
          currentSources: source_list.map((item: any) => item.id),
          groups: source_list,
        }
        dispatch({
          type: "[auth] Change user info",
          payload: user_data,
        })
      }

      updateUserAction(
        userId,
        {
          name,
          email,
          role,
          rolePermissions: format_role_permission_list(user_permission_list),
          groups: source_list,
        },
        { old_password, password },
      )

    } else {
      createUserAction({
        id,
        name,
        email,
        role,
        rolePermissions: format_role_permission_list(user_permission_list),
        credentials: { secret: password },
        mainGroup: { id: "logytech", name: "Logytech" },
        groups: [...groups, ...groupsToRequest()],
      })
    }
  }

  const classesPasswordStrength = () => {
    const alert = ["", "background_alert_danger", "background_alert_normal", "background_alert_ok"]
    const borderInput = ["", "border_input_pass_danger", "border_input_pass_normal", "border_input_pass_ok"]
    return [alert[idStrength], borderInput[idStrength]]
  }

  const handleCheckGroupSource = (sourceId: string) => {
    setGroupSources(
      groupSources.map((source: any) => {
        if (source.id === sourceId) return { ...source, checked: !source.checked }
        return source
      }),
    )
  }

  const loadSpinner = Boolean(loading || loading_roles || loading_upsert || loading_sources || loading_pass)
  return (
    <Fragment>
      {loadSpinner && <Loading defaultOpened={loadSpinner} />}
      <form className="new_user_screen__container_main" onSubmit={handleSubmit(handleClickSubmit)}>
        <div className="new_user_screen__container_header">
          <div className="new_user_screen__arrow_left_button" onClick={() => history.goBack()}>
            <img src={arrow_left_icon} alt="<-" />
          </div>
        </div>
        <div className="new_user_screen__container_body">
          <div className="new_user_screen__container_form">
            <h4 className="new_user_screen__title_section">Datos de usuario</h4>
            <div className="new_user_screen__container_group_inputs">
              <div className="new_user_screen__container_input_group">
                <input
                  {...register("id")}
                  type="text"
                  className="new_user_screen__input_form"
                  placeholder="Nombre de usuario"
                  disabled={Boolean(userId)}
                />
                <input
                  {...register("email")}
                  type="text"
                  className="new_user_screen__input_form"
                  placeholder="Correo electrónico"
                />
              </div>
              <div className="new_user_screen__container_input_group">
                <input {...register("name")} type="text" className="new_user_screen__input_form" placeholder="Nombre" />
                <select {...register("role")} className="new_user_screen__input_form new_user_screen__select_form">
                  <option value="" selected disabled>
                    Seleccionar rol
                  </option>
                  {data_list_roles.map((rol: any, i: number) => (
                    <option key={i} value={rol.id}>
                      {rol.id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <h4 className="new_user_screen__title_section">Gestor de contraseñas</h4>
            {userId && (
              <>
                <h3 className="new_user_screen__title_section_cursive">Actualizar contraseña</h3>
                <input
                  {...register("old_password")}
                  type="text"
                  placeholder="Contraseña Actual"
                  className="new_user_screen__input_pass"
                />
              </>
            )}
            <div className="new_user_screen__container_generate_pass">
              <button
                type="button"
                className="new_user_screen__button_generate_pass"
                onClick={() => setValue("password", password_generator(15))}
              >
                {`Generar ${userId ? "nueva " : ""}contraseña`}
              </button>
              <div className="new_user_screen__input_group_generate">
                <input
                  {...register("password")}
                  type="text"
                  placeholder={`${userId ? "Nueva " : ""}Contraseña`}
                  className={`new_user_screen__input_pass ${classesPasswordStrength()[1]}`}
                />
                {valueStrength && (
                  <div className={`new_user_screen__container_generate_pass_alert ${classesPasswordStrength()[0]}`}>
                    <img src={info_icon} alt="X" />
                    <p>{valueStrength}</p>
                  </div>
                )}
              </div>
            </div>
            <h4 className="new_user_screen__title_section">Sources</h4>
            <div className="new_user_screen__container_sources">
              <div className="new_user_screen__content-checked new_user_screen__content-checked-title">
                <LabelSwitch
                  id="source--ALLSOURCES"
                  value="ALLSOURCES"
                  checked={allGroupSources.checked}
                  onChange={() => {
                    setAllGroupSources({ ...allGroupSources, checked: !allGroupSources.checked })
                    setGroupSources(groupSources.map((srcs: any) => ({...srcs, checked: !allGroupSources.checked})))
                  }}
                  label="Asignar todos los sources"
                />
              </div>
              <div className="new_user_screen__container_list_sources">
                {groupSources.map((source: any, i: number) => (
                  <div key={i} className="new_user_screen__content-checked">
                    <LabelSwitch
                      id={`source--${source.id}`}
                      value={source.id}
                      onChange={() => handleCheckGroupSource(source.id)}
                      checked={source.checked}
                      label={source.id}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {data_list_roles && data_sources && (
            <PermissionList
              userData={userData}
              user_permission_list={user_permission_list}
              set_user_permission_list={set_user_permission_list}
              user_permission_list_all={user_permission_list_all}
              set_user_permission_list_all={set_user_permission_list_all}
            />
          )}
        </div>
        {!!Object.keys(errors).length && (
          <div className="new_user_screen__container_validation_error_fields">
            <div className="new_user_screen__container_error_fields_message">
              <p>Por favor, revise los siguientes campos:</p>
              {Object.values(errors).map((err: any, i: number) => (
                <p key={i}>- {err.message}</p>
              ))}
            </div>
          </div>
        )}
        <div className="new_user_screen__container_button_submit">
          <button className="new_user_screen__button_submit">
            <p>{`${userId ? "Editar" : "Crear"} Usuario`}</p>
            <img src={plus_icon} alt="X" />
          </button>
        </div>
      </form>
      <style jsx>{newUserScreenStyle}</style>
    </Fragment>
  )
}

export default NewUserScreen
