export const errorHandlerStyled = `
  .error_container__root {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1em;
  }
  .error-handler__content-error {
    width: 100%;
    gap: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
  }
  .error-handler__content-error h1, .error-handler__error-title {
    font-family: "Montserrat";
  }
  .error_container__code p {
    font-family: "Montserrat";
    color: #DA5600;
    font-size: 4rem;
    font-weight: 600;
  }
  .error-handler__content-botton-back button {
    width: 12rem;
    height: 3rem;
    padding: 0 1em;
    border-radius: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: #FFFFFF;
    background: linear-gradient(90deg, #EEAB28 -2.99%, #EE7628 16.38%, #DA5600 108.07%);
  }
  .error-handler__content-botton-back button:hover {
    background: linear-gradient(90deg, #EE7628 -2.99%, #DA5600 16.38%, #EEAB28 108.07%);
  }
`