import { useContext, useEffect, useState } from "react"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"
import { AuthContext } from "context/context/AuthContext"

const UseViewUploadFiles = () => {

	const [canIuseViewUploadFiles, setCanIuseViewUploadFiles] = useState(false)
  const [canIuseViewUploadFilesCourier, setCanIuseViewUploadFilesCourier] = useState(false)
	const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.orderViewUploadFiles] })

  const {
    state: { user },
  } = useContext(AuthContext)

	useEffect( () => {
    const courier = user?.groups.filter((_group: any) => _group.type === "Courier").map(({ id }) => id)
    
    courier?.length ? setCanIuseViewUploadFilesCourier(true) : setCanIuseViewUploadFilesCourier(false)

		permission[ResourcePermissionsEnum.orderViewUploadFiles] && setCanIuseViewUploadFiles(true)
	},
  //eslint-disable-next-line
  [permission, user])

  return {
    canIuseViewUploadFiles,
    canIuseViewUploadFilesCourier,
  }
}

export default UseViewUploadFiles
