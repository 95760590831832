import {Fragment} from 'react'
import logo_entel from "assets/images/logo_entel.svg"
import {leftSideStyle} from "overrides/theme/entel/base/components/OrderDetail/TagPrint/styles/TagPrintBody.style"

const LeftSide = ({data}: any) => {
  const { sender, addressee, numberGuide } = data;

  return (
    <Fragment>
      <div className="pt__print-tag__left">
        <div className="pt__tag__left-first">
          <img className="pt__logo" src={logo_entel} alt="X" />
          <div className="pt__flex_column pt__info-entel">
            <span>www.omnixcorp.com</span>
            <span>NIT 78.984.XXX</span>
          </div>
        </div>
        <div className="pt__tag__left-last">
          <span className="pt__number-guide">Guia N° {numberGuide}</span>
          <br/>
          <div className="pt__flex_column">
            <span className="pt__title-text">REMITENTE</span>
            <span className="pt__normal-text">NOMBRE/RAZON SOCIAL</span>
            <span className="pt__normal-text">{sender?.name}</span>
          </div>
          <br/> 
          
          <div className="pt__flex_column">
            <span className="pt__normal-text">CIUDAD <span>{sender?.city}</span></span>
            <span className="pt__normal-text">DIRECCION</span>
            <span className="pt__normal-text">{sender?.address}</span>
          </div>
          <br/> 
          <div className="pt__flex_column">
            <span className="pt__normal-text">DEPARTAMENTO <span>{sender?.department}</span></span>
            <span className="pt__normal-text">TELEFONO <span>{sender?.phone}</span></span>
            <span className="pt__normal-text">CODIGO POSTAL <span>{sender?.zipcode}</span></span>
          </div>
          <br/>
          <div className="pt__flex_column">
            <span className="pt__title-text">DESTINATARIO</span>
            <span className="pt__normal-text">DIRECCION</span>
            <span className="pt__normal-text">{addressee?.address}</span>
          </div>
          <br/>
          <div className="pt__flex_column">
            <span className="pt__normal-text">NOMBRE RAZON SOCIAL</span>
            <span className="pt__normal-text">{addressee?.name}</span>
          </div>
          <br/>
          <div className="pt__flex_column">
            <span className="pt__normal-text">CODIGO POSTAL <span>{addressee.zipcode}</span></span>
            <span className="pt__normal-text">DEPARTAMENTO <span>{addressee?.department}</span></span>
            <span className="pt__normal-text">CIUDAD <span>{addressee?.city}</span></span>
          </div>
        </div>
      </div>
      <style jsx>{leftSideStyle}</style>
    </Fragment>
  )
}

export default LeftSide