import Skeleton from "react-loading-skeleton"
import SkeletonGrid from "./SkeletonGrid"

const SkeletonShippinGroupDetail = () => {
  return (
    <div className="padding-content">
      <SkeletonGrid count={3} height={120} className="m-top1" />
      <Skeleton count={3} height={44} className="m-top1" />
    </div>
  )
}

export default SkeletonShippinGroupDetail
