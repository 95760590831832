import styled from "styled-components"

const ErrorHandlerStyled = styled.section`
  height: 100vh;

  & .content-error {
    position: relative;
    max-width: 300px;
    width: 100%;
    text-align: center;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);

    & img {
      width: 100%;
    }
    h2 {
      color: ${({ theme }) => theme.palette.grey90};
    }
    & .error-title {
      font-size: 1.286rem;
    }
    & .content-error-bg {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 60%;
      transform: translateY(-50%);
      z-index: -1;

      & > div {
        width: 100%;
        background: #fff;
        border-radius: 90px;
        height: 100px;

        &:nth-child(1) {
          box-shadow: 5px 5px 0px 0px #f3f3f3;
        }

        &:nth-child(2) {
          transform: scale(1.3);
          box-shadow: 5px 5px 0px 0px #f3f3f3;
          position: relative;
          z-index: 10;
        }

        &:nth-child(3) {
          box-shadow: 5px 5px 0px 0px #f3f3f3;
          position: relative;
          z-index: 90;
        }
      }
    }
  }

  & .content-botton-back {
    position: absolute;
    width: 100%;
  }
`

export default ErrorHandlerStyled
