import { useEffect, useState, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import Logger from "classes/Logger"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import { NOTIFY_REJECT } from "helpers/constHelper"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"
import { ProcessShippingGroupActionType } from "types/OrderType"
import { ShippingTypeEnum } from "enums/OrderEnum"

const UseCustomerRejectOrder = ({
  order,
  shippingGroupId,
  setFinishedShippingGroupAction,
}: ProcessShippingGroupActionType ) => {
  const { notification, errorHander } = useContext(GlobalContext)

  const [canIuseCustomerReject, setCanIuseCustomerReject] = useState(false)

  const [loadingCustomerReject, setLoadingCustomerReject] = useState(false)

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.customerRejectOrderAction] })

  useEffect(() => {
    permission[ResourcePermissionsEnum.customerRejectOrderAction] && order?.shippingType === ShippingTypeEnum.HD && setCanIuseCustomerReject(true)
    //eslint-disable-next-line
  }, [permission, order])

  const handleClickCustomerRejectOrder = async (action: EShippinggroupAction) => {
    try {
      setLoadingCustomerReject(true)
      const {
        data: { code },
      } = await executeShipingGroupAction({
        shippingGroupId,
        action,
      })

      setLoadingCustomerReject(false)

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        setFinishedShippingGroupAction(true)

        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Orden rechazada correctamente",
            title: NOTIFY_REJECT,
            type: "success",
          },
        })
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadingCustomerReject(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    handleClickCustomerRejectOrder,
    loadingCustomerReject,
    canIuseCustomerReject,
  }
}

export default UseCustomerRejectOrder
