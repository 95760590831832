import { useContext } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaFileUpladerForm } from "config/ShemaForms"
import { AuthContext } from "context/context/AuthContext"
import { FilesFormType } from "types/FilesFormType"
import { clients } from "webapp-clients/Clients"

/**
 * Form config to use in  Upload files
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @param {} 
 * 
 *
 * @returns {object} 
 */

const useFormConfig = ({customSchema}: any) => {
  const {
    state: { user },
  } = useContext(AuthContext)

  // TODO: Refactor multiclient
  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FileUpload = client["FileUpload" as keyof typeof client]
  const formSchema = customSchema ? customSchema : shemaFileUpladerForm

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(formSchema([])) : undefined,
  })

  return {
    errors,
    FileUpload,
    watch,
    handleSubmit,
    register,
    setValue,
  }
}

export default useFormConfig
