import { FunctionComponent, useEffect, useState } from "react"
import IPage from "interfaces/IPage"
import UseManifest from "hooks/UseManifest"
import { ContentStyledManifest } from "theme/common/Manifest.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import { formatAdvanceDate } from "helpers/FormatDateHelper"
import Skeleton from "react-loading-skeleton"
import { downloadManifest } from "helpers/pdfGenerationHelper"
import OrderFilterDate from "components/Core/Order/FilterDate/OrderFilterDate"
import BackBotton from "components/Commons/BackBotton/BackBotton"
import { IRangeExtend } from "interfaces/IContentCelendar"
import SkeletonGrid from "components/Commons/Skeleton/SkeletonGrid"
import AlertStyled from "theme/common/Alert.styled"

const ManifestScreen: FunctionComponent<IPage> = (props: any) => {

  const { list, loading, initial, searchManifest } = UseManifest()
  const [rangeDate, setRangeDate] = useState<IRangeExtend>()

  const onSelectedRangeDate = (range?: IRangeExtend) => {
    setRangeDate(range)
  }

  useEffect( () => {
    rangeDate && searchManifest(rangeDate)
  },
  //eslint-disable-next-line
  [rangeDate])

  return (
    <>
      <BackBotton>
        <OrderFilterDate onSelectedRangeDate={onSelectedRangeDate} />
      </BackBotton>

      {loading ? (
        <ContentStyledManifest>
          <div className="box-loading">
            <SkeletonGrid count={3} />
          </div>
        </ContentStyledManifest>
      ) : (
        <ContentStyledManifest>
          {initial ? (
            <div className="box-message">
              <AlertStyled variant="default">
                <p>Debe filtrar por fechas para mostrar los manifiestos.</p>
              </AlertStyled>
            </div>
          ) : (
            <div className="box-table">
              {list.length ? (
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Fecha</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item: any) => (
                      <tr key={item._id}>
                        <td>{item._id}</td>
                        <td>{formatAdvanceDate(item.date, "yyyy-MM-dd HH:mm:ss") || <Skeleton width={200} />}</td>
                        <td><ButtonStyled variant="primary" fullSize={true} onClick={() => downloadManifest(item)}><span>Descargar</span></ButtonStyled></td>                  
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="box-message">
                  <AlertStyled variant="warning">
                    <p>No se encontraron manifiestos en el rango de fechas selecionado.</p>
                  </AlertStyled>
                </div>
              )}
            </div>
          )}
        </ContentStyledManifest>
      )}
    </>
  )
}

export default ManifestScreen