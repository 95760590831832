import { wom } from "webapp-clients/Wom"
import { entel } from "webapp-clients/ENTEL"
import { privilege } from "webapp-clients/PRVL"
import { sandbox } from "webapp-clients/Sbx"
import { shiperto } from "webapp-clients/shiperto"
import { sodimac } from "webapp-clients/SDM"

export const clients = {
  wom,
  privilege,
  sandbox,
  sodimac,
  shiperto,
  entel
}
