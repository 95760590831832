import { useContext, useEffect, useState } from "react"
import Logger from "classes/Logger"
import { getUserByIdService } from "services/OSM"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"

const UseGetUserById = (userId: string) => {
  const [data, set_data] = useState<any>(null)
  const [loading, set_loading] = useState(false)
  const { errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()

  useEffect(() => {
    if (userId) {
      const getUserById = async () => {
        try {
          set_loading(true)
          const {
            data: { message },
          } = await getUserByIdService(userId, newCancelToken())
          set_data(message)
          set_loading(false)
        } catch (error: any) {
          set_loading(false)
          Logger.error(error)
          if (isCancel(error)) return  
          errorHander?.dispatch({
            hasError: true,
            code: error.response?.status || TransactionCodeEnum.server,
          })
        }
      }
      getUserById()
    }
    // eslint-disable-next-line
  }, [userId])

  return {
    data,
    loading
  }
}

export default UseGetUserById