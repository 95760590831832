import { useEffect, useState, useContext } from "react"
import { TypeVariation6 } from "types/CommonsTypes"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import { AuthContext } from "context/context/AuthContext"
import { updateCourierDelivery } from "services/ListenerServices"
import Logger from "classes/Logger"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "hooks/UseCancelToken"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"
import { formatDate } from "helpers/FormatDateHelper"
import { useLocation } from "react-router-dom"

const UseUpdateCourierDelivery = ({
  shippingGroupId,
}: { shippingGroupId: string | TypeVariation6 }) => {
  const history = useHistory()
  const { newCancelToken } = useCancelToken()
  const { pathname } = useLocation<{ type: string }>()
  const { state: { user } } = useContext(AuthContext)
  const { notification, errorHander } = useContext(GlobalContext)
  const [canIuseUpdateCourierDelivery, setCanIuseUpdateCourierDelivery] = useState(false)
  const [loadingUpdateCourierDelivery, setLoadingUpdateCourierDelivery] = useState(false)
  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.confirmReceptionOpl] });
  let view_reception_opl = pathname.includes("in_transit_for_reception_opl")

  useEffect( () => {
    permission[ResourcePermissionsEnum.confirmReceptionOpl] && view_reception_opl && setCanIuseUpdateCourierDelivery(true) 
  }, [permission, view_reception_opl])

  const handleClickUpdateCourierDelivery = async () => {
    const body = {
      shippingGroupId,
      user: user?.id,
      status: "Despacho recepcionado",
      scheduleDate: formatDate(new Date(), "dd/MM/yyyy hh:mm:ss"),
      timestamp: new Date().getTime(),
    }

    try {
      setLoadingUpdateCourierDelivery(true)
      const {
        data: { code },
      } = await updateCourierDelivery(
        body,
        newCancelToken(),
      )

      setLoadingUpdateCourierDelivery(false)

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Cambio de sub estado completado",
            title: "Actualizado",
            type: "success",
          },
        })

        setTimeout(() => history.push("/dashboard"), 3000)
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadingUpdateCourierDelivery(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    handleClickUpdateCourierDelivery,
    loadingUpdateCourierDelivery,
    canIuseUpdateCourierDelivery
  }
}

export default UseUpdateCourierDelivery