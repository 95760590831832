import { memo } from "react"
import ProfileInfoStyled from "components/User/Profile/UserInfo/UserInfoProfile.styled"
import { IProfileUser } from "interfaces/IProfileUser"

const UserInfoProfile = memo(({ name, rol, sources }: IProfileUser) => {
  const firstLetterName = name[0]
  return (
    <ProfileInfoStyled>
      <div className="img-profile">
        <p>{firstLetterName}</p>
      </div>
      <div className="info-profile ">
        <p className="user-name">{name}</p>
        <p>{rol}</p>
        <p>Source(s)</p>
        <ul className="list-source">
          {sources.map((source) => (
            <li key={source}>{source}</li>
          ))}
        </ul>
      </div>
    </ProfileInfoStyled>
  )
})

export default UserInfoProfile
