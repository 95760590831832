import product_image_default from "assets/images/shiperto/product-image-default.svg"
import { useTranslation } from "react-i18next"

const Item = (props: any) => {
  const { t } = useTranslation()
  const { item } = props

  const template = (
    <article className="order__products__list__item">
      <div className="order__products__list__item__wrapper">
        <div className="order__products__list__item__image">
          {item?.image ? (
            <img src={item?.image} alt={item?.name} />
          ) : (
            <img src={product_image_default} alt={item?.name} />
          )}
        </div>
        <div className="order__products__list__item__details">
          <h4>{item?.name}</h4>
          <div>
            <p>{t("SKU")}: </p>
            <p>
              <strong>{item?.sku}</strong>
            </p>
          </div>
          <div>
            <p>{t("Tamaño")}: </p>
            <p>
              <strong>{item?.logisticAttributes?.size}</strong>
            </p>
          </div>
          <div>
            <p>{t("Categorías")}: </p>
            <p>
              <strong>{item?.categories?.join(" ,")}</strong>
            </p>
          </div>
          <div>
            <p>{t("Cantidad")}: </p>
            <p>
              <strong>{item?.quantity}</strong>
            </p>
          </div>
        </div>
      </div>
    </article>
  )

  return template
}

export default Item
