import { useContext } from "react"
import NotificationList from "components/Commons/Notifications/NotificationList"
import { GlobalContext } from "context/context/GlobalContext"
import SideBar from "components/Theme/Sidebar/SideBar"
import ErrorHandler from "components/Commons/Error/ErrorHandler"
import { MainContent } from "components/Theme/Layout/Main/MainLayout.styled"
import Helmet from "components/Commons/Helmet/Helmet"

const MainLayout = (props: any) => {
  const { children } = props

  const { errorHander, notification } = useContext(GlobalContext)

  const notifications = notification?.state

  if (errorHander?.state?.hasError)
    return <ErrorHandler code={errorHander.state.code} message={errorHander?.state?.message} />

  return (
    <>
      <Helmet />
      {notifications && notifications.length > 0 && <NotificationList notifications={notifications} />}
      <SideBar />
      <MainContent>{children}</MainContent>
    </>
  )
}

export default MainLayout
