import { IMenu } from "interfaces/IMenu"
import CardOption from "overrides/theme/shiperto/base/components/Summary/SummaryOption/CardOption"

const SummaryOptions = ({ summaryOptions }: { summaryOptions: IMenu[] }) => {
  return (
    <>
      {summaryOptions.map(({ optionCount, path, title, status }, index) => (
        <CardOption
          index={index}
          key={`${path}-${title}`}
          optionCount={optionCount}
          path={path}
          title={title}
          status={status}
        />
      ))}
    </>
  )
}

export default SummaryOptions
