import { InputRadio, Label, Mark, RadioWrapper } from "theme/common/RadioButton"

const RadioButton = ({ name, checked, value,onChange, children }: any) => (
  <RadioWrapper>
    <Label>
      <InputRadio name={name} value={value} checked={checked} onChange={onChange} />
      <Mark />
      {children}
    </Label>
  </RadioWrapper>
)

export default RadioButton
