// import { useState, useEffect } from "react"
// import { useTranslation } from "react-i18next"
// import { IRangeExtend } from "interfaces/IContentCelendar"
// import OrderFilterDate from "overrides/theme/entel/base/components/Core/Order/FilterDate/OrderFilterDate"
// import { formatDate } from "helpers/FormatDateHelper"

// const kibana_token = "VaiGgu286f5HKlBdaOwp"
// const urlKibanaBase = `https://development-6ded9f.kb.us-west-2.aws.found.io:9243/app/dashboards?auth_provider_hint=${kibana_token}#/view/eaa4fa70-3b7f-11ee-bb0e-8385029a80d2?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)`

const DashboardAside = () => {
  // const { t } = useTranslation()
  // const [dateRange, setDateRange] = useState<IRangeExtend>()
  // const [kibanaUrl, setKibanaUrl] = useState<string>(`${urlKibanaBase}%2Ctime%3A(from%3A%27${formatDate(new Date(new Date().getDate() - 7), "yyyy-MM-dd")}T00:00:00%27%2Cto%3A%27${formatDate(new Date(), "yyyy-MM-dd")}T23:59:00%27))&hide-filter-bar=true`)

  // useEffect(() => {
  //   let date = new Date()
  //   date.setMonth(date.getMonth() - 1)
  //   onSelectedRangeDate({startDate: date, endDate: new Date()})
  // }, [])

  // useEffect(() => {
  //   setKibanaUrl(`${urlKibanaBase}%2Ctime%3A(from%3A%27${dateRange?.startDate && formatDate(dateRange?.startDate, "yyyy-MM-dd")}T00:00:00%27%2Cto%3A%27${dateRange?.endDate && formatDate(dateRange?.endDate, "yyyy-MM-dd")}T23:59:00%27))&hide-filter-bar=true`)
  // }, [dateRange])

  // const onSelectedRangeDate = (range: any) => {
  //   setDateRange(range)
  // }
  
  // const template_iframe = (
  //   <iframe
  //     title="Reportes"
  //     src={kibanaUrl}
  //   ></iframe>
  // )

  // const template = (
  //   <div className="dashboard__reports">
  //     <h3>{t("Reporte")}</h3>
  //     <OrderFilterDate onSelectedRangeDate={onSelectedRangeDate} />
  //     {template_iframe}
  //   </div>
  // )

  return null // template
}

export default DashboardAside
