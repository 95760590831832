import { FunctionComponent, Suspense, useCallback, useContext, useRef } from "react"
import { RouteComponentProps, useParams } from "react-router-dom"
import OrderList from "components/Core/Order/OrderList"
import OrderContainerStyled from "components/Core/Order/OrderList.styled"
// import useGetOrderByStatus from "hooks/useGetOrderByStatus"
import useGetSgsByStatus from "hooks/useGetSgsByStatus"
import IPage from "interfaces/IPage"
import SkeletonGrid from "components/Commons/Skeleton/SkeletonGrid"
import BackBotton from "components/Commons/BackBotton/BackBotton"
import Paginate from "components/Commons/Pagination/Paginate"
import OrderFilterDate from "components/Core/Order/FilterDate/OrderFilterDate"
import ErrorHandled from "components/Commons/Error/ErrorHandler"
import SearchForm from "components/SearchForm/SearchForm"
import { ISearchResults } from "interfaces/ISearchResults"
import SortOrder from "components/Core/Order/Sort/SortOrder"
import { AuthContext } from "context/context/AuthContext"
import { clients } from "webapp-clients/Clients"

const OrderScreen: FunctionComponent<IPage & RouteComponentProps<any>> = (props: any) => {
  const { type }: any = useParams()

  const {
    state: { user },
  } = useContext(AuthContext)

  const useGetOrderByStatus = useGetSgsByStatus()

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const CustomFilters = client["CustomFilters" as keyof typeof client]

  const refElemOrders = useRef<HTMLDivElement>(null)

  const {
    ordersGroup,
    loading,
    setPage,
    maxPage,
    onSelectedRangeDate,
    page,
    setSearch,
    onChangeSortDate,
    handleChangeCustomFilters,
  } = useGetOrderByStatus(type)

  const handleClickPage = (pageSeleted: number) => {
    setPage(pageSeleted)
  }

  const handleClickSearch = useCallback(
    ({ query, optionFilter }: ISearchResults) => {
      if (optionFilter) {
        if (query === "") {
          setSearch({
            [optionFilter]: undefined,
          })
        } else {
          setSearch({
            [optionFilter]: query,
          })
        }
      }
    },
    [setSearch],
  )

  return (
    <div ref={refElemOrders}>
      <BackBotton>
        <OrderFilterDate onSelectedRangeDate={onSelectedRangeDate} />
      </BackBotton>
      <SearchForm onClickSearch={handleClickSearch} isLoading={loading} />
      <OrderContainerStyled hasCustomFilters={!!CustomFilters}>
        <section className="section-filters">
          {CustomFilters && (
            <Suspense fallback={null}>
              <CustomFilters onChangeCustomFilters={handleChangeCustomFilters} />
            </Suspense>
          )}
          <SortOrder handleChangeSort={onChangeSortDate} disabled={loading} />
        </section>

        {loading ? (
          <SkeletonGrid count={3} isGroupOrder={true} />
        ) : (
          <>
            {ordersGroup ? (
              <>
                <OrderList ordersGroup={ordersGroup} />
                {maxPage > 1 && (
                  <Paginate
                    pageCount={maxPage}
                    onChangePage={handleClickPage}
                    elementFocus={refElemOrders}
                    pageInit={page}
                  />
                )}
              </>
            ) : (
              <ErrorHandled message="Sin resultados para esta busqueda" />
            )}
          </>
        )}
      </OrderContainerStyled>
    </div>
  )
}

export default OrderScreen
