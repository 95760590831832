import { Route, Redirect } from "react-router-dom"

const PublicRouteComponent = (props: any) => {
  if (!props.isAuthenticated) {
    return <Route {...props} />
  } else {
    const renderComponent = () => <Redirect to={{ pathname: props.redirectPath }} />
    return <Route {...props} component={renderComponent} render={undefined} />
  }
}

export default PublicRouteComponent
