import { Suspense, lazy, memo, useContext, useEffect, useRef, useState } from "react"
import Input from "components/Commons/Input"
import { useForm } from "react-hook-form"
import { OptionFilterWrapper, SearchFormStyled } from "components/SearchForm/SearchForm.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import { ISearch, ISearchForm } from "interfaces/ISearchForm"
import { IconBarcode, IconSearch, IconSpinner } from "theme/common/Icon.styled"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaSearchForm } from "config/ShemaForms"
import RadioButton from "components/Commons/RadioButton"
import { OptionsFilterEnum } from "enums/OptionsFilterEnum"
import { filterOrderData } from "overrides/theme/wom/base/assets/Data/FiltersData"
import { notAvailableStatus } from "overrides/theme/wom/base/assets/Data/ParentContainerData"
import Modal from "components/Commons/Modal/Modal"
import { useParams } from "react-router-dom"
import { AuthContext } from "context/context/AuthContext"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"

const SearchForm = memo(({ onClickSearch, search, optionFilter, isLoading }: ISearch) => {
  const [filter, setFilterValue] = useState(OptionsFilterEnum.query.toString())
  const { register, handleSubmit, setValue } = useForm<ISearchForm>({
    resolver: yupResolver(shemaSearchForm),
  })
  const [searchOptions, setSearchOptions]:any = useState([])

  const { ref: refInputSearch } = register('search')

  const {
    state: { user },
  } = useContext(AuthContext)

  const { type } = useParams<{ type: string }>()

  const ScannerSimple = lazy(
    () => import("components/Core/ScannerSimple/ScannerSimple"),
  )
  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const [canIuseSearchScan, setCanIuseSearchScan] = useState(false)
  
  useEffect(() => {
    if (search) setValue("search", search)
    if (optionFilter) setFilterValue(optionFilter)
  }, [search, setValue, optionFilter])

  const handleClickSubmit = ({ search }: ISearchForm) => {
    onClickSearch({ query: search, optionFilter: filter })
  }

  const handleChangeOptionFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(ev.target.value)
  }

  const resultPlaceHolder = searchOptions.find(({ value }:any) => value === filter)?.label

  const withOutParentContainer = filterOrderData.filter(data => data?.value !== OptionsFilterEnum["custom.contenedorPadre"])

  useEffect(() => {
    if(filter === OptionsFilterEnum["query"] || filter === OptionsFilterEnum["custom.contenedorPadre"])
      setCanIuseSearchScan(true)
    else
      setCanIuseSearchScan(false)
  },
  //eslint-disable-next-line
  [filter])

  const handleClickOpenScan = () => {
    refModal?.current?.open()
  }

  const handleScannerResult = (serieScanner: string) => {
    refModal?.current?.close()
    setValue('search', serieScanner)
    handleSubmit(handleClickSubmit)()
  }

  useEffect(() => {
    setSearchOptions(filterOrderData)

    if(user?.role !== ResourceRoleEnum["webapp-seller-courier"] && user?.role !== ResourceRoleEnum["webapp-sup-courier"] && notAvailableStatus.includes(type))
      setSearchOptions(withOutParentContainer)
  },
  //eslint-disable-next-line
  [type])

  return (
    <>
      <OptionFilterWrapper>
        {searchOptions.map(({ value, label }: any) => (
          <RadioButton
            key={value}
            name="optionFilter"
            value={value}
            checked={value === filter}
            onChange={handleChangeOptionFilter}
          >
            {label}
          </RadioButton>
        ))}
      </OptionFilterWrapper>
      <SearchFormStyled onSubmit={handleSubmit(handleClickSubmit)}>
        <ButtonStyled variant="primary" disabled={isLoading}>
          <span>BUSCAR</span>
          <IconSearch />
        </ButtonStyled>
        <Input type="search" placeHolder={resultPlaceHolder} {...register("search")} className={`${canIuseSearchScan ? "with-scan" : ""}`} ref={refInputSearch} />
        {canIuseSearchScan &&
          <ButtonStyled variant="primary" className="search-scan" disabled={isLoading} onClick={handleClickOpenScan}>
            <IconBarcode />
          </ButtonStyled>
        }
      </SearchFormStyled>

      <Modal ref={refModal}>
        <Suspense fallback={<IconSpinner variant="big" className="content-center" />}>
          <ScannerSimple
            onConfirmScanner={handleScannerResult}
          />
        </Suspense>
      </Modal>
    </>
  )
})

export default SearchForm
