import { displays } from "theme/base/Variables"
import styled from "styled-components"

export const RadioWrapper = styled.div`
  display: inline-block;

  & label {
    &:hover {
      & span {
        border: 1px solid ${({ theme }) => theme.radioButton.hoverColor};
      }
    }
  }
`

export const Mark = styled.span`
  display: inline-block;
  position: relative;
  border: 1px solid ${({ theme }) => theme.radioButton.color};
  width: 18px;
  height: 18px;
  border-radius: 50%;

  margin-right: 5px;
  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    transition: all 110ms;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.radioButton.hoverColor};
  }
`

export const InputRadio = styled.input.attrs({ type: "radio" })`
  position: absolute;
  visibility: hidden;
  display: none;

  &:checked + ${Mark} {
    background: ${({ theme }) => theme.radioButton.color};
    &:hover {
      background: ${({ theme }) => theme.radioButton.hoverColor};
    }
    &::after {
      opacity: 1;
      width: 6px;
      height: 6px;
      background: white;
    }
  }
`

export const Label = styled.label`
  ${displays.flexBase}
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
`
