export const tagPrintContentStyle = `
  .pt__app-container .pt__button_print_tag {
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px; 
    background: linear-gradient(171.14deg, #EEAB28 3.14%, #EE7628 20.68%, #DA5600 103.69%);
    box-shadow: 0px 4px 8px rgba(181, 185, 255, 0.4);
    border-radius: 2rem;
    padding: 0.5rem 1.4rem;
  }
`