import { keyframes } from "styled-components"

export const fade = keyframes`
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
 `

export const progressAnima = keyframes`
0% {
		width: 0%;
	}
	5% {
		width: 0%;
	}
	10% {
		width: 20%;
	}
	30% {
		width: 40%;
	}
	50% {
		width: 55%;
	}
	80% {
		width: 100%;
	}
	95% {
		width: 100%;
	}
	100% {
		width: 10%;
	}
`

export const BottomToTop = keyframes`
  0% {
    top: 0px;
  }

  100% {
    top: 20px;
  }
	`

export const spin = keyframes`
	 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
	`

export const animateBubble = keyframes`
		20% {
			transform: rotate3d(0, 0, 1, 15deg);
		}

		40% {
			transform: rotate3d(0, 0, 1, -10deg);
		}

		60% {
			transform: rotate3d(0, 0, 1, 5deg);
		}

		80% {
			transform: rotate3d(0, 0, 1, -5deg);
		}

		100% {
			transform: rotate3d(0, 0, 1, 0deg);
		}
`
