export const bulkLoadScreenStyle = `
  .bulk_load_screen_main_container {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 1rem;
  }
  .bulk_load_screen_header {
    width: 100%;
    padding: 1.5em 0 1em 1.5em;
    border-bottom: 0.5px solid #dddde9; 
  }
  .bulk_load_screen_header h4 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 22px;
    color: #23203F;
  }
  .bulk_load_screen_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3em 1.5em;
  }
  .bulk_load_screen_body_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #b4c1ff;
    padding: 2em 1em;
    border-radius: 0.5em;
    text-align: left;
  }
  .bulk_load_screen_body_form_group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5em;
  }
  .bulk_load_screen_body_form .bulk_load_screen_body_form_icon_load {
    padding: 1.5em 0 1em;
  }
  .bulk_load_screen_body_form_group select,.bulk_load_screen_body_form_group label {
    font-family: 'Montserrat';
    font-size: 1rem;
    color: #000000;
  }
  .bulk_load_screen_body_form_group select {
    font-weight: 500;
    background: #FFFFFF;
    padding: 0.5em 5em 0.5em 1em;
    border-radius: 0.5em;
  }
  .bulk_load_screen_uploader {
    width: 100%;
  }
  .bulk_load_screen_uploader .filepond--label-action, .bulk_load_screen_body_button_download_template {
    font-family: "Poppins";
    font-size: 1rem;
    color: #002eff;
    text-decoration-color: #002eff;
  }
  .bulk_load_screen_body_form_error_message {
    text-align: center;
    color: red;
    padding-bottom: 1em;
    font-family: "Poppins";
    font-size: 1rem;
  }
  .bulk_load_screen_body_form .button-form {
    width: 12rem;
    height: 3rem;
    border-radius: 1.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    color: #FFFFFF;
    background: #002eff;
    box-shadow: 0px 4px 8px rgba(181, 185, 255, 0.4);
  }
  .bulk_load_screen_body_form_disclaimer_cont {
    width: 100%;
    text-align: left;
    margin-top: 0.8em;
    padding: 0 1em;
  }
  .bulk_load_screen_body_form_disclaimer_cont p {
    font-family: "Montserrat";
    font-size: 0.8rem;
    font-weight: 500;
    color: #716F87;
  }
  .bulk_load_screen_body_cont_download_template {
    width: 100%;
    display: grid;
    place-items: end;
    padding: 0.5em;
  }
  .bulk_load_screen_body_button_download_template {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (min-width: 1280px) {
    .bulk_load_screen_body_form {
      width: 700px;
    }
    .bulk_load_screen_body_form_group {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 2em;
    }
    .bulk_load_screen_body_cont_download_template {
      width: 700px;
  }
`