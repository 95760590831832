import { useEffect, useContext, useState } from "react"
import { AuthContext } from "context/context/AuthContext"
import { IResourcePermission } from "interfaces/IResourcePermission"
import Logger from "classes/Logger"
import JsonEngine from "classes/JsonEngine"

/*
Hook to handle user permissions
*/

const useUserPermissions = ({ resources }: { resources: Array<string> }) => {
  const initState = resources.reduce(
    (prev, current) => ({
      ...prev,
      [current]: false,
    }),
    {},
  )
  const [permission, setPermission] = useState<IResourcePermission>(initState)
  const {
    state: { user },
  } = useContext(AuthContext)

  /* Set the rules for the JSON Engine library 
      @return {void} Nothing 
   */
  useEffect(() => {
    if (resources.length === 0 || !user) return

    let isSubscribed = true
    ;(async () => {
      try {
        const params = {
          source: user?.currentSources,
          shippingGroup: {
            custom: {
              eLocker: true,
            },
          },
        }
        const promesesPermissions = resources.map((resource) => hasPermission({ resource, params }))
        const resultPermissions = await Promise.all(promesesPermissions)

        if (isSubscribed) {
          setPermission((prevPermissions) => {
            const newPermissions = { ...prevPermissions }
            Object.keys(prevPermissions).forEach((key, index) => {
              newPermissions[key] = resultPermissions[index]
            })

            return newPermissions
          })
        }
      } catch (error) {
        Logger.error(error)
      }
    })()

    return () => {
      isSubscribed = false
    }

    //eslint-disable-next-line
  }, [user])

  /* Check if the current user have the permission to access one resource
      @return {void} Nothing 
   */
  const hasPermission = ({
    resource,
    params,
  }: {
    resource: string
    params: any
  }): Promise<boolean> => {
    return new Promise((resolve) => {
      if (!user) {
        resolve(false)
        return
      }
      const { groups, currentSources, mainGroup, permission } = user

      if (!permission || permission.length === 0) {
        resolve(true)
        return
      }

      const engine = JsonEngine.getInstance({ rules: permission.flatMap(({ rule }) => rule) })
      const facts = {
        resource: resource,
        params: params,
        securityModel: { groups, currentSources, mainGroup, permission },
      }

      engine.run(facts).then((results) => {
        const allow = results.events.some(
          ({ type, params }) => type === "Permission" && params && params.effect === "Allow",
        )

        resolve(allow)
      })
    })
  }

  return {
    permission,
  }
}

export default useUserPermissions
