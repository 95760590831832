import { useContext, useEffect, useState } from "react"
import Logger from "classes/Logger"
import { getSourceInfoById } from "services/SourceService"
// import { SourceType } from "types/SourceType"
import { GlobalContext } from "context/context/GlobalContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"

/*
Hook to handle Source Info

@param {String} sourceId - ID of a Source
@return {Object<SourceType>} order Object with the source info

*/

const useGetSourceInfo = (sourceId: any) => {
  const [sources, setSources] = useState<any>(null)
  const { errorHander } = useContext(GlobalContext)
  const { isCancel, newCancelToken } = useCancelToken()

  useEffect(() => {
    sourceId && getDetailSources(sourceId)
    // eslint-disable-next-line
  }, [sourceId])

  const getDetailSources = async (sourceId: string) => {
    try {
      const {
        data: { message: sources },
      } = await getSourceInfoById(sourceId, newCancelToken())
      setSources(sources)
    } catch (error: any) {
      Logger.error(error)
      if (isCancel(error)) return

      errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
    }
  }

  return { sources }
}

export default useGetSourceInfo
