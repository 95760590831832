import { lazy } from "react"
import { ComponentsType } from "types/CommonsTypes"

const ManualTransition = lazy(() => import("components/Core/ProductTransition/ManualTransition"))

const FileUpload = lazy(() => import("components/Commons/FileUpload/FileUpalder"))

const ContentFileMedia = lazy(
  () => import("webapp-clients/Wom/Components/ViewContentMedia/ContentFileMedia"),
)

export const shiperto: ComponentsType = {
  ContentFileMedia,
  ProductTransition: ManualTransition,
  FileUpload
}
