import styled from "styled-components"
import { displays, textuppercase } from "theme/base/Variables"

const ProfileInfoStyled = styled.section`
  ${displays.flexColumn}

  & .img-profile {
    background: ${({ theme }) => theme.palette.grey90};
    border-radius: 50%;
    height: 120px;
    width: 120px;
    margin-bottom: 1rem;
    & > p {
      text-align: center;
      font-size: 3.1875rem;
      font-weight: 600;
      line-height: 2.5;
      ${textuppercase}
    }
  }

  & .info-profile {
    ${displays.flexColumn}
    font-size: 1rem;
    align-items: center;
    & p {
      margin-bottom: 0.4rem;
      &.user-name {
        font-weight: 600;
      }
    }

    & .list-source {
      ${displays.flexWrap};
      gap: 0.7rem;
    }
  }
`

export default ProfileInfoStyled
