import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "theme/common/Icon.styled"
import { IPickingAction } from "interfaces/IShippingGroupAction"
import { useTranslation } from "react-i18next"

const PickingAction = ({ loadingConfirm, onClickConfirmOrder }: IPickingAction) => {
  const { t } = useTranslation()
  
  return (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled
        variant="primary"
        loadingButton={loadingConfirm}
        disabled={loadingConfirm}
        onClick={onClickConfirmOrder}
      >
        <IconCheck />
        <span>{t("LABEL_PICKING_ACTION")}</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )
}

export default PickingAction
