import { IPropsButtonSecunday } from "interfaces/IPropsButton"
import {Fragment } from "react"
import { IconCheck } from "theme/common/Icon.styled"
import {buttonOptionCalendarStyle} from "overrides/theme/shiperto/base/components/Core/Order/FilterDate/ContentCalendar/ContentCalendar.style"

const ButtonOptionCalendar = ({ text, active, onclick, id }: IPropsButtonSecunday) => {
  return (
    <Fragment>
      <button
        className={active ? "button_option_calendar__container button_option_calendar__active" : "button_option_calendar__container"}
        onClick={onclick}
        id={id.toString()}
      >
        {active && <IconCheck className="icon" />}
        {text}
      </button>
      <style jsx>{buttonOptionCalendarStyle}</style>
    </Fragment>
  )
}

export default ButtonOptionCalendar
