import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "theme/common/Icon.styled"
import { IPackingAction } from "interfaces/IShippingGroupAction"

const PackageAction = ({ loadingPaking, onClickPackageOrder }: IPackingAction) => {
  return (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled
        variant="primary"
        loadingButton={loadingPaking}
        disabled={loadingPaking}
        onClick={onClickPackageOrder}
      >
        <IconCheck />
        <span>Confirmar Empacado</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )
}

export default PackageAction
