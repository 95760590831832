import { Fragment, memo, useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { ISearch, ISearchForm } from "interfaces/ISearchForm"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaSearchForm } from "config/ShemaForms"
import { OptionsFilterEnum } from "enums/OptionsFilterEnum"
import { filterOrderData } from "theme/assets/Data/FiltersData"
import { inputFormStyle, searchFormStyle } from "overrides/theme/shiperto/base/components/SearchForm/styles/SearchForm.style"
import loupe_icon from "assets/icons/loupe-icon.svg"
import arrow_left_icon from "assets/icons/arrow-left-icon.svg"

const SearchForm = memo(({ onClickSearch, search, optionFilter, isLoading, backButton }: ISearch) => {
  const [filter, setFilterValue] = useState(OptionsFilterEnum.query.toString())
  const { register, handleSubmit, setValue } = useForm<ISearchForm>({
    resolver: yupResolver(shemaSearchForm),
  })
  const history = useHistory()
  const formRef: any = useRef(null)

  useEffect(() => {
    if (search) setValue("search", search)
    if (optionFilter) setFilterValue(optionFilter)
  }, [search, setValue, optionFilter])

  const handleClickSubmit = ({ search }: ISearchForm) => {
    onClickSearch({ query: search, optionFilter: filter })
  }

  const handleChangeOptionFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(ev.target.value)
  }

  const resultPlaceHolder = filterOrderData.find(({ value }) => value === filter)?.label

  const InputForm = () => (
    <Fragment>
      <div className="searchform__input--form">
        <input {...register("search")} type="text" placeholder={resultPlaceHolder} disabled={isLoading} />
        <img
          src={loupe_icon}
          alt="X"
          className="searchform__arrow-left_lupe_icon"
          onClick={() => formRef?.current?.requestSubmit()}
        />
      </div>
      <style jsx>{inputFormStyle}</style>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="searchform__container">
        <form
          className="searchform__input-form-container"
          onSubmit={handleSubmit(handleClickSubmit)}
          ref={formRef}
        >
          {backButton && <div
            className="searchform__arrow-left--container"
            onClick={() => history.goBack()}
          >
            <img src={arrow_left_icon} alt="<-" />
          </div>}
          <InputForm />
        </form>
        <div className="searchform__filters-container">
          {filterOrderData.map(({ value, label }) => (
            <label key={value} className="searchform__label-checkmark">{label}
              <input
                type="radio"
                key={value}
                name="optionFilter"
                value={value}
                checked={value === filter}
                onChange={handleChangeOptionFilter}
                disabled={isLoading}
              />
              <span className="searchform__input-checkmark"></span>
            </label>
          ))}
        </div>
      </div>
      <style jsx>{searchFormStyle}</style>
    </Fragment>
  )
})

export default SearchForm
