import { ModalMessageContentStyled } from "./ModalMessage.styled"

const ModalMessage = ({code, source, available}: any) => {

  return (
    <ModalMessageContentStyled>
      <div className="error-code">{code}</div>

      {(code === "ORDER_NOT_FILL" && available.length) ? (
        <>
          <div className="error-title">Los siguientes producto(s) están disponible en la tienda <span className="currren-source">{source}</span> </div>
          <div className="box-error-table">
            <table>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {available.map((item: any) => (
                  <>
                    <tr>
                      <td>{item.sku}</td>
                      <td>{item.quantity}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ): (
        <div className="error-title">Ha ocurrido un error inesperado al crear la orden. Intente nuevamente</div>
      )}
    </ModalMessageContentStyled>
  )
}

export default ModalMessage