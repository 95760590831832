import { useState, useEffect, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { useTranslation } from "react-i18next"
import { ChartsScreenStyles } from "overrides/theme/shiperto/base/pages/ChartsScreen/styles/ChartsScreen.style"
import { IRangeExtend } from "interfaces/IContentCelendar"
import OrderFilterDate from "overrides/theme/shiperto/base/components/Core/Order/FilterDate/OrderFilterDate"
import { formatDate } from "helpers/FormatDateHelper"

const kibana_token = "VaiGgu286f5HKlBdaOwp"
const urlKibanaBase = `https://development-6ded9f.kb.us-west-2.aws.found.io:9243/app/dashboards?auth_provider_hint=${kibana_token}#/view/eaa4fa70-3b7f-11ee-bb0e-8385029a80d2?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)`

const ChartsScreen = () => {
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState<IRangeExtend>()
  const [kibanaUrl, setKibanaUrl] = useState<string>(`${urlKibanaBase}%2Ctime%3A(from%3A%27${formatDate(new Date(new Date().getDate() - 7), "yyyy-MM-dd")}T00:00:00%27%2Cto%3A%27${formatDate(new Date(), "yyyy-MM-dd")}T23:59:00%27))&hide-filter-bar=true`)

  useEffect(() => {
    let date = new Date()
    date.setMonth(date.getMonth() - 1)
    onSelectedRangeDate({startDate: date, endDate: new Date()})
    displayHeaderRouteName.dispatch("Reportes")
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setKibanaUrl(`${urlKibanaBase}%2Ctime%3A(from%3A%27${dateRange?.startDate && formatDate(dateRange?.startDate, "yyyy-MM-dd")}T00:00:00%27%2Cto%3A%27${dateRange?.endDate && formatDate(dateRange?.endDate, "yyyy-MM-dd")}T23:59:00%27))&hide-filter-bar=true`)
  }, [dateRange])

  console.log("kibanaUrl", kibanaUrl)
  const template_iframe = (
    <iframe
    title="Reportes"
      src={kibanaUrl}
    ></iframe>
  )

  const onSelectedRangeDate = (range: any) => {
    setDateRange(range)
  }

  const template = (
    <div className="reports">
      <h1>{t("Reporte")}</h1>
      <OrderFilterDate onSelectedRangeDate={onSelectedRangeDate} />
      {template_iframe}
      <style jsx>{ChartsScreenStyles}</style>
    </div>
  )

  return template
}

export default ChartsScreen
