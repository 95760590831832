import { OrderActionsType } from "context/actionsTypes"

/**
 * Operations that can be executed on the order context
 *
 * @version 1.0.1
 * @author Naldo Duran<naldorck@gmail.com>
 *
 * @param {IContextOrder} state * TODO Interface *
 * @param {any} action
 *
 * @returns {IContextOrder} * TODO Interface *
 */
const OrderReducer = (state: any, action: any) => {
  switch (action.type) {
    case OrderActionsType.set_action:
      return {
        ...state,
        action: action.payload,
      }
    case OrderActionsType.set_next_page:
      return {
        ...state,
        next_page: action.payload,
      }
    default:
      return state
  }
}

export default OrderReducer
