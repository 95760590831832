import { FunctionComponent, useState, useContext, Suspense, useRef } from "react"
import { IProcessItem } from "interfaces/IItemsOrder"
import IPage from "interfaces/IPage"
import UseTransfer from "hooks/UseTransfer"
import { clients } from "webapp-clients/Clients"
import SelectBox from "overrides/theme/wom/base/components/Commons/SelectBox/SelectBox"
import { ContentForm, TransferContentStyled, ContentFilterSource } from "theme/common/Transfer.styled"
import { IconCheck } from "theme/common/Icon.styled"
import { AuthContext } from "context/context/AuthContext"
import AlertStyled from "theme/common/Alert.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import Input from "components/Commons/Input"
import { schemaProductFormValidation } from "config/ShemaForms"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ProcessProductsList from "components/Transfer/ProcessProductsList"
import Modal from "components/Commons/Modal/Modal"
import WindowModal from "components/Commons/WindowModal/WindowModal"
import ModalMessage from "components/Transfer/ModalMessage/ModalMessage"


const TransferScreen: FunctionComponent<IPage> = (props: any) => {
  //const { name } = props
  
  const {
    state: { user },
  } = useContext(AuthContext)

  const ProductTransition =
    user?.mainGroup?.id && clients[user.mainGroup.id as keyof typeof clients]?.ProductTransition
    
  
  const [skuDelete, setSkuDelete] = useState<string>("")
  const refModalConfirmDelete = useRef<React.ElementRef<typeof Modal>>(null)
  
  const {
    register,
    handleSubmit,
    getValues,
    reset,
  } = useForm<any>({
    resolver: yupResolver(schemaProductFormValidation()),
  })

  const _quantity:any = getValues("quantity");

  const {
    source,
    transfer,
    handleChangeSources,
    submitAddProduct,
    onErrorAddProduct,
    confirmProcessProduct,
    confirmDeleteProduct,
    handleCreateOrder,
    handleNewOrder,
    refModalError,
  } = UseTransfer()

  const callbackProcessSerial = (item: IProcessItem) => {
    if (item) {
      reset({sku: '', quantity: ''})
      confirmProcessProduct(item)
    }
  }

  const showText = () => {
    return parseInt(_quantity) > 1 ? _quantity + " seriales" : "un serial"
  }

  const handleClickDeleteItem = (sku: string) => {
    setSkuDelete(sku)
    refModalConfirmDelete.current?.open()
  }

  /*
  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])
  */

  return (
    <>
      <Modal ref={refModalConfirmDelete}>
        <WindowModal
          title="Confirmación"
          description="Usted está seguro que desea eliminar el producto"
          handleConfirm={() => {
            confirmDeleteProduct(skuDelete)
            refModalConfirmDelete.current?.close()
          }}
          handleCancel={() => refModalConfirmDelete.current?.close()}
        />
      </Modal>

      <Modal ref={refModalError}>
        <WindowModal
          title="Algo salió mal al crear la orden"
          description={transfer.modal.description}
          showCancel={false}
          handleConfirm={() => {
            refModalError.current?.close()
          }}
          handleCancel={() => refModalError.current?.close()}
        >
          <ModalMessage
            code={transfer.modal.code}
            source={source.current}
            available={transfer.modal.available}
          />
        </WindowModal>
      </Modal>
      
      {!transfer.success ? (
        <>
          {source.show && (
            <TransferContentStyled>
              <div className="content-info">
                <ContentFilterSource>
                  <h3 className="sub-title">Tienda Origen</h3>
                  <SelectBox
                    options={source.options}
                    onchange={handleChangeSources}
                    defaultValue={source.current}
                    disabled={source.disabled}
                  />
                </ContentFilterSource>
              </div>
            </TransferContentStyled>
          )}

          {source.current && (
            <TransferContentStyled>
              <AlertStyled variant="info" className="info-source">
                <p>Tienda Origen {source.current} -{'>'} Tienda Destino {source.target}</p>
              </AlertStyled>
            </TransferContentStyled>
          )}
          
          {source.current && (
            <TransferContentStyled className="content-form">
              <div className="content-info">
                <ContentForm>
                  <h2 className="title">Información del Producto</h2>

                  {transfer.error.form && (
                    <div className="box-error">
                      <ErrorFieldForm>{transfer.error.form}</ErrorFieldForm>
                    </div>
                  )}
                  
                  <form onSubmit={handleSubmit(submitAddProduct, onErrorAddProduct)}>
                    <div className="box-form-product">
                      <div className="field">
                        <Input
                          {...register("sku")}
                          type="text"
                          placeHolder='SKU'
                          className="field-input"
                        />
                      </div>
                      <div className="field">
                        <Input
                          {...register("quantity")}
                          type="number"
                          placeHolder='Cantidad'
                          className="field-input"
                        />
                      </div>
                      <div className="action">
                        <ButtonStyled
                          variant="primary"
                          loadingButton={transfer.loading}
                          disabled={transfer.loading}
                          type="submit"
                        >
                          <span>Agregar</span>
                        </ButtonStyled>
                        </div>
                    </div>
                  </form>
                  
                  {(transfer.serial.show && ProductTransition) && (
                    <div className="box-serial">
                      {_quantity && (
                        <AlertStyled variant="danger" className="msg-alert-serial">
                          <p>Para continuar, necesita ingresar o escanear {showText()} </p>
                        </AlertStyled>
                      )}
                      
                      <Suspense fallback={null}>
                        <ProductTransition
                          itemOrder={transfer.item}
                          onClicProcessProduct={callbackProcessSerial}
                          source={source.current}
                        />
                      </Suspense>
                    </div>
                  )}
                </ContentForm>
              </div>
            </TransferContentStyled>
          )}
          
          {transfer.data.length > 0 && (
            <>
              <TransferContentStyled>
                <AlertStyled variant="success" className="products-title">Productos</AlertStyled>
              </TransferContentStyled>
              <TransferContentStyled className="products-list">
                <div className="content-info pd0">
                  <ProcessProductsList
                    processItems={transfer.data}
                    onClickDeleteItem={handleClickDeleteItem}
                  />
                </div>
              </TransferContentStyled>
              <TransferContentStyled>
                <div className="box-buttons">
                  <ButtonStyled
                    variant="primary"
                    loadingButton={transfer.creating}
                    disabled={transfer.creating}
                    onClick={handleCreateOrder}
                  >
                    <IconCheck />
                    <span>Crear Orden</span>
                  </ButtonStyled>
                </div>
              </TransferContentStyled>
            </>
          )}
        </>
      ) : (
        <>
          <TransferContentStyled>
            <AlertStyled variant="success" className="products-title">Información de la Orden</AlertStyled>
            
            <div className="box-success">
              <div className="title">ID SG</div>
              <div className="info">{transfer.idSG}</div>
              <div className="title">ID Orden</div>
              <div className="info">{transfer.idOrden}</div>
            </div>
          </TransferContentStyled>
          <TransferContentStyled>
            <div className="box-buttons">
              <ButtonStyled
                variant="secondary"
                loadingButton={transfer.creating}
                disabled={transfer.creating}
                onClick={handleNewOrder}
              >
                <span>Crear Nueva Orden</span>
              </ButtonStyled>
            </div>
          </TransferContentStyled>
        </>
      )} 
    </>
  )
}

export default TransferScreen