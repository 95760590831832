import UserProfileFormStyled from "components/User/Profile/UserProfileForm.styled"
import Input from "components/Commons/Input"
import AlertStyled from "theme/common/Alert.styled"
import { ButtonStyled } from "theme/common/Button.styled"

const UserProfileForm = () => {
  return (
    <UserProfileFormStyled>
      <Input className="field-input" type="email" name="emailUser" label="Correo electrónico" />
      <AlertStyled variant="info">
        <p>MODIFICAR CONTRASEÑA</p>
      </AlertStyled>
      <Input
        className="field-input"
        type="password"
        name="currentPassword"
        placeHolder="Contraseña actual"
      />
      <div className="field-input new-password-group">
        <Input
          type="password"
          name="newPassword"
          placeHolder="Nueva contraseña"
          autocompleteValue="new-password"
        />
        <Input type="password" name="confirmPassword" placeHolder="Re-ingresar Contraseña" />
        <ButtonStyled type="submit" variant="primary">
          Guardar Modificaciones
        </ButtonStyled>
      </div>
    </UserProfileFormStyled>
  )
}

export default UserProfileForm
