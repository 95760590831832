import DashBordScreen from "pages/HomeScreens/HomeScreen/DashBoardScreen"
import OrderScreen from "pages/OrderScreens/OrderScreen"
import ProfileScreen from "pages/UserScreens/ProfileScreen/ProfileScreen"
import ReportPage from "pages/Report/ReportPage"
import ConfirmOrderScreen from "pages/DetailOrderScreens/Confirm/ConfirmOrderScreen"
import PackageScreen from "pages/DetailOrderScreens/Package/PackageScreen"
import DeliveryScreen from "pages/DetailOrderScreens/Delivery/DeliveryScreen"
import TransitScreen from "pages/DetailOrderScreens/Transit/TransitScreen"
import PickupScreen from "pages/DetailOrderScreens/Pickup/PickupScreen"
import InReturnScreen from "pages/DetailOrderScreens/InReturn/InReturnScreen"
import ReturnedScreen from "pages/DetailOrderScreens/Returned/ReturnedScreen"
import DeliveredScreen from "pages/DetailOrderScreens/Delivered/DeliveredSreen"
import ErrorHandler from "components/Commons/Error/ErrorHandler"
import { IRouteComponent } from "interfaces/IRoute"
import { validateAccessRouteOrder } from "helpers/validationRoutesHelper"
import ClosedScreen from "pages/DetailOrderScreens/Closed/ClosedScreen"
import CanceledScreen from "pages/DetailOrderScreens/Canceled/CanceledScreen"
import SearchResultsScreen from "pages/SearchResults/SearchResultsScreen"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"
import OrderDetailScreen from "overrides/theme/sandbox/base/Pages/DetailOrderScreens/OrderDetailScreen"

const RouteList: IRouteComponent[] = [
  {
    path: "/",
    name: "DashBordScreen",
    component: DashBordScreen,
    exact: true,
  },
  {
    path: "/dashboard",
    name: "DashBordScreen",
    component: DashBordScreen,
    exact: true,
  },
  {
    path: "/profile",
    name: "ProfileScreen",
    component: ProfileScreen,
    exact: true,
  },
  {
    path: "/order/:type",
    name: "OrderPage",
    component: OrderScreen,
    exact: true,
    handleValidateParams: validateAccessRouteOrder,
    redirectPath: "/",
  },
  {
    path: ["/order/confirmation/:shippingGroupId/:id"],
    name: "oderDetailConfrim",
    component: ConfirmOrderScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.confirmation,
  },
  {
    path: ["/order/package/:shippingGroupId/:id"],
    name: "oderDetailPackage",
    component: PackageScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.package,
  },
  {
    path: ["/order/delivery/:shippingGroupId/:id"],
    name: "oderDetailDelivery",
    component: DeliveryScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.delivery,
  },
  {
    path: ["/order/transit/:shippingGroupId/:id"],
    name: "oderDetailTransit",
    component: TransitScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.transit,
  },
  
  {
    path: ["/order/in_source_preparation/:shippingGroupId/:id"],
    name: "orderInsourcePreparation",
    component: OrderDetailScreen,
    exact: true
  },
  {
    path: ["/order/return_in_review/:shippingGroupId/:id"],
    name: "orderReturnInReview",
    component: OrderDetailScreen,
    exact: true
  },

  {
    path: ["/order/pickup/:shippingGroupId/:id"],
    name: "oderDetailPickup",
    component: PickupScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.pickup,
  },
  {
    path: ["/order/delivered/:shippingGroupId/:id"],
    name: "oderDetailDelivered",
    component: DeliveredScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.delivered,
  },
  {
    path: ["/order/in_return/:shippingGroupId/:id"],
    name: "oderDetailInReturn",
    component: InReturnScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.in_return,
  },
  {
    path: ["/order/returned/:shippingGroupId/:id"],
    name: "oderDetailReturned",
    component: ReturnedScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.returned,
  },
  {
    path: ["/order/closed/:shippingGroupId/:id"],
    name: "oderDetailClosed",
    component: ClosedScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.closed,
  },
  {
    path: ["/order/canceled/:shippingGroupId/:id"],
    name: "oderDetailCanceled",
    component: CanceledScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.canceled,
  },
  {
    path: ["/order/rejected/:shippingGroupId/:id"],
    name: "oderDetailRejected",
    component: CanceledScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.rejected,
  },

  {
    path: "/search/:query/:optionFilter",
    name: "SearchResultsPage",
    component: SearchResultsScreen,
    exact: true,
  },

  {
    path: "/report",
    name: "ReportPage",
    component: ReportPage,
    exact: true,
  },

  {
    path: "*",
    name: "notFound",
    component: ErrorHandler,
    exact: false,
  },
]

export default RouteList
