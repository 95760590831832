import { lazy, Suspense, useContext } from "react"
import ProductOrderDetailStyled, {
  ProductInfoDetail,
} from "components/OrderDetail/ProductOrderConfirm/ProductOrderDetail.styled"
import { IconSpinner } from "theme/common/Icon.styled"
import { IProductOrderDetail } from "interfaces/IProductOrderDetail"
import { clients } from "webapp-clients/Clients"
import { AuthContext } from "context/context/AuthContext"

import ProductDetail from "components/Core/ProductBase/ProductDetail"
import { useGetProductInfoByClient } from "hooks/UseGetMultiClient"

const ProductOrderDetail = ({ itemOrder, onClicProcessProduct }: IProductOrderDetail) => {
  const Img = lazy(() => import("components/Commons/Image/Image"))

  const {
    state: { user },
  } = useContext(AuthContext)

  const fieldProduct = useGetProductInfoByClient(itemOrder)

  const ProductTransition = user?.mainGroup?.id && clients["entel"]?.ProductTransition

  return (
    <>
      <ProductOrderDetailStyled>
        <div className="content-info-product">
          <ProductInfoDetail>
            {itemOrder?.custom?.thumbnail && (
              <Suspense fallback={null}>
                <Img alt={itemOrder?.name} url={itemOrder?.custom?.thumbnail} />
              </Suspense>
            )}
            <div className="info">
              <ProductDetail fields={fieldProduct} />
            </div>
          </ProductInfoDetail>

          {ProductTransition && (
            <Suspense fallback={<IconSpinner variant="big" className="content-center" />}>
              <ProductTransition itemOrder={itemOrder} onClicProcessProduct={onClicProcessProduct} />
            </Suspense>
          )}
        </div>
      </ProductOrderDetailStyled>
    </>
  )
}

export default ProductOrderDetail
