import { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import Logger from "classes/Logger"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import { NOTIFY_CANCEL } from "helpers/constHelper"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import { IOrder } from "interfaces/IOrder"
import { TypeVariation6 } from "types/CommonsTypes"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"

const useCancelDecreaseOrder = ({
  order,
  shippingGroupId,
}: { order: IOrder | TypeVariation6, shippingGroupId: string } ) => {
  const { notification, errorHander } = useContext(GlobalContext)

  const [canIuseCancelDecraese, setCanIuseCancelDecraese] = useState(false);

  const [loadingCancelDecrase, setLoadingCancelDecrase] = useState(false)

  const history = useHistory()

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.rejectOrderDecrease] });

  useEffect( () => {
    permission[ResourcePermissionsEnum.rejectOrderDecrease] && setCanIuseCancelDecraese(true) 
  }, [permission])

  const handleClickCancelDecreaseOrder = async (action: EShippinggroupAction) => {
    try {
      setLoadingCancelDecrase(true)
      const {
        data: { code },
      } = await executeShipingGroupAction({
        shippingGroupId,
        action, 
      })

      setLoadingCancelDecrase(false)

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Orden cancelada correctamente",
            title: NOTIFY_CANCEL,
            type: "success",
          },
        })

        setTimeout(() => history.push("/order/canceled"), 2000)
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadingCancelDecrase(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    handleClickCancelDecreaseOrder,
    loadingCancelDecrase,
    canIuseCancelDecraese
  }
}

export default useCancelDecreaseOrder
