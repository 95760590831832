import styled, { css } from "styled-components"
import { respondTo } from "theme/base/Mixins"
import { displays } from "theme/base/Variables"

const TagInfoCustomerStyled = styled.section`
  ${displays.flexWrap};
  margin: 1rem;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.grey20};
  background: ${({ theme }) => theme.palette.white};

  ${respondTo.sm`
    margin: 1rem auto;
    max-width: 930px; 
  `}

  & .info-customer {
    ${displays.flexColumn};
    align-items: flex-start;
    gap: 1rem;
    flex-grow: 1;
  }
  & .secundary-section {
    ${displays.flexColumn};
    flex-grow: 1;
    gap: 1rem;
    & .qr-code {
      align-self: center;
    }

    & .qr-details {
      text-align: center;
      & .title-code {
        font-size: 1.071rem;
        font-weight: bold;
        margin-top: 1rem;
      }
    }

    & > button {
      width: 100%;
    }
  }
`

export default TagInfoCustomerStyled

export const printStyled = css`
  @media all {
    * {
      -webkit-print-color-adjust: exact;
    }

    .secundary-section > button {
      display: none;
    }
  }
`
