export default class Env {
  static _comment = "Information about the environment. @version 1.0.1 @author Naldo Duran<naldorck@gmail.com>"

  static clients = ["localhost", "wom", "privilege", "sandbox", "shiperto", "sodimac", "entel"]

  static children_clients = [
    {
      client: "logytech",
      children: this.clients[4],
    },
  ]

  static env = ["dev", "uat", "prod"]

  static defaults = {
    client: this.clients[6],
    env: this.env[0],
  }

  static sg_shipping_types = ["HD", "SP", "RT", "TR", "HDR"]

  static sg_shipping_types_wom = [this.sg_shipping_types[0], this.sg_shipping_types[1], this.sg_shipping_types[3]]

  static sg_shipping_types_privilege = [this.sg_shipping_types[0], this.sg_shipping_types[1]]

  static sg_shipping_types_shiperto = [
    this.sg_shipping_types[0],
    this.sg_shipping_types[1],
    this.sg_shipping_types[2],
    this.sg_shipping_types[4],
  ]

  static sg_shipping_types_entel = [this.sg_shipping_types[0]]

  static sg_actions = [
    "accept", // 0
    "packed", // 1
    "shipped", // 2
    "delivered", // 3
    "prepared", // 4
    "delivered_reject", // 5
    "picked_up", // 6
    "reject", // 7
    "cancel", // 8
    "cancel_decrease", // 9
    "cancelLogistic ", // 10
    "keep", // 11
    "discard", // 12
    "reschedule", // 13
    "courier_reject", // 14
    "delivered_return", // 15
  ]

  static sg_status = [
    "AWAITING_STORE_CONFIRMATION", // 0
    "PENDING_PACKAGE", // 1
    "AWAITING_DELIVERY", // 2
    "IN_TRANSIT", // 3
    "READY_FOR_PICKUP", // 4 // No aplica privilege
    "IN_SOURCE_PREPARATION", // 5  ? sandbox
    "RETURN_IN_REVIEW", // 6 ? sandbox: target
    "DELIVERED", // 7
    "CANCELED", // 8
    "CANCELED_DECREASE", // 9 ? sandbox
    "CLOSED", // 10
    "IN_TRANSIT_FOR_RECEPTION", // 11  ? wom: target
    "READY_FOR_PICKUP_SOURCE", // 12 privilege: target
    "IN_TRANSIT_SOURCE", // 13 privilege: target
    "IN_TRANSIT_FOR_RECEPTION_OPL", // 14  ? wom: target
    "RESCHEDULED", // 15
    "RETURNED", // 16
    "IN_RETURN_COURIER", // 17
    "IN_RETURN", // 18
  ]

  static order_status_active = [
    "RESERVED",
    "PENDING_PREPARATION",
    "READY_FOR_PICKUP",
    "IN_TRANSIT",
    "INCOMPLETE_SHIPPING",
    "INCOMPLETE_DELIVERY",
  ]

  static order_status_inactive = [
    "CANCELED",
    "CANCELED_DECREASE",
    "CANCELED_LOGISTIC",
    "CLOSED",
    "DELIVERED",
    "RESCHEDULED",
    "DELIVERED_REJECT",
  ]

  static sg_status_privilege = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[13],
    this.sg_status[12],
    this.sg_status[7],
    this.sg_status[10],
  ]

  static sg_status_wom = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[11],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[10],
    this.sg_status[8],
    this.sg_status[14],
  ]

  static sg_status_wom_hd = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[10],
    this.sg_status[8],
  ]

  static sg_status_wom_sp = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[10],
    this.sg_status[8],
  ]

  static sg_status_wom_rt = []

  static sg_status_wom_specials = [this.sg_status[11], this.sg_status[14]]

  static sg_status_shiperto_hd = [
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[7],
    this.sg_status[8],
    this.sg_status[10],
    // this.sg_status[15],
  ]

  static sg_status_shiperto_hdr = [this.sg_status[16], this.sg_status[17], this.sg_status[15], this.sg_status[8]]

  static sg_status_shiperto_sp = [
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[8],
    this.sg_status[10],
  ]

  static sg_status_shiperto_rt = [
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[6],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_sandbox = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[5],
    this.sg_status[6],
    this.sg_status[4],
    this.sg_status[7],
    this.sg_status[16],
    this.sg_status[18],
    this.sg_status[10],
    this.sg_status[8],
  ]

  static sg_status_sandbox_hd = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[18],
    this.sg_status[16],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_sandbox_sp = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[5],
    this.sg_status[4],
    this.sg_status[8],
    this.sg_status[10],
  ]

  static sg_status_sandbox_rt = [
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[6],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_sandbox_tr = [
    this.sg_status[0],
    this.sg_status[1],
    this.sg_status[2],
    this.sg_status[3],
    this.sg_status[7],
    this.sg_status[8],
  ]

  static sg_status_entel = [
    "ASSIGNED", // 0
    "IN_PREPARATION", // 1
    "PICKING_ID_GENERATED", // 2
    "PICKING_IN_PROGRESS", // 3
    "VALIDATE_PICKING", // 4
    "PICKING_CONFIRMED", // 5
    "PICKING_COMPLETED_PENDING_PACKAGE", // 6
    "PACKED_AND_READY_FOR_PICKUP", // 7
    "DELIVER_TO_COURIER", // 8
    "IN_TRANSIT", // 9
    "ORDER_RECEIVED", // 10
    "DELIVERED", // 11
    "DELIVERED_IN_STORE", // 12
    "READY_FOR_PICKUP", // 13
    "PICKUP_AVAILABLE", // 14
    "SUCCESSFUL_PAYMENT", // 15
    "PICKED_UP", // 16
    "CANCELED", // 17
    "DELIVERED_IN_SOURCE", // 18
    "ORDER_RETURNED", // 19
  ]

  static sg_actions_entel = [
    "select", // 0
    "generate_picking_id", // 1
    "pick", // 2
    "validate_picking", // 3
    "picking_confirm", // 4
    "picking_complete", // 5
    "packed", // 6
    "deliver_to_courier", // 7
    "shipped", // 8
    "order_received", // 9
    "delivered", // 10
    "delivered_in_store", // 11
    "ready_for_pickup", // 12
    "pickup_available", // 13
    "successful_payment", // 14
    "picked_up", // 15
    "delivered_in_source", // 16
    "order_returned", // 17
    "cancel", // 18
  ]

  static sg_status_action_entel_hd = {
    [this.sg_status_entel[0]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[0],
        next_page: this.sg_status_entel[1].toLowerCase(),
      },
    ],
    [this.sg_status_entel[1]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[1],
        next_page: this.sg_status_entel[2].toLowerCase(),
      },
    ],
    [this.sg_status_entel[2]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[2],
        next_page: this.sg_status_entel[3].toLowerCase(),
      },
    ],
    [this.sg_status_entel[3]]: [
      {
        action: this.sg_actions_entel[2],
        next_page: this.sg_status_entel[3].toLowerCase(),
      },
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      // {
      //   action: this.sg_actions_entel[3],
      //   next_page: this.sg_status_entel[4].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[4]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      // {
      //   action: this.sg_actions_entel[4],
      //   next_page: this.sg_status_entel[5].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[5]]: [
      // {
      //   action: this.sg_actions_entel[18],
      //   next_page: this.sg_status_entel[17].toLowerCase(),
      // },
      // {
      //   action: this.sg_actions_entel[5],
      //   next_page: this.sg_status_entel[6].toLowerCase(),
      // },
    ],
    [this.sg_status_entel[6]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[6],
        next_page: this.sg_status_entel[7].toLowerCase(),
      },
    ],
    [this.sg_status_entel[7]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[7],
        next_page: this.sg_status_entel[8].toLowerCase(),
      },
    ],
    [this.sg_status_entel[8]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[8],
        next_page: this.sg_status_entel[9].toLowerCase(),
      },
    ],
    [this.sg_status_entel[9]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      // {
      //   action: this.sg_actions_entel[9],
      //   next_page: this.sg_status_entel[10].toLowerCase(),
      // },
      {
        action: this.sg_actions_entel[10],
        next_page: this.sg_status_entel[11].toLowerCase(),
      },
    ],
    [this.sg_status_entel[10]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[11],
        next_page: this.sg_status_entel[12].toLowerCase(),
      },
    ],
    [this.sg_status_entel[11]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
    ],
    [this.sg_status_entel[12]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[12],
        next_page: this.sg_status_entel[13].toLowerCase(),
      },
    ],
    [this.sg_status_entel[13]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[13],
        next_page: this.sg_status_entel[14].toLowerCase(),
      },
    ],
    [this.sg_status_entel[14]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[14],
        next_page: this.sg_status_entel[15].toLowerCase(),
      },
    ],
    [this.sg_status_entel[15]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[15],
        next_page: this.sg_status_entel[16].toLowerCase(),
      },
    ],
    [this.sg_status_entel[16]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
    ],
    [this.sg_status_entel[17]]: [
      {
        action: this.sg_actions_entel[16],
        next_page: this.sg_status_entel[18].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[17],
        next_page: this.sg_status_entel[19].toLowerCase(),
      },
    ],
    [this.sg_status_entel[18]]: [
      {
        action: this.sg_actions_entel[18],
        next_page: this.sg_status_entel[17].toLowerCase(),
      },
      {
        action: this.sg_actions_entel[17],
        next_page: this.sg_status_entel[19].toLowerCase(),
      },
    ],
    [this.sg_status_entel[19]]: [],
  }

  // Temporal: requirement should be improved, this should be controlled by shippingtypes
  static sg_shippingtype_rt_status = [this.sg_status[6], this.sg_status[16], this.sg_status[18]]

  static sg_status_action: any = {
    [this.sg_status[0]]: [
      {
        action: this.sg_actions[0],
        next_page: this.sg_status[1].toLowerCase(),
        label: "Confirmar",
      },
    ],
    [this.sg_status[5]]: [
      {
        action: this.sg_actions[4],
        next_page: "pickup", // fix this route
        label: "Confirmar",
      },
    ],
    [this.sg_status[6]]: [
      {
        action: this.sg_actions[11],
        next_page: this.sg_status[7].toLowerCase(),
        label: "Conservar",
      },
      {
        action: this.sg_actions[12],
        next_page: this.sg_status[7].toLowerCase(),
        label: "Despachar",
      },
    ],
  }

  static sg_status_action_shiperto_hd = {
    [this.sg_status[1]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[1],
        next_page: this.sg_status[2].toLowerCase(),
      },
    ],
    [this.sg_status[2]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[2],
        next_page: this.sg_status[3].toLowerCase(),
      },
    ],
    [this.sg_status[3]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[3],
        next_page: this.sg_status[7].toLowerCase(),
      },
      {
        action: this.sg_actions[14],
        next_page: this.sg_status[17].toLowerCase(),
      },
    ],
    [this.sg_status[7]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
    ],
    [this.sg_status[8]]: [],
    [this.sg_status[10]]: [],
    // [this.sg_status[15]]: [
    //   {
    //     action: this.sg_actions[8],
    //     next_page: this.sg_status[8].toLowerCase(),
    //   },
    // ],
  }

  static sg_status_action_shiperto_hdr = {
    [this.sg_status[15]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
    ],
    [this.sg_status[16]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[13],
        next_page: this.sg_status[15].toLowerCase(),
      },
    ],
    [this.sg_status[17]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[15],
        next_page: this.sg_status[16].toLowerCase(),
      },
    ],
  }

  static sg_status_action_shiperto_sp = {
    [this.sg_status[1]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[1],
        next_page: this.sg_status[4].toLowerCase(),
        alt_next_page: this.sg_status[2].toLowerCase(),
      },
    ],
    [this.sg_status[2]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[2],
        next_page: this.sg_status[3].toLowerCase(),
      },
    ],
    [this.sg_status[3]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[3],
        next_page: this.sg_status[4].toLowerCase(),
      },
    ],
    [this.sg_status[4]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[6],
        next_page: this.sg_status[10].toLowerCase(),
      },
    ],
    [this.sg_status[7]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
    ],
    [this.sg_status[8]]: [],
    [this.sg_status[10]]: [],
  }

  static sg_status_action_shiperto_rt = {
    [this.sg_status[2]]: [
      {
        action: this.sg_actions[8],
        next_page: this.sg_status[8].toLowerCase(),
      },
      {
        action: this.sg_actions[2],
        next_page: this.sg_status[3].toLowerCase(),
      },
    ],
    [this.sg_status[3]]: [
      // {
      //   action: this.sg_actions[8],
      //   next_page: this.sg_status[8].toLowerCase()
      // },
      {
        action: this.sg_actions[3],
        next_page: this.sg_status[6].toLowerCase(),
      },
    ],
    [this.sg_status[6]]: [
      // {
      //   action: this.sg_actions[8],
      //   next_page: this.sg_status[8].toLowerCase()
      // },
      {
        action: this.sg_actions[12],
        next_page: this.sg_status[7].toLowerCase(),
      },
      {
        action: this.sg_actions[11],
        next_page: this.sg_status[7].toLowerCase(),
      },
    ],
    [this.sg_status[7]]: [
      // {
      //   action: this.sg_actions[8],
      //   next_page: this.sg_status[8].toLowerCase()
      // }
    ],
    [this.sg_status[8]]: [],
  }

  static theme: any = {
    core: {
      id: "core",
      sg: {
        shipping_types: this.sg_shipping_types,
        default: {
          status: this.sg_status,
          actions: this.sg_actions,
        },
      },
    },
    wom: {
      id: this.clients[1],
      sg: {
        shipping_types: this.sg_shipping_types_wom,
        default: {
          status: this.sg_status_wom,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_wom_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_wom_sp,
        },
        [this.sg_shipping_types[2]]: {
          status: this.sg_status_wom_rt,
        },
      },
    },
    privilege: {
      id: this.clients[2],
      sg: {
        shipping_types: this.sg_shipping_types_privilege,
        default: {
          status: this.sg_status_privilege,
        },
      },
    },
    sandbox: {
      id: this.clients[3],
      sg: {
        default: {
          status: this.sg_status_sandbox,
          status_actions: this.sg_status_action,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_sandbox_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_sandbox_sp,
        },
        [this.sg_shipping_types[2]]: {
          status: this.sg_status_sandbox_rt,
        },
        [this.sg_shipping_types[3]]: {
          status: this.sg_status_sandbox_tr,
        },
      },
    },
    shiperto: {
      id: this.clients[4],
      sg: {
        shipping_types: this.sg_shipping_types_shiperto,
        default: {
          status_actions: this.sg_status_action_shiperto_hd,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_shiperto_hd,
          status_actions: this.sg_status_action_shiperto_hd,
        },
        [this.sg_shipping_types[1]]: {
          status: this.sg_status_shiperto_sp,
          status_actions: this.sg_status_action_shiperto_sp,
        },
        [this.sg_shipping_types[2]]: {
          status: this.sg_status_shiperto_rt,
          status_actions: this.sg_status_action_shiperto_rt,
        },
        [this.sg_shipping_types[4]]: {
          status: this.sg_status_shiperto_hdr,
          status_actions: this.sg_status_action_shiperto_hdr,
        },
      },
    },
    sodimac: {
      id: this.clients[5],
    },
    entel: {
      id: this.clients[6],
      sg: {
        shipping_types: this.sg_shipping_types_entel,
        default: {
          status: this.sg_status_entel,
          status_actions: this.sg_status_action_entel_hd,
        },
        [this.sg_shipping_types[0]]: {
          status: this.sg_status_entel,
          status_actions: this.sg_status_action_entel_hd,
        },
      },
    },
  }
}
