import HeaderDetailStyled from "components/OrderDetail/HeaderDetail/HeaderDetail.styled"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import { OderType, OrderShippingType } from "types/OrderType"
import { formatAdvanceDate } from "helpers/FormatDateHelper"
import { WebAppClientsEnum } from "enums/CommonEnum"
import { ShippingTypeEnum } from "enums/OrderEnum"
import { useEnv } from "hooks"

const HeaderDetail = ({ order, sg }: { order: OderType; sg: OrderShippingType }) => {
  const { t } = useTranslation()
  const formatString = "dd-MM-yyyy HH:mm"
  const [_client] = useEnv().multiclient.get_user_env()

  return (
    <HeaderDetailStyled>
      <div className="header-oder-info">
        {order?.customer?.name && <p className="relevant-title">{order?.customer?.name || <Skeleton width={200} />}</p>}
        {order?.customer?.id && <p>{order?.customer?.id || <Skeleton width={200} />} </p>}
        {order?.customer?.email && <p>{order?.customer?.email || <Skeleton width={200} />}</p>}
        {sg?.currentStatus?.date && (
          <p>
            Fecha de estado actual:{" "}
            {formatAdvanceDate(sg?.currentStatus?.date, formatString) || <Skeleton width={200} />}
          </p>
        )}
      </div>
      <div className="header-oder-info">
        {order?.shippingType ? (
          <p className="field">
            <span>Tipo de despacho:</span>
            <span className="relevant-title">{t(order.shippingType)} </span>
          </p>
        ) : (
          <p>
            <Skeleton width={200} />
          </p>
        )}

        {order?.deliveryType ? (
          <p className="field">
            <span>Tipo de entrega:</span>
            <span className="relevant-title">{t(order.deliveryType)} </span>
          </p>
        ) : (
          <p>
            <Skeleton width={200} />
          </p>
        )}
        {order?.salesChannelId ? (
          <p className="field">
            <span> ID canal ventas:</span>
            <span className="relevant-title">{order.salesChannelId} </span>
          </p>
        ) : (
          <p>
            <Skeleton width={200} />
          </p>
        )}

        {_client === WebAppClientsEnum.wom && order?.shippingType === ShippingTypeEnum.HD && (
          <p className="field">
            <span> Comuna:</span>
            <span className="relevant-title">{order?.shippingAddress?.location?.commune} </span>
          </p>
        )}
      </div>
    </HeaderDetailStyled>
  )
}

export default HeaderDetail
