import React, { Suspense, useContext, useEffect, useRef, useState } from "react"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import usePickupOrder from "pages/DetailOrderScreens/Pickup/UsePickupOrder"
import PickupAction from "components/OrderDetail/PickupProductOrder/PickupAction"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import { IconSpinner } from "theme/common/Icon.styled"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { clients } from "webapp-clients/Clients"
import { AuthContext } from "context/context/AuthContext"
import useHideActionButtons from "hooks/UseHideActionButtons"
import { useForm } from "react-hook-form"
import { FilesFormType } from "types/FilesFormType"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaFileUpladerFormFull } from "config/ShemaForms"
import AlertStyled from "theme/common/Alert.styled"
import { IconAttachment } from "theme/common/Icon.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "theme/common/Icon.styled"
import Input from "components/Commons/Input"
import Modal from "components/Commons/Modal/Modal"
import WindowModal from "components/Commons/WindowModal/WindowModal"
import SendCode from "components/OrderDetail/SendCode/SendCode"
import UseViewUploadFiles from "hooks/UseViewUploadFiles"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"

const InfoElocker = React.lazy(() => import("components/OrderDetail/ElockerQR/InfoElocker"))

const PickupScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { hidePickupActions, updateVisibilityButtonActions } = useHideActionButtons()
  const [showWithdrawalCode, setShowWithdrawalCode] = useState(false)
  const [rolSellerAndSupCourier, setRolSellerAndSupCourier] = useState(false)

  useEffect(() => {
    updateVisibilityButtonActions(shippinGroupDetail?.source?.id, shippinGroupDetail?.target?.source?.id)
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const FileUpload = client["FileUpload" as keyof typeof client]
  const ContentFileMedia = client["ContentFileMedia" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const documents = shippinGroupDetail?.custom?.documents

  const files = shippinGroupDetail?.status
    .filter(({ media }) => media)
    .flatMap(({ media }) => media?.ref.map(({ id }) => id))

  const filesByStatus = shippinGroupDetail?.status
    .filter(status => status.tag === "IN_TRANSIT" && status.media?.ref?.length)
    .flatMap(({ media }) => media?.ref.map(({ id }) => id))
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(shemaFileUpladerFormFull([])) : undefined,
  })

  const filesShippinGroup = watch("filesShippinGroup")
  const [disabledButton, setDisabledButton] = useState(true)
  const [disabledButtonFiles, setDisabledButtonFiles] = useState(false)

  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const {
    loadingCancel,
    loadingPickup,
    handleClickCancelAction,
    handleClickPickupAction,
    hasPermissionQREntry,
    hasPermissionQRExitCancel,
    canCancelOrder,
    denyPickupAction,
    shouldBeFilePresentOnSubmit,
    handleClickPickupActionWithFiles,
    checkValidateCode,
    checkVerificationCode,
    canIuseValidateCode,
    canIuseSendCode,
    callbackProcessCode,
    generatedCode,
    hideOnlyCourier,
    loadingFiles,
    handleClickActionWithFiles,
    successUploadFiles,
  } = usePickupOrder({
    shippingGroupId,
    order,
    setFinishedShippingGroupAction,
    isElocker: elockerInfo?.isElocker,
    shippinGroupDetail,
    refModal,
  })

  useEffect(() => {
    if (canIuseValidateCode) {
      filesShippinGroup?.length && canIuseValidateCode && checkValidateCode
        ? setDisabledButton(false)
        : setDisabledButton(true)
      if(order?.custom?.portabilidad === "SI" && shippinGroupDetail?.custom?.infoElocker?.isElocker) {
        if(files && files?.length < 1)
          setDisabledButtonFiles(true)

        if(successUploadFiles && checkValidateCode)
          setDisabledButtonFiles(false)
      }
    } else {
      filesShippinGroup?.length ? setDisabledButton(false) : setDisabledButton(true)
      if(order?.custom?.portabilidad === "SI" && shippinGroupDetail?.custom?.infoElocker?.isElocker) {
        if(files && files?.length < 1) {
          setDisabledButtonFiles(true)
        }
      }
      
      if(successUploadFiles)
        setDisabledButtonFiles(false)
    }
  },
  //eslint-disable-next-line
  [filesShippinGroup, canIuseValidateCode, checkValidateCode, order, successUploadFiles])

  const refFormFiles = useRef<any>()

  const { canIuseViewUploadFiles, canIuseViewUploadFilesCourier } = UseViewUploadFiles()

  useEffect(() => {
    if ((files && files?.length > 0) || (successUploadFiles))
      setShowWithdrawalCode(true)
  },
  //eslint-disable-next-line
  [files, successUploadFiles])

  useEffect(() => {
    setRolSellerAndSupCourier(user?.role === ResourceRoleEnum["webapp-seller-courier"] || user?.role === ResourceRoleEnum["webapp-sup-courier"])
    // eslint-disable-next-line
  }, [user, order])

  console.log(shippinGroupDetail)

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos por recoger" : undefined}
          />
          {elockerInfo && elockerInfo.isElocker && (
            <Suspense
              fallback={
                <>
                  <IconSpinner variant="big" className="content-center" />
                  <p className="padding-content">Cargando información Elocker</p>
                </>
              }
            >
              {hasPermissionQREntry && order?.custom?.portabilidad === "SI" && shippinGroupDetail?.custom?.infoElocker?.isElocker && (
                <InfoElocker
                  codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo.codigoCancelacion : null}
                  codigoEntrega={null}
                  codigoRetiro={showWithdrawalCode ? elockerInfo.codigoRetiro : null}
                  puerta={elockerInfo.puerta}
                />
              )}
              
              {hasPermissionQREntry && order?.custom?.portabilidad !== "SI" && (
                <InfoElocker
                  codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo.codigoCancelacion : null}
                  codigoEntrega={null}
                  codigoRetiro={elockerInfo.codigoRetiro}
                  puerta={elockerInfo.puerta}
                />
              )}
              
            </Suspense>
          )}

          {canIuseValidateCode && !checkValidateCode && !hideOnlyCourier && (
            <>
              <AlertStyled variant="info">
                <p>Código de verificación</p>
              </AlertStyled>
              <div style={{ padding: 15 }}>
                <Input
                  type="text"
                  name="verificationCode"
                  placeHolder="Código de verificación"
                  onChange={(e) => checkVerificationCode(e.target.value)}
                  disabled={Boolean(!generatedCode?.code)}
                />
              </div>
              <Modal ref={refModal}>
                <WindowModal
                  title="Código de verificación"
                  description="El código de retiro no coincide con el enviado al cliente."
                  handleConfirm={() => {
                    refModal.current?.close()
                  }}
                  showCancel={false}
                />
              </Modal>
            </>
          )}

          {canIuseSendCode && !hideOnlyCourier && (
            <SendCode
              orderId={order?.id}
              customer={order?.customer}
              shippingGroupId={shippingGroupId}
              onProcessCode={callbackProcessCode}
              shippingType={order?.shippingType}
              portabilidad={order?.custom?.portabilidad}
            />
          )}

          {shouldBeFilePresentOnSubmit() && !hideOnlyCourier && !finishedShippingGroupAction && (
            <>
              <form ref={refFormFiles} onSubmit={handleSubmit(handleClickPickupActionWithFiles)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section>
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} label="Archivos adjuntos" />
                    </section>
                  </Suspense>
                )}

                {disabledButton && (
                  <AlertStyled variant="danger" className="transparent">
                    <p>Para continuar, necesita adjuntar al menos un archivo</p>
                  </AlertStyled>
                )}

                {errors.filesShippinGroup && <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>}

                <ContentDetailOrderActionsStyled>
                  <ButtonStyled
                    variant="primary"
                    loadingButton={loadingPickup}
                    disabled={loadingPickup}
                    disabledButton={disabledButton}
                    type="submit"
                  >
                    <IconCheck />
                    <span>Confirmar entrega</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              </form>
            </>
          )}
          
          {hasPermissionQREntry && order?.custom?.portabilidad === "SI" && shippinGroupDetail?.custom?.infoElocker?.isElocker && !successUploadFiles && (
            <>
              <form ref={refFormFiles} onSubmit={handleSubmit(handleClickActionWithFiles)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section>
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} label="Archivos adjuntos" />
                    </section>
                  </Suspense>
                )}

                {disabledButton && (
                  <AlertStyled variant="danger" className="transparent">
                    <p>Para continuar, necesita adjuntar al menos un archivo</p>
                  </AlertStyled>
                )}

                {errors.filesShippinGroup && <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>}

                <ContentDetailOrderActionsStyled>
                  <ButtonStyled
                    variant="primary"
                    loadingButton={loadingFiles}
                    disabled={loadingFiles}
                    disabledButton={disabledButton}
                    type="submit"
                  >
                    <IconCheck />
                    <span>Subir Archivo</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              </form>
            </>
          )}

          {!finishedShippingGroupAction && !hidePickupActions && (
            <PickupAction
              //disabledPickup={checkValidateCode}
              onClickPickup={!denyPickupAction ? handleClickPickupAction : undefined}
              onClickCancel={canCancelOrder ? handleClickCancelAction : undefined}
              loadingCancel={loadingCancel}
              loadingPickup={loadingPickup}
              shouldBeFilePresentOnSubmit={shouldBeFilePresentOnSubmit}
              disabledButton={disabledButtonFiles}
            />
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
              shippinGroupDetail={shippinGroupDetail}
            />
          )}
          
          {canIuseViewUploadFiles  && !canIuseViewUploadFilesCourier && ContentFileMedia && files && files.length > 0 && (
            <Suspense fallback={null}>
              <ContentFileMedia listIdFiles={files} shippingGroupId={shippingGroupId} documents={documents} />
            </Suspense>
          )}
          
          {canIuseViewUploadFilesCourier && ContentFileMedia && filesByStatus !== undefined && filesByStatus.length > 0 && (
            <Suspense fallback={null}>
              <ContentFileMedia listIdFiles={filesByStatus} shippingGroupId={shippingGroupId} documents={documents} />
            </Suspense>
          )}

          {!rolSellerAndSupCourier && FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(PickupScreen)
