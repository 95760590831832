// TODO: Use global variable to get current client/website
import Env from "classes/Env"

const dataFields: any = {
  [Env.theme.core.id]: {
    name: {
      label: "Nombre",
      input: "customerName",
      type: "text",
      order: 1,
    },
    direction: {
      label: "Dirección",
      input: "address",
      type: "text",
      order: 2,
    },
    commune: {
      label: "Comuna",
      input: "commune",
      type: "text",
      order: 3,
    },
    contactName: {
      label: "Recibe",
      input: "contactName",
      type: "text",
      order: 4,
    },
    reference: {
      label: "Observación",
      input: "reference",
      type: "text",
      order: 5,
    },
    contactPhone: {
      label: "Contacto",
      input: "contactPhone",
      type: "text",
      order: 6,
    },
  },
  [Env.theme.privilege.id]: {
    storePickupId: {
      label: "Tienda que entrega",
      input: "storePickupId",
      type: "text",
      order: 1,
    },
    commune: {
      label: "Código de la Comuna",
      input: "commune",
      type: "text",
      order: 3,
    },
    communeName: {
      label: "Nombre de la comuna",
      input: "communeName",
      type: "text",
      order: 3,
    },
  },
  [Env.theme.wom.id]: {
    rut: {
      label: "Rut",
      input: "rut",
      type: "text",
      order: 2,
    },
    direction: {
      label: "Dirección",
      input: "address",
      type: "text",
      order: 3,
    },
    contactPhone: {
      label: "Teléfono",
      input: "contactPhone",
      type: "text",
      order: 6,
    },
    portability: {
      label: "Portabilidad",
      input: "portabilidad",
      type: "check",
      order: 7,
      forceShow: true,
      attrs: [
        {
          canEdit: false,
          id: "yes",
          label: "SI",
          name: "financed",
        },
        {
          canEdit: false,
          id: "No",
          label: "NO",
          name: "financed",
        },
      ],
      attrDefault: {
        name: "financed",
        default: "NO",
      },
    },
    recibeTercero: {
      label: "Recibe tercero",
      input: "recibeTercero",
      type: "text",
      order: 8,
      children: {
        contactCustomName: {
          label: "Nombre",
          input: "contactCustomName",
          type: "text",
          order: 8,
        },
        contactRut: {
          label: "RUT",
          input: "contactRut",
          type: "text",
          order: 9,
        },
      },
    },
    courier: {
      label: "Courier",
      input: "courier",
      type: "text",
      order: 10,
    },
    channel: {
      label: "Canal",
      input: "channel",
      type: "text",
      order: 11,
    },
    tipoRetiro: {
      label: "Tipo de Retiro",
      input: "tipoRetiro",
      type: "text",
      order: 12,
    },
  },
}

export default dataFields
