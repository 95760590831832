import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "theme/common/Icon.styled"
import { ITransitAction } from "interfaces/IShippingGroupAction"
import { useLocation } from "react-router-dom"
import { useEffect, useState, useContext } from "react"
import { AuthContext } from "context/context/AuthContext"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import useUserPermissions from "hooks/UseUserPermissions"

const TransitAction = ({ loadingTransit, disabledButton }: ITransitAction) => {
  // TODO: This could be refactorized
  const { pathname } = useLocation<{ type: string }>()
  const authState = useContext(AuthContext)
  const website: any = authState?.state.user?.mainGroup.id
  let transit_to_source = pathname.includes("transit_to_source")
  let text_in_transit_for_reception = pathname.includes("in_transit_for_reception")
  let in_transit_for_reception_opl = pathname.includes("in_transit_for_reception_opl")
  const [canIuseUpdateCourierDelivery, setCanIuseUpdateCourierDelivery] = useState(false)
  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.confirmReceptionOpl] });
  useEffect( () => {
    permission[ResourcePermissionsEnum.confirmReceptionOpl] && setCanIuseUpdateCourierDelivery(true) 
  }, [permission])

  if (website === "privilege" && !transit_to_source) return null
  if (in_transit_for_reception_opl && canIuseUpdateCourierDelivery) return null

  const renderBtnTitle = (): string => {
    if(transit_to_source)  return "recibido"
    if(text_in_transit_for_reception) return "recepción"

    return "entrega"
  }

  return (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled variant="primary" loadingButton={loadingTransit} disabled={loadingTransit} disabledButton={disabledButton}>
        <IconCheck />
        {/* // TODO: This could be refactorized */}
        <span>Confirmar {renderBtnTitle()}</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )
}

export default TransitAction
