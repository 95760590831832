import styled from "styled-components"
import { displays } from "theme/base/Variables"

const HeaderDetailStyled = styled.section`
  ${displays.flexWrap};
  justify-content: space-between;
  padding: 1.5rem 1rem;
  color: ${({ theme }) => theme.palette.grey90};
  background: ${({ theme }) => theme.palette.grey5};
  & .header-oder-info p {
    margin: 0;
  }
`
export default HeaderDetailStyled
