import { useContext } from "react"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import useOrderAction from "overrides/theme/sandbox/base/Hooks/Order/useOrderAction"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { OrderContext } from "context/context"
import { OrderActionsType } from "context/actionsTypes"

// Cloned from TransitScreen
import useFormConfig from "overrides/theme/sandbox/base/Hooks/Media/useFormConfig"

import { IShippinGroupDetail } from "interfaces/IOrder"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "theme/common/Icon.styled"
import { useEnv } from "hooks"

const ButtonActionTemplate = (props: any) => {
  const { loading, label, action, next_page } = props
  const { dispatch } = useContext(OrderContext)

  const template = (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled
        variant="primary"
        loadingButton={loading}
        disabled={loading}
        onClick={() => {
          dispatch({
            type: OrderActionsType.set_action,
            payload: action,
          })
          dispatch({
            type: OrderActionsType.set_next_page,
            payload: next_page,
          })
        }}
      >
        <IconCheck />
        <span>{label}</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )

  return template
}

const ButtonsActions = (props: any) => {
  const { shippinGroupDetail }: { shippinGroupDetail: IShippinGroupDetail } = props
  const { sg } = useEnv()
  const status: any = shippinGroupDetail.currentStatus?.tag
  const envStatus: Array<{ action: string; label: string }> = sg.get_sg_status_actions_list()[status]

  let template: any = []

  console.log(envStatus)
  envStatus.forEach((_status) => {
    template.push(<ButtonActionTemplate {...props} key={_status.action} {..._status} />)
  })

  return template
}

const OrderDetailScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const { handleSubmit } = useFormConfig()

  const { submitActionOrder, loadingTransit } = useOrderAction({
    shippingGroupId,
    setFinishedShippingGroupAction,
    order,
    shippinGroupDetail,
  })

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList itemsOrder={itemsOrder} groupPicking={false} />

          <>
            <form onSubmit={handleSubmit(submitActionOrder)}>
              <ButtonsActions shippinGroupDetail={shippinGroupDetail} loading={loadingTransit} />
            </form>
          </>

          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(OrderDetailScreen)
