import { useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { useLocation } from "react-router-dom"
import { ROUTE_ORDER } from "helpers/constHelper"
import { AuthContext } from "context/context/AuthContext"
import HeaderStyle from "components/Theme/Header/Header.styled"
import UserInfoProfile from "components/User/Profile/UserInfo/UserInfoProfile"
import { LinkIconStyled } from "theme/common/Link.styled"
import { IconBarsMenu, IconUser } from "theme/common/Icon.styled"
import logo from "theme/assets/img/ico_header_logo.svg"
import { summaryOptions } from "theme/assets/Data/SummaryOptionsData"
import { useMediaPredicate } from "react-media-hook"
import { breakpointsMedia } from "theme/base/Variables"
import LogoClient from "components/Commons/BrandClient/LogoClient"
import { useTranslation } from "react-i18next"
import { ILocationState } from "interfaces/IMenu"
import { pageOptions } from "theme/assets/Data/pageData"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"

const Header = ({ onShowSidebar }: { onShowSidebar: () => void }) => {
  const { t } = useTranslation()

  const location = useLocation<ILocationState>()
  const { userProfileHeader } = useContext(GlobalContext)

  const biggerThanMobile = useMediaPredicate(`(min-width: ${breakpointsMedia.sm})`)

  const authState = useContext(AuthContext)
  const user = authState?.state.user

  const womRoleCourier = () => {
    return user?.role === ResourceRoleEnum["webapp-seller-courier"] || user?.role === ResourceRoleEnum["webapp-sup-courier"]
  }

  const showTitleCourier: boolean = womRoleCourier()
  
  const renderCurrentPath = () => {
    const pathLocation = location.pathname
    let fromTransitToSource = location.state?.fromTransitToSource

    if (pathLocation.includes(ROUTE_ORDER)) {

      const menuSubItemOrder = summaryOptions.find(({ path, stateLocation }) => {
        // TODO: This should be refactorized asap
        let customRoute = ["pickup_in_my_source", "transit_to_source"].some(option => {
          return pathLocation.includes(option)
        })
        if (customRoute) {
          fromTransitToSource = true
        }
        // ----
        return pathLocation.includes(path) && (!fromTransitToSource || stateLocation)
      })

      return (
        menuSubItemOrder && (
          <div className="path-current">
            {menuSubItemOrder.icon} <span>{showTitleCourier ? `${t(`${menuSubItemOrder.status}.courierSubTitle`)}` : `${t(`${menuSubItemOrder.status}.subTitle`)}`}</span>
          </div>
        )
      )
    }else{
      const pathLocation = location.pathname
      const page:any = pageOptions.find(page => page.path === pathLocation)

      if(!page) return null

      return (
        <div className="path-current">
          {page.icon} <span>{page.title}</span>
        </div>
      )
    }
  }

  return (
    <HeaderStyle showProfileUser={userProfileHeader?.state || false}>
      <div className="options-header">
        <div className="section-icon-menu">
          <LinkIconStyled to="#">
            <IconBarsMenu onClick={onShowSidebar} />
          </LinkIconStyled>

          <LogoClient
            render={(logo: JSX.Element) => <LinkIconStyled to="/dashboard">{logo}</LinkIconStyled>}
          />
        </div>
        {renderCurrentPath()}
        <div className="icon-user">
          {biggerThanMobile && user?.name && <p>{user.name}</p>}
          <LinkIconStyled to="/dashboard">
            <img src={logo} alt="logo omnix" />
          </LinkIconStyled>
          <LinkIconStyled to="/profile">
            <IconUser />
          </LinkIconStyled>
        </div>
      </div>

      {userProfileHeader?.state && user && (
        <UserInfoProfile name={user.name} rol={user.role} sources={user.currentSources} />
      )}
    </HeaderStyle>
  )
}

export default Header
