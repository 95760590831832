import axiosClient from "config/axiosClient"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"
import { IResponseService, IResponseServiceCollection, IResponseServiceArray } from "interfaces/IResponseService"
import { IOrderShippinggroup, IShippinGroupDetail, IGroupedOrder, IRequestGroupedOrder } from "interfaces/IOrder"
import configEnv from "config/configEnv"
import { AxiosResponse, CancelToken } from "axios"
import { IRequestService } from "interfaces/IRequestService"
import { IRequestShippingGroup } from "interfaces/IShippingGroup"
import { IFileMedia, IRequedMedia, IResponseAttachFile } from "interfaces/IResponseAttachFile"

const clientAxios = axiosClient("OOM")
const clientAxiosOSRM = axiosClient("OSRM")

export const getOrderByStatus = async (
  status: EShippinggroupStatus,
  requestBody: IRequestService,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseServiceCollection<IOrderShippinggroup>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/all/shippinggroup/all/status/${status}`

  const response = await client.post<IResponseServiceCollection<IOrderShippinggroup>>(url, requestBody, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
    cancelToken,
  })

  return response
}

export const getShippinGroupDetail = async (
  orderId: string,
  shippinGroupId: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<IShippinGroupDetail>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/${orderId}/shippinggroup/${shippinGroupId}`

  const response = await client.get<IResponseService<IShippinGroupDetail>>(url, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const get_osrm_order_group = async (request: any, cancelToken: any) => {
  const client: any = clientAxiosOSRM.getClient()
  const headers = { headers: { "Content-Type": "application/json" } }
  const url = "search/shipping_groups/group"
  const response = await client.post(url, request, headers)

  return response
}

export const getGroupedOrder = async (
  request: IRequestGroupedOrder,
): Promise<AxiosResponse<IResponseServiceArray<IGroupedOrder>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/all/shippinggroup/all/status/all/grouped`

  const response = await client.post<IResponseServiceArray<IGroupedOrder>>(url, request, {
    headers: { "Content-Type": "application/json" },
  })
  return response
}

export const executeShipingGroupAction = async ({ action, requestBody, shippingGroupId }: any) => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/all/shippinggroup/${shippingGroupId}/${action}`

  const response = await client.post<IResponseService<string>>(url, requestBody, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
  })

  return response
}

export const retryGetShippinGroupElocker = async ({
  shippingGroupId,
  orderId,
  cancelToken,
  retryCount = Number(configEnv.retriesReques),
  backoff = 1000,
}: IRequestShippingGroup): Promise<boolean | undefined> => {
  return new Promise(async (resolve) => {
    const interval = setInterval(async () => {
      const {
        data: {
          message: { custom },
        },
      } = await getShippinGroupDetail(orderId, shippingGroupId, cancelToken)

      if (custom?.infoElocker) {
        resolve(true)
        clearInterval(interval)
      } else if (retryCount <= 1) {
        resolve(false)
        clearInterval(interval)
      }

      --retryCount
    }, backoff)
  })
}
export const attachFileShippingGroup = async (
  shippingGroupId: string,
  formData: FormData,
): Promise<AxiosResponse<IResponseService<IResponseAttachFile>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/all/shippinggroup/${shippingGroupId}/attachments`

  const response = await client.post<IResponseService<IResponseAttachFile>>(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    data: {},
  })

  return response
}

export const getFilesMedia = async (
  request: IRequedMedia,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseServiceArray<IFileMedia>>> => {
  const client = clientAxios.getClient()
  const url = `${configEnv.urlOder}/media`

  const response = await client.post<IResponseServiceArray<IFileMedia>>(url, request, {
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: {},
    cancelToken,
  })

  return response
}

export const updateShippingGroup = async (
  { shippingGroupId, custom }: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = clientAxios.getClient()
  const url = `${configEnv.urlOder}/all/shippinggroup/${shippingGroupId}`

  const body = { custom }

  const response = await client.post<IResponseService<string>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const updateShippingGroupTrace = async (
  { shippingGroupId, custom }: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = clientAxios.getClient()
  const url = `${configEnv.urlOder}/all/shippinggroup/${shippingGroupId}/trace`

  const body = [ custom ]

  const response = await client.post<IResponseService<string>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}

export const detachFileShippingGroup = async (
  shippingGroupId: string,
  data: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  const client = clientAxios.getClient()
  const url = `${configEnv.urlOder}/all/shippinggroup/${shippingGroupId}/attachments`

  const body = data

  const response = await client.delete<IResponseService<string>>(url, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
    data: body,
  })

  return response
}
