import SupportWraper from "components/Theme/Sidebar/Support/SupportSection.styled"

const SupportSection = () => (
  <SupportWraper>
    <span className="support-title">Support</span>
    <p> Chile +56226660690</p>
    <p> Colombia +5717396096</p>
    <p> Perú +5117060970 </p>
    <p>soporte@omnixsystem.com</p>
  </SupportWraper>
)

export default SupportSection
