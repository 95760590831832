import { memo } from "react"
import SummaryContainerStyled from "components/Summary/Summary.styled"
import { IMenu } from "interfaces/IMenu"
import CardOption from "components/Summary/SummaryOption/CardOption"
import { GridContainer } from "theme/common/Grid.styled"

const SummaryOptions = memo(({ summaryOptions }: { summaryOptions: IMenu[] }) => {
  return (
    <SummaryContainerStyled>
      <p className="title-summary">Resumen de actividades</p>
      <GridContainer minSize="320px">
        {summaryOptions.map(({ icon, optionCount, path, title, stateLocation }, index) => (
          <CardOption
            index={index}
            key={`${path}-${title}`}
            icon={icon}
            optionCount={optionCount}
            path={path}
            title={title}
            stateLocation={stateLocation}
          />
        ))}
      </GridContainer>
    </SummaryContainerStyled>
  )
})

export default SummaryOptions
