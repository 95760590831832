export const inputFormStyle = `
  .searchform__input--form {
    width: 100%;
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #FAE5B0;
    border-radius: 1.5rem;
    padding: 0 1em;
  }
  .searchform__input--form input {
    width: 80%;
    height: 90%;
    background-color: #FAE5B0;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 1rem;
  }
  .searchform__input--form input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px #FAE5B0 inset;
  }
  .searchform__input--form input::placeholder {
    color: #716F87;
  }
  .searchform__input--form img {
    height: 1.5rem;
  }
  .searchform__arrow-left_lupe_icon {
    cursor:pointer;
  }
`

export const searchFormStyle = `
  .searchform__container {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    padding: 0.5em 1em;
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
  }
  .searchform__input-form-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .searchform__arrow-left--container {
    width: 3rem;
    height: 3rem;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 3rem;
    cursor: pointer;
    margin: 1em 0;
  }
  .searchform__arrow-left--container:hover {
    opacity: 0.7;
  }
  .searchform__arrow-left--container img {
    object-fit: contain;
  }
  .searchform__filters-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5em 0;
  }
  .searchform__filters-container label {
    font-family: 'Montserrat';
    margin: 0.2em 0;
  }
  .searchform__label-checkmark {
    display: block;
    position: relative;
    padding-left: 1.8em;
    cursor: pointer;
    font-size: 1rem;
    
  }
  .searchform__label-checkmark input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .searchform__input-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #FAE5B0;
    border-radius: 50%;
  }
  .searchform__label-checkmark:hover input ~ .searchform__input-checkmark {
    background-color: #FAE5B0;
  }
  .searchform__label-checkmark input:checked ~ .searchform__input-checkmark {
    background-color: #FAE5B0;
  }
  .searchform__input-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .searchform__label-checkmark input:checked ~ .searchform__input-checkmark:after {
    display: block;
  }
  .searchform__label-checkmark .searchform__input-checkmark:after {
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #EE7628;
  }
  @media screen and (min-width: 768px) {
    .searchform__filters-container {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .searchform__filters-container label {
      font-family: 'Montserrat';
      margin: 0 0.2em;
    }
  }
  @media screen and (min-width: 1280px) {
    .searchform__container {
      flex-direction: row;
      height: 4rem;
      padding: 0 2em;
      justify-content: flex-start;
    }
    .searchform__input-form-container {
      width: 60%;
      flex-direction: row;
      align-items: center;
    }
    .searchform__arrow-left--container {
      margin: 0 1em;
    }
    .searchform__filters-container {
      padding: 0 1em;
    }
    .searchform__filters-container label {
      padding-right: 1em;
    }
  }
`