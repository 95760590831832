import styled from "styled-components"
import { displays } from "theme/base/Variables"

const ProcessProductCardStyled = styled.section<{ isConfirm?: boolean }>`
  ${displays.flexBetween};
  border-radius: 7px;
  border: 1px solid
    ${({ theme, isConfirm }) =>
      isConfirm ? theme.alert.success.backgroundColor : theme.alert.danger.backgroundColor};
  background: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.black};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.07);
  padding: 0.7rem;
  font-size: 1.143rem;

  & .section-info {
    ${displays.flexColumn};
    align-items: flex-start;
    gap: 0.5rem;

    & > svg {
      font-size: 2.5rem;
      color: ${({ theme, isConfirm }) =>
        isConfirm ? theme.alert.success.backgroundColor : theme.alert.danger.backgroundColor};
    }
  }
`

export default ProcessProductCardStyled
