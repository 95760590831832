import { Fragment } from 'react'
import { formatDate } from "helpers/FormatDateHelper"
import {rightSideStyle} from "overrides/theme/entel/base/components/OrderDetail/TagPrint/styles/TagPrintBody.style"

const RightSide = ({data}: any) => {
  const { sender, creationDate } = data
  return (
    <Fragment>
      <div className="pt__print-tag__right">
        <div className="pt__right_body1">
          <div className="pt__right_body1_box1">
            <span className="pt__small-text">Nombre</span>
            <span className="pt__normal-text">{sender?.name}</span>
          </div>
          <div className="pt__right_body1_box2">
            <span className="pt__small-text">Fecha</span>
            <span className="pt__normal-text">{creationDate && formatDate(creationDate, "dd/MM/yyyy")}</span>
          </div>
        </div>
        <div className="pt__right_body2">
          <div className="pt__right_body2_box1">
            <div className="pt__right_body2_box1_header">
              <div className="pt__right_body2_box1_header_title_cont">
                <span className="pt__small-text">CAUSAL DE DEVOLUCION DEL ENVIO</span>
              </div>
              <span className="pt__small-text">Fecha de Devolución</span>
            </div>
            <div className="pt__right_body2_box1_body">
              <div className="pt__right_body2_box1_body_child">
                <label className="pt__right_body2_box1_container_check">REUSADO
                  <input type="checkbox" />
                  <span className="pt__right_body2_box1_checkmark"></span>
                </label>
                <label className="pt__right_body2_box1_container_check">NO EXISTE
                  <input type="checkbox" />
                  <span className="pt__right_body2_box1_checkmark"></span>
                </label>
                <label className="pt__right_body2_box1_container_check">NO RECIBE
                  <input type="checkbox" />
                  <span className="pt__right_body2_box1_checkmark"></span>
                </label>
                <label className="pt__right_body2_box1_container_check">NO RECLAMADO
                  <input type="checkbox" />
                  <span className="pt__right_body2_box1_checkmark"></span>
                </label>
                <label className="pt__right_body2_box1_container_check">DESCONOCIDO
                  <input type="checkbox" />
                  <span className="pt__right_body2_box1_checkmark"></span>
                </label>
                <label className="pt__right_body2_box1_container_check">DIRECCION ERRADA
                  <input type="checkbox" />
                  <span className="pt__right_body2_box1_checkmark"></span>
                </label>
              </div>
              <div className="pt__right_body2_box1_body_child">
                <div className="pt__right_body2_box1_container_check_double">
                  <label className="pt__right_body2_box1_container_check">
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark"></span>
                  </label>
                  <label className="pt__right_body2_box1_container_check">CERRADO
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark"></span>
                  </label>
                </div>
                <div className="pt__right_body2_box1_container_check_double">
                  <label className="pt__right_body2_box1_container_check">
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark"></span>
                  </label>
                  <label className="pt__right_body2_box1_container_check">NO CONTACTADO
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark"></span>
                  </label>
                </div>
                <div className="pt__right_body2_box1_container_check_double">
                  <label className="pt__right_body2_box1_container_check">
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark"></span>
                  </label>
                  <label className="pt__right_body2_box1_container_check">FALLECIDO
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark pt__visibility_hidden"></span>
                  </label>
                </div>
                <div className="pt__right_body2_box1_container_check_double">
                  <label className="pt__right_body2_box1_container_check">
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark"></span>
                  </label>
                  <label className="pt__right_body2_box1_container_check">CLAUSURADO
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark pt__visibility_hidden"></span>
                  </label>
                </div>
                <div className="pt__right_body2_box1_container_check_double">
                  <label className="pt__right_body2_box1_container_check">
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark"></span>
                  </label>
                  <label className="pt__right_body2_box1_container_check">FUERZA MAYOR
                    <input type="checkbox" />
                    <span className="pt__right_body2_box1_checkmark pt__visibility_hidden"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="pt__right_body2_box2">
            <span className="pt__normal-text">OBSERVACIONES DE DISTRIBUCION</span>
          </div>
          <div className="pt__right_body2_box3">
            <span className="pt__normal-text">DATOS DE LA ENTREGA</span>
            <div className="pt__right_body2_box3_name_cont">
              <span className="pt__normal-text">NOMBRE LEGIBLE Y CEDULA</span>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{rightSideStyle}</style>
    </Fragment>
  )
}

export default RightSide;