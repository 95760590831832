import { FunctionComponent, useEffect } from "react"
import Logger from "classes/Logger"
import IPage from "interfaces/IPage"

const ReportPage: FunctionComponent<IPage> = (props: any) => {
  const { name } = props

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  return (
    <>
      <h1>Report</h1>
    </>
  )
}

export default ReportPage
