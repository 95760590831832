import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { AuthContext } from "context/context/AuthContext"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import { clients } from "webapp-clients/Clients"
import { Suspense, useContext } from "react"
import useDeliveredOrder from "pages/DetailOrderScreens/Delivered/UseDeliveredOrder"
import { COUNT_MAX_FILE } from "helpers/constHelper"
import { useForm } from "react-hook-form"
import { FilesFormType } from "types/FilesFormType"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaFileUpladerForm } from "config/ShemaForms"
import AlertStyled from "theme/common/Alert.styled"
import { IconAttachment } from "theme/common/Icon.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import { IconCheck } from "theme/common/Icon.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"

const DeliveredScreen = ({ shippinGroupDetail, shippingGroupId, order }: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const ContentFileMedia = client["ContentFileMedia" as keyof typeof client]
  const FileUpload = client["FileUpload" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker

  const files = shippinGroupDetail?.status
    .filter(({ media }) => media)
    .flatMap(({ media }) => media?.ref.map(({ id }) => id))

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(shemaFileUpladerForm(files || [])) : undefined,
  })

  const { submitFilesDeliveredOrder, loadingDelivered, hasPermissionUploadFiles } = useDeliveredOrder({
    shippingGroupId,
    order
  })

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={"Productos entregados"}
          />
          {(files || []).length < 3 && hasPermissionUploadFiles && (
            <>
              <form onSubmit={handleSubmit(submitFilesDeliveredOrder)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section className="padding-content ">
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo {COUNT_MAX_FILE - (files || []).length} archivo(s)</p>
                      </AlertStyled>
                      <FileUpload
                        register={register}
                        setValue={setValue}
                      />
                    </section>
                  </Suspense>
                )}

                {errors.filesShippinGroup && (
                  <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>
                )}
                <ContentDetailOrderActionsStyled>
                  <ButtonStyled variant="primary" loadingButton={loadingDelivered} disabled={loadingDelivered}>
                    <IconCheck />
                    <span>Guardar archivo</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              </form>
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
              shippinGroupDetail={shippinGroupDetail}
            />
          )}

          {ContentFileMedia && files && files.length > 0 && (
            <Suspense fallback={null}>
              <ContentFileMedia listIdFiles={files} shippingGroupId={shippingGroupId} documents={documents} />
            </Suspense>
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(DeliveredScreen)
