import { Fragment } from "react"
import { IPagination } from "interfaces/IPagination"
import ReactPaginate from "react-paginate"
import { paginateStyled } from "overrides/theme/shiperto/base/components/Commons/Pagination/Pagination.styled"
import { IconArrowLeft, IconArrowRigth } from "theme/common/Icon.styled"

const Paginate = ({ pageCount, onChangePage, elementFocus, pageInit }: IPagination) => {
  const handlePageChange = ({ selected }: { selected: number }) => {
    onChangePage(selected)
    elementFocus?.current?.scrollIntoView({ behavior: "smooth" })
  }

  if (pageCount === 0) return null
  return (
    <Fragment>
      <div className="paginate__container">
        <ReactPaginate
          previousLabel={<IconArrowLeft />}
          previousLinkClassName="prev-next"
          nextLabel={<IconArrowRigth />}
          nextLinkClassName="prev-next"
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={1}
          activeClassName={"active"}
          onPageChange={handlePageChange}
          forcePage={pageInit}
        />
      </div>
      <style jsx>{paginateStyled}</style>
    </Fragment>
  )
}

export default Paginate
