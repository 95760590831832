import styled, { css } from "styled-components"
import { displays } from "theme/base/Variables"
import { IPropsButton } from "interfaces/IPropsButton"
import { progressAnima } from "theme/base/Animations"

export const ButtonStyled = styled.button<IPropsButton>`
  ${displays.flexCenter};
  gap: 0.7rem;
  border-radius: 8px;
  border: 0;
  height: 44px;
  transition: all 500ms ease-out;
  color: white;
  padding: 0.5rem 1rem;
  position: relative;
  font-size: 1.075rem;
  font-weight: 700;
  user-select: none;

  & > svg {
    font-size: 2rem;
  }

  &:active {
    transform: scale(0.9);
  }

  ${({ variant, theme, loadingButton, disabledButton, fullSize }) => css`
    background: ${theme.button[variant].backgroundColor};
    color: ${theme.button[variant].textColor};
    border: ${theme.button[variant].borderColor
      ? `1px solid ${theme.button[variant].borderColor}`
      : `none`};

    &:hover:not(:focus) {
      ${theme.button[variant].hoverColor
        ? css`
            background: ${theme.button[variant].hoverColor};
            ${theme.button[variant].textColorHover
              ? css`
                  color: ${theme.button[variant].textColorHover};
                `
              : null}
            ${theme.button[variant].borderColorHover
              ? css`
                  border: 1px solid ${theme.button[variant].borderColorHover};
                `
              : null}
          `
        : css`
            filter: brightness(1.1);
          `}
    }

    :focus {
      ${theme.button[variant].focusColor
        ? css`
            background: ${theme.button[variant].focusColor};
          `
        : css`
            filter: brightness(1.1);
          `}
    }

    ${loadingButton &&
    css`
      &:before {
        content: "";
        width: 0%;
        z-index: 5;
        background: lightgray;
        transition: all 0.3s ease;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        position: absolute;
        opacity: 0.5;
        animation: ${progressAnima} 10s infinite;
      }
    `}

    ${disabledButton &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background: ${theme.palette.grey5};
      color: ${theme.palette.grey20};
      border: 1px solid ${theme.palette.grey20};
    `}

    ${fullSize &&
    css`
      width: 100%;
    `}
  `}
`
export const ButtonSecondaryStyled = styled.button<{ active?: boolean }>`
  ${displays.flexCenter};
  border: 0;
  gap: 0.5rem;
  color: ${({ theme }) => theme.palette.white};
  background: ${({ theme, active }) =>
    active ? theme.button.primary.backgroundColor : theme.palette.grey60};
  border-radius: 12px;
  transition: all 500ms ease;
  padding: 2px 20px;
  &:hover {
    background: ${({ theme }) => theme.button.primary.backgroundColor};
  }

  & .icon {
    font-size: 1.2rem;
  }
`
