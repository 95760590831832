import { Fragment, useEffect, useContext } from "react"
import { errorHandlerStyled } from "overrides/theme/entel/base/components/Commons/Error/ErrorHandler.styled"
import logo from "theme/assets/img/omnix-logo.svg"
import logo_entel from "assets/images/logo_entel.svg"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { ERROR_GENERIC, Error_GNERAL } from "helpers/constHelper"
import useLogin from "hooks/UseLogin"
import { GlobalContext } from "context/context/GlobalContext"

const ErrorHandler = ({
  code = TransactionCodeEnum.notFound,
  message = ERROR_GENERIC,
  ordersGroup
}: any) => {
  const { errorHander } = useContext(GlobalContext)
  const { logout } = useLogin()

  let isNotFound = code === TransactionCodeEnum.notFound
  let messageResult = isNotFound ? message : Error_GNERAL

  if (code === TransactionCodeEnum.ok && Object.keys(ordersGroup).length === 0) {
    messageResult = message
  }

  useEffect(() => {
    if (code === TransactionCodeEnum.unauthorized) logout()
  }, [code, logout, errorHander])

  return (
    <Fragment>
      <div className="error_container__root">
        <div className="error-handler__content-error">
          <img alt="logo" src={logo_entel} loading="lazy" />
          <h1>oops!</h1>
          <section className="error_container__code">
            <p>{code}</p>
          </section>
          <p className="error-handler__error-title">
            {messageResult}
          </p>
          {!isNotFound && <div className="error-handler__content-botton-back">
            <button onClick={() => errorHander?.dispatch({ hasError: false })}>
              <span>Volver al inicio</span>
            </button>
          </div>}
        </div>
        <img alt="logo" src={logo} loading="lazy" />
      </div>
      <style jsx>{errorHandlerStyled}</style>
    </Fragment>
  )
}

export default ErrorHandler
