import { AuthActionTypes } from "context/actionsTypes/AuthTypes"
import { keyBy } from "helpers/UtilsHelper"
import { IAuth } from "interfaces/IUser"

/**
 * that he reduces with the different cases of the actions to log in and log out of the user. and also set the menu that the user is allowed to enter
 * @param state
 * @param action
 * @return {Objet}
 * @return {IAuth} state. returns the state when authenticated
 * @return {Boolean} loading. returns if it is loading or not according to the selected case
 * @return {Strung} error. returns message of error
 * @return {Array} user. returns Info of user autenticated
 * @return {Array} optionsMenuAccess. returns Info of menu for user
 */
export const AuthReducer = (state: IAuth, action: AuthActionTypes): IAuth => {
  switch (action.type) {
    case "[auth] Login start":
      return {
        ...state,
        loading: true,
      }

    case "[auth] Login error":
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case "[auth] Login success": {
      const { payload } = action
      return {
        ...state,
        user: payload,
        loading: false,
        error: null,
      }
    }

    case "[auth] Set menu user": {
      const { payload } = action
      return {
        ...state,
        optionsMenuAccess: payload,
      }
    }
    case "[auth] Logout":
      return {
        ...state,
        user: null,
        optionsMenuAccess: [],
      }

    case "[auth] Change user info":
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      }

    case "[auth] Update Menu user": {
      const keyByGroupedOrder: any = keyBy(action.payload, "status")
      const newOptionsMenuAccess = state.optionsMenuAccess?.map((option) => {
        const countOrders = keyByGroupedOrder[option.statusOrder?.toString()]?.count || 0
        return {
          ...option,
          optionCount: countOrders,
        }
      })

      return {
        ...state,
        optionsMenuAccess: newOptionsMenuAccess,
      }
    }

    default:
      return state
  }
}
