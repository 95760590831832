import { useTranslation } from "react-i18next"
import TagPrintButton from "overrides/theme/shiperto/base/components/OrderDetail/TagPrint/TagPrintButton"
import Barcode from "components/Commons/BarCode"
import { formatDate } from "helpers/FormatDateHelper"

const OrderInfoPrint = (props: any) => {
  const { t } = useTranslation()
  const { data } = props

  const template = (
    <section className="order__main__detail">
      <h2>{t("Etiqueta")}</h2>
      <article className="order__main__detail__summary">
        <h3>
          Información General
        </h3>
        <div>
          <p>{t("Fecha de venta")}:</p>
          <p>
            <strong>{formatDate(data._order?.creationDate)}</strong>
          </p>
        </div>
        <div>
          <p>{t("Fecha estimada de entrega")}:</p>
          <p>
            <strong>{formatDate(data._order?.estimatedDeliveryDate)}</strong>
          </p>
        </div>
        {data._order?.shippingAddress?.city && <div>
          <p>{t("Ciudad")}:</p>
          <p>
            <strong>{data._order?.shippingAddress?.city}</strong>
          </p>
        </div>}
        {data._order?.shippingAddress?.address && <div>
          <p>{t("Dirección")}:</p>
          <p>
            <strong>{data._order?.shippingAddress?.address}</strong>
          </p>
        </div>}
        <Barcode value={data._sg?.id} />
        <div className="order__main__detail__action">
          <TagPrintButton shippingGroup={data._sg} order={data._order} />
        </div>
      </article>
    </section>
  )

  return template
}

export default OrderInfoPrint
