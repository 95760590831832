import { useContext, useRef, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Env } from "classes"
import { GlobalContext } from "context/context/GlobalContext"
import OrderInfoPrint from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderInfoPrint"
import OrderDetailProductList from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailProductList"
import OrderDetailSeriesList from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailSeriesList"
import OrderDetailTraces from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailTraces"
import { formatDate } from "helpers/FormatDateHelper"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { useEnv } from "hooks"
import Modal from "components/Commons/Modal/Modal"
import OrderDetailModalAction from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetailModalAction"
import { updateShippingGroupStateCustom } from "../../services/ShippingGroupService"
import ProductOderDetail from "overrides/theme/entel/base/components/OrderDetail/ProductOderDetail/ProductOrderDetail"
import usePickingItems from "overrides/theme/entel/base/hooks/v4/usePickingItems"
import { IProcessItem } from "interfaces/IItemsOrder"

const OrderDetailsBtnAction = (props: any) => {
  const { action, index, event_sg_action, t, permissions, status, set_reason_cancel, set_transport_reference } = props
  const cond = permissions.get_permission(`status_${status}`, `action-${action.action}`)
  const refModal = useRef<React.ElementRef<typeof Modal>>(null)
  const statusses: any = translatesObj

  const template = (
    <>
      <Modal ref={refModal}>
        <OrderDetailModalAction
          action={action}
          confirmAction={() => event_sg_action(action)}
          close={() => refModal.current?.close()}
          set_reason_cancel={set_reason_cancel}
          set_transport_reference={set_transport_reference}
        />
      </Modal>
      <button
        key={index}
        className={`order__main__detail__action__action order__main__detail__action__${action.action}`}
        onClick={() => refModal.current?.open()}
      >
        {t(statusses[action.action])}
      </button>
    </>
  )

  return cond && template
}

const OrderDetails = (props: any) => {
  const { t } = useTranslation()
  const statusses: any = translatesObj
  const { data, set_loading } = props
  const { shippingtype, type, shippingGroupId, id }: any = useParams()
  const history = useHistory()
  const { permissions } = useEnv()
  const { notification, errorHander } = useContext(GlobalContext)
  const [reason_cancel, set_reason_cancel] = useState<string>()
  const [transportReference, set_transport_reference] = useState<string>()
  const actions = Env.theme.entel.sg[shippingtype.toUpperCase()].status_actions[type.toUpperCase()]
  let itemsOrder = data?._sg?.logisticPlan?.items

  const {
    currentItem,
    dispachPickingTransition,
    pickingTransition,
    is_picking_state,
    show_series_input_if,
    hide_options_if_all_series_completed,
  } = usePickingItems({
    itemsOrder,
    status: type,
    shippingType: shippingtype,
  })

  const event_sg_action = async (event: any) => {
    const action = event.action
    let shipping_type_action = shippingtype

    try {
      set_loading(true)

      let data_req = {
        entityId: shippingGroupId,
        action: action.toUpperCase(),
        custom: {
          executer: {
            id: "OP-001",
            name: "OMNIX",
          },
        },
      }

      if (action === "cancel" && reason_cancel) {
        Object.assign(data_req.custom, { reason: reason_cancel })
      }

      if (action === "select") {
        Object.assign(data_req, { courier: { transportReference } })
      }

      if (is_picking_state) {
        Object.assign(data_req.custom, {
          items: pickingTransition.processItems,
          pickingId: data._sg?.pickingId,
          description: "Picking de producto",
        })
      }

      await updateShippingGroupStateCustom(data_req)
      set_loading(false)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Orden modificada exitosamente!",
          title: `${t(`Acción aplicada`)}: ${t(`${action}`)}`,
          type: "success",
        },
      })
      setTimeout(() => history.push(`/order/${shipping_type_action}/${event.next_page}/${shippingGroupId}/${id}`), 3000)
      setTimeout(() => window?.location?.reload(), 5000)
    } catch (e: any) {
      errorHander?.dispatch({ hasError: true, code: 500 })
    }
  }

  const handleClickProcessProduct = (item: IProcessItem) => {
    if (item) {
      if (pickingTransition.isEditing) {
        const nextItemIndex = itemsOrder?.findIndex(
          (item: any) => !pickingTransition.processItems.some(({ sku }) => sku === item.sku),
        )
        dispachPickingTransition({
          type: "EDIT_ITEM_SERIES",
          payload: {
            itemEdit: item,
            nextItemIndex: nextItemIndex || -1,
          },
        })

        return
      }
      dispachPickingTransition({ type: "ADD_PROCESS_ITEM", payload: item })
    }
  }

  const currentStatusTag = data._sg?._omnixStateMachine?.currentState?.state?.id?.toUpperCase()

  const template = (
    <section className="page__order">
      <article className="order__main">
        {permissions.get_permission("details", "block-info") && (
          <section className="order__main__detail">
            <h2>{t(`Orden # ${data._sg?.id}`)}</h2>

            <article className="order__main__detail__summary">
              {data._sg?.source?.name && (
                <h3>
                  {t("Source")} {data._sg?.source?.name}
                </h3>
              )}

              {data._sg?.source?.shippingAddress && (
                <>
                  <h3>
                    {t("Cliente")} {data._sg?.source?.shippingAddress?.contact?.name}
                  </h3>
                  {data._sg?.source?.shippingAddress?.contact?.id && (
                    <div>
                      <p>{t("Documento")}:</p>
                      <p>
                        <strong>{data._sg?.source?.shippingAddress?.contact?.id}</strong>
                      </p>
                    </div>
                  )}
                  {Object.values(data._sg?.source?.shippingAddress).map((info: any, _index: any) => {
                    if (typeof info === "string") return <p key={_index}>{info}</p>
                    return null
                  })}
                  {data._sg?.target?.source?.id && (
                    <div>
                      <p>{t("Destino")}:</p>
                      <p>
                        <strong>{data._sg?.target?.source?.id}</strong>
                      </p>
                    </div>
                  )}
                </>
              )}

              {data._sg?.target?.customer?.name && <h4>{data._sg?.target?.customer?.name}</h4>}

              {data._sg?.target?.source?.id && data._sg?.shippingType === "SP" && (
                <div>
                  <p>{t("Punto de retiro")}:</p>
                  <p>
                    <strong>{data._sg?.target?.source?.id}</strong>
                  </p>
                </div>
              )}

              {data._sg?.target?.customer?.id && (
                <div>
                  <p>{t("Documento")}:</p>
                  <p>
                    <strong>{data._sg?.target?.customer?.id}</strong>
                  </p>
                </div>
              )}

              {data._sg?.target?.customer?.email && (
                <div>
                  <p>{t("Email")}:</p>
                  <p>
                    <strong>{data._sg?.target?.customer?.email}</strong>
                  </p>
                </div>
              )}

              <div>
                <p>{t("Estado actual")}:</p>
                <p>
                  <strong>{t(statusses[currentStatusTag]?.title) || currentStatusTag}</strong>
                </p>
              </div>
              <div>
                <p>{t("Fecha de estado actual")}:</p>
                <p>
                  <strong>{formatDate(data._sg?._omnixStateMachine?.currentState?.date)}</strong>
                </p>
              </div>
              <div>
                <p>{t("Tipo de de despacho")}:</p>
                <p>
                  <strong>{t(`${data._sg?.shippingType}`)}</strong>
                </p>
              </div>
              {data._order?.deliveryType && (
                <div>
                  <p>{t("Tipo de entrega")}:</p>
                  <p>
                    <strong>{t(`${data._order?.deliveryType}`)}</strong>
                  </p>
                </div>
              )}
              <div>
                <p>{t("ID canal de ventas")}:</p>
                <p>
                  <strong>{data._sg?.salesChannelId}</strong>
                </p>
              </div>
              {hide_options_if_all_series_completed ? (
                <>
                  {show_series_input_if ? (
                    <ProductOderDetail itemOrder={currentItem} onClicProcessProduct={handleClickProcessProduct} />
                  ) : null}

                  {!show_series_input_if ? (
                    <div className="order__main__detail__action">
                      {!!actions &&
                        actions?.map((action: any, index: number) => (
                          <OrderDetailsBtnAction
                            action={action}
                            index={index}
                            t={t}
                            permissions={permissions}
                            event_sg_action={event_sg_action}
                            status={type}
                            key={index}
                            set_reason_cancel={set_reason_cancel}
                            set_transport_reference={set_transport_reference}
                          />
                        ))}
                    </div>
                  ) : null}
                </>
              ) : null}
            </article>
          </section>
        )}
        <span className="show_desktop_and_hide_mobile">
          {permissions.get_permission("details", "block-tag") && <OrderInfoPrint data={data} />}
        </span>
      </article>

      {permissions.get_permission("details", "block-traces") && <OrderDetailTraces data={data} />}

      {permissions.get_permission("details", "block-products") && <OrderDetailProductList data={data} />}

      <span className="show_mobile_and_hide_desktop">
        {permissions.get_permission("details", "block-tag") && <OrderInfoPrint data={data} />}
      </span>

      {permissions.get_permission("details", "block-series") && <OrderDetailSeriesList data={data} />}
    </section>
  )

  return template
}

export default OrderDetails
