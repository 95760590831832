import CardOrdeItem from "overrides/theme/wom/base/components/Core/Order/CardOrder/CardOrdeItem"
import { GroupItemStyled } from "theme/common/Paragraph.styled"
import { GridContainer } from "theme/common/Grid.styled"
import { useCallback, useContext, useState, Suspense, useEffect } from "react"
import { OrderListType } from "types/OrderType"
import Checkbox from "components/Commons/CheckBox"
import useOrderList from "components/Core/Order/hooks/UseChekedOrderList"
import { ButtonStyled } from "theme/common/Button.styled"
import { IconCheck } from "theme/common/Icon.styled"
import UseParentContainer from "hooks/UseParentContainer"
import { clients } from "webapp-clients/Clients"
import { useForm } from "react-hook-form"
import { FilesFormType } from "types/FilesFormType"
import { shemaFileUpladerForm } from "config/ShemaForms"
import { AuthContext } from "context/context/AuthContext"
import { yupResolver } from "@hookform/resolvers/yup"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"

const OrderList = ({ ordersGroup, showStatus }: OrderListType) => {
  const {
    checkAllOrders,
    setOrdersSGChecked,
    ordersSGChecked,
    selectAll,
    canSelectMultiple,
    statusSG,
    disabledUpdateSG,
    loadingUpdateSG,
    updateShippingGroupAction,
    conditionChecked,
    labelGroupAction,
    setOrdersSGCheckedToPrint,
  } = useOrderList(ordersGroup)

  const {
    state: { user },
  } = useContext(AuthContext)

  const [disableConfirmDelivery, setDisableConfirmDelivery] = useState(true)

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FileUpload = client["FileUpload" as keyof typeof client]

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(shemaFileUpladerForm([])) : undefined,
  })

  const filesShippinGroup = watch("filesShippinGroup")

  //events
  const handleClickCheckOrder = useCallback((ev: any, infoSgToPrint: any) => {
    const { id, checked } = ev.target
    if (!checked) {
      setOrdersSGChecked((prevOrderSG) => {
        return prevOrderSG.filter((sg) => sg !== id)
      })
      setOrdersSGCheckedToPrint(prevValue =>
        prevValue.filter((sg) => sg.id !== id)
      )
      return
    }
    setOrdersSGChecked((prevOrderSG) => [...prevOrderSG, id])
    setOrdersSGCheckedToPrint(prevValue => [...prevValue, infoSgToPrint])
    },[setOrdersSGChecked, setOrdersSGCheckedToPrint],
  )

  const handleClickSelectAll = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = ev.target
    checkAllOrders(checked)
  }

  const handleClickUpdateOrders = () => {
    updateShippingGroupAction()
  }

  const {
    showActionCourier,
    canIuseParentContainer,
    loadingParentContainer,
    handleClickParentContainer,
    submitFilesParentContainer,
  } = UseParentContainer()

  useEffect(() => {
    filesShippinGroup?.length ? setDisableConfirmDelivery(false) : setDisableConfirmDelivery(true)
  },
  //eslint-disable-next-line
  [filesShippinGroup])

  return (
    <>
      {ordersGroup && (
        <>
          {canSelectMultiple ? (
            <div className="content-dispatch-action">
              {canIuseParentContainer ? (
                <>
                  <ButtonStyled
                    variant="secondary"
                    disabledButton={loadingParentContainer}
                    loadingButton={loadingParentContainer}
                    onClick={handleClickParentContainer}
                  >
                    <IconCheck />
                    <span>Recibir todas las SG'S</span>
                  </ButtonStyled>
                </>
              ) : (
                <>
                  <Checkbox
                    id="selectAll"
                    name="selectAll"
                    onChange={handleClickSelectAll}
                    checked={selectAll}
                    label="Seleccionar todo"
                  />
                  <ButtonStyled
                    variant="secondary"
                    disabledButton={disabledUpdateSG}
                    loadingButton={loadingUpdateSG}
                    disabled={disabledUpdateSG}
                    onClick={handleClickUpdateOrders}
                  >
                    <IconCheck />
                    <span>{labelGroupAction}</span>
                  </ButtonStyled>
                </>
              )}
            </div>
          ) : (
            <form onSubmit={handleSubmit(submitFilesParentContainer)}>
              <div className="content-dispatch-action courier-action">
                {canIuseParentContainer && showActionCourier &&
                  <>
                    <ButtonStyled
                      variant="primary"
                      disabledButton={disableConfirmDelivery}
                      loadingButton={loadingParentContainer}
                    >
                      <IconCheck />
                      <span>Confirmar Entrega</span>
                    </ButtonStyled>

                    <div className="attachFilesCourierList">                      
                      {FileUpload && (
                        <Suspense fallback={null}>
                          <section>
                            <FileUpload
                              register={register}
                              setValue={setValue}
                              label="Archivos adjuntos"
                            />
                          </section>
                        </Suspense>
                      )}

                      {errors.filesShippinGroup && (
                        <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>
                      )}
                    </div>
                  </>
                }
              </div>
            </form>
          )}
          {Object.keys(ordersGroup).map((key) => (
            <div className="content-group" key={key}>
              <div className="group-item">
                <GroupItemStyled>{key}</GroupItemStyled>
              </div>
              <GridContainer>
                {ordersGroup[key].map((order) => (
                  <CardOrdeItem
                    key={`${order.orderId}-${order?.id}`}
                    {...order}
                    canSelect={canSelectMultiple && !canIuseParentContainer && (!conditionChecked || conditionChecked(order))}
                    handleClickSelectOrder={handleClickCheckOrder}
                    isCheckedOrder={ordersSGChecked.includes(order.id)}
                    statusSG={statusSG}
                    showStatus={showStatus}
                  />
                ))}
              </GridContainer>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default OrderList
