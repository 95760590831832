import styled from "styled-components"
import { respondTo } from "theme/base/Mixins"
import { displays } from "theme/base/Variables"

export const ContentForm = styled.div`
`

export const ContentFormFinal = styled.div`
  padding: 2rem 1rem;
`

export const TransferContentStyled = styled.section`
  padding: 0 1rem;

  &.content-form {
    margin-top: 1rem;

    & .title {
      color: #3E405B;
      font-size: 1.125rem;
      margin-bottom: 0.3rem;
    }

    .box-form-product {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;

      .field {
        flex: 1;
        margin-bottom: 1rem;
      }

      .action {
        & > button {
          width: 100%;
        }
      }
    }

    .box-error {
      p {
        margin: 0 0 1rem 0;
      }
    }
  }

  & .content-info {
    ${displays.flexColumn}
    gap: 1rem;
    align-items: initial;
    padding: 1rem 1.5rem;
    color: ${({ theme }) => theme.palette.grey90};
    border-radius: 7px;
    background: ${({ theme }) => theme.palette.white};
    box-shadow: 1px 2px 4px rgb(0 0 0 / 10%);

    &.pd0 {
      padding: 0;
    }
  }

  & .info-source {
    margin-top: 1rem;
  }

  & .box-serial {
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 1px solid #3E405B;

    .msg-alert-serial {
      margin-bottom: 1.5rem;
    }

    & > button:last-of-type {
      width: 100%;
    }
  }

  & .box-buttons {
    margin-top: 1rem;

    & > button {
      width: 100%;
    }
  }
  
  & .box-success {
    ${displays.flexColumn}
    align-items: initial;
    color: ${({ theme }) => theme.palette.grey90};

    .title {
      background: #E5E7ED;
      padding: 0.5rem;
      color: #3E405B;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
    }

    .info {
      color: #3E405B;
      font-size: 1.286rem;
      font-weight: 700;
      text-align: center;
      background: #ECEDF1;
      padding: 0.3rem;
    }
  }

  &.products-list,
  & .products-title {
    margin-top: 1rem;
  }

  ${respondTo.sm`
    &.content-form {
      .box-form-product {
        flex-direction: row;

        .field {
          margin: 0 1rem 0 0;
        }

        .action {
          & > button {
            width: inherit;
          }
        }
      }
    }
  `}
`

export const ContentFilterSource = styled.div`
  & .sub-title {
    color: #3E405B;
    font-size: 1.125rem;
    margin-bottom: 0.3rem;
  }
`