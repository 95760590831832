import axiosClient from "config/axiosClient"
import { IResponseService } from "interfaces/IResponseService"
import { AxiosResponse, CancelToken } from "axios"

const clientAxios = axiosClient("OSM")

export const listUsersService = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = "user/all"

  const response = await client.post<IResponseService<any>>(
    url,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}

export const getUserByIdService = async (
  userId: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = `user/${userId}`

  const response = await client.get<IResponseService<any>>(
    url,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}

export const createUserService = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = "user"

  const response = await client.put<IResponseService<any>>(
    url,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}

export const updateUserService = async (
  userId: string,
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = `user/${userId}`

  const response = await client.post<IResponseService<any>>(
    url,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}

export const enableUserService = async (
  userId: string,
  body: any = {},
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = `user/${userId}/enable`

  const response = await client.post<IResponseService<any>>(
    url,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}

export const getRolesService = async (
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = "role"

  const response = await client.get<IResponseService<any>>(
    url,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}

export const changePasswordUserService = async (
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = `user/me/password`

  const response = await client.post<IResponseService<any>>(
    url,
    body,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}

export const deleteUserService = async (
  userId: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<any>>> => {
  const client = clientAxios.getClient()

  const url = `user/${userId}`

  const response = await client.delete<IResponseService<any>>(
    url,
    {
      headers: { "Content-Type": "application/json" },
      cancelToken
    },
  )

  return response
}