export const cardOrderItemStyle = `
  .card-order-item__container {
    min-width: 25rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: #FFFFFF;
    border-radius: 1rem;
    padding: 0 1em 1em;
    box-shadow: 0px 6px 24px rgba(207, 195, 172, 0.2);
    border: 1px solid #DDDDE9;
    transition: all 0.5s ease-in-out;
    gap: 1em;
  }
  .card-order-item__container:hover {
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
  }
  .card-order-item__content-item-order {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .card-order-item__content-order-checked {
    position: relative;
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-order-item__check-container {
    display: block;
    position: relative;
    cursor: pointer;
  }
  .card-order-item__check-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .card-order-item__check-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.2rem;
    width: 1.2rem;
    background-color: #FFFFFF;
    border: solid 1.5px #002eff;
    border-radius: 0.3rem;
  }
  .card-order-item__check-container:hover input ~ .card-order-item__check-checkmark {
    background-color: #002eff;
  }
  .card-order-item__check-container input:checked ~ .card-order-item__check-checkmark {
    background-color: #002eff;
  }
  .card-order-item__check-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .card-order-item__check-container input:checked ~ .card-order-item__check-checkmark:after {
    display: block;
  }
  .card-order-item__check-container .card-order-item__check-checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .card-order-item__content-order-info {
    width: 90%;
    height: 100%;
  }
  .card-order-item__header-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    border-bottom: 1px solid #DDDDDD;
  }
  .card-order-item__header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0 1em;
  }
  .card-order-item__header-icon, .card-order-item__header-arrow {
    width: 1.5rem;
    height: 1.5rem;
    color: #002eff;
  }
  .card-order-item__header-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 0.9rem;
    color: #23203F;
    text-transform: uppercase;
  }
  .card-order-item__body-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0.5em 1em;
  }
  .card-order-item__body-card-item-title, .card-order-item__body-card-item-value {
    font-family: 'Montserrat';
    font-size: 0.8rem;
    color: #716F87;
  }
  .card-order-item__body-card-item-value {
    font-weight: 600;
    padding-left: 1em;
  }
  @media screen and (min-width: 1280px) {
    .card-order-item__container {
      flex-wrap: wrap;
      gap: 0;
    }
    .card-order-item__lv__content-order-checked {
      position: relative;
      width: auto;
      padding: 0;
    }
    .card-order-item__lv__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      padding: 0.5em 1em;
      border-radius: 1rem;
      box-shadow: 0px 6px 24px rgba(207, 195, 172, 0.2);
      border: 1px solid #DDDDE9;
      transition: all 1s ease-in-out;
    }
    .card-order-item__lv__content-order-info {
      width: calc(100% - 2rem);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .card-order-item__lv__content-item-order {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .card-order-item__lv__header-card {
      width: 20%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0 1em;
      border: none;
    }
    .card-order-item__lv__header-card .card-order-item__header-arrow {
      display: none;
    }
    .card-order-item__lv__body-card {
      width: 75%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .card-order-item__lv__body-card-item--titles {
      display: none;
    }
    .card-order-item__lv__body-card-item--values {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
      text-align: left;
    }
  }
`