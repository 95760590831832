import { Fragment } from "react"
import CardOrdeItem from "overrides/theme/entel/base/components/Core/Order/CardOrder/CardOrdeItem"
import {orderListStyle} from "overrides/theme/entel/base/components/Core/Order/styles/OrderList.style"

const OrderList = ({
  ordersGroup,
  showStatus,
  currentDisplayList,
  handleClickCheckOrder,
  canSelectMultiple,
  conditionChecked,
  ordersSGChecked,
  statusSG
}: any) => {

  return (
    <Fragment>
      {ordersGroup && (
        <>
          {Object.keys(ordersGroup).map((key, index) => (
            <div className="order-list__content-group" key={key}>
              <div className="order-list__group-item">
                <p>{key}</p>
              </div>
              {currentDisplayList === "list" && index === 0 && <div className="order-list__lv__header-titles-container">
                <div className="order-list__lv__content-titles">
                  <p className="order-list__lv__item-title">ID CNAL VTA</p>
                  <p className="order-list__lv__item-title">ORDEN</p>
                  <p className="order-list__lv__item-title">SG</p>
                  <p className="order-list__lv__item-title">SOURCE</p>
                  <p className="order-list__lv__item-title">CANAL</p>
                </div>
              </div>}
              <div
                className={currentDisplayList === "grid" ?
                  "order-list__cont-list order-list__gv__cont-list" : "order-list__cont-list"
                }
              >
                {ordersGroup[key].map((order: any) => (
                  <CardOrdeItem
                    key={`${order.orderId}-${order?.id}`}
                    {...order}
                    canSelect={canSelectMultiple && (!conditionChecked || conditionChecked(order))}
                    handleClickSelectOrder={handleClickCheckOrder}
                    isCheckedOrder={ordersSGChecked?.includes(order.id)}
                    statusSG={statusSG}
                    showStatus={showStatus}
                    currentDisplayList={currentDisplayList}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      )}
      <style jsx>{orderListStyle}</style>
    </Fragment>
  )
}

export default OrderList
