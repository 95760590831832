import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import useTransitOrder from "pages/DetailOrderScreens/Transit/UseTransitOrder"
import TransitAction from "components/OrderDetail/TransitProductOrder/TransitAction"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { Suspense, useContext, useEffect } from "react"
import { AuthContext } from "context/context/AuthContext"
import { clients } from "webapp-clients/Clients"
import AlertStyled from "theme/common/Alert.styled"
import { IconAttachment } from "theme/common/Icon.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import useHideActionButtons from "hooks/UseHideActionButtons"
import { IconCancel } from "theme/common/Icon.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import useCancelDecreaseOrder from "hooks/UseCancelDecreaseOrder"
import UseDeliveredRejectOrder from "hooks/UseDeliveredRejectOrder"
import UseCustomerRejectOrder from "hooks/UseCustomerRejectOrder"
import { WebAppClientsEnum } from "enums/CommonEnum"
import UseCancelOrder from "hooks/UseCancelOrder"
import { useGetCancelActionByClient } from "hooks/UseGetMultiClient"

// TODO: multiclient
import useFormConfig from "hooks/Media/useFormConfig"
import useFormConfigSandbox from "overrides/theme/sandbox/base/Hooks/Media/useFormConfig"
import { useEnv } from "hooks"

const TransitScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { hideTransitActions, updateVisibilityButtonActions } = useHideActionButtons()
  const [_client] = useEnv().multiclient.get_user_env()

  useEffect(() => {
    updateVisibilityButtonActions(shippinGroupDetail?.source?.id, shippinGroupDetail?.target?.source?.id)
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[_client as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const NoExistTransit = client["NoExistTransit" as keyof typeof client]

  // TODO: multiclient
  const useFormConfigWrapper = user?.mainGroup.id === "sandbox" ? useFormConfigSandbox : useFormConfig
  const { errors, FileUpload, handleSubmit, register, setValue } = useFormConfigWrapper({})

  const { submitDeliveredOrder, loadingTransit } = useTransitOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
    order,
  })

  const { handleClickCancelDecreaseOrder, loadingCancelDecrase, canIuseCancelDecraese } = useCancelDecreaseOrder({
    order,
    shippingGroupId,
  })

  const { handleClickDeliveredRejectOrder, loadingDeliveredReject, canIuseDeliveredReject } = UseDeliveredRejectOrder({
    order,
    shippingGroupId,
  })

  const { handleClickCustomerRejectOrder, loadingCustomerReject, canIuseCustomerReject } = UseCustomerRejectOrder({
    order,
    shippingGroupId,
    setFinishedShippingGroupAction,
  })

  const handleClickCancelDecrease = () => {
    handleClickCancelDecreaseOrder(EShippinggroupAction.cancel_decrease)
  }

  const handleClickDeliveredReject = () => {
    handleClickDeliveredRejectOrder(EShippinggroupAction.delivered_reject)
  }

  const handleClickCustomerReject = () => {
    handleClickCustomerRejectOrder(EShippinggroupAction.customer_reject)
  }

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const { handleClickCancelOrder, loadingCancel, canIuseCancel } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })

  return (
    <>
      {NoExistTransit && (
        <Suspense fallback={null}>
          <NoExistTransit
            shippingGroupId={shippingGroupId}
            courierDeliveryInfo={shippinGroupDetail?.custom?.courierDeliveryInfo}
            shippinGroupDetail={shippinGroupDetail}
          />
        </Suspense>
      )}
      {itemsOrder ? (
        <>
          {canIuseCancel && _client === WebAppClientsEnum.sandbox && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}

          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos en tránsito" : undefined}
          />
          {!finishedShippingGroupAction && (
            <>
              <form onSubmit={handleSubmit(submitDeliveredOrder)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section className="padding-content ">
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} />
                    </section>
                  </Suspense>
                )}

                {errors.filesShippinGroup && <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>}

                {canIuseCancelDecraese && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickCancelDecrease}
                      loadingButton={loadingCancelDecrase}
                      disabled={loadingCancelDecrase}
                    >
                      <IconCancel />
                      <span>Cancelar Orden sin Modificar Inventario</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {canIuseDeliveredReject && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickDeliveredReject}
                      loadingButton={loadingDeliveredReject}
                      disabled={loadingDeliveredReject}
                    >
                      <IconCancel />
                      <span>Cancelar orden por siniestro</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}
                
                {canIuseCustomerReject && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickCustomerReject}
                      loadingButton={loadingCustomerReject}
                      disabled={loadingCustomerReject}
                    >
                      <IconCancel />
                      <span>Rechazado por el cliente</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {!hideTransitActions && <TransitAction loadingTransit={loadingTransit} />}
              </form>
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList documents={documents} channel={shippinGroupDetail?.channel} orderId={order?.id} />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(TransitScreen)
