import { useState, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import Logger from "classes/Logger"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { NOTIFY_PACKING } from "helpers/constHelper"
import { ProcessShippingGroupActionType } from "types/OrderType"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import useUserPermissions from "hooks/UseUserPermissions"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"

const usePackageOrder = ({
  setFinishedShippingGroupAction,
  shippingGroupId,
  isElocker
}: ProcessShippingGroupActionType) => {
  const [loadingPackage, setLoadingPackage] = useState(false)

  const { notification, errorHander } = useContext(GlobalContext)

    const { permission } = useUserPermissions({
      resources: [
        ResourcePermissionsEnum.qrEntry,
        ResourcePermissionsEnum.qrExit,
        ResourcePermissionsEnum.qrCancel,
        ResourcePermissionsEnum.packingElocker,
      ],
    })

  const handleClickPackageOrder = async () => {
    try {
      setLoadingPackage(true)
      const {
        data: { code },
      } = await executeShipingGroupAction({
        shippingGroupId,
        action: EShippinggroupAction.packed,
      })

      setLoadingPackage(false)

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        setFinishedShippingGroupAction(true)

        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Productos empacados correctamente",
            title: NOTIFY_PACKING,
            type: "success",
          },
        })
      }
    } catch (error: any) {
      setLoadingPackage(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    loadingPackage,
    handleClickPackageOrder,
    denyPackingAction: isElocker && !permission[ResourcePermissionsEnum.packingElocker],
    canViewQrCancel: permission[ResourcePermissionsEnum.qrCancel],
    canViewQrEntry: permission[ResourcePermissionsEnum.qrEntry],
    canViewQrExit: permission[ResourcePermissionsEnum.qrExit],
  }

}

export default usePackageOrder
