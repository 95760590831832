import { useContext, useEffect, useState } from "react"
import useCancelToken from "hooks/UseCancelToken"
import { useHistory, useParams } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import { OptionsFilterEnum } from "enums/OptionsFilterEnum"
import { AuthContext } from "context/context/AuthContext"
import { ShippingTypeEnum, SortOrderEnum } from "enums/OrderEnum"
import { EShippinggroupAction, EShippinggroupStatus } from "enums/shippinggroupEmun"
import { request_post } from "services/OSRM"
import { getOrderById } from "services/OrderServices"
import Logger from "classes/Logger"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import {
  availableStatusActionGlobal,
  availableStatusActionCourier,
} from "overrides/theme/wom/base/assets/Data/ParentContainerData"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"
import Env from "classes/Env"
import { FilesFormType } from "types/FilesFormType"
import { updateOrderById } from "services/OrderServices"
import { attachFileShippingGroup } from "services/ShippingGroupService"

const UseParentContainer = () => {
  const [canIuseParentContainer, setCanIuseParentContainer] = useState(false)
  const [loadingParentContainer, setLoadingParentContainer] = useState(false)
  const [showActionCourier, setShowActionCourier] = useState(false)
  const history = useHistory()

  const {
    state: { user },
  } = useContext(AuthContext)

  const { type } = useParams<{ type: string }>()

  const { userSearch, notification, errorHander } = useContext(GlobalContext)

  const { isCancel, newCancelToken } = useCancelToken()

  useEffect(() => {
    if (
      (user?.role !== ResourceRoleEnum["webapp-seller-courier"] && user?.role !== ResourceRoleEnum["webapp-sup-courier"]) &&
      userSearch.state.option === OptionsFilterEnum["custom.contenedorPadre"] &&
      availableStatusActionGlobal.includes(type)
    ) {
      setCanIuseParentContainer(true)
      setShowActionCourier(false)
    }

    if (
      (user?.role === ResourceRoleEnum["webapp-seller-courier"] || user?.role === ResourceRoleEnum["webapp-sup-courier"]) &&
      availableStatusActionCourier.includes(type) &&
      userSearch.state.option === OptionsFilterEnum["custom.contenedorPadre"]
    ) {
      setCanIuseParentContainer(true)
      setShowActionCourier(true)
    }
  },
  //eslint-disable-next-line
  [userSearch, type])

  const handleClickParentContainer = async () => {
    setLoadingParentContainer(true)
    const ids = await getOrders()
    updateShippingGroup(ids)
  }

  const handleClickParentContainerCourier = async () => {
    setLoadingParentContainer(true)
    const ids = await getOrdersCourier()
    updateShippingGroup(ids)
  }

  const get_user_type_groups = (type: string) => {
    let channels = user?.groups
    channels = channels?.filter((channel: any) => channel.type === type).map((channel: any) => channel.id)
    return channels
  }

  const getOrders = async () => {
    try {
      let response: any = {}
      let responseRecords = []

      const requestBody: any = {
        size: 1000,
        from: 0,
        filters: {
          shippingType: [ShippingTypeEnum.SP],
          channel: get_user_type_groups("Channel"),
          "source.id": ["WWH"],
          "target.source.id": user?.currentSources,
          "currentStatus.tag": EShippinggroupStatus.transit,
          "custom.contenedorPadre": userSearch.state.value,
        },
        sortBy: {
          "status.date": {
            order: SortOrderEnum.asc,
            mode: "min",
          },
        },
        fields: ["id", "orderId"],
      }

      response = await request_post(`search/shipping_groups`, requestBody, newCancelToken())
      response = response.data.message
      responseRecords = response.records

      if (responseRecords.length <= 0) {
        setLoadingParentContainer(false)
        return
      } else {
        return responseRecords
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setLoadingParentContainer(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
      Logger.error("No results with source.id filter active:", user?.currentSources.toString())
    }
  }

  const getOrdersCourier = async () => {
    try {
      let response: any = {}
      let responseRecords = []

      const requestBody: any = {
        size: 1000,
        from: 0,
        sortBy: {
          "status.date": {
            order: SortOrderEnum.asc,
            mode: "min",
          },
        },
        entities: {
          parent: {
            entity: "shipping_groups",
            filters: {
              shippingType: [ShippingTypeEnum.HD, ShippingTypeEnum.SP],
              channel: get_user_type_groups("Channel"),
              "custom.contenedorPadre": userSearch.state.value,
              "source.id": get_user_type_groups("Source"),
              "currentStatus.tag": EShippinggroupStatus.transit,
            },
            fields: [
              "orderId",
              "orderCreationDate",
              "id",
              "channel",
              "shippingType",
              "salesChannelId",
              "source.id",
              "source.name",
              "target.id",
              "target.name",
              "target.customer",
              "currentStatus",
              "custom.infoElocker",
              "custom.consolidation",
              "target.source.id",
              "custom.courierDeliveryInfo",
              "status",
              "trace",
            ],
          },
          child: {
            entity: "orders",
            filters: {
              "additionalInformation.couriers": get_user_type_groups("Courier"),
              "currentStatus.tag": Env.order_status_active, //EShippinggroupStatus.transit,
            },
            fields: ["id"],
            filterInParentEntity: "orderId",
          },
        },
      }

      response = await request_post(`compound_search`, requestBody, newCancelToken())
      response = response.data.message
      responseRecords = response.records

      if (responseRecords.length <= 0) {
        setLoadingParentContainer(false)
        return
      } else {
        return responseRecords
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setLoadingParentContainer(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
      Logger.error("No results with source.id filter active:", user?.currentSources.toString())
    }
  }

  const getInfoOrderToPrint = async (orderId: string) => {
    try {
      const {
        data: { message: order },
      } = await getOrderById(orderId, newCancelToken())
      return order
    } catch (error: any) {
      Logger.error(error)
      setLoadingParentContainer(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const updateShippingGroup = async (ids: any) => {
    try {
      let resultOrdersPrint: any[] = []

      let ordersToPrint = ids.map(async (item: any) => {
        const order = await getInfoOrderToPrint(item.orderId)
        return { ...item, order }
      })

      resultOrdersPrint = await Promise.all(ordersToPrint)

      const executeOrders = resultOrdersPrint.map((item) =>
        executeShipingGroupAction({
          shippingGroupId: item.id,
          action: EShippinggroupAction.delivered,
        }),
      )

      const [resultUpdateOrders] = await Promise.all(executeOrders)

      if (resultUpdateOrders.data.code === TransactionCodeEnum.ok) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "se actualizo correctamente los SG’s",
            title: "Actualización SG’s",
            type: "success",
          },
        })

        userSearch?.dispatch({ option: "", value: "" })

        setTimeout(() => history.push("/"), 2000)
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadingParentContainer(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const attachFile = async (filesShippinGroup: FileList, shippingGroupId: any, orderId: any): Promise<boolean> => {
    const formAttachFile = new FormData()

    for (let indexFile = 0; indexFile < filesShippinGroup.length; indexFile++) {
      const file = filesShippinGroup[indexFile]
      if (file) formAttachFile.append(`$attach${indexFile}`, file)
    }

    const dataOrder = await updateOrderById(
      orderId,
      {
        custom: {
          contrato_portabilidad: "SI",
        },
      },
      newCancelToken(),
    )

    console.log(dataOrder)

    formAttachFile.append("tags", [...filesShippinGroup].map(({ name }) => name).join(","))
    const {
      data: { code },
    } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

    return code === TransactionCodeEnum.ok
  }

  const submitFilesParentContainer = async ({ filesShippinGroup }: FilesFormType) => {
    try {
      setLoadingParentContainer(true)

      const ids = await getOrdersCourier()

      const executeOrders = ids.map((item: any) =>
        attachFile(filesShippinGroup, item.id, item.orderId)
      )

      const [resultUpdateOrders] = await Promise.all(executeOrders)

      if (resultUpdateOrders) {
        updateShippingGroup(ids)
      }

    } catch (error: any) {
      setLoadingParentContainer(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    showActionCourier,
    canIuseParentContainer,
    loadingParentContainer,
    handleClickParentContainer,
    handleClickParentContainerCourier,
    submitFilesParentContainer,
  }
}

export default UseParentContainer
