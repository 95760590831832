import { Fragment } from 'react'
import { LabelSwitchStyle } from "overrides/theme/shiperto/base/components/Commons/LabelSwitch/LabelSwitch.style"
import { useTranslation } from "react-i18next"

interface LabelSwitchProps {
  id: string
  label: string
  onChange: any
  value: string
  checked?: boolean
}

const LabelSwitch = ({id, label, onChange, value, checked}: LabelSwitchProps) => {
  const { t } = useTranslation()
  
  return (
    <Fragment>
      <div className="label_switch__container">
        <div className="label_switch__cont_label">
          <label htmlFor={id} className="label_switch__label">{t(label)}</label>
        </div>
        <label className="label_switch__switch">
          <input id={id} type="checkbox" checked={checked} onChange={onChange} value={value} />
          <span className="label_switch__slider label_switch__round"></span>
        </label>
      </div>
      <style jsx>{LabelSwitchStyle}</style>
    </Fragment>
  )
}

export default LabelSwitch