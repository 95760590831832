import {useState, useEffect} from "react"
import { forwardRef } from "react"

// Import React FilePond
import { ContainerFiles } from "./Uploader.styled"
import { COUNT_MAX_FILE } from "helpers/constHelper"
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUpload = forwardRef<any, any>((props, ref) => {
  const { register, setValue, name = "filesShippinGroup" } = props
  const [files, setFiles] = useState<any[]>([])
  const [labelInput, setlabelInput] = useState<string>("Seleccionar archivo")

  useEffect(() => {
    setValue(name, files.map((file: any) => file.file))

    if(props.label !== "undefined" && files.length)
      setlabelInput(`${props.label} ${files.length} de ${COUNT_MAX_FILE}`)
    else
      setlabelInput("Seleccionar archivo")
  },
  //eslint-disable-next-line
  [files])

  return (
    <>
      <ContainerFiles>
        <FilePond
          id="file"
          ref={ref}
          {...props}
          {...register(name)}
          files={files}
          onupdatefiles={(val) => setFiles(val)}
          allowMultiple={true}
          maxFiles={3}
          labelIdle={`<span class="filepond--label-action">${labelInput}</span>`}
          dropOnPage
          dropOnElement
          dropValidation
        />
      </ContainerFiles>
    </>
  )
})

export default FileUpload
