import { FunctionComponent, useEffect, Fragment, useContext, useRef } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import Logger from "classes/Logger"
import IPage from "interfaces/IPage"
import ErrorHandled from "overrides/theme/shiperto/base/components/Commons/Error/ErrorHandler"
import SearchFormUsers from "overrides/theme/shiperto/base/components/SearchForm/SearchFormUsers"
import {
  cardUserItemStyle,
  listUsersScreenStyle,
} from "overrides/theme/shiperto/base/pages/UsersScreens/styles/ListUsersScreen.style"
import Paginate from "overrides/theme/shiperto/base/components/Commons/Pagination/Paginate"
import useUpsertUsers from "hooks/useUpsertUsers"
import UseListUsers from "hooks/UseListUsers"
import Loading from "components/Commons/Loading/Loading"
import ReactTooltip from "react-tooltip"
import Swal from "sweetalert2"
import { useEnv } from "hooks"

//icons
import user_icon_profile from "assets/icons/user_icon_alt.svg"
import edit_icon from "assets/icons/edit-icon.svg"
import trash_icon from "assets/icons/trash-icon.svg"
import { FiEye } from "react-icons/fi";

const ListUsersScreen: FunctionComponent<IPage> = (props: any) => {
  const { name } = props
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const { permissions } = useEnv()
  const refElemOrders = useRef<HTMLDivElement>(null)
  const { data, pages, page, loading, query, handleClickPage, handleClickSearch } = UseListUsers({
    groups: ["shiperto"],
    showDeleted: true,
  })
  const { loading_delete, loading_enable, deleteUserAction, enableUserAction } = useUpsertUsers({
    redirectAfter: true
  })

  useEffect(() => {
    displayHeaderRouteName.dispatch(query ? `Busqueda por: ${query}` : "Perfiles")
    //eslint-disable-next-line
  }, [query])

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  const onPressDeleteUser = (userId: string) =>
    Swal.fire({
      title: `Desea eliminar al usuario ${userId}?`,
      text: "Esta acción no se puede revertir!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EE7628",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result: any) => {
      if (result.isConfirmed) {
        deleteUserAction(userId)
      }
    })

  const onPressEnableUser = (userId: string) =>
    Swal.fire({
      title: `Desea habilitar al usuario ${userId}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#EE7628",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, habilitar!",
      cancelButtonText: "No, cancelar!",
    }).then((result: any) => {
      if (result.isConfirmed) {
        enableUserAction(userId)
      }
    })

  const CardUserItem = ({ user }: any) => (
    <Fragment>
      <ReactTooltip />
      <div
        className={
          !user?.deleted ? "lus__card_user_item_container" : "lus__card_user_item_container lus__cui_btn_border_deleted"
        }
      >
        <div className="lus__card_user_item_element">
          <img src={user_icon_profile} alt="X" />
          <p className="lus__cui_element_value lus__cui_element_username" data-tip={user?.id}>
            <span className="lus__cui_title">Nombre de usuario: </span>
            {user?.id}
          </p>
        </div>
        <div className="lus__card_user_item_element">
          <p className="lus__cui_element_value" data-tip={user?.name}>
            <span className="lus__cui_title">Nombre: </span>
            {user?.name}
          </p>
        </div>
        <div className="lus__card_user_item_element">
          <p className="lus__cui_element_value" data-tip={user?.email}>
            <span className="lus__cui_title">Correo electrónico: </span>
            {user?.email}
          </p>
        </div>
        <div className="lus__card_user_item_element">
          <p className="lus__cui_element_value" data-tip={user?.role}>
            <span className="lus__cui_title">Rol: </span>
            {user?.role}
          </p>
        </div>
        <div className="lus__card_user_item_element lus__cui_element_actions">
          <Link to={`/users/${user?.id}`} data-tip="Ver usuario">
            <FiEye color="#EE7628" size="20"/>
          </Link>
          {!user?.deleted ? (
            <>
              {permissions.get_permission("users", "action-edit") && (
                <Link to={`/users/edit/${user?.id}`} data-tip="Editar usuario">
                  <img src={edit_icon} alt="X" />
                </Link>
              )}
              {permissions.get_permission("users", "action-delete") && (
                <img src={trash_icon} alt="X" data-tip="Eliminar usuario" onClick={() => onPressDeleteUser(user?.id)} />
              )}
            </>
          ) : (
            <>
              <button className="lus__cui_btn_enable" onClick={() => onPressEnableUser(user?.id)}>
                Habilitar
              </button>
            </>
          )}
        </div>
      </div>
      <style jsx>{cardUserItemStyle}</style>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="list_users_screen__container">
        <SearchFormUsers search={query} onClickSearch={handleClickSearch} backButton />
        <div className="list_users_screen__body">
          <div className="list_users_screen__content_header">
            <p>Nombre de usuario</p>
            <p>Nombre</p>
            <p>Correo electrónico</p>
            <p>Rol</p>
            <p>Acciones</p>
          </div>
          {loading || loading_delete ? (
            <Loading defaultOpened={loading || loading_delete || loading_enable} />
          ) : !!(data || []).length ? (
            <div className="list_users_screen__content_body">
              {(data || []).map((user, index) => {
                return <CardUserItem key={index} user={user} />
              })}
            </div>
          ) : (
            <ErrorHandled orders={data} message="Sin resultados para esta busqueda" />
          )}
        </div>
        <div className="list_users_screen__container_pagination">
          {pages > 1 && (
            <div className="order-screen__paginate-container">
              <Paginate pageCount={pages} onChangePage={handleClickPage} elementFocus={refElemOrders} pageInit={page} />
            </div>
          )}
        </div>
      </div>
      <style jsx>{listUsersScreenStyle}</style>
    </Fragment>
  )
}

export default ListUsersScreen
