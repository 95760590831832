export const orderListStyle = `
  .order-list__content-group {
    width: 100%;
  }
  .order-list__group-item {
    width: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background: #E0E0E0;
    padding: 0.6em 1em;
    margin: 1em 0;
  }
  .order-list__group-item p {
    font-family: 'Montserrat';
    font-size: 0.9rem;
    color: #716F87;
  }
  .order-list__cont-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
  }
  .order-list__gv__cont-list {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1em;
    display: grid;
  }
  .order-list__lv__header-titles-container {
    display: none;
  }
  @media screen and (min-width: 768px) {
    .order-list__gv__cont-list {
      grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    }
  }
  @media screen and (min-width: 1280px) {
    .order-list__lv__header-titles-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    .order-list__lv__content-titles {
      width: calc(75% - 3em);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      text-align: left;
      font-family: 'Montserrat';
      font-size: 0.9rem;
      color: #716F87;
    }
  }
`