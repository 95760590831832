import { DateRange, Range } from "react-date-range"
import { es } from "date-fns/locale"
import { IPropsDateRangeCalendar } from "interfaces/IDateRangeCalendar"

const DateRangeCalendar = ({ range, setRange }: IPropsDateRangeCalendar) => {
  const handleChangeRange = (range: any) => {
    const { selection } = range as { selection: Range }

    setRange({
      startDate: selection.startDate,
      endDate: selection.endDate,
      key: selection.key,
    })
  }

  return (
    <div style={{ margin: '10px 0' }}>
      <DateRange
        onChange={handleChangeRange}
        moveRangeOnFirstSelection={false}
        ranges={[range]}
        rangeColors={["#EE7628"]}
        locale={es}
        editableDateInputs={true}
      />
    </div>
  )
}

export default DateRangeCalendar
