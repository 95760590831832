import { FunctionComponent, useRef, Fragment, useEffect, useState, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import IPage from "interfaces/IPage"
import { ISearchResults } from "interfaces/ISearchResults"
import { RouteComponentProps, useHistory, useParams } from "react-router-dom"
import ErrorHandled from "overrides/theme/entel/base/components/Commons/Error/ErrorHandler"
import Paginate from "overrides/theme/entel/base/components/Commons/Pagination/Paginate"
import SearchForm from "overrides/theme/entel/base/components/SearchForm/SearchForm"
import OrderList from "overrides/theme/entel/base/components/Core/Order/OrderList"
import { searchResultsScreenStyle } from "overrides/theme/entel/base/pages/SearchResults/SearchResultsScreen.style"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import { useEnv } from "hooks"
import usePostMiddlewareQuery from "../../hooks/v4/usePostMiddlewareQuery"
import { formatDate } from "helpers/FormatDateHelper"
import { OptionsFilterEnum } from "overrides/theme/entel/base/enums/OptionsFilterEnum"

const SearchResultsScreen: FunctionComponent<IPage & RouteComponentProps<ISearchResults>> = () => {
  const { query, optionFilter }: any = useParams()
  const { permissions } = useEnv()
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const history = useHistory()
  const [data, set_data] = useState(null)
  const [pages, set_pages] = useState(0)
  const [page, set_page] = useState(0)
  const [loading, set_loading] = useState(false)
  const refElemOrders = useRef<HTMLDivElement>(null)
  const { post_middleware_query_action } = usePostMiddlewareQuery()
  const formatString = "dd MMM yyyy"

  const key_to_filter =
    optionFilter !== OptionsFilterEnum.query ? optionFilter : query.includes("SG") ? "id" : "orderId"

  useEffect(() => {
    displayHeaderRouteName.dispatch(`Busqueda por: ${query}`)
    //eslint-disable-next-line
  }, [query])

  useEffect(() => {
    const post_request = async () => {
      set_loading(true)

      let params = {
        skipRecords: page * 10,
        maxRecords: 10,
        entityType: "ShippingGroup",
      }

      params = Object.assign(params, { filter: { [key_to_filter]: query } })

      const res: any = await post_middleware_query_action(params)

      const records = res?.data?.message?.records

      const groupedRecords = Object.entries(
        records.reduce((acc: any, record: any) => {
          const dateParsed = formatDate(record?.orderCreationDate, formatString)

          if (!acc[dateParsed]) {
            acc[dateParsed] = []
          }
          acc[dateParsed].push(record)

          return acc
        }, {}),
      ).reduce((acc: any, [key, value]: any) => {
        acc[key] = value
        return acc
      }, {})

      set_pages(res.data.message.maxPage)
      set_data(groupedRecords)
      set_loading(false)
    }

    post_request()
    //eslint-disable-next-line
  }, [query, optionFilter, page])

  const handleClickPage = (pageSeleted: number) => set_page(pageSeleted)

  const handleClickSearch = (e: any) => {
    if (!!e.query) history.push(`/search/${e.optionFilter}/${e.query}`)
  }

  return (
    <Fragment>
      <div className="search-results-screen__container">
        {permissions.get_permission("global", "block-search-results") && (
          <SearchForm
            search={query}
            optionFilter={optionFilter}
            onClickSearch={handleClickSearch}
            isLoading={loading}
            backButton={true}
          />
        )}
        {loading ? (
          <Loading defaultOpened={loading} />
        ) : (
          <>
            {Object.keys(data || {}).length ? (
              <div>
                <div className="search-results__order-list-container">
                  <OrderList ordersGroup={data} currentDisplayList="grid" showStatus />
                </div>
                {pages > 1 && (
                  <div className="order-screen__paginate-container">
                    <Paginate
                      pageCount={pages}
                      onChangePage={handleClickPage}
                      elementFocus={refElemOrders}
                      pageInit={page}
                    />
                  </div>
                )}
              </div>
            ) : (
              <ErrorHandled orders={data} message="Sin resultados para esta busqueda" />
            )}
          </>
        )}
      </div>
      <style jsx>{searchResultsScreenStyle}</style>
    </Fragment>
  )
}

export default SearchResultsScreen
