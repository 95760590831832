import {useState, useEffect} from "react"
import useUserPermissions from "hooks/UseUserPermissions"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import { useEnv } from "hooks"

const useHideActionButtons = () => {
  const [hideConfirmActions, setHideConfirmActions] = useState(false)
  const [hideDeliveryActions, setHideDeliveryActions] = useState(false)
  const [hidePackageActions, setHidePackageActions] = useState(false)
  const [hidePickupActions, setHidePickupActions] = useState(false)
  const [hideTransitActions, setHideTransitActions] = useState(false)
  const [isStore, setIsStore] = useState(false)
  const [client] = useEnv().multiclient.get_user_env()
  const { permission } = useUserPermissions({ resources: [
    ResourcePermissionsEnum.hideConfirmActions,
    ResourcePermissionsEnum.hideDeliveryActions,
    ResourcePermissionsEnum.hidePackageActions,
    ResourcePermissionsEnum.hidePickupActions,
    ResourcePermissionsEnum.hideTransitActions,
    ResourcePermissionsEnum.storeCd,
  ]})
  useEffect(() => {
    if (permission) {
      setHideConfirmActions(permission[ResourcePermissionsEnum.hideConfirmActions])
      setHideDeliveryActions(permission[ResourcePermissionsEnum.hideDeliveryActions])
      setHidePackageActions(permission[ResourcePermissionsEnum.hidePackageActions])
      setHidePickupActions(permission[ResourcePermissionsEnum.hidePickupActions])
      setHideTransitActions(permission[ResourcePermissionsEnum.hideTransitActions])
      setIsStore(permission[ResourcePermissionsEnum.storeCd])
    }
  }, [permission])

  const setHideAllActions = (action: boolean) => {
    setHideConfirmActions(action)
    setHideDeliveryActions(action)
    setHidePackageActions(action)
    setHidePickupActions(action)
    setHideTransitActions(action)
  }

  const updateVisibilityButtonActions = (sourceIdSg: any, targetIdSg: any) => {
    if (client === "privilege" && isStore ) {
      if ([sourceIdSg, targetIdSg].includes("90")) {
        setHideAllActions(false)
      } else {
        setHideAllActions(true)
      }
    }
  }
  
  return {
    hideConfirmActions,
    hideDeliveryActions,
    hidePackageActions,
    hidePickupActions,
    hideTransitActions,
    updateVisibilityButtonActions
  }
}

export default useHideActionButtons