import { useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "context/context/GlobalContext"
import { OrderContext } from "context/context"
import Logger from "classes/Logger"
import { attachFileShippingGroup, executeShipingGroupAction } from "services/ShippingGroupService"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { ProcessShippingGroupActionType } from "types/OrderType"

//eslint-disable-next-line
const attachFile = async (filesShippinGroup: FileList, shippingGroupId: any): Promise<boolean> => {
  const formAttachFile = new FormData()

  for (let indexFile = 0; indexFile < filesShippinGroup.length; indexFile++) {
    const file = filesShippinGroup[indexFile]
    if (file) formAttachFile.append(`$attach${indexFile}`, file)
  }

  formAttachFile.append("tags", [...filesShippinGroup].map(({ name }) => name).join(","))
  const {
    data: { code },
  } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

  return code === TransactionCodeEnum.ok
}
//eslint-disable-next-line
const actionOrder = async (
  shippingGroupId: any,
  action: any,
  setFinishedShippingGroupAction: any,
  notification: any,
) => {
  const {
    data: { code },
  } = await executeShipingGroupAction({
    shippingGroupId,
    action: action,
  })

  if (code === TransactionCodeEnum.ok) {
    setFinishedShippingGroupAction(true)

    notification?.dispatch({
      type: "ADD_NOTIFICATION",
      payload: {
        message: "Orden modificada correctamente",
        title: action,
        type: "success",
      },
    })
  }
}

const useOrderAction = ({
  shippingGroupId,
  setFinishedShippingGroupAction,
  order,
  shippinGroupDetail,
}: ProcessShippingGroupActionType) => {
  const { state: orderState } = useContext(OrderContext)  
  const action: any = orderState.action
  const nextPage: any = orderState.next_page
  console.log(nextPage)
  const [loadingTransit, setLoadingTransit] = useState(false)
  const { notification, errorHander } = useContext(GlobalContext)
  const history = useHistory()

  const submitActionOrder = async (props: any) => {
    try {
      const { filesShippinGroup } = props
      setLoadingTransit(true)

      let successAttachFiles: boolean = true

      if (filesShippinGroup) {
        successAttachFiles = await attachFile(filesShippinGroup, shippingGroupId)
      }

      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error al cargar los archivo",
            title: action,
            type: "danger",
          },
        })

        return
      }

      await actionOrder(shippingGroupId, action, setFinishedShippingGroupAction, notification)

      setLoadingTransit(false)
      setTimeout(() => history.push(`/order/${nextPage}/${shippingGroupId}/${order?.id}`), 1000)
    } catch (error: any) {
      setLoadingTransit(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    loadingTransit,
    submitActionOrder,
  }
}

export default useOrderAction
