import Checkbox from "components/Commons/CheckBox"

// TODO: Make interface
const Field = (props: any) => {
  let { attrs, attrDefault, label, value, type, children } = props
  let template: any

  if (type === "text" && !children) {
    template = (
      <p className="field">
        <span className="relevant-title">{label}:</span>
        <span>{value}</span>
      </p>
    )
  }

  if (type === "text" && children) {
    template = (
      <>
        <p className="field complex">
          <span className="relevant-title">{label}:</span>
          <span>{value}</span>
        </p>
        <div className="field-complex">
          {Object.entries(children).map((item: any) => {
            const _item = item[1]
            return (
              <p className="field" key={_item["input"]}>
                <span className="relevant-title">{_item["label"]}:</span>
                <span>{_item["value"]}</span>
              </p>
            )
          })}
        </div>
      </>
    )
  }

  if (type === "check") {
    // Refactor next line
    value = typeof value === "undefined" ? attrDefault["default"] : value
    template = (
      <div className="field">
        <span className="relevant-title">{label}</span>
        {attrs &&
          attrs.map((item: any) => (
            <Checkbox
              key={item.id}
              id={item.id}
              name={item.name}
              readOnly={!item.canEdit}
              checked={value === item.label}
              label={item.label}
            />
          ))}
      </div>
    )
  }

  return value && template
}

export default Field
