import { Suspense, useContext } from "react"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import useUserPermissions from "hooks/UseUserPermissions"
import { IconSpinner } from "theme/common/Icon.styled"
import { OrderDetailType } from "types/OrderType"
import React from "react"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { AuthContext } from "context/context/AuthContext"
import { clients } from "webapp-clients/Clients"

const InfoElocker = React.lazy(() => import("components/OrderDetail/ElockerQR/InfoElocker"))

const CanceledScreen = ({ shippinGroupDetail, order, shippingGroupId }: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const documents = shippinGroupDetail?.custom?.documents

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.qrcancel2] })
  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList itemsOrder={itemsOrder} groupPicking={false} titleMain={"Orden cancelada"} />
          {elockerInfo && elockerInfo.isElocker && (
            <Suspense
              fallback={
                <>
                  <IconSpinner variant="big" className="content-center" />
                  <p className="padding-content">Cargando información Elocker</p>
                </>
              }
            >
              <InfoElocker
                codigoCancelacion={permission[ResourcePermissionsEnum.qrcancel2] ? elockerInfo.codigoCancelacion : null}
                puerta={elockerInfo.puerta}
              />
            </Suspense>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(CanceledScreen)
