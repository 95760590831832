import { useState, useEffect } from "react"
import { IImage } from "interfaces/IImage"
import ImageStyled from "./Image.styled"
import { TypeVariation4 } from "types/CommonsTypes"
import Skeleton from "react-loading-skeleton"
import Logger from "classes/Logger"

const Img = ({ url, alt }: IImage) => {
  const [loadUrl, setLoagUrl] = useState<TypeVariation4>()

  useEffect(() => {
    if (!url) return

    const img: HTMLImageElement = new Image()
    img.src = url

    function loadImag() {
      setLoagUrl(url)
    }

    function errorLoad() {
      Logger.error("Error load image")
    }

    img.addEventListener("load", loadImag)
    img.addEventListener("error", errorLoad)

    return () => {
      img.removeEventListener("load", loadImag)
      img.removeEventListener("eror", errorLoad)
    }
  }, [url])

  return (
    <>
      {loadUrl ? (
        <ImageStyled alt={alt} src={loadUrl} loading="lazy" />
      ) : (
        <Skeleton height={180} width={100} />
      )}
    </>
  )
}

export default Img
