// import axiosClient from "config/axiosClient"
import { IResponseService } from "interfaces/IResponseService"
import axios, { AxiosResponse, CancelToken } from "axios"
// const clientAxios = axiosClient("OOM")

export const updateShippingGroupStateCustom = async (
  env: string,
  token: any,
  body: any,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<string>>> => {
  // const client = clientAxios.getClient()
  const urls: {[key:string]: string} = {
    dev: "https://g5iqn774i0.execute-api.us-east-2.amazonaws.com/dev/v3/shippinggroup_update_state",
    uat: "https://bsg2qvrjm5.execute-api.us-east-2.amazonaws.com/uat/v3/shippinggroup_update_state"
  }
  const response = await axios.post<IResponseService<string>>(urls[env], body, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": token
    },
    cancelToken,
  })

  return response
}
