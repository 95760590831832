import { useState, useEffect, useContext, useRef } from "react"
import { getItemsQuantity } from "services/ItemsService"
import { AuthContext } from "context/context/AuthContext"
import Logger from "classes/Logger"
import { GlobalContext } from "context/context"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useCancelToken from "./UseCancelToken"
import { IProcessItem } from "interfaces/IItemsOrder"
import { TODAY } from "helpers/constHelper"
import { createOrderService } from "services/OrderServices"
import { executeShipingGroupAction } from "services/ShippingGroupService"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import Modal from "components/Commons/Modal/Modal"

const UseTransfer = () => {
	
	const [source, setSource] = useState({
    current: "",
		target: "WWH",
    options: [{
			id: "",
			value: "",
		}],
		show: false,
		disabled: false
	})

	const [transfer, setTransfer] = useState({
    loading: false,
		serial: {
			show: false,
		},
		error: {
			form: ""
		},
		data: [],
		item: {
			sku: "",
			name: "",
			quantity: null,
			categories: [],
		},
    modal: {
      code: "",
      description: "",
      available: [],
    },
		channel: "omnicanal",
		creating: false,
		success: false,
		idSG: "",
		idOrden: "",
	})

	const {
		state: { user },
	} = useContext(AuthContext)

	const refModalError = useRef<React.ElementRef<typeof Modal>>(null)

	const { notification, errorHander } = useContext(GlobalContext)
	const { isCancel, newCancelToken } = useCancelToken()

	const get_user_sources = () => {

    const sources = user?.groups

		const values:any = sources?.filter((item: any) => item.type === "Source").map((item: any) => {
			return ({id: item.id, value: item.id})
		})

		setSource(source => ({ ...source, options: values }))
  }

	const handleChangeSources = (value: string) => {
		setSource(source => ({ ...source, current: value, disabled: true }))
  }

	const validateSkuProductList = (sku: string) => {
		const _data: Array<IProcessItem>  = transfer.data
    const found:any = _data.find(element => element.sku === sku)

		return (typeof found !== 'undefined') ? true : false
  }

	const submitAddProduct = async (data: any) => {
		try {
			setTransfer(transfer => ({ ...transfer, error: {...transfer.error, ...{ form: "" }}}))

			const product = validateSkuProductList(data.sku)

			if(product) {
				setTransfer(transfer => ({ ...transfer, error: {...transfer.error, ...{ form: "Este producto ya fue agregado" }}}))
			}else{
				const {
					data: { message: item },
				} = await getItemsQuantity([data.sku], newCancelToken(), transfer.channel)

				if (item.records.length) {
					const _categories:any = item.records[0].categories
					const _quantity:any = parseInt(data.quantity)

					setTransfer(transfer => ({ 
						...transfer,
						serial: { show: true },
						item: { 
							sku: data.sku,
							name: item.records[0].name,
							quantity: _quantity,
							categories: _categories,
						}
					}))
				} else {
					setTransfer(transfer => ({ ...transfer, error: {...transfer.error, ...{ form: "El SKU ingresado no existe" }}}))
				}
			}
			
			setTransfer(transfer => ({ ...transfer, loading: false }))

		} catch (error: any) {
      Logger.error(error)
			setTransfer(transfer => ({ ...transfer, loading: false }))
      if (isCancel(error)) return

      errorHander?.dispatch({
        hasError: true,
        code: error.response?.status || TransactionCodeEnum.server,
      })
    }
	}

	const onErrorAddProduct = (errors: any) => {
    const error:any = Object.values(errors)
		setTransfer(transfer => ({ ...transfer, error: {...transfer.error, ...{ form: error[0].message }}}))
  }

	const confirmProcessProduct = (product: any) => {
		const _data:any = [...transfer.data, product]

		setTransfer(transfer => ({
			...transfer,
			data: _data,
			serial: { show: false },
			item: {
				sku: "",
				name: "",
				quantity: null,
				categories: [],
			}
		}))
		
		notification?.dispatch({
			type: "ADD_NOTIFICATION",
			payload: {
				message: "Se agregó el producto correctamente",
				title: TODAY,
				type: "success",
			},
		})
	}

	const confirmDeleteProduct = (sku: string) => {
		const _data: Array<IProcessItem>  = transfer.data
    const found:any = _data.filter(element => element.sku !== sku)

		setTransfer(transfer => ({ ...transfer, data: found }))
	}

	const getItems = () => {
		const items: Array<IProcessItem>  = transfer.data
		
		const _items = items.map((item) => {
			return {
				sku: item.sku,
				name: item.name,
				quantity: item.quantity,
			}
		});

		return _items
	}

	const getItemsWithSerie = () => {
		const items: Array<IProcessItem>  = transfer.data
		
		const _items = items.map((item) => {
			return {
				sku: item.sku,
				name: item.name,
				quantity: item.quantity,
				custom: item.custom,
			}
		});

		return _items
	}

	const handleCreateOrder = async () => {
		try {
			setTransfer(transfer => ({ ...transfer, creating: true}))

			const bodyOrder = {
				shippingType: "TR",
				channel: transfer.channel,
				custom: {
					origin: "webapp",
					generateGuia: true,
					reason: "Transferencia entre ORG",
				},
				items: getItems(),
				source: source.current,
				target: source.target,
			}

			const {
				data: { code: codeOrder, message: responseOrder, },
			} = await createOrderService(transfer.channel, bodyOrder, newCancelToken())

			if (codeOrder === TransactionCodeEnum.ok) {

				const _idSG = responseOrder[0].id
				const _idOrden = responseOrder[0].orderId

				const bodyAccept = {
					seller: {
						id: "1",
					},
					items: getItemsWithSerie(),
				}
				
				const {
					data: { code: codeAccept },
				} = await executeShipingGroupAction({
					action: EShippinggroupAction.accept,
					requestBody: bodyAccept,
					shippingGroupId: _idSG,
				})

				if (codeAccept === TransactionCodeEnum.ok) {
					const {
						data: { code: codePacked },
					} = await executeShipingGroupAction({
						action: EShippinggroupAction.packed,
						requestBody: {},
						shippingGroupId: _idSG,
					})

					if (codePacked === TransactionCodeEnum.ok) {
						const {
							data: { code: codeShipped },
						} = await executeShipingGroupAction({
							action: EShippinggroupAction.shipped,
							requestBody: {},
							shippingGroupId: _idSG,
						})

						if (codeShipped === TransactionCodeEnum.ok) {
							notification?.dispatch({
								type: "ADD_NOTIFICATION",
								payload: {
									message: "Se creó la orden correctamente",
									title: TODAY,
									type: "success",
								},
							})

							setTransfer(transfer => ({
								...transfer,
								creating: false,
								success: true,
								idSG: _idSG,
								idOrden: _idOrden,
							}))
						}
					}
				}
			}else{
				setTransfer(transfer => ({
          ...transfer,
          creating: false,
          modal: {
            ...transfer.modal,
            ...{ code: responseOrder.error },
            ...{ available: responseOrder?.available[0].items.length ? responseOrder?.available[0].items : [] },
          },
        }))
				
				refModalError.current?.open()
			}
		} catch (error: any) {
			setTransfer(transfer => ({ ...transfer, creating: false }))

			Logger.error(error)
      if (isCancel(error)) return
      
      if (error.response?.status === 500) {
				setTransfer(transfer => ({
          ...transfer,
          modal: {
            ...transfer.modal,
            ...{ code: error.response?.data?.message }
          }
        }))
      } else {
				setTransfer(transfer => ({
          ...transfer,
          modal: {
            ...transfer.modal,
            ...{ code: error.response?.status || TransactionCodeEnum.server }
          }
        }))
      }

      refModalError.current?.open()
    }
	}

	const handleNewOrder = () => {
		setTransfer(transfer => ({
			...transfer,
			data: [],
			idSG: "",
			idOrden: "",
			success: false,
		}))

		if(source.show) {
			setSource(source => ({
				...source,
				current: "",
				disabled: false,
				options: [],
			}))
			get_user_sources()
		}
	}

	useEffect(() => {
    const sources:any = user?.currentSources
    if(sources?.length > 1) {
			setSource(source => ({ ...source, show: true }))
			get_user_sources()
		}else{
			setSource(source => ({ ...source, current: sources[0] }))
		}
    //eslint-disable-next-line
  }, [user?.currentSources])

	/*
  useEffect(() => {
   	//console.log(transfer)
		//console.log(source)
  }, [transfer])
  */
	

	return {
		source,
		transfer,
		handleChangeSources,
		submitAddProduct,
		onErrorAddProduct,
		confirmProcessProduct,
		confirmDeleteProduct,
		handleCreateOrder,
		handleNewOrder,
		refModalError,
	}
}
  
export default UseTransfer
