import styled, { css } from "styled-components"
import { displays } from "theme/base/Variables"
import { NavLinkStyled } from "theme/common/Link.styled"

const selectOptionParent = css`
  background: ${({ theme }) => theme.palette.grey85};
  cursor: pointer;
`
const selectOptionChild = css`
  background: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
`

export const SideBarLink = styled(NavLinkStyled)`
  ${displays.flexBetween}
  height: 40px;
  padding: 0.5rem;
  font-weight: 600;

  &.active-option-parent {
    ${selectOptionParent}
  }

  &:hover {
    ${selectOptionParent}
  }

  & .option-parent {
    ${displays.flexCenter}
    gap: 1rem;
  }
`

export const DropdownLink = styled(NavLinkStyled)`
  ${displays.flexBase}
  min-height: 25px;
  text-decoration: none;
  gap: 1rem;
  font-size: 0.8rem;
  padding: 0.6rem;
  position: relative;

  &.active-option-child {
    ${selectOptionChild}
  }

  &:hover {
    ${selectOptionChild}
  }

  & .count-number-intem {
    position: absolute;
    right: 8px;
  }
`
